import { IdAndValue } from 'ufinet-web-functions'
import { MapsClickEvent } from '../events/MapsClickEvent'
import { MapsClickEventType } from '../events/MapsClickEventType'
import { MapMarkerType } from './MapMarkerType'

export class MarkerClickEvent extends MapsClickEvent {
	origin: IdAndValue<string, google.maps.Marker> & { type: MapMarkerType }
	constructor(input: {
		origin: IdAndValue<string, google.maps.Marker> & { type: MapMarkerType }
		nativeEvent: google.maps.MapMouseEvent
	}) {
		super({ nativeEvent: input.nativeEvent, type: MapsClickEventType.MARKER })
		this.origin = input.origin
	}
}
