import { useFormik } from 'formik'
import { FC, useCallback, useContext, useMemo, useRef } from 'react'
import { useIntl } from 'react-intl'
import {
	AreaSelect,
	AreaSelectHandle,
	ClientSelect,
	ClientSelectHandle,
	CountrySelect,
	emptyUfinetSelectOption,
	IUfinetSelectOption,
	UfinetActionButton,
	UfinetActionButtonHandle,
	UfinetInput,
	UfinetSelect,
} from 'ufinet-web-components'
import {
	AreaType,
	AuthContext,
	foCostsService,
	InfrastructureTypeOptions,
	onFormikChanges,
	onFormikNumberChanges,
	ZoneTypeOptions,
} from 'ufinet-web-functions'

import * as Yup from 'yup'

type Props = {
	hideModal: Function
	findCosts: Function
}

type FOModalData = {
	countrySelect: IUfinetSelectOption
	areaSelect: IUfinetSelectOption & { color: string }
	clientSelect: IUfinetSelectOption
	infraSelect: IUfinetSelectOption
	zoneSelect: IUfinetSelectOption
	minDistance: string
	maxDistance: string
	opticalFactor: string
	punctualCost: string
	punctualCostMO: string
	punctualCostPerMeter: string
	punctualCostPerMeterMO: string
}

const NewFOModalBody: FC<Props> = ({ findCosts, hideModal }) => {
	const intl = useIntl()
	const areaRef = useRef<AreaSelectHandle>(null)
	const clientRef = useRef<ClientSelectHandle>(null)
	const actionButtonRef = useRef<UfinetActionButtonHandle>(null)

	const authData = useContext(AuthContext)
	const _FOCostsService = useMemo(() => foCostsService(authData, intl), [authData, intl])

	const sendData = (value: FOModalData) => {
		actionButtonRef.current?.changeActionStatus(true)
		_FOCostsService
			.create({
				countryId: value.countrySelect.value,
				countryName: value.countrySelect.label,
				networkAreaName: value.areaSelect.value,
				client: value.clientSelect.value,
				zoneType: value.zoneSelect.value,
				infrastructureType: value.infraSelect.value,
				minimalDistance: value.minDistance,
				maximumDistance: value.minDistance,
				opticalFactor: value.opticalFactor,
				punctualCost: value.punctualCost,
				laborPunctualCost: value.punctualCostMO,
				punctualCostPerMeter: value.punctualCostPerMeter,
				laborPunctualCostPerMeter: value.punctualCostPerMeterMO,
			})
			.then(() => {
				findCosts()
				hideModal()
			})
			.catch(() => {})
			.finally(() => actionButtonRef.current?.changeActionStatus(false))
	}

	const dataFormSchema = Yup.object().shape({
		countrySelect: Yup.object().shape({ label: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })) }),
		clientSelect: Yup.object().shape({ label: Yup.string().notRequired() }),
		infraSelect: Yup.object().shape({ label: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })) }),
		zoneSelect: Yup.object().shape({ label: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })) }),
		areaSelect: Yup.object().shape({ label: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })) }),
		minDistance: Yup.number()
			.test('', intl.formatMessage({ id: 'ERROR.POSITIVE' }), (value: any) => value >= 0)
			.required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
		maxDistance: Yup.number()
			.test('', intl.formatMessage({ id: 'ERROR.POSITIVE' }), (value: any) => value >= 0)
			.required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
		opticalFactor: Yup.number()
			.test('', intl.formatMessage({ id: 'ERROR.POSITIVE' }), (value: any) => value >= 0)
			.required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
		punctualCost: Yup.number()
			.test('', intl.formatMessage({ id: 'ERROR.POSITIVE' }), (value: any) => value >= 0)
			.required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
		punctualCostMO: Yup.number()
			.test('', intl.formatMessage({ id: 'ERROR.POSITIVE' }), (value: any) => value >= 0)
			.required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
		punctualCostPerMeter: Yup.number()
			.test('', intl.formatMessage({ id: 'ERROR.POSITIVE' }), (value: any) => value >= 0)
			.required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
		punctualCostPerMeterMO: Yup.number()
			.test('', intl.formatMessage({ id: 'ERROR.POSITIVE' }), (value: any) => value >= 0)
			.required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
	})

	const formInitialValues = useMemo<FOModalData>(
		() => ({
			countrySelect: emptyUfinetSelectOption,
			areaSelect: { ...emptyUfinetSelectOption, color: '' },
			clientSelect: emptyUfinetSelectOption,
			infraSelect: emptyUfinetSelectOption,
			zoneSelect: emptyUfinetSelectOption,
			minDistance: '',
			maxDistance: '',
			opticalFactor: '',
			punctualCost: '',
			punctualCostMO: '',
			punctualCostPerMeter: '',
			punctualCostPerMeterMO: '',
		}),
		[]
	)

	const formik = useFormik<FOModalData>({
		initialValues: formInitialValues,
		validationSchema: dataFormSchema,
		onSubmit: sendData,
		validateOnChange: false,
		validateOnBlur: false,
	})

	const onCountryChange = (country?: IUfinetSelectOption) => {
		onChange(formik, 'countrySelect')(country || emptyUfinetSelectOption)
		onChange(formik, 'areaSelect')(emptyUfinetSelectOption)
		onChange(formik, 'clientSelect')(emptyUfinetSelectOption)
		if (country) {
			areaRef.current?.fillSelect([country.value])
			clientRef.current?.fillSelect([country.value], [])
		}
	}
	const onChange = useCallback(onFormikChanges, [])
	const onNumberChange = useCallback(onFormikNumberChanges, [])

	return (
		<form onSubmit={formik.handleSubmit} className="container p-15 h-100 d-flex flex-column justify-content-center">
			<div className="row">
				<CountrySelect
					requiredIcon
					className="col-4"
					onChange={(c) => onCountryChange(c as IUfinetSelectOption)}
					value={formik.values.countrySelect}
					error={formik.errors.countrySelect?.label}
				/>
				<AreaSelect
					ref={areaRef}
					requiredIcon
					className="col-4"
					areaType={AreaType.OPTICAL_FIBER_AREA}
					value={formik.values.areaSelect}
					error={formik.errors.areaSelect?.label}
					isDisabled={!formik.values.countrySelect.value}
					onChange={onChange(formik, 'areaSelect')}
				/>

				<ClientSelect
					ref={clientRef}
					requiredIcon={false}
					className="col-4"
					value={formik.values.clientSelect}
					error={formik.errors.clientSelect?.label}
					isDisabled={!formik.values.countrySelect.value}
					onChange={onChange(formik, 'clientSelect')}
				/>
			</div>
			<div className="row pt-4">
				<UfinetSelect
					requiredIcon
					className="col-6"
					tooltipTitle={intl.formatMessage({ id: 'COST.INFRASTRUCTURETYPE_2' })}
					labelTitle={intl.formatMessage({ id: 'COST.INFRASTRUCTURETYPE_2' })}
					isClearable
					error={formik.errors.infraSelect?.label}
					onChange={onChange(formik, 'infraSelect')}
					value={formik.values.infraSelect?.label && formik.values.infraSelect}
					options={InfrastructureTypeOptions}
					placeholder={intl.formatMessage({ id: 'COST.INFRASTRUCTURETYPE' })}
				/>
				<UfinetSelect
					requiredIcon
					className="col-6"
					tooltipTitle="Ámbito"
					labelTitle="Ámbito"
					error={formik.errors.zoneSelect?.label}
					isClearable
					onChange={onChange(formik, 'zoneSelect')}
					value={formik.values.zoneSelect?.label && formik.values.zoneSelect}
					options={ZoneTypeOptions}
					placeholder="Ámbito"
				/>
			</div>
			<div className="row pt-4">
				<UfinetInput
					requiredIcon
					error={formik.errors.minDistance}
					type="decimal"
					labelTitle="Distancia mínima"
					tooltipTitle="Distancia mínima"
					className="col-6"
					onChange={onNumberChange(formik, 'minDistance')}
					value={formik.values.minDistance}
					solid={false}
				/>
				<UfinetInput
					requiredIcon
					error={formik.errors.maxDistance}
					type="decimal"
					labelTitle="Distancia máxima"
					tooltipTitle="Distancia máxima"
					className="col-6"
					onChange={onNumberChange(formik, 'maxDistance')}
					value={formik.values.maxDistance}
					solid={false}
				/>
			</div>
			<div className="row pt-4">
				<UfinetInput
					requiredIcon
					error={formik.errors.opticalFactor}
					type="decimal"
					labelTitle="Factor óptico"
					tooltipTitle="Factor óptico"
					className="col-6"
					onChange={onNumberChange(formik, 'opticalFactor')}
					value={formik.values.opticalFactor}
					solid={false}
				/>
				<UfinetInput
					requiredIcon
					error={formik.errors.punctualCost}
					type="decimal"
					labelTitle="Costo puntual"
					tooltipTitle="Costo puntual"
					className="col-6"
					onChange={onNumberChange(formik, 'punctualCost')}
					value={formik.values.punctualCost}
					solid={false}
				/>
			</div>
			<div className="row pt-4">
				<UfinetInput
					requiredIcon
					error={formik.errors.punctualCostMO}
					type="decimal"
					labelTitle="Costo puntual MO"
					tooltipTitle="Costo puntual MO"
					className="col-6"
					onChange={onNumberChange(formik, 'punctualCostMO')}
					value={formik.values.punctualCostMO}
					solid={false}
				/>
				<UfinetInput
					requiredIcon
					error={formik.errors.punctualCostPerMeter}
					type="decimal"
					labelTitle="Costo puntual por Metro"
					tooltipTitle="Costo puntual por Metro"
					className="col-6"
					onChange={onNumberChange(formik, 'punctualCostPerMeter')}
					value={formik.values.punctualCostPerMeter}
					solid={false}
				/>
			</div>
			<div className="row pt-4">
				<UfinetInput
					requiredIcon
					error={formik.errors.punctualCostPerMeterMO}
					type="decimal"
					labelTitle="Costo puntual por Metro MO"
					tooltipTitle="Costo puntual por Metro MO"
					className="col-6"
					onChange={onNumberChange(formik, 'punctualCostPerMeterMO')}
					value={formik.values.punctualCostPerMeterMO}
					solid={false}
				/>
			</div>
			<UfinetActionButton ref={actionButtonRef} className="mt-10" content={intl.formatMessage({ id: 'SAVE' })} />
		</form>
	)
}
export { NewFOModalBody }
