import { SelectOptionMapper, SelectOptionTranslateMapper } from 'modules/common/domain/mapper/SelectOptionMapper'
import { useQuery } from 'react-query'
import { IdAndValue, useTranslator } from 'ufinet-web-functions'
import { CollocationRepository } from '../repository/CollocationRepository'

export interface CollocationListPrice {
	corporateGroupId: string
	destinationLatitude: number
	destinationLongitude: number
	emplacementTypeId: number
	collocationUnitId: number
}

export const useEmplacementTypesQuery = (repository: CollocationRepository) => {
	const { getEmplacementTypes } = repository
	const translate = useTranslator()
	return useQuery(`emplacement-types-query`, () => getEmplacementTypes(), {
		select: (data: IdAndValue<number, string>[]) =>
			SelectOptionTranslateMapper(data, translate, 'SERVICE.COLLOCATION.EMPLACEMENT_TYPES'),
	})
}

export const useCollocationUnitsQuery = (repository: CollocationRepository) => {
	const { getCollocationUnits } = repository
	const translate = useTranslator()
	return useQuery(`collocotaion-units-query`, () => getCollocationUnits(), {
		select: (data: IdAndValue<number, string>[]) =>
			SelectOptionTranslateMapper(data, translate, 'SERVICE.COLLOCATION.COLLOCATION_UNITS'),
	})
}

export const useCollocationQuantitiesQuery = (
	isEnabled = true,
	repository: CollocationRepository,
	collocationListPrice: CollocationListPrice
) => {
	const { getCollocationQuantities } = repository
	const { corporateGroupId, collocationUnitId, destinationLatitude, destinationLongitude, emplacementTypeId } =
		collocationListPrice
	return useQuery(
		[
			`collocation-quantities-query-${corporateGroupId}`,
			`collocationUnitId-${collocationUnitId}`,
			`emplacementTypeId-${emplacementTypeId}`,
			`destinationLatitude-${destinationLatitude}`,
			`destinationLongitude-${destinationLongitude}`,
		],
		() => getCollocationQuantities(collocationListPrice),
		{
			enabled: isEnabled,
			select: SelectOptionMapper,
		}
	)
}
