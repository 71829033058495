import { FormikProps } from 'formik'
import { CapacityRepository } from 'modules/product/repository/CapacityRepository'
import { FormBuilder } from 'pages/quotation/individual/components/FormBuilder'
import { FC } from 'react'
import { useCapacityServiceForm } from './hooks/useCapacityServiceForm'

type CapacityServiceFormProps = {
	formik: FormikProps<any>
	capacityRepository: CapacityRepository
}

export const CapacityServiceForm: FC<CapacityServiceFormProps> = ({ formik, capacityRepository }) => {
	const { formFields, translate, onChange } = useCapacityServiceForm(capacityRepository)

	return (
		<div className="row col-11">
			{Object.keys(formFields).map((field) => {
				return (
					<FormBuilder
						key={field}
						formField={formFields[field]}
						translate={translate}
						formik={formik}
						onChange={onChange}
					/>
				)
			})}
		</div>
	)
}
