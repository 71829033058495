import { MapMarkerType } from 'modules/google-maps/markers/MapMarkerType'
import { IdAndValue } from 'ufinet-web-functions'
import { MapsInfoWindowEventType } from './MapsInfoWindowEventType'

export abstract class MapsInfoWindowEvent {
	type: MapsInfoWindowEventType
	marker: IdAndValue<string, google.maps.Marker> & { type: MapMarkerType }
	infoWindow: google.maps.InfoWindow

	constructor(input: {
		type: MapsInfoWindowEventType
		marker: IdAndValue<string, google.maps.Marker> & { type: MapMarkerType }
		infoWindow: google.maps.InfoWindow
	}) {
		this.type = input.type
		this.marker = input.marker
		this.infoWindow = input.infoWindow
	}
}
