import { useEffect } from 'react'
import { useModal } from 'ufinet-web-functions'

type PrefeasibilityModalHook = {
	isPrefeasibilityModalOpen: boolean
	hidePrefeasibilityModal: () => void
}

export const usePrefeasibilityModal: (openModalCallback: (callback: () => void) => void) => PrefeasibilityModalHook = (
	openModalCallback: (callback: () => void) => void
) => {
	const [isPrefeasibilityModalOpen, showPrefeasibilityModal, hidePrefeasibilityModal] = useModal()

	useEffect(() => {
		openModalCallback(() => showPrefeasibilityModal())
	}, [])

	return {
		isPrefeasibilityModalOpen,
		hidePrefeasibilityModal,
	}
}
