import { useFormik } from 'formik'
import { FC, useCallback, useContext, useMemo, useRef } from 'react'
import { useIntl } from 'react-intl'
import {
	AreaSelect,
	AreaSelectHandle,
	ClientSelect,
	ClientSelectHandle,
	CountrySelect,
	emptyUfinetSelectOption,
	IUfinetSelectOption,
	UfinetActionButton,
	UfinetActionButtonHandle,
	UfinetInput,
	UfinetSelect,
} from 'ufinet-web-components'
import {
	AreaType,
	AuthContext,
	infrastructureCostsService,
	InfrastructureTypeOptions,
	onFormikChanges,
	onFormikNumberChanges,
} from 'ufinet-web-functions'

import * as Yup from 'yup'

type Props = {
	hideModal: Function
	findCosts: Function
}

type InfrastructureModalData = {
	countrySelect: IUfinetSelectOption
	areaSelect: IUfinetSelectOption & { color: string }
	clientSelect: IUfinetSelectOption
	infraSelect: IUfinetSelectOption
	monthlyCostPerMeter: string
	punctualCost: string
}

const NewInfrastructureModalBody: FC<Props> = ({ hideModal, findCosts }) => {
	const intl = useIntl()
	const areaRef = useRef<AreaSelectHandle>(null)
	const clientRef = useRef<ClientSelectHandle>(null)
	const actionButtonRef = useRef<UfinetActionButtonHandle>(null)

	const authData = useContext(AuthContext)
	const _infrastructureCostsService = useMemo(() => infrastructureCostsService(authData, intl), [authData, intl])

	const sendData = (value: InfrastructureModalData) => {
		actionButtonRef.current?.changeActionStatus(true)
		_infrastructureCostsService
			.create({
				countryId: value.countrySelect.value,
				countryName: value.countrySelect.label,
				networkAreaName: value.areaSelect.value,
				client: value.clientSelect.value,
				infrastructureType: value.infraSelect.value,
				punctualCost: value.punctualCost,
				monthlyCostPerMeter: value.monthlyCostPerMeter,
			})
			.then((res) => {
				findCosts()
				hideModal()
				return res
			})
			.catch(() => {})
			.finally(() => actionButtonRef.current?.changeActionStatus(false))
	}
	const dataFormSchema = Yup.object().shape({
		countrySelect: Yup.object().shape({ label: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })) }),
		clientSelect: Yup.object().shape({ label: Yup.string().notRequired() }),
		infraSelect: Yup.object().shape({ label: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })) }),
		areaSelect: Yup.object().shape({ label: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })) }),
		monthlyCostPerMeter: Yup.number()
			.test('', intl.formatMessage({ id: 'ERROR.POSITIVE' }), (value: any) => value >= 0)
			.required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
		punctualCost: Yup.number()
			.test('', intl.formatMessage({ id: 'ERROR.POSITIVE' }), (value: any) => value >= 0)
			.required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
	})

	const formInitialValues = useMemo<InfrastructureModalData>(
		() => ({
			countrySelect: emptyUfinetSelectOption,
			areaSelect: { ...emptyUfinetSelectOption, color: '' },
			clientSelect: emptyUfinetSelectOption,
			infraSelect: emptyUfinetSelectOption,
			monthlyCostPerMeter: '',
			punctualCost: '',
		}),
		[]
	)

	const formik = useFormik<InfrastructureModalData>({
		initialValues: formInitialValues,
		validationSchema: dataFormSchema,
		onSubmit: sendData,
		validateOnChange: false,
		validateOnBlur: false,
	})

	const onCountryChange = (country?: IUfinetSelectOption) => {
		onChange(formik, 'countrySelect')(country || emptyUfinetSelectOption)
		onChange(formik, 'areaSelect')(emptyUfinetSelectOption)
		onChange(formik, 'clientSelect')(emptyUfinetSelectOption)
		if (country) {
			clientRef.current?.fillSelect([country.value], [])
			areaRef.current?.fillSelect([country.value])
		}
	}
	const onChange = useCallback(onFormikChanges, [])
	const onNumberChange = useCallback(onFormikNumberChanges, [])
	return (
		<form onSubmit={formik.handleSubmit} className="container p-15 h-100 d-flex flex-column justify-content-center">
			<div className="row">
				<CountrySelect
					requiredIcon
					className="col-4"
					onChange={(c) => onCountryChange(c as IUfinetSelectOption)}
					value={formik.values.countrySelect}
					error={formik.errors.countrySelect?.label}
				/>

				<AreaSelect
					ref={areaRef}
					requiredIcon
					className="col-4"
					areaType={AreaType.INFRASTRUCTURE_AREA}
					value={formik.values.areaSelect}
					error={formik.errors.areaSelect?.label}
					isDisabled={!formik.values.countrySelect.value}
					onChange={onChange(formik, 'areaSelect')}
				/>

				<ClientSelect
					ref={clientRef}
					requiredIcon={false}
					className="col-4"
					value={formik.values.clientSelect}
					error={formik.errors.clientSelect?.label}
					isDisabled={!formik.values.countrySelect.value}
					onChange={onChange(formik, 'clientSelect')}
				/>
			</div>

			<div className="row pt-4">
				<UfinetSelect
					requiredIcon
					className="col-5"
					tooltipTitle={intl.formatMessage({ id: 'COST.INFRASTRUCTURETYPE_2' })}
					labelTitle={intl.formatMessage({ id: 'COST.INFRASTRUCTURETYPE_2' })}
					isClearable
					error={formik.errors.infraSelect?.label}
					onChange={onChange(formik, 'infraSelect')}
					value={formik.values.infraSelect?.label && formik.values.infraSelect}
					options={InfrastructureTypeOptions}
					placeholder={intl.formatMessage({ id: 'COST.INFRASTRUCTURETYPE' })}
				/>
				<UfinetInput
					requiredIcon
					error={formik.errors.monthlyCostPerMeter}
					type="decimal"
					labelTitle={intl.formatMessage({ id: 'COST.MONTHLYCAPACITY' })}
					tooltipTitle={intl.formatMessage({ id: 'COST.MONTHLYCAPACITY_2' })}
					className="col-4"
					onChange={onNumberChange(formik, 'monthlyCostPerMeter')}
					value={formik.values.monthlyCostPerMeter}
					solid={false}
				/>
				<UfinetInput
					requiredIcon
					error={formik.errors.punctualCost}
					type="decimal"
					labelTitle={intl.formatMessage({ id: 'COST.PUNCTUALCOST_2' })}
					tooltipTitle={intl.formatMessage({ id: 'COST.PUNCTUALCOST_2' })}
					className="col-3"
					onChange={onNumberChange(formik, 'punctualCost')}
					value={formik.values.punctualCost}
					solid={false}
				/>
			</div>
			<UfinetActionButton ref={actionButtonRef} className="mt-10" content={intl.formatMessage({ id: 'SAVE' })} />
		</form>
	)
}
export { NewInfrastructureModalBody }
