import { useQuery } from 'react-query'
import { ICorporateGroupRepository } from 'ufinet-web-functions'

export const useCorporateGroupByIdAndCountryQuery = (
	repository: ICorporateGroupRepository,
	isEnabled = true,
	corporateGroupId: string | undefined,
	countryId: string | undefined
) => {
	return useQuery(
		`corporateGroup-${corporateGroupId}-${countryId}`,
		async () => {
			if (!corporateGroupId || !countryId) return
			const corporateGroups = await repository.getCorporateGroupsByCountries({ countries: [countryId] })
			return corporateGroups.find((cg) => cg.id === corporateGroupId)
		},
		{
			enabled: isEnabled,
		}
	)
}
