import { DuplicateQuotationResponse } from 'modules/prefeasibility/application/duplicateQuotationResponse'
import { PrefeasibilityRequest } from 'modules/prefeasibility/application/prefeasibilityRequest'
import { PrefeasibilityRepository } from 'modules/prefeasibility/domain/repository/PrefeasibilityRepository'
import { FetchAPI, FetchOptions, IAuthData, IPagingRequestParameters, defaultOptionsFetch } from 'ufinet-web-functions'
import { PagedPendingQuotationResponse, PendingQuotationDetailsResponse } from '../application/pendingQuotationResponse'

export const HttpPrefeasibilityRepository: (authData: IAuthData) => PrefeasibilityRepository = (
	authData: IAuthData
) => {
	const url = `${process.env.REACT_APP_URL_BACK_AQS}api/prefeasibility`
	const pendingQuotationsUrl = `${url}/pendingQuotations`

	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		notifyError: false,
		authenticationData: authData,
		sendApiKey: true,
	}

	const requestPrefeasibility: (prefeasibilityRequest: PrefeasibilityRequest) => Promise<void> = async (
		prefeasibilityRequest: PrefeasibilityRequest
	) => {
		return await FetchAPI.post<void>(url, prefeasibilityRequest, fetchOptions)
	}

	const getPendingQuotations: (params: IPagingRequestParameters) => Promise<PagedPendingQuotationResponse> = async (
		params: IPagingRequestParameters
	) => {
		return await FetchAPI.get_params<PagedPendingQuotationResponse>(pendingQuotationsUrl, params, fetchOptions)
	}

	const getPendingQuotationDetails: (quotationId: string) => Promise<PendingQuotationDetailsResponse> = async (
		quotationId
	) => {
		return await FetchAPI.get<PendingQuotationDetailsResponse>(`${pendingQuotationsUrl}/${quotationId}`, fetchOptions)
	}

	const duplicateQuotation: (quotationId: string) => Promise<DuplicateQuotationResponse> = async (quotationId) => {
		return await FetchAPI.get<DuplicateQuotationResponse>(`${url}/duplicateQuotation/${quotationId}`, fetchOptions)
	}

	return {
		requestPrefeasibility,
		duplicateQuotation,
		getPendingQuotations,
		getPendingQuotationDetails,
	}
}
