import { FC, useCallback, useContext, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import {
	HardFilterWrapper,
	HardFilterWrapperHandle,
	UfinetActionButtonHandle,
	UfinetBox,
	UfinetSectionBox,
} from 'ufinet-web-components'
import {
	areaService,
	AuthContext,
	convertToLocalTZ,
	IAreaResponse,
	INetworkAreaRequest,
	useAsync,
} from 'ufinet-web-functions'
import { AreasTable } from './table/AreasTable'

const AreasPage: FC = () => {
	const intl = useIntl()
	const actionButtonRef = useRef<UfinetActionButtonHandle>()
	const [areas, setAreas] = useState<IAreaResponse[]>([])

	const [loadingRecords, setLoadingRecords] = useState<boolean>(true)

	const authData = useContext(AuthContext)
	const _areaService = areaService(authData, intl)

	const upperFilterRef = useRef<HardFilterWrapperHandle>(null)

	const getAreas = useCallback(async () => {
		setLoadingRecords(true)
		actionButtonRef.current?.changeActionStatus(true)

		const criteria: INetworkAreaRequest = {
			countries: upperFilterRef.current?.getCountries().map((e) => e.value) || [],
			networkAreaTypes: upperFilterRef.current?.getAreaTypes().map((e) => e.value) || [],
			networkAreas: [],
		}
		return await _areaService.findArea(criteria)
	}, [_areaService])

	const onAreasFetched = (areas: IAreaResponse[]): void => {
		const parsedAreas = areas.map((area) => ({
			...area,
			typeName: intl.formatMessage({ id: `AREA_TYPE.${area.typeId}` }),
			lastModifiedAt: convertToLocalTZ(area.lastModifiedAt),
		}))

		setAreas(parsedAreas)
	}

	const onAreasFailedToFetch = (): void => {
		toast.error(intl.formatMessage({ id: 'AREAS.FETCH.ERROR' }))
	}

	const runFinally = () => {
		setLoadingRecords(false)
		actionButtonRef.current?.changeActionStatus(false)
	}

	const fetchAndStore = useAsync(
		{
			asyncFn: getAreas,
			onSuccess: onAreasFetched,
			onFailure: onAreasFailedToFetch,
			runFinally: runFinally,
		},
		[]
	)

	return (
		<UfinetBox>
			<HardFilterWrapper
				ref={upperFilterRef}
				actionButtonRef={actionButtonRef}
				search={fetchAndStore}
				hidden={{ hideAreaTypes: false, hideAreas: true }}
			/>
			<UfinetSectionBox>
				<AreasTable searchAreas={fetchAndStore} areas={areas} loadingRecords={loadingRecords} />
			</UfinetSectionBox>
		</UfinetBox>
	)
}

export { AreasPage }
