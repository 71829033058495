import { mkEngancheIcon } from '../icon/functions/mkEngancheIcon'
import { MapMarkerBaseOptions } from '../MapMarkerBaseOptions'
import { MapMarkerType } from '../MapMarkerType'

export const baseMarkerForCoupling = (): google.maps.MarkerOptions => {
	return {
		...MapMarkerBaseOptions.get(MapMarkerType.COUPLING),
		icon: mkEngancheIcon(),
	}
}
