import { markerOptionsFromKmzAccessArea } from 'modules/google-maps/markers/functions/markerOptionsFromKmzAccessArea'
import { MapMarker } from 'modules/google-maps/markers/MapMarker'
import { IntlShape } from 'react-intl'
import {
	AreaAccessStatus,
	AreaStatusColors,
	AreaType,
	AreaTypeColors,
	IKmzAreaFindResponse,
} from 'ufinet-web-functions'
import { MapPolygon } from '../MapPolygon'
import { polygonOptionsFromKmzAreaResponse } from './polygonOptionsFromKmzAreaResponse'

export const markersAndPolygonFromFromKmzAreas = (
	kmzAreasData: IKmzAreaFindResponse[],
	intl: IntlShape
): { polygons: MapPolygon[]; markers: MapMarker[] } => {
	const markersAndBuildingAreas: Map<MapPolygon, MapMarker | undefined> = new Map()

	kmzAreasData.forEach((areaData) => {
		const accessAreaMarker: MapMarker | undefined =
			areaData.areaTypeId === AreaType.ACCESS_AREA ? markerOptionsFromKmzAccessArea(areaData, intl) : undefined
		const accessAreaPolygon: MapPolygon = {
			...polygonOptionsFromKmzAreaResponse(
				areaData,
				areaData.areaTypeId === AreaType.ACCESS_AREA
					? AreaStatusColors.get(areaData.accessAreaStatus || AreaAccessStatus.NOT_CONNECTED)
					: AreaTypeColors.get(areaData.areaTypeId)
			),
			markerId: accessAreaMarker?.id,
		}
		markersAndBuildingAreas.set(
			accessAreaPolygon,
			accessAreaMarker ? { ...accessAreaMarker, polygonId: accessAreaPolygon.id } : undefined
		)
	})

	const polygons = [...markersAndBuildingAreas.keys()]
	const markers = [...markersAndBuildingAreas.values()].filter(Boolean) as MapMarker[]

	return { polygons, markers }
}
