import { useTranslator } from 'ufinet-web-functions'

const CardText = ({ label, pendingValueData }: { label: string; pendingValueData: string }) => {
	const translate = useTranslator()
	return (
		<h5 className="mb-0 mt-2">
			{translate(label)}
			{`: ${pendingValueData}`}
		</h5>
	)
}

export { CardText }
