import { ILocalService, ProductId } from 'ufinet-web-functions'
import { MapMarkerBaseOptions } from '../MapMarkerBaseOptions'
import { MapMarkerType } from '../MapMarkerType'
import { mkServiceIcon } from '../icon/functions/mkServiceIcon'
import { MarkerFromServiceSettings } from './MarkerFromServiceSettings'

export const markerOptionsFromLocalService = (
	service: ILocalService,
	settings: MarkerFromServiceSettings
): google.maps.MarkerOptions[] => {
	const destinationMarker: google.maps.MarkerOptions = {
		position: { lat: +service.destinationLatitude!, lng: +service.destinationLongitude! },
		// label: settings.labelDestination || (service.pointNumber! + 1)!.toString(),
		title: settings.titleDestination,
	}

	const finalMarkers =
		service.serviceTypeSelect.value === ProductId.INTERNET
			? [destinationMarker]
			: ([
					{
						position: { lat: +service.originLatitude!, lng: +service.originLongitude! },
						// label: settings.labelOrigin || (service.pointNumber! + 1)!.toString(),
						title: settings.titleOrigin,
					},
					destinationMarker,
			  ] as google.maps.MarkerOptions[])

	return finalMarkers.map((marker) => ({
		...MapMarkerBaseOptions.get(MapMarkerType.SERVICE),
		...marker,
		label: `${service.pointNumber! + 1}`,
		icon: mkServiceIcon(),
	}))
}
