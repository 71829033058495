import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { ISelectBaseInterface, IUfinetSelectOption, UfinetSelect, UfinetSelectHandle } from 'ufinet-web-components'
import {
	AuthContext,
	Authority,
	IProductResponse,
	ProductId,
	productsService,
	useLoading,
	useTranslator,
} from 'ufinet-web-functions'

export type ProductSelectHandle = UfinetSelectHandle & {}

const ProductSelect = forwardRef<ProductSelectHandle, ISelectBaseInterface>(
	({ isMulti = false, isDisabled = false, className, onChange, value, error = undefined, ...props }, ref) => {
		const selectRef = useRef<UfinetSelectHandle>(null)
		const translate = useTranslator()
		const authData = useContext(AuthContext)

		const hasPrefeasibility = Authority.getAqsPrefeasibilityPermissions(authData?.userData?.authorities || []).canWrite
		const _productService = productsService(authData)

		const [productOptions, setProductOptions] = useState<IUfinetSelectOption[]>()

		const { loading, startLoading, stopLoading } = useLoading()

		const filterProducts = ({ id }: IProductResponse) => {
			const basicProducts = [ProductId.INTERNET, ProductId.ELINE]
			const prefeasibilityProducts = [
				...basicProducts,
				ProductId.FIBRA_OSCURA_ID,
				ProductId.COLOCATION_ID,
				ProductId.TOWERING_ID,
			]

			return hasPrefeasibility
				? prefeasibilityProducts.includes(id as ProductId)
				: basicProducts.includes(id as ProductId)
		}

		useEffect(() => {
			startLoading()
			_productService
				.getAllProducts()
				.then((response: IProductResponse[]) => {
					setProductOptions(response.filter(filterProducts).map(({ id: value, name: label }) => ({ value, label })))
					return response
				})
				.catch(console.error)
				.finally(stopLoading)
		}, [])

		useImperativeHandle(
			ref,
			() =>
				({
					clearSelect: selectRef.current?.clearSelect,
					isEmpty: selectRef.current?.isEmpty,
				} as ProductSelectHandle),
			[]
		)

		return (
			<UfinetSelect
				ref={selectRef}
				{...props}
				labelTitle={translate('SERVICE.TYPE')}
				tooltipTitle={translate('SERVICE.TYPE.TOOLTIP')}
				options={productOptions}
				value={(value as IUfinetSelectOption)?.label && value}
				requiredIcon
				className={className}
				isMulti={isMulti}
				error={error}
				isClearable
				onChange={onChange}
				isDisabled={isDisabled}
				placeholder={translate('SERVICE.TYPE.PLACEHOLDER')}
				isLoadingOptions={loading}
			/>
		)
	}
)
export { ProductSelect }
