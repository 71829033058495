import { getCoordinatesText } from './getCoordinatesText'
import { getCopyElement } from './getCopyElement'

export const getCoordinatesCopyElement: (
	coordinates: google.maps.LatLngLiteral,
	options?: { separator?: string; tooltip?: string }
) => string = (coordinates, { separator = ', ', tooltip } = {}) => {
	const coordinatesText = getCoordinatesText(coordinates, separator)
	return getCopyElement(coordinatesText, { tooltip })
}
