import { PrefeasibilityRepository } from 'modules/prefeasibility/domain/repository/PrefeasibilityRepository'
import { DataTablePFSEvent } from 'primereact/datatable'
import { useQuery } from 'react-query'
import { ServiceServerStatus, useTranslator } from 'ufinet-web-functions'

export const usePendingQuotationsQuery = (
	repository: PrefeasibilityRepository,
	tablePFSEvent: DataTablePFSEvent | undefined,
	isEnabled = true
) => {
	const page = tablePFSEvent?.page ?? 0
	const size = tablePFSEvent?.rows ?? 10
	return useQuery(
		`pending-quotations-query-page-${page}-size-${size}`,
		() => repository.getPendingQuotations({ pageNumber: page, pageSize: size }),
		{
			enabled: isEnabled,
			staleTime: 0,
			cacheTime: 0,
			keepPreviousData: false,
		}
	)
}

export const usePendingQuotationDetailsQuery = (
	repository: PrefeasibilityRepository,
	isEnabled = true,
	quotationId: string
) => {
	const translate = useTranslator()
	return useQuery(
		`pending-quotation-details-query-${quotationId}`,
		async () => {
			const pendingQuotationDetails = await repository.getPendingQuotationDetails(quotationId)
			pendingQuotationDetails.services.forEach((s) => {
				s.statusName = translate(`SERVICE.STATUS.${s.status}`)
				s.statusDetails = s.status === ServiceServerStatus.NOT_CALCULATED ? s.statusDetails : ''
			})
			return pendingQuotationDetails
		},
		{
			enabled: isEnabled,
			staleTime: 0,
			cacheTime: 0,
			keepPreviousData: false,
		}
	)
}
