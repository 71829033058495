import { Status, Wrapper } from '@googlemaps/react-wrapper'
import { MapControlsDefinition } from 'modules/google-maps/controls/MapControlsDefinition'
import { DirectionsData } from 'modules/google-maps/directions/typesAndValues'
import { MapsClickEvent } from 'modules/google-maps/events/MapsClickEvent'
import { MapsInfoWindowCloseEvent } from 'modules/google-maps/events/infowindow/MapsInfoWindowCloseEvent'
import { MapsInfoWindowOpenEvent } from 'modules/google-maps/events/infowindow/MapsInfoWindowOpenEvent'
import { mapDefaults } from 'modules/google-maps/mapDefaults'
import { MapMarker } from 'modules/google-maps/markers/MapMarker'
import { MapPolygon } from 'modules/google-maps/polygon/MapPolygon'
import React, {
	PropsWithChildren,
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useMemo,
	useState,
} from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Map } from '../Map'
import { Directions } from '../directions/Directions'
import { KmlLayer } from '../kml/KmlLayer'
import { Marker } from '../markers/Marker'
import { Polygon } from '../polygon/Polygon'
import { MapWrapperOptions, defaultMapWrapperOptions } from './MapWrapperOptions'
import { mapWrapperBaseStyles } from './MapWrapperStyles'

interface MapWrapperProps {
	additionalStyles?: React.CSSProperties
	mapOptions?: MapWrapperOptions
	kmzUrls?: string[]
	markers?: MapMarker[]
	polygons?: MapPolygon[]
	directions?: DirectionsData[]
	controls?: MapControlsDefinition[]
	centerOnOpen?: boolean
	onLoad?: (e: Event, map: google.maps.Map) => void
	onClick?: (e: MapsClickEvent, map: google.maps.Map) => void
	onInfoWindowOpen?: (e: MapsInfoWindowOpenEvent, map: google.maps.Map) => void
	onInfoWindowClose?: (e: MapsInfoWindowCloseEvent, map: google.maps.Map) => void
	focusOnLocation?: boolean
}

export type MapWrapperHandle = {
	map?: google.maps.Map
	placesService?: google.maps.places.PlacesService
	currentInfoWindow?: google.maps.InfoWindow
	geocoder?: google.maps.Geocoder
	scrollIntoView: () => void
}

const MapWrapper = forwardRef<MapWrapperHandle, PropsWithChildren<MapWrapperProps>>(
	(
		{
			additionalStyles,
			mapOptions = defaultMapWrapperOptions,
			kmzUrls = [],
			markers = [],
			polygons = [],
			directions = [],
			controls = [],
			onClick: onClickProps,
			onLoad: onLoadProps,
			onInfoWindowOpen: onInfoWindowOpenProps,
			onInfoWindowClose: onInfoWindowCloseProps,
			centerOnOpen = true,
			focusOnLocation = true,
		},
		ref
	) => {
		const mapId = useMemo(() => uuidv4(), [])
		const zoom = useMemo(() => mapOptions.zoom, [mapOptions.zoom])
		const [center, setCenter] = useState<google.maps.LatLngLiteral | null>(mapDefaults.mapDefaultCenter)
		const [mapTypeId, _setMapTypeId] = useState(mapOptions.mapType)

		const mapStyle = useMemo(() => ({ ...mapWrapperBaseStyles, ...additionalStyles }), [additionalStyles])
		const [mapObject, setMapObject] = useState<google.maps.Map>()

		const [currentInfoWindow, setCurrentInfoWindow] = useState<google.maps.InfoWindow>()

		const placesService = useMemo(
			() => (mapObject ? new google.maps.places.PlacesService(mapObject) : undefined),
			[mapObject]
		)

		const geocoder = useMemo(() => (mapObject ? new google.maps.Geocoder() : undefined), [mapObject])

		const centerMap = useCallback(() => {
			// 1. User passed data (coordinates or text address)
			if (mapOptions.center) {
				if (typeof mapOptions.center === 'object') setCenter(mapOptions.center)
				else if (placesService && typeof mapOptions.center === 'string') {
					placesService.findPlaceFromQuery({ fields: ['geometry.location'], query: mapOptions.center }, (results) => {
						// Try with first result, else reset
						if (results && results[0].geometry?.location) {
							const latLng: google.maps.LatLngLiteral = {
								lat: results[0].geometry.location.lat(),
								lng: results[0].geometry.location.lng(),
							}
							setCenter(latLng)
						} else {
							setCenter(mapDefaults.mapDefaultCenter)
						}
					})
				}
			}

			// 2. Location
			else if (focusOnLocation && navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					(position) => setCenter({ lat: position.coords.latitude, lng: position.coords.longitude }),
					() => setCenter(mapDefaults.mapDefaultCenter)
				)
			}
			// 3. Default hardcoded
			else {
				setCenter(mapDefaults.mapDefaultCenter)
			}
		}, [focusOnLocation, mapOptions.center, placesService])

		useEffect(() => {
			mapObject && placesService && centerOnOpen && centerMap()
		}, [centerMap, centerOnOpen, mapObject, placesService])

		const onClick = useCallback<(event: MapsClickEvent) => void>(
			(event) => {
				onClickProps?.(event, mapObject!)
			},
			[mapObject, onClickProps]
		)

		const markersWithMap = useMemo(
			() => markers.map((marker) => ({ ...marker, options: { ...marker.options, map: mapObject } })),
			[mapObject, markers]
		)

		const polygonsWithMap = useMemo(
			() => polygons.map((polygon) => ({ ...polygon, options: { ...polygon.options, map: mapObject } })),
			[mapObject, polygons]
		)

		const onInfoWindowOpen = useCallback<(event: MapsInfoWindowOpenEvent) => void>(
			(event) => {
				setCurrentInfoWindow(event.infoWindow)
				onInfoWindowOpenProps?.(event, mapObject!)
			},
			[mapObject, onInfoWindowOpenProps]
		)

		const onInfoWindowClose = useCallback<(event: MapsInfoWindowCloseEvent) => void>(
			(event) => {
				setCurrentInfoWindow(undefined)
				onInfoWindowCloseProps?.(event, mapObject!)
			},
			[mapObject, onInfoWindowCloseProps]
		)

		const scrollIntoView = useCallback(
			(behavior: ScrollBehavior = 'smooth') => {
				document.getElementById(mapId)?.scrollIntoView({ behavior })
			},
			[mapId]
		)

		const onLoad = useCallback<(event: Event, map: google.maps.Map) => void>(
			(event, map) => {
				setMapObject(map)
				onLoadProps?.(event, map)
			},
			[onLoadProps]
		)

		useImperativeHandle(
			ref,
			() => ({
				map: mapObject,
				placesService,
				currentInfoWindow: currentInfoWindow,
				geocoder,
				scrollIntoView,
			}),
			[mapObject, placesService, currentInfoWindow, geocoder, scrollIntoView]
		)

		const render = (status: Status): React.ReactElement => {
			switch (status) {
				case Status.LOADING:
					return <span className="spinner-border spinner-border-sm align-middle ms-2" />
				case Status.FAILURE:
					return <h1>{status}</h1>
				case Status.SUCCESS:
					return (
						<Map
							mapId={mapId}
							zoom={zoom}
							center={center}
							controls={controls}
							style={mapStyle}
							mapTypeId={mapTypeId}
							mapTypeControlOptions={{
								mapTypeIds: mapOptions.mapTypes,
								style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
							}}
							streetViewControl={false}
							onLoad={onLoad}
							onClick={onClick}
						>
							{kmzUrls?.map((url: string) => (
								<KmlLayer key={url} options={{ map: mapObject, url, preserveViewport: !!currentInfoWindow }} />
							))}
							{markersWithMap.map(({ id, type, options, infoWindow }) => (
								<Marker
									key={id}
									id={id}
									type={type}
									options={options}
									infoWindow={infoWindow}
									onClick={onClick}
									onInfoWindowOpen={onInfoWindowOpen}
									onInfoWindowClose={onInfoWindowClose}
								/>
							))}
							{polygonsWithMap.map(({ id, options }) => (
								<Polygon key={id} id={id} options={options} onClick={onClick} />
							))}
							{directions?.map((direction: DirectionsData, index: number) => (
								<Directions key={`googlemaps-${mapId}-direction-${index}`} {...direction} map={mapObject} />
							))}
						</Map>
					)
			}
		}

		return (
			<Wrapper
				apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}
				libraries={['places', 'geometry']}
				render={render}
			/>
		)
	}
)

export { MapWrapper }
