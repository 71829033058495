import { useFormik } from 'formik'

import { FC, useCallback, useContext, useMemo, useRef } from 'react'
import { useIntl } from 'react-intl'
import {
	AreaSelect,
	AreaSelectHandle,
	ClientSelect,
	ClientSelectHandle,
	CountrySelect,
	IUfinetSelectOption,
	UfinetActionButton,
	UfinetActionButtonHandle,
	UfinetInput,
	UfinetSelect,
	emptyUfinetSelectOption,
} from 'ufinet-web-components'
import {
	AreaType,
	AuthContext,
	ZoneTypeOptions,
	equipmentCostsService,
	onFormikChanges,
	onFormikNumberChanges,
} from 'ufinet-web-functions'

import * as Yup from 'yup'

type Props = {
	hideModal: Function
	findCosts: Function
}

type EquipmentModalData = {
	countrySelect: IUfinetSelectOption
	areaSelect: IUfinetSelectOption & { color: string }
	clientSelect: IUfinetSelectOption
	zoneSelect: IUfinetSelectOption
	minCap: string
	maxCap: string
	cost: string
	costMO: string
}

const NewEquipmentModalBody: FC<Props> = ({ hideModal, findCosts }: Props) => {
	const intl = useIntl()
	const areaRef = useRef<AreaSelectHandle>(null)
	const clientRef = useRef<ClientSelectHandle>(null)
	const actionButtonRef = useRef<UfinetActionButtonHandle>(null)

	const authData = useContext(AuthContext)
	const _equipmentCostsService = useMemo(() => equipmentCostsService(authData, intl), [authData, intl])

	const sendData = (value: EquipmentModalData) => {
		actionButtonRef.current?.changeActionStatus(true)
		_equipmentCostsService
			.create({
				countryId: value.countrySelect.value,
				countryName: value.countrySelect.label,
				networkAreaName: value.areaSelect.value,
				zoneType: value.zoneSelect.value,
				minimumCapacity: value.minCap,
				maximumCapacity: value.maxCap,
				cost: value.cost,
				laborCost: value.costMO,
				clientId: value.clientSelect.value,
			})
			.then(() => {
				findCosts()
				hideModal()
			})
			.catch(() => {})
			.finally(() => actionButtonRef.current?.changeActionStatus(false))
	}
	const dataFormSchema = Yup.object().shape({
		countrySelect: Yup.object().shape({ label: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })) }),
		zoneSelect: Yup.object().shape({ label: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })) }),
		areaSelect: Yup.object().shape({ label: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })) }),
		clientSelect: Yup.object().shape({ label: Yup.string().notRequired() }),
		minCap: Yup.number()
			.test('', intl.formatMessage({ id: 'ERROR.POSITIVE' }), (value: any) => value >= 0)
			.required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
		maxCap: Yup.number()
			.test('', intl.formatMessage({ id: 'ERROR.POSITIVE' }), (value: any) => value >= 0)
			.required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
		cost: Yup.number()
			.test('', intl.formatMessage({ id: 'ERROR.POSITIVE' }), (value: any) => value >= 0)
			.required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
		costMO: Yup.number()
			.test('', intl.formatMessage({ id: 'ERROR.POSITIVE' }), (value: any) => value >= 0)
			.required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
	})

	const formInitialValues = useMemo<EquipmentModalData>(
		() => ({
			countrySelect: emptyUfinetSelectOption,
			areaSelect: { label: '', value: '', color: '' },
			clientSelect: emptyUfinetSelectOption,
			zoneSelect: emptyUfinetSelectOption,
			minCap: '',
			maxCap: '',
			cost: '',
			costMO: '',
		}),
		[]
	)

	const formik = useFormik<EquipmentModalData>({
		initialValues: formInitialValues,
		validationSchema: dataFormSchema,
		onSubmit: sendData,
		validateOnChange: false,
		validateOnBlur: false,
	})

	const onCountryChange = (country?: IUfinetSelectOption) => {
		onChange(formik, 'countrySelect')(country || emptyUfinetSelectOption)
		onChange(formik, 'areaSelect')(emptyUfinetSelectOption)
		onChange(formik, 'clientSelect')(emptyUfinetSelectOption)
		if (country) {
			areaRef.current?.fillSelect([country.value])
			clientRef.current?.fillSelect([country.value], [])
		}
	}
	const onChange = useCallback(onFormikChanges, [])
	const onNumberChange = useCallback(onFormikNumberChanges, [])

	return (
		<form onSubmit={formik.handleSubmit} className="container p-15 h-100 d-flex flex-column justify-content-center">
			<div className="row">
				<CountrySelect
					requiredIcon
					className="col-4"
					onChange={(c) => onCountryChange(c as IUfinetSelectOption)}
					value={formik.values.countrySelect}
					error={formik.errors.countrySelect?.label}
				/>
				<AreaSelect
					ref={areaRef}
					requiredIcon
					className="col-4"
					areaType={AreaType.OPTICAL_FIBER_AREA}
					value={formik.values.areaSelect}
					error={formik.errors.areaSelect?.label}
					isDisabled={!formik.values.countrySelect.value}
					onChange={onChange(formik, 'areaSelect')}
				/>
				<ClientSelect
					ref={clientRef}
					requiredIcon={false}
					className="col-4"
					value={formik.values.clientSelect}
					error={formik.errors.clientSelect}
					isDisabled={!formik.values.countrySelect.value}
					onChange={onChange(formik, 'clientSelect')}
				/>
			</div>
			<div className="row pt-4">
				<UfinetSelect
					requiredIcon
					className="col-6"
					labelTitle={intl.formatMessage({ id: 'COST.SCOPE' })}
					tooltipTitle={intl.formatMessage({ id: 'COST.SCOPE' })}
					error={formik.errors.zoneSelect?.label}
					isClearable
					onChange={onChange(formik, 'zoneSelect')}
					value={formik.values.zoneSelect?.label && formik.values.zoneSelect}
					options={ZoneTypeOptions}
					placeholder={intl.formatMessage({ id: 'COST.SCOPE' })}
				/>
				<UfinetInput
					requiredIcon
					error={formik.errors.minCap}
					type="decimal"
					labelTitle={intl.formatMessage({ id: 'COST.MIN_CAPACITY' })}
					tooltipTitle={intl.formatMessage({ id: 'COST.MIN_CAPACITY' })}
					className="col-6"
					onChange={onNumberChange(formik, 'minCap')}
					value={formik.values.minCap}
					solid={false}
				/>
			</div>
			<div className="row pt-4">
				<UfinetInput
					requiredIcon
					error={formik.errors.maxCap}
					type="decimal"
					labelTitle={intl.formatMessage({ id: 'COST.MAX_CAPACITY' })}
					tooltipTitle={intl.formatMessage({ id: 'COST.MAX_CAPACITY' })}
					className="col-4"
					onChange={onNumberChange(formik, 'maxCap')}
					value={formik.values.maxCap}
					solid={false}
				/>
				<UfinetInput
					requiredIcon
					error={formik.errors.cost}
					type="decimal"
					labelTitle={intl.formatMessage({ id: 'COST.COST' })}
					tooltipTitle={intl.formatMessage({ id: 'COST.COST' })}
					className="col-4"
					onChange={onNumberChange(formik, 'cost')}
					value={formik.values.cost}
					solid={false}
				/>
				<UfinetInput
					requiredIcon
					error={formik.errors.costMO}
					type="decimal"
					labelTitle={intl.formatMessage({ id: 'COST.COSTMO' })}
					tooltipTitle={intl.formatMessage({ id: 'COST.COSTMO' })}
					className="col-4"
					onChange={onNumberChange(formik, 'costMO')}
					value={formik.values.costMO}
					solid={false}
				/>
			</div>
			<UfinetActionButton ref={actionButtonRef} className="mt-10" content={intl.formatMessage({ id: 'SAVE' })} />
		</form>
	)
}
export { NewEquipmentModalBody }
