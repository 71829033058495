import { SelectOptionMapper } from 'modules/common/domain/mapper/SelectOptionMapper'
import { useQuery } from 'react-query'
import { CapacityRepository } from '../repository/CapacityRepository'

export const useTechnologiesQuery = (isEnabled = true, repository: CapacityRepository) => {
	const { getTechnologies } = repository
	return useQuery('technologies-query', () => getTechnologies(), {
		enabled: isEnabled,
		select: SelectOptionMapper,
	})
}

export const useTransportLayerQuery = (repository: CapacityRepository) => {
	const { getTransportLayer } = repository
	return useQuery('transport-layer-query', () => getTransportLayer(), {
		select: SelectOptionMapper,
	})
}

export const useRedundanceQuery = (repository: CapacityRepository) => {
	const { getRedundance } = repository
	return useQuery('redundance-query', () => getRedundance(), {
		select: SelectOptionMapper,
	})
}

export const useTransmissionMeanQuery = (repository: CapacityRepository) => {
	const { getTransmissionMean } = repository
	return useQuery('transmission-medium-query', () => getTransmissionMean(), {
		select: SelectOptionMapper,
	})
}

export const useStandardQuery = (repository: CapacityRepository) => {
	const { getStandard } = repository
	return useQuery('standard-query', () => getStandard(), {
		select: SelectOptionMapper,
	})
}

export const useSubstandardQuery = (repository: CapacityRepository) => {
	const { getSubstandard } = repository
	return useQuery('substandard-query', () => getSubstandard(), {
		select: SelectOptionMapper,
	})
}
