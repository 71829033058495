import { AreaType, NetworkItemType } from 'ufinet-web-functions'

export type MapControlsAreasCablesBoxesContextData = {
	preSelectedAreaTypes: AreaType[]
	preSelectedItemTypes: NetworkItemType[]
}

export const defaultMapControlsAreasCablesBoxesContextData: MapControlsAreasCablesBoxesContextData = {
	preSelectedAreaTypes: [],
	preSelectedItemTypes: [],
}
