import { ICablesAndBoxes } from 'pages/loadKmz/common/LoadKmzTypesAndInterfaces'
import { FC, useCallback, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import {
	IWizardInterface,
	UfinetActionButtonHandle,
	UfinetBox,
	WizardVertical,
	WizardVerticalHandle,
} from 'ufinet-web-components'
import { Confirm } from './steppers/Confirm'
import { DataForm } from './steppers/DataForm'
import { PreviewSummary } from './steppers/preview/PreviewSummary'

const CablesAndBoxesPage: FC = () => {
	const intl = useIntl()
	const wizardRef = useRef<WizardVerticalHandle>()
	const actionButtonRef = useRef<UfinetActionButtonHandle>()
	const actionButtonConfirmRef = useRef<UfinetActionButtonHandle>()
	const [data, setData] = useState<ICablesAndBoxes>({
		country: '',
		itemType: { label: '', value: '', color: '' },
		filePath: '',
		numCoordinates: 0,
		storageUrls: [''],
	})

	const settingData = useCallback((data: ICablesAndBoxes) => {
		setData(data)
	}, [])

	const contentArray: IWizardInterface[] = [
		{
			stepperTitle: intl.formatMessage({ id: 'STEP.CABLES&BOXES.FILL_DATA' }),
			stepperDescription: intl.formatMessage({
				id: 'STEP.DESCRIPTION.CABLES&BOXES.FILL_DATA',
			}),
			stepperComponent: <DataForm actionButtonRef={actionButtonRef} settingData={settingData} wizardRef={wizardRef} />,
		},
		{
			stepperTitle: intl.formatMessage({ id: 'STEP.CABLES&BOXES.PREVIEW' }),
			stepperDescription: intl.formatMessage({
				id: 'STEP.DESCRIPTION.CABLES&BOXES.PREVIEW',
			}),
			stepperComponent: <PreviewSummary data={data} wizardRef={wizardRef} />,
		},
		{
			stepperTitle: intl.formatMessage({ id: 'STEP.CABLES&BOXES.LOAD_KMZ' }),
			stepperDescription: intl.formatMessage({
				id: 'STEP.DESCRIPTION.CABLES&BOXES.LOAD_KMZ',
			}),
			stepperComponent: <Confirm actionButtonRef={actionButtonConfirmRef} data={data} wizardRef={wizardRef} />,
		},
	]
	return (
		<UfinetBox>
			<WizardVertical ref={wizardRef} content={contentArray} />
		</UfinetBox>
	)
}

export { CablesAndBoxesPage }
