import { useCallback, useContext, useState } from 'react'
import { toast } from 'react-toastify'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { colorConstants } from 'ufinet-web-components'
import {
	AqsModules,
	AuthContext,
	Authority,
	ILocalService,
	IPagePermissions,
	IQuotation,
	ProcessedService,
	QuotationConfirmationStatus,
	ServiceLocalState,
	ServiceServerStatus,
	useTranslator,
} from 'ufinet-web-functions'

import { PrefeasibilityRepository } from 'modules/prefeasibility/domain/repository/PrefeasibilityRepository'
import { useGetPermission } from 'modules/security/useGetPermission'

type ServiceTableHeaderHook = {
	submitQuotation: () => Promise<void>
	cancelQuotation: () => void
	hasUnquotableProducts: () => boolean
	openPrefeasibilityModal: (() => void) | undefined
	openPrefeasibilityModalCallback: (callback: () => void) => void
	permissions: IPagePermissions
	prefeasibilityPermissions: boolean
	quotationCalculating: boolean
	quotationConfirmationStatus: QuotationConfirmationStatus
	shouldButtonsBeDisabled: (isEditingService: boolean, services: ProcessedService[]) => boolean
	handleQutoationIIButtonClicked: () => Promise<void>
}

const useServiceTableHeaderButtons = (
	aqsId: string,
	services: ProcessedService[],
	quotationData: IQuotation,
	prefeasibilityRepository: PrefeasibilityRepository,
	setSelectedValues: React.Dispatch<React.SetStateAction<ILocalService[]>>,
	deleteServices: (indexes: number[]) => Promise<void>,
	confirmQuotation: () => Promise<void>,
	confirmPrefeasibility: () => void,
	resetForm: () => void
): ServiceTableHeaderHook => {
	const authData = useContext(AuthContext)
	const translate = useTranslator()
	const NON_QUOTABLE_ATTRIBUTES_MESSAGE = 'Este producto tiene atributos que no son autocotizables'

	const roles = authData.userData?.authorities || []
	const permissions = Authority.getQuotationPermissions(roles)
	const prefeasibilityPermissions = useGetPermission(AqsModules.PREFEASIBILITY, 'canWrite')

	const { calculating: quotationCalculating, confirmationStatus: quotationConfirmationStatus } = quotationData

	const hasUnquotableProducts = () => {
		return quotationData.services.some((s) => s.status !== ServiceServerStatus.CALCULATED)
	}

	const ReactSwal = withReactContent(Swal)

	const [openPrefeasibilityModal, setOpenPrefeasibilityModal] = useState<() => void>()
	const openPrefeasibilityModalCallback = useCallback((callback: () => void) => {
		setOpenPrefeasibilityModal(() => callback)
	}, [])

	const shouldButtonsBeDisabled = (isEditingService: boolean, services: ProcessedService[]) => {
		return services.length === 0 || services.some((s) => s.state !== ServiceLocalState.Computed) || isEditingService
	}

	const handleQutoationIIButtonClicked = useCallback(async () => {
		try {
			await prefeasibilityRepository.requestPrefeasibility({
				aqsId: aqsId,
				contactId: undefined,
				reference: quotationData.reference,
				finalClient: quotationData.finalClient,
			})

			toast.success(`${translate('QUOTATION.CONFIRM.SENT.SUCCESS')}`)
			confirmPrefeasibility()
		} catch {
			toast.error(translate('QUOTATION.CONFIRM.SENT.ERROR'))
		}
	}, [aqsId, prefeasibilityRepository, translate, confirmPrefeasibility])

	const cancelQuotation = () => {
		if (services.length === 0) {
			console.warn('Cannot cancel a quotation with no associated services')
			return
		}
		ReactSwal.fire({
			icon: 'warning',
			title: translate(quotationData.isBulk ? 'QUOTATION.RESET.BULK' : 'QUOTATION.RESET.INDIVIDUAL'),
			html: translate(
				quotationData.isBulk ? 'QUOTATION.RESET.BULK.DESCRIPTION' : 'QUOTATION.RESET.INDIVIDUAL.DESCRIPTION'
			),
			showCancelButton: true,
			showConfirmButton: true,
			cancelButtonText: translate('CANCEL'),
			confirmButtonText: translate('OK'),
			reverseButtons: true,
			confirmButtonColor: colorConstants.primaryColor,
			cancelButtonColor: colorConstants.dangerColor,
			preConfirm: () => {
				setSelectedValues([])
				deleteServices(services.map((s: { id: any }) => s.id!))
					.then(resetForm)
					.catch(() => toast.error(translate('QUOTATION.RESET.ERROR')))
			},
		})
	}

	const submitQuotation = async () => {
		if (services.length === 0) {
			console.warn('Cannot confirm a quotation with no associated services')
			return
		}
		await ReactSwal.fire({
			icon: 'warning',
			title: translate(quotationData.isBulk ? 'QUOTATION.CONFIRM.BULK' : 'QUOTATION.CONFIRM.INDIVIDUAL'),
			html: translate(
				quotationData.isBulk ? 'QUOTATION.CONFIRM.BULK.DESCRIPTION' : 'QUOTATION.CONFIRM.INDIVIDUAL.DESCRIPTION'
			),
			showCancelButton: true,
			showConfirmButton: true,
			cancelButtonText: translate('CANCEL'),
			confirmButtonText: translate('OK'),
			reverseButtons: true,
			confirmButtonColor: colorConstants.primaryColor,
			cancelButtonColor: colorConstants.dangerColor,
			preConfirm: () => {
				setSelectedValues([])
				confirmQuotation()
			},
		})
	}

	return {
		submitQuotation,
		cancelQuotation,
		hasUnquotableProducts,
		openPrefeasibilityModal,
		openPrefeasibilityModalCallback,
		permissions,
		prefeasibilityPermissions,
		quotationCalculating,
		quotationConfirmationStatus,
		shouldButtonsBeDisabled,
		handleQutoationIIButtonClicked,
	}
}

export { useServiceTableHeaderButtons }
