import { MapsClickEventType } from './MapsClickEventType'

export abstract class MapsClickEvent {
	type: MapsClickEventType
	nativeEvent: google.maps.MapMouseEvent

	constructor(input: { type: MapsClickEventType; nativeEvent: google.maps.MapMouseEvent }) {
		this.type = input.type
		this.nativeEvent = input.nativeEvent
	}
}
