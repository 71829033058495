/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react-hooks/exhaustive-deps */
import { isLatLngLiteral } from '@googlemaps/typescript-guards'
import { createCustomEqual } from 'fast-equals'
import { useEffect, useRef } from 'react'

const deepCompareEqualsForMaps = createCustomEqual((deepEqual): boolean => {
	// @ts-ignore
	return (a: any, b: any) => {
		if (
			isLatLngLiteral(a) ||
			a instanceof google.maps.LatLng ||
			isLatLngLiteral(b) ||
			b instanceof google.maps.LatLng
		) {
			return new google.maps.LatLng(a).equals(new google.maps.LatLng(b))
		}

		// use fast-equals for other objects
		return deepEqual(a, b)
	}
})

function useDeepCompareMemoize(value: any) {
	const ref = useRef()

	// @ts-ignore
	if (!deepCompareEqualsForMaps(value, ref.current)) {
		ref.current = value
	}

	return ref.current
}

export function useDeepCompareEffectForMaps(callback: React.EffectCallback, dependencies: any[]) {
	useEffect(callback, dependencies.map(useDeepCompareMemoize))
}
