import { TechnicalGroupRepository } from 'modules/prefeasibility/domain/repository/TechnicalGroupRepository'
import { useQuery } from 'react-query'

export const useTechnicalGroupsQuery = (countryId: string, isEnabled = true, repository: TechnicalGroupRepository) => {
	return useQuery(
		`technical-groups-query-${countryId}`,
		() => (countryId ? repository.getTechnicalGroups(countryId) : []),
		{
			enabled: isEnabled,
		}
	)
}

export const useTechnicalGroupByIdQuery = (id: string, isEnabled = true, repository: TechnicalGroupRepository) => {
	return useQuery(`technical-group-by-id-query-${id}`, () => (id ? repository.getTechnicalGroupById(id) : undefined), {
		enabled: isEnabled,
	})
}
