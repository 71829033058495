import { isInteger } from 'formik'
import { Technology } from 'modules/product/capacity/domain'
import { ClockLoader } from 'react-spinners'
import { BandwidthEditSelect, ColData, UfinetSimpleInput, mkGenericEditableField } from 'ufinet-web-components'
import {
	ILocalService,
	IServerService,
	ProcessedService,
	ProductId,
	ServiceLocalState,
	ServiceServerStatus,
	coordinatesCellNumberFormattingSettings,
	formatCellNumber,
	localServiceCoordinates,
	makeBandwidthLabel,
	tableCostsFormattingSettings,
	tableDataFormattingSettings,
	tableDeadlineFormattingSettings,
} from 'ufinet-web-functions'

const disabledSelectEditor = (options: { rowData: ILocalService | ProcessedService; value: any }) => {
	return <UfinetSimpleInput type="text" isDisabled value={options.value || options.value?.label} onChange={() => {}} />
}

const makeSpinner = (row: ILocalService) =>
	row.state !== ServiceLocalState.Computed ? (
		<ClockLoader color={row.state === ServiceLocalState.Deleting ? 'red' : 'black'} size={20} />
	) : (
		<></>
	)

const mkLocationTextIndividual = (row: ILocalService, isOrigin: boolean): string => {
	const locationData = (
		isOrigin
			? [
					row.originPopSelect?.label,
					row.originAddress,
					`(${formatCellNumber(row.originLatitude, coordinatesCellNumberFormattingSettings)}, ${formatCellNumber(
						row.originLongitude,
						coordinatesCellNumberFormattingSettings
					)})`,
			  ]
			: [
					row.destinationPopSelect?.label,
					row.destinationAddress,
					`(${formatCellNumber(row.destinationLatitude, coordinatesCellNumberFormattingSettings)}, ${formatCellNumber(
						row.destinationLongitude,
						coordinatesCellNumberFormattingSettings
					)})`,
			  ]
	)
		.filter(Boolean)
		.join(', ')

	return locationData
}

const mkLocationTextBulk = (row: IServerService, isOrigin: boolean): string => {
	let locationData = (
		isOrigin
			? [
					row.originPoint?.name || row.originPoint?.address,
					`(${formatCellNumber(row.originPoint?.latitude, coordinatesCellNumberFormattingSettings)}, ${formatCellNumber(
						row.originPoint?.longitude,
						coordinatesCellNumberFormattingSettings
					)})`,
			  ]
			: [
					row.destinationPoint?.name || row.destinationPoint?.address,
					`(${formatCellNumber(
						row.destinationPoint?.latitude,
						coordinatesCellNumberFormattingSettings
					)}, ${formatCellNumber(row.destinationPoint?.longitude, coordinatesCellNumberFormattingSettings)})`,
			  ]
	)
		.filter(Boolean)
		.join(', ')

	const placeReference = isOrigin ? row.originPoint?.placeReference : row.destinationPoint.placeReference
	if (placeReference) locationData = `${placeReference} - ${locationData}`

	return locationData
}

export const colsIntService: ColData[] = [
	{
		field: 'pointNumber',
		header: 'SERVICE.POINTS',
		width: '7rem',
		nonFilterable: true,
		body: (row: ILocalService) => (
			<div className="d-flex justify-content-between align-items-center ufinet-table-cell">
				{makeSpinner(row)}
				<span className="p-6">{row.pointNumber! + 1}</span>
			</div>
		),
		customEditableField: (col: ColData, options) => {
			return <UfinetSimpleInput type="text" isDisabled value={options.rowIndex + 1} onChange={() => {}} />
		},
	},
	{
		field: 'originPoint',
		header: 'SERVICE.ORG',
		width: '12rem',
		nonFilterable: true,
		body: (row: ILocalService | ProcessedService) => {
			const value = !row.isBulk
				? mkLocationTextIndividual(row as ILocalService, true)
				: mkLocationTextBulk(row as ProcessedService, true)

			return <div className="ufinet-table-cell overflow">{value}</div>
		},
		customEditableField: (col: ColData, options) => {
			const service: ILocalService | ProcessedService = options.rowData
			const value = !service.isBulk
				? mkLocationTextIndividual(service as ILocalService, true)
				: mkLocationTextBulk(service as ProcessedService, true)

			return <UfinetSimpleInput type="text" isDisabled value={value} onChange={() => {}} />
		},
	},
	{
		field: 'destinationPoint',
		header: 'SERVICE.DEST',
		width: '12rem',
		nonFilterable: true,
		body: (row: ILocalService | ProcessedService) => {
			const value = !row.isBulk
				? mkLocationTextIndividual(row as ILocalService, false)
				: mkLocationTextBulk(row as ProcessedService, false)

			return <div className="ufinet-table-cell overflow">{value}</div>
		},
		customEditableField: (col: ColData, options) => {
			const service: ILocalService | ProcessedService = options.rowData
			const value = !service.isBulk
				? mkLocationTextIndividual(service as ILocalService, false)
				: mkLocationTextBulk(service as ProcessedService, false)

			return <UfinetSimpleInput type="text" isDisabled value={value} onChange={() => {}} />
		},
	},
	{
		field: 'serviceType',
		header: 'SERVICE.SERVICE',
		width: '8rem',
		nonFilterable: true,
		body: (row: ILocalService | ProcessedService) => (
			<div className="ufinet-table-cell">{row.serviceType || row.serviceTypeSelect.label}</div>
		),
		customEditableField: (col, options) => disabledSelectEditor(options),
	},
	{
		field: 'bandwidthSelect',
		header: 'SERVICE.BANDWIDTH',
		width: '12rem',
		body: (row: ILocalService | ProcessedService) => {
			const service = row as ProcessedService
			return (
				<div className="ufinet-table-cell">
					{makeBandwidthLabel(
						service.bandwidthUpdated || service.bandwidth || service.bandwidthSelect.bandwidth,
						service.bandwidthUnitUpdated || service.bandwidthUnit || service.bandwidthSelect.unit
					)}
				</div>
			)
		},
		editable: true,
		customEditableField: bandwidthEditableField,
		nonFilterable: true,
	},
	{
		field: 'deadlineUpdated',
		header: 'SERVICE.DEADLINE',
		width: '10rem',
		type: 'number',
		editable: true,
		nonFilterable: true,
		body: (row: ILocalService | ProcessedService) => {
			const service = row as ProcessedService
			return (
				<div className="ufinet-table-cell">
					{formatCellNumber(service.deadlineUpdated || service.deadline, tableDeadlineFormattingSettings)}
				</div>
			)
		},
		customEditableField: mkGenericEditableField<ILocalService>('deadlineUpdated', 'number', {
			validChangeCondition: (p) => !isNaN(p) && isInteger(p) && p > 0 && p < 61,
			fieldFallback: 'deadline',
			onInvalidValue: () => undefined,
		}),
	},
	{
		field: 'nrc',
		header: 'SERVICE.NRC',
		width: '8rem',
		nonFilterable: true,
		body: (row: ILocalService | ProcessedService) => (
			<div className="ufinet-table-cell">{formatCellNumber(row.nrc, tableDataFormattingSettings)}</div>
		),
	},
	{
		field: 'mrc',
		header: 'SERVICE.MRC',
		width: '8rem',
		nonFilterable: true,
		body: (row: ILocalService | ProcessedService) => (
			<div className="ufinet-table-cell">{formatCellNumber(row.mrc, tableDataFormattingSettings)}</div>
		),
	},
	{
		field: 'nrcAdjustment',
		header: 'SERVICE.NRC_ADJUSTMENT_SHORT',
		width: '8rem',
		editable: true,
		nonFilterable: true,
		body: (row: ILocalService | ProcessedService) => (
			<div className="ufinet-table-cell">
				{formatCellNumber(row.nrc === row.nrcAdjustment ? undefined : row.nrcAdjustment, tableCostsFormattingSettings)}
			</div>
		),
		customEditableField: mkGenericEditableField('nrcAdjustment', 'text', {
			validChangeCondition: (p) => !isNaN(p),
			onInvalidValue: () => undefined,
		}),
	},
	{
		field: 'mrcAdjustment',
		header: 'SERVICE.MRC_ADJUSTMENT_SHORT',
		width: '8rem',
		editable: true,
		nonFilterable: true,
		body: (row: ILocalService | ProcessedService) => (
			<div className="ufinet-table-cell">
				{formatCellNumber(row.mrc === row.mrcAdjustment ? undefined : row.mrcAdjustment, tableCostsFormattingSettings)}
			</div>
		),
		customEditableField: mkGenericEditableField('mrcAdjustment', 'text', {
			validChangeCondition: (p) => !isNaN(p),
			onInvalidValue: () => undefined,
		}),
	},
	{
		field: 'distance',
		header: 'SERVICE.DISTANCE',
		width: '8rem',
		nonFilterable: true,
		body: (row: ILocalService | ProcessedService) => (
			<div className="ufinet-table-cell">{formatCellNumber(row.distance, tableDataFormattingSettings)}</div>
		),
	},
	{
		field: 'pCost',
		header: 'SERVICE.P_COST',
		width: '12rem',
		nonFilterable: true,
		body: (row: ILocalService | ProcessedService) => (
			<div className="ufinet-table-cell">{formatCellNumber(row.pCost, tableDataFormattingSettings)}</div>
		),
	},
	{
		field: 'recCost',
		header: 'SERVICE.REC_COST',
		width: '9rem',
		nonFilterable: true,
		body: (row: ILocalService | ProcessedService) => (
			<div className="ufinet-table-cell">{formatCellNumber(row.recCost, tableDataFormattingSettings)}</div>
		),
	},
	{
		field: 'van',
		header: 'SERVICE.VAN_SHORT',
		width: '9rem',
		nonFilterable: true,
		body: (row: ILocalService | ProcessedService) => (
			<div className="ufinet-table-cell">{formatCellNumber(row.van, tableDataFormattingSettings)}</div>
		),
	},
	{
		field: 'status',
		header: 'SERVICE.STATUS',
		width: '12rem',
		nonFilterable: true,
		body: (row: ILocalService | ProcessedService) => {
			const service = row as ProcessedService
			return (
				<div className="ufinet-table-cell">
					{(service.status === ServiceServerStatus.NOT_CALCULATED &&
					service.statusDetails &&
					!service.statusDetails.includes('Unknown')
						? service.statusName + ' - ' + service.statusDetails
						: service.statusName) || '-'}
				</div>
			)
		},
	},
]

export const colsExtService: ColData[] = [
	{
		field: 'pointNumber',
		header: 'SERVICE.POINTS',
		width: '7rem',
		body: (row: ILocalService) => (
			<div className="d-flex justify-content-between align-items-center ufinet-table-cell">
				{makeSpinner(row)}
				<span className="p-6">{row.pointNumber! + 1}</span>
			</div>
		),
		nonFilterable: true,
		customEditableField: (col: ColData, options) => {
			return <UfinetSimpleInput type="text" isDisabled value={options.rowIndex + 1} onChange={() => {}} />
		},
	},
	{
		field: 'originPoint',
		header: 'SERVICE.ORG',
		width: '12rem',
		nonFilterable: true,
		body: (row: ILocalService | ProcessedService) => {
			const value = !row.isBulk
				? mkLocationTextIndividual(row as ILocalService, true)
				: mkLocationTextBulk(row as ProcessedService, true)

			return <div className="ufinet-table-cell overflow">{value}</div>
		},
		customEditableField: (col: ColData, options) => {
			const service: ILocalService | ProcessedService = options.rowData
			const value = !service.isBulk
				? mkLocationTextIndividual(service as ILocalService, true)
				: mkLocationTextBulk(service as ProcessedService, true)

			return <UfinetSimpleInput type="text" isDisabled value={value} onChange={() => {}} />
		},
	},
	{
		field: 'destinationPoint',
		header: 'SERVICE.DEST',
		width: '12rem',
		nonFilterable: true,
		body: (row: ILocalService | ProcessedService) => {
			const value = !row.isBulk
				? mkLocationTextIndividual(row as ILocalService, false)
				: mkLocationTextBulk(row as ProcessedService, false)

			return <div className="ufinet-table-cell overflow">{value}</div>
		},
		customEditableField: (col: ColData, options) => {
			const service: ILocalService | ProcessedService = options.rowData
			const value = !service.isBulk
				? mkLocationTextIndividual(service as ILocalService, false)
				: mkLocationTextBulk(service as ProcessedService, false)

			return <UfinetSimpleInput type="text" isDisabled value={value} onChange={() => {}} />
		},
	},
	{
		field: 'serviceTypeSelect',
		header: 'SERVICE.SERVICE',
		width: '8rem',
		body: (row: ILocalService | ProcessedService) => (
			<div className="ufinet-table-cell">{row.serviceTypeSelect.label}</div>
		),
		nonFilterable: true,
		customEditableField: (col, options) => disabledSelectEditor(options),
	},
	{
		field: 'bandwidthSelect',
		header: 'SERVICE.BANDWIDTH',
		width: '12rem',
		body: (row: ILocalService | ProcessedService) => {
			const service = row as ProcessedService
			return (
				<div className="ufinet-table-cell">
					{makeBandwidthLabel(
						service.bandwidthUpdated || service.bandwidth,
						service.bandwidthUnitUpdated || service.bandwidthUnit
					)}
				</div>
			)
		},
		customEditableField: (col, options) => disabledSelectEditor(options),
		nonFilterable: true,
	},
	{
		field: 'deadlineUpdated',
		header: 'SERVICE.DEADLINE',
		width: '8rem',
		nonFilterable: true,
		body: (row: ILocalService | ProcessedService) => {
			const service = row as ProcessedService
			return (
				<div className="ufinet-table-cell">
					{formatCellNumber(service.deadlineUpdated || service.deadline, { figures: 0, placeholder: '-' })}
				</div>
			)
		},
		customEditableField: (col, options) => disabledSelectEditor(options),
	},
]

function bandwidthEditableField(col: ColData, options: any) {
	const service: ProcessedService = options.rowData
	const technologyId =
		service.serviceTypeSelect?.value === ProductId.INTERNET
			? Technology.Ethernet
			: service.technologySelect?.value || service.technologyId || ''
	return (
		<BandwidthEditSelect
			value={options.rowData.bandwidthSelect}
			noLabel
			labelTitle=""
			unclearable
			onChange={(e: any) => {
				!!e && options.editorCallback(e)
			}}
			className="w-100"
			// @ts-expect-error merge types
			bandwidthRequest={{
				countryId: service.countrySelect!.value,
				corporateGroupId: service.corporateGroupSelect!.value,
				clientId: service.clientSelect!.value,
				productId: service.serviceTypeSelect!.value,
				accessId: service.accessSelect?.value || service.accessId || undefined,
				technologyId,
				...localServiceCoordinates(service),
			}}
		/>
	)
}
