import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { MapWrapper } from 'components/google-maps/wrapper/MapWrapper'

import { useFormik } from 'formik'
import { mapDefaults } from 'modules/google-maps/mapDefaults'
import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import {
	ClientSelectOption,
	Filter,
	IFilterState,
	IUfinetSelectOption,
	emptyUfinetSelectOption,
} from 'ufinet-web-components'
import {
	AuthContext,
	ILocalService,
	IQuotation,
	IQuotationConfirmRequest,
	IQuotationRepository,
	IQuotationRequest,
	IServiceUpdateRequest,
	IServicesDeletionRequest,
	IServicesRepository,
	ProcessedService,
	QuotationConfirmationStatus,
	ServiceLocalState,
	ServiceServerStatus,
	initialQuotationIndividual,
	onFormikChanges,
	populateServiceFromUpdateResponse,
	quotationRequestFromServicesModel,
	quotationService,
	serviceUpdateRequestFromServiceModel,
	servicesService,
	silentFailureOptionsFetch,
	useInternalUser,
	useModal,
	useTranslator,
} from 'ufinet-web-functions'

import { useMapWrapper } from 'components/google-maps/wrapper/MapWrapperHook'
import { HttpPrefeasibilityRepository } from 'modules/prefeasibility/infrastructure/HttpPrefeasibilityRepository'
import { HttpTechnicalGroupRepository } from 'modules/prefeasibility/infrastructure/HttpTechnicalGroupRepository'
import { NewServiceModal } from 'pages/quotation/common/components/NewServiceModal'
import { QuotationCards } from '../../common/components/QuotationCards'
import { ServiceTable } from '../../common/components/service-table/ServiceTable'
import { ModalNoValidClient } from './ModalNoValidClient'

export interface ISelect {
	label: string
	value: string
}

export interface ClientSelect extends IUfinetSelectOption {
	kamId?: string
}

type IndividualQuotationFormProps = {
	aqsId: string
	onReset?: () => void
}

const IndividualQuotationForm: FC<IndividualQuotationFormProps> = ({ aqsId, onReset }) => {
	const intl = useIntl()
	const [resetFilter, setResetFilter] = useState(false)
	const [serviceModal, showServiceModal, hideServiceModal] = useModal()
	const onChange = useCallback(onFormikChanges, [])
	const resetFilterCallback = useCallback(() => {
		setResetFilter(false)
	}, [])

	const translate = useTranslator()

	const authData = useContext(AuthContext)

	const prefeasibilityRepository = useMemo(() => HttpPrefeasibilityRepository(authData), [authData])
	const technicalGroupRepository = useMemo(() => HttpTechnicalGroupRepository(authData), [authData])

	const internalUser = useInternalUser()

	const _serviceService: IServicesRepository = useMemo(() => servicesService(authData), [authData])
	const _quotationService: IQuotationRepository = useMemo(() => quotationService(authData), [authData])

	const [quotationData, setQuotationData] = useState<IQuotation>(initialQuotationIndividual)
	const [servicesModDate, setServicesModDate] = useState<Date>(new Date())

	const [selectedCountry, setSelectedCountry] = useState<string>()
	const [selectedCountryName, setSelectedCountryName] = useState<string>()
	const selectedCountryId = useRef<string>()
	const [selectedContact, setSelectedContact] = useState<string>()
	const [selectedCorporateGroup, setSelectedCorporateGroup] = useState<string>()
	const [selectedClient, setSelectedClient] = useState<string>()
	const [isTableUpdating, setIsTableUpdating] = useState(false)

	const quotationConfirmed = quotationData.confirmationStatus === QuotationConfirmationStatus.Confirmed

	const initialValues = {
		countrySelect: emptyUfinetSelectOption,
		corporateGroupSelect: emptyUfinetSelectOption,
		clientSelect: emptyUfinetSelectOption,
		contactSelect: emptyUfinetSelectOption,
		services: [] as ProcessedService[],
		reference: '',
		finalClient: '',
	}

	// FORM-STATE/WORKFLOW FUNCTIONALITY

	const formik = useFormik({
		initialValues,
		onSubmit: () => {},
		validateOnChange: true,
		validateOnBlur: true,
		enableReinitialize: true,
	})

	const getServices = () => [...formik.values.services]

	const {
		mapWrapperRef,
		mapControls,
		mapMarkers,
		mapKmzUrls,
		mapPolygons,
		mapOptions,
		mapDirections,
		onMapLoaded,
		onMapClick,
		onMapInfoWindowClose,
		clearMap,
	} = useMapWrapper(formik, selectedCountry, selectedCountryName)

	const setFilter = (filterData: IFilterState) => {
		onChange(formik, 'countrySelect')(filterData.countrySelect)
		onChange(formik, 'corporateGroupSelect')(filterData.corporateGroupSelect)
		onChange(formik, 'clientSelect')(filterData.clientSelect)
		onChange(formik, 'contactSelect')(filterData.contactSelect)
		onChange(formik, 'reference')(filterData.reference)
		onChange(formik, 'finalClient')(filterData.finalClient)
		setQuotationData({ ...quotationData, reference: filterData.reference, finalClient: filterData.finalClient })
		showServiceModal()
	}

	const _unlockForm = () => {
		setQuotationData({ ...quotationData, confirmationStatus: QuotationConfirmationStatus.Unconfirmed })
	}

	const resetForm = () => {
		formik.resetForm()
	}

	const resetIndividualQuotationPage = () => {
		setQuotationData(initialQuotationIndividual)
		resetForm()
		onReset?.()
	}

	// SERVER-QUOTATION MANAGEMENT

	// Calculate single service

	const updateService = (updatedService: ProcessedService, preUpdatedService?: ProcessedService) => {
		// Update the affected service in state
		setIsTableUpdating(true)
		const updatedServices: ILocalService[] = getServices().map((service) =>
			service.pointNumber === updatedService.pointNumber
				? { ...updatedService, state: ServiceLocalState.Computing }
				: service
		)
		onChange(formik, 'services')(updatedServices)

		// Send update request
		const req: IServiceUpdateRequest = serviceUpdateRequestFromServiceModel(
			aqsId,
			updatedService as ProcessedService,
			preUpdatedService as ProcessedService
		)

		return _serviceService
			.updateService(req, silentFailureOptionsFetch)
			.then((response) => {
				// Complete/overwrite service with server data
				const serverService: ProcessedService = {
					...populateServiceFromUpdateResponse(updatedService, response, intl, preUpdatedService),
					state: ServiceLocalState.Computed,
				}
				const currentServices = getServices()
				// Find the corresponding service and complete it in state
				const serviceIndex = currentServices.findIndex((service) => service.id === serverService.id)
				if (serviceIndex !== -1) {
					currentServices.splice(serviceIndex, 1, serverService)
					onChange(formik, 'services')(currentServices)
					if (serverService.status === ServiceServerStatus.CALCULATED) {
						if (!serverService.statusDetails) {
							toast.success(intl.formatMessage({ id: 'SERVICE.CALCULATE.CALCULATED' }))
						} else {
							toast.warn(intl.formatMessage({ id: 'SERVICE.CALCULATE.WARNING' }))
						}
					} else {
						if (internalUser) toast.warn(intl.formatMessage({ id: 'SERVICE.CALCULATE.WARNING' }))
					}
				} else {
					toast.warn(intl.formatMessage({ id: 'SERVICE.UPDATE.ERROR' }))
				}
				return response
			})
			.catch((err) => {
				console.error(err)
				// Return to base state before updates
				onChange(
					formik,
					'services'
				)(getServices().map((s) => (s.id === updatedService.id ? { ...s, state: ServiceLocalState.Computed } : s)))
				toast.error(intl.formatMessage({ id: 'SERVICE.UPDATE.ERROR' }))
			})
			.finally(() => setIsTableUpdating(false))
	}

	const deleteServices = (serviceIds: number[]): Promise<void> => {
		setIsTableUpdating(true)
		const newStateServices = getServices().map((service) => ({
			...service,
			state: serviceIds.includes(service.id!) ? ServiceLocalState.Deleting : service.state,
		}))
		onChange(formik, 'services')(newStateServices)

		const req: IServicesDeletionRequest = { aqsId, serviceIds }
		return (
			_serviceService
				.deleteServices(req, silentFailureOptionsFetch)
				// eslint-disable-next-line promise/always-return
				.then(() => {
					onChange(
						formik,
						'services'
					)(
						getServices()
							.filter((s) => !serviceIds.includes(s.id!))
							.map((s, idx) => ({ ...s, pointNumber: idx }))
					)
					toast.success(intl.formatMessage({ id: 'SERVICE.DELETE.DELETED' }))
				})
				.catch(() => {
					onChange(formik, 'services')(getServices().map((s) => ({ ...s, state: ServiceLocalState.Computed })))
					toast.error(intl.formatMessage({ id: 'SERVICE.DELETE.ERROR' }))
				})
				.finally(() => setIsTableUpdating(false))
		)
	}

	const calculateProject = (stateServices: ILocalService[]) => {
		const quotationStartDate = new Date()
		setQuotationData({ ...quotationData, calculating: true, startDate: quotationStartDate })
		const req: IQuotationRequest = quotationRequestFromServicesModel(aqsId, stateServices)

		_quotationService
			.calculateQuotation(req, silentFailureOptionsFetch)
			.then((res) => {
				// Quotation results may be outdated
				if (quotationStartDate >= servicesModDate)
					setQuotationData({
						...quotationData,
						...res,
						currency:
							`${res.moneda.name || ''}${res.moneda.symbol ? ` (${res.moneda.symbol})` : ''}` ||
							intl.formatMessage({ id: 'UNKNOWN' }),
						calculating: false,
						outdated: false,
						reference: quotationData.reference,
						finalClient: quotationData.finalClient,
					})
				return res
			})
			.catch((_err) => {
				toast.error(intl.formatMessage({ id: 'ERROR.QUOTATION' }))
				setQuotationData({ ...quotationData, calculating: false })
			})
	}

	const confirmPrefeasibility = useCallback(() => {
		resetIndividualQuotationPage()
	}, [])

	const confirmQuotation = async (): Promise<void> => {
		setQuotationData({ ...quotationData, confirmationStatus: QuotationConfirmationStatus.Confirming })
		const req: IQuotationConfirmRequest = {
			id: aqsId,
			contactId: formik.values?.contactSelect?.value || undefined,
			reference: formik.values.reference || undefined,
			finalClient: formik.values.finalClient || undefined,
		}
		try {
			await _quotationService.confirmQuotation(req, silentFailureOptionsFetch)
			setQuotationData({ ...quotationData, confirmationStatus: QuotationConfirmationStatus.Confirmed })
			toast.success(intl.formatMessage({ id: 'QUOTATION.CONFIRM.SENT.SUCCESS' }))
			resetIndividualQuotationPage()
		} catch (_err) {
			setQuotationData({ ...quotationData, confirmationStatus: QuotationConfirmationStatus.Unconfirmed })
			toast.error(intl.formatMessage({ id: 'QUOTATION.CONFIRM.SENT.ERROR' }))
		}
	}

	// Observe changes in services and auto-recompute quotation
	useEffect(() => {
		const services = getServices()
		setServicesModDate(new Date())
		setQuotationData({ ...quotationData, outdated: true })
		if (!services) return

		if (services.length === 0) {
			setQuotationData(initialQuotationIndividual)
		} else if (services.every((s) => s.state === ServiceLocalState.Computed)) {
			calculateProject(services)
		}
	}, [formik.values])

	// MAP FUNCTIONALITY

	const afterCountryChange = useCallback(
		(country: IUfinetSelectOption) => {
			setSelectedCountry(country?.value)
			selectedCountryId.current = country?.value
			setSelectedCountryName(country?.label)
			clearMap()

			if (!country?.label) return
			mapWrapperRef.current?.placesService?.textSearch({ query: country.label, type: 'country' }, (results) => {
				if (!!results && results[0]?.geometry?.viewport) {
					mapWrapperRef.current?.map?.fitBounds(results[0].geometry?.viewport, mapDefaults.mapBoundingPadding)
				}
			})
		},
		[clearMap]
	)

	const afterCorporateGroupChange = (cg: IUfinetSelectOption) => {
		setSelectedCorporateGroup(cg?.value)
	}

	const afterContactChange = (contact: IUfinetSelectOption | undefined) => {
		setSelectedContact(contact?.value)
	}

	const afterClientChange = (client: ClientSelectOption) => {
		if (!client || client?.kamId) {
			setSelectedClient(client?.value)
		} else {
			ModalNoValidClient(translate)
			setSelectedClient(undefined)
		}
	}

	return (
		<div className="container p-15 pt-5 h-100 d-flex flex-column justify-content-center">
			<Filter
				internalUser={internalUser}
				resetFilter={resetFilter}
				resetFilterCallback={resetFilterCallback}
				setFilter={setFilter}
				afterCountryChange={afterCountryChange}
				afterCorporateGroupChange={afterCorporateGroupChange}
				afterClientChange={afterClientChange}
				afterContactChange={afterContactChange}
				afterReferenceChange={(reference) => onChange(formik, 'reference')(reference)}
				afterFinalClientChange={(finalClient) => onChange(formik, 'finalClient')(finalClient)}
				disabled={{
					allowCountrySelection: formik.values.services.length === 0,
					allowCorporateGroupSelection: !!selectedCountry && formik.values.services.length === 0,
					allowClientSelection: !!selectedCountry && !!selectedCorporateGroup && formik.values.services.length === 0,
					allowSubmit: !!selectedClient && !quotationConfirmed && !isTableUpdating,
					allowContact: true,
					allowReference: true,
					allowFinalClient: true,
				}}
				required={{ requiredCountry: true, requiredCorporateGroup: true, requiredClient: true }}
				hidden={{ hideContact: !internalUser, hideFinalClient: false }}
				icon={faPlus}
				submitButtonContent={intl.formatMessage({ id: 'SERVICE.NEW' })}
			/>
			<NewServiceModal
				aqsId={aqsId}
				formik={formik}
				isOpen={serviceModal}
				hideModal={hideServiceModal}
				getServices={getServices}
				serviceService={_serviceService}
			/>
			<form onSubmit={formik.handleSubmit}>
				{internalUser && (
					<div className="mt-10">
						<QuotationCards quotationData={quotationData} />
					</div>
				)}
				<div className="mt-10">
					<ServiceTable
						aqsId={aqsId}
						contactId={selectedContact}
						internalUser={internalUser}
						services={formik.values.services}
						quotationData={{ ...quotationData, isBulk: false }}
						prefeasibilityRepository={prefeasibilityRepository}
						technicalGroupRepository={technicalGroupRepository}
						setModifiedService={(newServiceData, preUpdateServiceData) => {
							updateService({ ...newServiceData }, preUpdateServiceData)
						}}
						deleteServices={deleteServices}
						confirmQuotation={confirmQuotation}
						confirmPrefeasibility={confirmPrefeasibility}
						resetForm={resetForm}
						countryId={selectedCountry ?? ''}
					/>
				</div>
				<div className="mt-10">
					<MapWrapper
						ref={mapWrapperRef}
						additionalStyles={{ height: '50vh' }}
						mapOptions={mapOptions}
						markers={mapMarkers}
						kmzUrls={mapKmzUrls}
						polygons={mapPolygons}
						onLoad={onMapLoaded}
						onClick={onMapClick}
						onInfoWindowClose={onMapInfoWindowClose}
						directions={mapDirections}
						controls={mapControls}
					/>
				</div>
			</form>
		</div>
	)
}
export { IndividualQuotationForm }
