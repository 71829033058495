import { faArrowsRotate, faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PendingQuotationResponse } from 'modules/prefeasibility/application/pendingQuotationResponse'
import { usePendingQuotationsQuery } from 'modules/prefeasibility/application/queries/PendingQuotationsQueries'
import { PrefeasibilityRepository } from 'modules/prefeasibility/domain/repository/PrefeasibilityRepository'
import { DataTablePFSEvent } from 'primereact/datatable'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ColData, PATH_DETAIL, Table, UfinetButton } from 'ufinet-web-components'
import { AuthContext, PagingData, useTranslator } from 'ufinet-web-functions'

interface PendingQuotationsTableProps {
	prefeasibilityRepository: PrefeasibilityRepository
}

export const PendingQuotationsTable = ({ prefeasibilityRepository }: PendingQuotationsTableProps) => {
	const translate = useTranslator()
	const { userData } = useContext(AuthContext)
	const [tablePFSEvent, setTablePFSEvent] = useState<DataTablePFSEvent>()
	const navigate = useNavigate()

	const defaultPaging: PagingData = useMemo(
		() => ({
			pageNumber: 0,
			pageSize: 10,
			totalElements: 0,
			totalPages: 0,
		}),
		[]
	)
	const [paging, setPaging] = useState<PagingData>(defaultPaging)
	const {
		data: pendingQuotations,
		isFetching: isFetchingPendingQuotations,
		refetch: refetchPendingQuotations,
	} = usePendingQuotationsQuery(prefeasibilityRepository, tablePFSEvent)

	useEffect(() => {
		setPaging(pendingQuotations?.pagingData ?? defaultPaging)
	}, [pendingQuotations])

	const handleNavigate = (rowQuotationId: string) => navigate(`${PATH_DETAIL}/${rowQuotationId}`)

	const cols: ColData[] = [
		{
			field: 'quotationPhxAqsId',
			header: 'QUOTATIONS.PENDING.TABLE.QUOTATION_PHX_AQS_ID',
			width: '15%',
			nonSortable: true,
			nonFilterable: true,
		},
		{
			field: 'clientName',
			header: 'QUOTATIONS.PENDING.TABLE.CLIENT_NAME',
			width: '18%',
			nonSortable: true,
			nonFilterable: true,
		},
		{
			field: 'totalServices',
			header: 'QUOTATIONS.PENDING.TABLE.TOTAL_SERVICES',
			width: '9%',
			nonSortable: true,
			nonFilterable: true,
		},
		{
			field: 'totalNotCalculatedServices',
			header: 'QUOTATIONS.PENDING.TABLE.TOTAL_NOT_CALCULATED_SERVICES',
			width: '9%',
			nonSortable: true,
			nonFilterable: true,
		},
		{
			field: 'totalCalculatedServices',
			header: 'QUOTATIONS.PENDING.TABLE.TOTAL_CALCULATED_SERVICES',
			width: '9%',
			nonSortable: true,
			nonFilterable: true,
		},
		{
			field: 'requestedBy',
			header: 'QUOTATIONS.PENDING.TABLE.REQUESTED_BY',
			width: '18%',
			nonSortable: true,
			nonFilterable: true,
			body: (row: PendingQuotationResponse) =>
				row.requestedBy === userData?.username || row.requestedBy === userData?.user.fullName
					? translate(`PREFEASIBILITY.REQUESTED_BY.YOURSELF`)
					: row.requestedBy,
		},
		{
			field: 'quotationStatus',
			header: 'QUOTATIONS.PENDING.TABLE.QUOTATION_STATUS',
			width: '14%',
			nonSortable: true,
			nonFilterable: true,
			body: (row: PendingQuotationResponse) => translate(`PREFEASIBILITY.STATUSES.${row.quotationStatus}`),
		},
		{
			field: 'actions',
			header: 'DETAILS',
			width: '8%',
			nonSortable: true,
			contentDisplayFormat: 'center',
			nonFilterable: true,
			body: (row: PendingQuotationResponse) => (
				<FontAwesomeIcon className="fa-solid" size="lg" icon={faEye} onClick={() => handleNavigate(row.id)} />
			),
		},
	]

	return (
		<Table
			dataKey="quotationPhxAqsId"
			className="fs-4 service-table"
			cols={cols}
			headerButtons={
				<UfinetButton
					className={`me-3 ${isFetchingPendingQuotations ? 'edit-disabled' : ''}`}
					iconClassName={isFetchingPendingQuotations ? 'rotating' : ''}
					icon={faArrowsRotate}
					onClick={() => {
						!isFetchingPendingQuotations && refetchPendingQuotations()
					}}
					content=""
				/>
			}
			content={pendingQuotations?.pendingQuotations}
			onRowDoubleClick={(row) => handleNavigate(row.data.id)}
			afterTablePFSEvent={setTablePFSEvent}
			lazySettings={
				paging && {
					...paging,
					loading: isFetchingPendingQuotations,
					onPage: () => {},
					onFilter: () => {},
					onSort: () => {},
				}
			}
		/>
	)
}
