import { FC, SyntheticEvent, useCallback, useContext, useMemo, useRef, useState } from 'react'
import { OverlayTrigger, Tab, Tabs, Tooltip } from 'react-bootstrap'
import { useIntl } from 'react-intl'

import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { EquipmentTable } from 'pages/costs/EquipmentAndConstruction/EquipmentTable'
import { FOTable } from 'pages/costs/EquipmentAndConstruction/FOTable'
import { toast } from 'react-toastify'
import {
	HardFilterWrapper,
	HardFilterWrapperHandle,
	UfinetActionButtonHandle,
	UfinetBox,
	UfinetSectionBox,
} from 'ufinet-web-components'
import {
	AreaType,
	AuthContext,
	equipmentCostsService,
	foCostsService,
	getTableTranslations,
	ICostsEquipmentResponse,
	ICostsFOResponse,
	ICostsQueryCriteria,
	useAsync,
} from 'ufinet-web-functions'
import { IXLSDownloadRequest } from 'ufinet-web-functions/dist/model/common/networking/typesAndValues'

enum TabsOptions {
	FO = 'FO',
	EQUIPMENT = 'EQUIPMENT',
}

export type EquipmentAndConstructionTableBaseProps = {
	findCosts: () => void
	className?: string
	loadingRecords?: boolean
}

type EquipmentOrConstructionCost = ICostsFOResponse | ICostsEquipmentResponse

const EquipmentAndConstructionPage: FC = () => {
	const intl = useIntl()
	const searchButtonRef = useRef<UfinetActionButtonHandle>()
	const [tab, setTab] = useState<TabsOptions>(TabsOptions.FO)
	const [foResponse, setFoResponse] = useState<ICostsFOResponse[]>([])
	const [equipmentResponse, setEquipmentResponse] = useState<ICostsEquipmentResponse[]>([])

	const [loadingRecords, setLoadingRecords] = useState<boolean>(true)

	const authData = useContext(AuthContext)

	const _FOCostsService = useMemo(() => foCostsService(authData, intl), [authData, intl])
	const _equipmentCostsService = useMemo(() => equipmentCostsService(authData, intl), [authData, intl])

	const upperFilterRef = useRef<HardFilterWrapperHandle>(null)

	const findCosts: () => Promise<EquipmentOrConstructionCost[]> = useCallback(() => {
		setLoadingRecords(true)
		searchButtonRef.current?.changeActionStatus(true)
		const criteria: ICostsQueryCriteria = {
			countries: upperFilterRef.current?.getCountries().map((e) => e.value) || [],
			networkAreas: upperFilterRef.current?.getAreas().map((e) => e.value) || [],
		}
		return tab === TabsOptions.FO
			? _FOCostsService.findByCriteria(criteria)
			: _equipmentCostsService.findByCriteria(criteria)
	}, [tab, _FOCostsService, _equipmentCostsService])

	const getParsedFOResult = (result: ICostsFOResponse[]) =>
		result.map((cost: ICostsFOResponse) => ({
			...cost,
			networkAreaName: getTableTranslations(cost.networkAreaName, intl),
			infrastructureType: intl.formatMessage({ id: cost.infrastructureType }),
			zoneType: intl.formatMessage({ id: cost.zoneType }),
		}))

	const getParsedEquipmentResult = (result: ICostsEquipmentResponse[]) =>
		result.map((cost: ICostsEquipmentResponse) => ({
			...cost,
			networkAreaName: getTableTranslations(cost.networkAreaName, intl),
			zoneType: intl.formatMessage({ id: cost.zoneType }),
		}))

	const onCostsFetched = (input: EquipmentOrConstructionCost[]): void => {
		tab === TabsOptions.FO
			? setFoResponse(getParsedFOResult(input as ICostsFOResponse[]))
			: setEquipmentResponse(getParsedEquipmentResult(input as ICostsEquipmentResponse[]))
	}

	const onCostsFailedToFetch = (): void => {
		toast.error(intl.formatMessage({ id: 'COSTS.FETCH.ERROR' }))
	}

	const runFinally = () => {
		searchButtonRef.current?.changeActionStatus(false)
		setLoadingRecords(false)
	}

	const fetchAndStore = useAsync(
		{
			asyncFn: findCosts,
			onSuccess: onCostsFetched,
			onFailure: onCostsFailedToFetch,
			runFinally: runFinally,
		},
		[tab]
	)

	const handleDownloadExcel = () => {
		const data: IXLSDownloadRequest = {
			countries: upperFilterRef.current?.getCountries().map((e) => e.value) || [],
			networkAreas: upperFilterRef.current?.getAreas().map((e) => e.value) || [],
		}
		tab === TabsOptions.FO ? _FOCostsService.downloadXLS(data) : _equipmentCostsService.downloadXLS(data)
	}

	/// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	/// /////////////////////////////////////// HEADER TABLE AND ACTION BUTTONS //////////////////////////////////////////
	/// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const getActionButtons = () => {
		return (
			<>
				<OverlayTrigger
					placement="bottom"
					delay={{ show: 250, hide: 400 }}
					overlay={<Tooltip className="tooltip-dark">{intl.formatMessage({ id: 'DOWNLOAD_EXCEL' })}</Tooltip>}
				>
					<div className="d-inline">
						<FontAwesomeIcon className="fa-icon-primary" size="1x" icon={faDownload} onClick={handleDownloadExcel} />
					</div>
				</OverlayTrigger>
			</>
		)
	}

	const handleSelect = (eventKey: string | null, _e: SyntheticEvent<unknown>) => {
		eventKey === 'fiber-cost' ? setTab(TabsOptions.FO) : setTab(TabsOptions.EQUIPMENT)
	}

	return (
		<UfinetBox limitHeightToPageBottom={false}>
			<HardFilterWrapper
				ref={upperFilterRef}
				actionButtonRef={searchButtonRef}
				forceUpdate={tab}
				search={fetchAndStore}
				networkAreaType={AreaType.OPTICAL_FIBER_AREA}
				actionButtons={getActionButtons()}
			/>
			<UfinetSectionBox>
				<Tabs id="equipment-construction-tabs" onSelect={handleSelect} defaultActiveKey="fiber-cost">
					<Tab eventKey="fiber-cost" title={intl.formatMessage({ id: 'TABS.EQUIPMENT&CONSTRUCTION.FIBER_COST' })}>
						<FOTable className="fs-5" content={foResponse} findCosts={fetchAndStore} loadingRecords={loadingRecords} />
					</Tab>
					<Tab
						eventKey="equipment-cost"
						title={intl.formatMessage({ id: 'TABS.EQUIPMENT&CONSTRUCTION.EQUIPMENT_COST' })}
					>
						<EquipmentTable
							className="fs-5"
							content={equipmentResponse}
							findCosts={fetchAndStore}
							loadingRecords={loadingRecords}
						/>
					</Tab>
				</Tabs>
			</UfinetSectionBox>
		</UfinetBox>
	)
}
export { EquipmentAndConstructionPage }
