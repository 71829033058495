import { infoWindowFromAreaForMarker } from 'modules/google-maps/infowindow/functions/infoWindowFromAreaForMarker'
import { IntlShape } from 'react-intl'
import { IKmzAreaFindResponse } from 'ufinet-web-functions'
import { v4 as uuidv4 } from 'uuid'
import { MapMarker } from '../MapMarker'
import { MapMarkerBaseOptions } from '../MapMarkerBaseOptions'
import { MapMarkerType } from '../MapMarkerType'

export const markerOptionsFromKmzGenericArea = (
	area: IKmzAreaFindResponse,
	coordinates: google.maps.LatLngLiteral,
	intl: IntlShape
): MapMarker => {
	return {
		id: uuidv4(),
		type: MapMarkerType.USER,
		options: {
			...MapMarkerBaseOptions.get(MapMarkerType.USER),
			position: coordinates,
			optimized: true,
		},
		infoWindow: {
			options: {
				...infoWindowFromAreaForMarker(area, coordinates, intl),
			},
			open: true,
		},
	}
}
