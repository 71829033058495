import { FormikProps } from 'formik'
import { FormFields, FormFieldType } from 'modules/common/domain/model/FormFields'
import {
	useCollocationQuantitiesQuery,
	useCollocationUnitsQuery,
	useEmplacementTypesQuery,
} from 'modules/product/queries/CollocationQueries'
import { CollocationRepository } from 'modules/product/repository/CollocationRepository'
import { useCallback, useMemo } from 'react'
import { ICalculationPoint, localServiceCoordinates, onFormikChanges, useTranslator } from 'ufinet-web-functions'

export const useCollocationServiceForm = (
	formik: FormikProps<any>,
	collocationRepository: CollocationRepository,
	corporateGroupId: string
) => {
	const { data: emplacementTypes, isLoading: isLoadingEmplacementTypes } =
		useEmplacementTypesQuery(collocationRepository)

	const { data: collocationUnits, isLoading: isLoadingCollocationUnits } =
		useCollocationUnitsQuery(collocationRepository)

	const getDestinationCoordinates = () => {
		const { destination } = localServiceCoordinates(formik.values) as { destination: ICalculationPoint }

		return {
			destinationLatitude: destination?.latitude,
			destinationLongitude: destination?.longitude,
		}
	}

	const {
		data: collocationQuantities,
		isLoading: isLoadingCollocationQuantities,
		isSuccess: isSuccessCollocationQuantities,
	} = useCollocationQuantitiesQuery(
		Boolean(
			formik.values.collocationService?.emplacementTypeSelect?.value &&
				formik.values.collocationService?.collocationUnitSelect?.value &&
				formik.values.destinationLatitude &&
				formik.values.destinationLongitude
		),
		collocationRepository,
		{
			collocationUnitId: formik.values.collocationService?.collocationUnitSelect?.value,
			corporateGroupId: corporateGroupId,
			...getDestinationCoordinates(),
			emplacementTypeId: formik.values.collocationService?.emplacementTypeSelect?.value,
		}
	)

	const formFields: FormFields = useMemo(() => {
		const result: FormFields = {
			emplacementType: {
				text: 'EMPLACEMENT_TYPE',
				formActions: 'collocationService.emplacementTypeSelect',
				inputType: FormFieldType.SELECT,
				isLoading: isLoadingEmplacementTypes,
				options: emplacementTypes,
			},
			collocationUnit: {
				text: 'COLLOCATION_UNIT',
				formActions: 'collocationService.collocationUnitSelect',
				inputType: FormFieldType.SELECT,
				isLoading: isLoadingCollocationUnits,
				options: collocationUnits,
			},
			collocationQuantity: {
				text: 'COLLOCATION_QUANTITY',
				formActions: 'collocationService.collocationQuantitySelect',
				inputType: FormFieldType.SELECT,
				isLoading: isLoadingCollocationQuantities,
				options: collocationQuantities,
				isDisabled: !isSuccessCollocationQuantities,
			},
		}

		return result
	}, [emplacementTypes, collocationUnits, collocationQuantities])

	const translate = useTranslator()

	const onChange = useCallback(onFormikChanges, [])
	return {
		formFields,
		onChange,
		translate,
	}
}
