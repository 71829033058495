import { useFormik } from 'formik'
import { ErrorSection } from 'pages/loadKmz/cables-boxes/steppers/ErrorSection'
import React, { useContext } from 'react'
import { useIntl } from 'react-intl'
import {
	AreaSelect,
	AreaTypeSelect,
	CountrySelect,
	IUfinetSelectOption,
	UfinetDropzone,
	WizardButtonNext,
	WizardButtonsWrapper,
} from 'ufinet-web-components'
import {
	AuthContext,
	IAreaResponse,
	IFileUploadData,
	IKmzAreaResponse,
	kmzAreaService,
	KmzMaxSize,
	useAqsError,
} from 'ufinet-web-functions'

import * as Yup from 'yup'

type Props = {
	data: IAreaResponse
	setWizardData: Function
	wizardRef: any
	actionButtonRef: any
}
const AreaModForm: React.FC<Props> = React.memo(
	({ data, setWizardData, wizardRef, actionButtonRef }: Props) => {
		const intl = useIntl()
		const [error, setError] = useAqsError()

		const authData = useContext(AuthContext)
		const _kmzAreaService = kmzAreaService(authData)

		/*
		 ******************************
		 *************Formik***********
		 ******************************
		 */
		const dataFormSchema = Yup.object().shape({
			file: Yup.mixed()
				.test('file size', `${intl.formatMessage({ id: 'ERROR.FILE.SIZE' })} 10MB`, (value) => {
					return value?.size <= KmzMaxSize
				})
				.test('file type', intl.formatMessage({ id: 'ERROR.FILE.EXTENSION' }), (value) => {
					return !value?.path?.split('.').pop()?.includes(['kmz', 'kml'])
				})
				.test('file required', intl.formatMessage({ id: 'ERROR.REQUIRED' }), (value) => value.size !== 0),
		})

		const formik = useFormik({
			initialValues: {
				countrySelect: { label: data?.countryName, value: data?.countryId } as IUfinetSelectOption,
				areaTypeSelect: {
					label: data?.typeId ? intl.formatMessage({ id: data?.typeId }) : '',
					value: data?.typeId,
				} as IUfinetSelectOption,
				area: { label: data?.name, value: data?.name } as IUfinetSelectOption,
				file: {
					path: '',
					size: 0,
				} as IFileUploadData,
			},
			validationSchema: dataFormSchema,
			onSubmit: (values) => {
				sendData(values)
			},
			validateOnChange: false,
			validateOnBlur: false,
		})
		/*
		 * end Formik
		 * */

		const sendData = (values: any) => {
			actionButtonRef.current.changeActionStatus()
			const formData = new FormData()

			formData.append('file', values.file)

			_kmzAreaService
				.uploadKmzToUpdateArea(data.id, formData)
				.then((response: IKmzAreaResponse) => {
					setWizardData({
						...data,
						filePath: formik.values.file.path.toString(),
						storageUrls: response.storageUrls,
						areas: response.areas,
					})
					wizardRef.current.goNext()
					return response
				})
				.catch((error: any) => setError(error))
				.finally(() => {
					actionButtonRef.current.changeActionStatus()
				})
		}

		const onChange = React.useCallback(
			(field: string) => (e: any) => {
				formik.setErrors({})
				formik.setFieldValue(field, e !== null ? e : {})
			},
			[]
		)

		return (
			<form onSubmit={formik.handleSubmit} className="container p-15 h-100 d-flex flex-column justify-content-center">
				<div className="row pb-10">
					<CountrySelect
						className="col-xl-4 col-12"
						withoutFetch
						onChange={onChange('countrySelect')}
						value={formik.values.countrySelect}
						isDisabled
					/>
					<AreaTypeSelect
						className="col-xl-4 my-xl-0 my-3 col-12"
						withoutFetch
						onChange={onChange('areaTypeSelect')}
						value={formik.values.areaTypeSelect}
						isDisabled
					/>
					<AreaSelect className="col-xl-4 col-12" isDisabled value={formik.values.area} />
				</div>
				<div className="row">
					<div className="col-12">
						<UfinetDropzone
							onChange={onChange('file')}
							value={formik.values.file}
							error={formik.errors.file}
							text={intl.formatMessage({ id: 'STEPPER.LOAD_KMZ.DROPZONE' })}
							accept={{ 'application/vnd.google-earth': ['.kmz', '.kml'] }}
						/>
					</div>
				</div>
				<ErrorSection error={error} />
				<WizardButtonsWrapper>
					<div />
					<WizardButtonNext
						wizardRef={wizardRef}
						actionButtonRef={actionButtonRef}
						isDisabled={!formik.isValid}
						submitForm
					/>
				</WizardButtonsWrapper>
			</form>
		)
	},
	(prevProps, nextProps) => {
		return prevProps.setWizardData === nextProps.setWizardData
	}
)

export { AreaModForm }
