import {
	defaultMapsTravelMode,
	defaultMapsUnitSystem,
	DirectionsData,
} from 'modules/google-maps/directions/typesAndValues'
import { baseMarkerForCoupling } from 'modules/google-maps/markers/functions/baseMarkerForCoupling'
import { FC, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'

const Directions: FC<DirectionsData> = ({
	request: {
		origin,
		destination,
		waypoints = [],
		travelMode = defaultMapsTravelMode(),
		unitSystem = defaultMapsUnitSystem(),
		service,
	},
	...options
}) => {
	const intl = useIntl()

	const directionsService = useMemo(() => new google.maps.DirectionsService(), [options.map, origin, destination])
	const directionsRenderer = useMemo(() => new google.maps.DirectionsRenderer(), [options.map])
	const [couplingMarker, setCouplingMarker] = useState<google.maps.Marker>()

	const routingRequest: google.maps.DirectionsRequest = {
		origin,
		destination,
		waypoints,

		optimizeWaypoints: false,
		provideRouteAlternatives: false,

		travelMode,
		unitSystem,
	}

	const isCouplingOrigin = (): boolean => {
		if (!service?.originNetworkItem) return false

		const originCouplingCoordinates = new google.maps.LatLng({
			lat: service.originNetworkItem.latitude,
			lng: service.originNetworkItem.longitude,
		})

		return destination.equals(originCouplingCoordinates)
	}

	const isCouplingDestination = (): boolean => {
		if (!service?.destinationNetworkItem) return false
		else return !isCouplingOrigin()
	}

	const processRoutingResults = (
		result: google.maps.DirectionsResult | null,
		status: google.maps.DirectionsStatus
	): void => {
		if (status === google.maps.DirectionsStatus.OK) {
			directionsRenderer.setDirections(result)
			// Create custom markers at destination (enganches) if default one are supressed
			if (options.suppressMarkers) {
				const detailsStr = isCouplingOrigin()
					? `[${intl.formatMessage({ id: 'ORIGIN' })}]`
					: isCouplingDestination()
					? `[${intl.formatMessage({ id: 'DESTINATION' })}]`
					: ''

				setCouplingMarker(
					new google.maps.Marker({
						...baseMarkerForCoupling(),
						map: options.map,
						position: destination,
						title: `${intl.formatMessage({ id: 'ROUTE.COUPLING' })} ${detailsStr}`,
					})
				)
			}
		} else console.warn(`Error while fetching maps directions: ${status}`)
	}

	useEffect(() => {
		if (!directionsRenderer.getMap()) {
			directionsRenderer.setOptions(options)
			directionsService.route(routingRequest, processRoutingResults)
		}

		return () => {
			directionsRenderer.setMap(null)
		}
	}, [directionsRenderer, directionsService])

	useEffect(() => {
		return () => {
			couplingMarker?.setMap(null)
		}
	}, [couplingMarker])

	return null
}

export { Directions }
