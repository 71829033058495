import { rest } from 'msw'
import { getAllCountriesResponseMock } from 'tests/mocks/countries/countriesMock'
import { getAreasMock } from './areas/areasMock'

const baseHandlers = [
	rest.get(/^(?!.*api\/).*$/, (req, res, ctx) => {
		return res(ctx.status(200), ctx.json([]))
	}),
]
export const handlers = [
	rest.get(/.*api\/countries/, (req, res, ctx) => {
		return res(ctx.status(200), ctx.json(getAllCountriesResponseMock))
	}),
	rest.post(/.*api\/network\/area\/find/, (req, res, ctx) => {
		return res(ctx.status(200), ctx.json(getAreasMock))
	}),
	rest.post(/.*api\/quotation\/bulk\/find/, (req, res, ctx) => {
		return res(ctx.status(200), ctx.json([]))
	}),
	rest.get(/.*api\/client\/find/, (req, res, ctx) => {
		return res(ctx.status(200), ctx.json([]))
	}),
	rest.get(/.*api\/corporate-group/, (req, res, ctx) => {
		return res(ctx.status(200), ctx.json([]))
	}),
	rest.get(/.*api\/prefeasibility\/pendingQuotations/, (req, res, ctx) => {
		return res(ctx.status(200), ctx.json({ pendingQuotations: [] }))
	}),
	rest.get(/.*svg/, (req, res, ctx) => {
		return res(
			ctx.status(200),
			ctx.body(`
				<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
					<path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512z" />
				</svg>
				`)
		)
	}),
	...baseHandlers,
]
