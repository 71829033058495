import {
	MapControlsAreasCablesBoxes,
	MapControlsAreasCablesBoxesHandle,
} from 'components/google-maps/controls/areas-cables-boxes/MapControlsAreasCablesBoxes'
import { MapWrapperHandle } from 'components/google-maps/wrapper/MapWrapper'
import { defaultMapWrapperOptions } from 'components/google-maps/wrapper/MapWrapperOptions'
import { FormikValues } from 'formik'
import { MapControlPositions } from 'modules/google-maps/controls/MapControlPositions'
import { enganchesFromServices } from 'modules/google-maps/directions/functions/enganchesFromServices'
import { DirectionsData, DirectionsRequest } from 'modules/google-maps/directions/typesAndValues'
import { MapsClickEvent } from 'modules/google-maps/events/MapsClickEvent'
import { UserClickEvent } from 'modules/google-maps/events/UserClickEvent'
import { MapsInfoWindowCloseEvent } from 'modules/google-maps/events/infowindow/MapsInfoWindowCloseEvent'
import { makeBounds } from 'modules/google-maps/functions/makeBounds'
import { mapDefaults } from 'modules/google-maps/mapDefaults'
import { MapMarker } from 'modules/google-maps/markers/MapMarker'
import { MapMarkerType } from 'modules/google-maps/markers/MapMarkerType'
import { MarkerClickEvent } from 'modules/google-maps/markers/MarkerClickEvent'
import { markerOptionsFromCoordinates } from 'modules/google-maps/markers/functions/markerOptionsFromCoordinates'
import { markerOptionsFromKmzGenericArea } from 'modules/google-maps/markers/functions/markerOptionsFromKmzGenericArea'
import { markerOptionsFromLocalService } from 'modules/google-maps/markers/functions/markerOptionsFromLocalService'
import { markerOptionsFromPOI } from 'modules/google-maps/markers/functions/markerOptionsFromPOI'
import { MapPolygon } from 'modules/google-maps/polygon/MapPolygon'
import { PolygonClickEvent } from 'modules/google-maps/polygon/PolygonClickEvent'
import { getPolygonCenter } from 'modules/google-maps/polygon/functions/getPolygonCenter'
import { markersAndPolygonFromFromKmzAreas } from 'modules/google-maps/polygon/functions/markersAndPolygonFromFromKmzAreas'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { Loading } from 'ufinet-web-components'
import {
	AreaType,
	NetworkItemType,
	ProcessedService,
	kmzAreasService,
	kmzFilesService,
	localServiceSummary,
	useAuthData,
	useDeepEffect,
	useInternalUser,
} from 'ufinet-web-functions'
import { v4 as uuidv4 } from 'uuid'

export const useMapWrapper = (
	formik: FormikValues,
	selectedCountryId: string | undefined,
	selectedCountryName: string | undefined,
	getServices: () => ProcessedService[] = () => [...formik.values.services]
) => {
	const internalUser = useInternalUser()
	const intl = useIntl()
	const authData = useAuthData()

	const [enganches, setEnganches] = useState<DirectionsRequest[]>([])

	const _kmzFileService = useMemo(() => kmzFilesService(authData), [authData])
	const _kmzAreaService = useMemo(() => kmzAreasService(authData), [authData])

	const mapControlsRef = useRef<MapControlsAreasCablesBoxesHandle>()
	const [mapControlsClosed, setMapControlsClosed] = useState<boolean>(false)
	const [mapControlsLoading, setMapControlsLoading] = useState<boolean>(false)
	const mapWrapperRef = useRef<MapWrapperHandle>(null)
	const [mapMarkers, setMapMarkers] = useState<MapMarker[]>([])
	const _mapUserMarkers = useMemo(() => mapMarkers.filter((m) => m.type === MapMarkerType.USER), [mapMarkers])
	const mapServiceMarkers = useMemo(() => mapMarkers.filter((m) => m.type === MapMarkerType.SERVICE), [mapMarkers])
	const _mapBuildingMarkers = useMemo(() => mapMarkers.filter((m) => m.type === MapMarkerType.BUILDING), [mapMarkers])
	const [mapPolygons, setMapPolygons] = useState<MapPolygon[]>([])
	const [mapKmzUrls, setMapKmzUrls] = useState<string[]>([])

	const [isMapLoaded, setIsMapLoaded] = useState(false)

	const onMapLoaded = (_e: Event, _map: google.maps.Map) => {
		setIsMapLoaded(true)
	}

	const onAreaTypesChange = useCallback(
		(networkAreaTypes: AreaType[]) => {
			setMapMarkers((oldMarkers) =>
				oldMarkers.filter((marker) => [MapMarkerType.SERVICE, MapMarkerType.COUPLING].includes(marker.type))
			)
			if (networkAreaTypes.length === 0) {
				setMapPolygons((mapPolygons) => (mapPolygons.length !== 0 ? [] : mapPolygons))
				return
			}
			setMapControlsLoading(true)

			_kmzAreaService
				.findArea({
					countryId: selectedCountryId,
					networkAreaTypes,
				})
				.then((kmzAreasData) => kmzAreasData.filter((area) => internalUser || area.visible))
				.then((kmzAreasData) => {
					const newPolygonsAndMarkers = markersAndPolygonFromFromKmzAreas(kmzAreasData, intl)
					if (newPolygonsAndMarkers.polygons.length !== 0 || mapPolygons.length !== 0)
						setMapPolygons([...newPolygonsAndMarkers.polygons])
					if (newPolygonsAndMarkers.markers.length !== 0)
						setMapMarkers((oldMarkers) => [...oldMarkers, ...newPolygonsAndMarkers.markers])
					return kmzAreasData
				})
				.catch(console.error)
				.finally(() => setMapControlsLoading(false))
		},
		[_kmzAreaService, internalUser, intl, mapPolygons.length, selectedCountryId]
	)

	const onCablesAndBoxesChange = useCallback(
		(networkItemTypes: NetworkItemType[]) => {
			if (networkItemTypes.length === 0) setMapKmzUrls([])
			else {
				setMapControlsLoading(true)
				_kmzFileService
					.findFile({
						countryId: selectedCountryId,
						networkItemTypes,
					})
					.then((kmzFilesData) => {
						setMapKmzUrls([...new Set(kmzFilesData.map((file) => file.url))])
						return kmzFilesData
					})
					.catch(console.error)
					.finally(() => setMapControlsLoading(false))
			}
		},
		[_kmzFileService, selectedCountryId]
	)

	const mapControls = useMemo(
		() => [
			{
				position: MapControlPositions.BottomCenter,
				body: selectedCountryId ? (
					<>
						<MapControlsAreasCablesBoxes
							ref={mapControlsRef}
							countryId={selectedCountryId}
							onAreaTypesChange={onAreaTypesChange}
							onCablesAndBoxesChange={onCablesAndBoxesChange}
							onClose={setMapControlsClosed}
							disabled={mapControlsLoading}
							preSelectedAreaTypes={[AreaType.ACCESS_AREA]}
						/>
						{mapControlsLoading && <Loading />}
					</>
				) : (
					<></>
				),
				className: `${!internalUser ? 'd-none' : ''} f-flex justify-content-center mb-2 ${
					mapControlsClosed ? 'w-auto start-50' : 'w-75'
				}`,
			},
		],
		[internalUser, mapControlsClosed, mapControlsLoading, onAreaTypesChange, onCablesAndBoxesChange, selectedCountryId]
	)

	const mapOptions = useMemo(
		() => ({ ...defaultMapWrapperOptions, center: selectedCountryName }),
		[selectedCountryName]
	)

	const mapDirections = useMemo<DirectionsData[]>(
		() =>
			internalUser
				? enganches.map((e) => ({
						request: e,
						service: e.service,
						suppressMarkers: true,
						preserveViewport: true,
				  }))
				: [],
		[enganches, internalUser]
	)

	useDeepEffect(() => {
		const allMapItemLocations = [
			mapServiceMarkers.map((marker) => marker.options.position),
			mapPolygons.map((polygon) => getPolygonCenter(polygon.options)),
			enganches.flatMap((e) => (e.waypoints ? e.waypoints.map((w) => w.location) : [])),
		]
			.flat()
			.filter(Boolean) as (google.maps.LatLng | google.maps.LatLngLiteral)[]

		allMapItemLocations.length &&
			mapWrapperRef.current?.map?.fitBounds(makeBounds(allMapItemLocations), mapDefaults.mapBoundingPadding)
	}, [mapWrapperRef.current?.map, mapServiceMarkers, mapPolygons, enganches])

	const mapMarkersFromServices = useCallback(
		(services: ProcessedService[]): MapMarker[] => {
			return services
				.filter((service) => !!service.destinationPoint)
				.flatMap((service) => {
					const baseTitle = `${localServiceSummary(service)}`
					return markerOptionsFromLocalService(service, {
						titleOrigin: `${baseTitle} [${intl.formatMessage({ id: 'ORIGIN' })}]`,
						titleDestination: `${baseTitle} [${intl.formatMessage({ id: 'DESTINATION' })}]`,
					}).map((options) => ({ id: uuidv4(), type: MapMarkerType.SERVICE, options }))
				})
		},
		[intl]
	)

	const onUserClickEvent = useCallback(
		({ nativeEvent }: UserClickEvent) => {
			setMapMarkers((oldMarkers) =>
				oldMarkers
					.filter((marker) => marker.type !== MapMarkerType.USER)
					.map((marker) => ({
						...marker,
						infoWindow: marker.infoWindow ? { ...marker.infoWindow, open: false } : undefined,
					}))
			)
			if ('placeId' in nativeEvent && !!nativeEvent.placeId) {
				nativeEvent.stop()
				mapWrapperRef.current?.placesService?.getDetails(
					{ placeId: nativeEvent.placeId, fields: ['place_id', 'name', 'geometry', 'adr_address', 'url'] },
					(place, status) => {
						const baseNewMarker: MapMarker =
							!!place && status === google.maps.places.PlacesServiceStatus.OK
								? markerOptionsFromPOI(place, intl)
								: markerOptionsFromCoordinates(
										{
											lat: place?.geometry?.location?.lat()!,
											lng: place?.geometry?.location?.lng()!,
										},
										intl
								  )

						const newMarker: MapMarker = {
							...baseNewMarker,
							infoWindow: baseNewMarker.infoWindow ? { ...baseNewMarker.infoWindow, open: true } : undefined,
						}

						setMapMarkers((oldMarkers) => [...oldMarkers.filter((marker) => marker.id !== place?.place_id), newMarker])
					}
				)
			} else {
				const newCoordinates: google.maps.LatLngLiteral = {
					lat: nativeEvent.latLng!.lat(),
					lng: nativeEvent.latLng!.lng(),
				}
				const baseNewMarker: MapMarker = markerOptionsFromCoordinates(newCoordinates, intl)
				const newMarker: MapMarker = {
					...baseNewMarker,
					infoWindow: baseNewMarker.infoWindow ? { ...baseNewMarker.infoWindow, open: true } : undefined,
				}
				setMapMarkers((oldMarkers) => [...oldMarkers, newMarker])
			}
		},
		[intl]
	)

	const onMarkerClickEvent = useCallback(({ origin }: MarkerClickEvent) => {
		switch (origin.type) {
			case MapMarkerType.BUILDING:
				setMapMarkers((oldMarkers) =>
					oldMarkers
						.filter((marker) => marker.type !== MapMarkerType.USER)
						.map((marker) => ({
							...marker,
							infoWindow: marker.infoWindow
								? { options: marker.infoWindow.options, open: marker.id === origin.id }
								: undefined,
						}))
				)
				break
			default:
				break
		}
	}, [])
	const onPolygonClickEvent = useCallback(
		({ origin, nativeEvent }: PolygonClickEvent) => {
			const selectedArea = mapPolygons.find((polygon) => polygon.id === origin.id)?.area

			setMapMarkers((oldMarkers) => {
				const polygonAssociatedMarker = oldMarkers.find((marker) => marker.polygonId === origin.id)
				let newMarkers: MapMarker[] = oldMarkers
					.filter((marker) => marker.type !== MapMarkerType.USER)
					.map((marker) => ({
						...marker,
						infoWindow: marker.infoWindow ? { ...marker.infoWindow, open: false } : undefined,
					}))

				if (polygonAssociatedMarker) {
					newMarkers = newMarkers.map((marker) => ({
						...marker,
						infoWindow: marker.infoWindow
							? { options: marker.infoWindow.options, open: marker.polygonId === origin.id }
							: undefined,
					}))
				} else if (selectedArea && nativeEvent.latLng) {
					const newUserMarker = markerOptionsFromKmzGenericArea(
						selectedArea,
						{
							lat: nativeEvent.latLng.lat(),
							lng: nativeEvent.latLng.lng(),
						},
						intl
					)
					newMarkers = [...newMarkers, newUserMarker]
				}

				return newMarkers
			})
		},
		[intl, mapPolygons]
	)

	const onMapClick = useCallback(
		(event: MapsClickEvent, _map: google.maps.Map): void => {
			if (event instanceof UserClickEvent) {
				onUserClickEvent(event)
			} else if (event instanceof MarkerClickEvent) {
				onMarkerClickEvent(event)
			} else if (event instanceof PolygonClickEvent) {
				onPolygonClickEvent(event)
			}
		},
		[onMarkerClickEvent, onPolygonClickEvent, onUserClickEvent]
	)

	useEffect(() => {
		if (!isMapLoaded) return

		const newServiceMarkers = mapMarkersFromServices(getServices())
		setMapMarkers((oldMarkers) => [
			...oldMarkers.filter((marker) => ![MapMarkerType.USER, MapMarkerType.SERVICE].includes(marker.type)),
			...newServiceMarkers,
		])
		setEnganches(enganchesFromServices(getServices()))
	}, [formik.values.services, isMapLoaded, mapMarkersFromServices])

	const onMapInfoWindowClose = useCallback(({ marker }: MapsInfoWindowCloseEvent, _map: google.maps.Map): void => {
		if (marker.type === MapMarkerType.USER) {
			setMapMarkers((oldMarkers) => oldMarkers.filter((m) => m.id !== marker.id))
		}
	}, [])

	const clearMap = useCallback(() => {
		mapControlsRef.current?.clearAreaTypeSelect(false)
		mapControlsRef.current?.clearCablesBoxesSelect(false)
		setMapKmzUrls([])
		setMapPolygons([])
		setMapMarkers([])
		setEnganches([])
	}, [])

	return {
		mapWrapperRef,
		mapControls,
		mapMarkers,
		mapKmzUrls,
		mapPolygons,
		mapOptions,
		mapDirections,
		onMapLoaded,
		onMapClick,
		onMapInfoWindowClose,
		clearMap,
	}
}
