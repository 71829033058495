import { FC, useCallback, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { AreaModConfirm } from './steppers/AreaModConfirm'
import { AreaModForm } from './steppers/AreaModForm'

import { IWizardInterface, UfinetActionButtonHandle, WizardVertical, WizardVerticalHandle } from 'ufinet-web-components'
import { IAreaModal } from 'ufinet-web-functions'
import { AreaPreview } from '../common/preview/AreaPreview'

type Props = {
	handleClose: any
	data: IAreaModal
}

const ModAreaModalBody: FC<Props> = ({ handleClose, data }: Props) => {
	const intl = useIntl()
	const wizardRef = useRef<WizardVerticalHandle>()
	const actionButtonRef = useRef<UfinetActionButtonHandle>()
	const actionButtonConfirmRef = useRef<UfinetActionButtonHandle>()
	const [wizardData, setWizardData] = useState<IAreaModal>({ ...data, storageUrls: [''], areas: [''] })

	const settingWizardData = useCallback((data: any) => {
		const typeId = intl.formatMessage({ id: data.typeId })
		setWizardData({ ...data, typeId })
	}, [])
	const contentArray: IWizardInterface[] = [
		{
			stepperTitle: intl.formatMessage({ id: 'STEP.CABLES&BOXES.FILL_DATA' }),
			stepperDescription: intl.formatMessage({ id: 'STEP.DESCRIPTION.CABLES&BOXES.FILL_DATA' }),
			stepperComponent: (
				<AreaModForm
					actionButtonRef={actionButtonRef}
					wizardRef={wizardRef}
					data={wizardData}
					setWizardData={settingWizardData}
				/>
			),
		},
		{
			stepperTitle: intl.formatMessage({ id: 'STEP.CABLES&BOXES.PREVIEW' }),
			stepperDescription: intl.formatMessage({ id: 'STEP.DESCRIPTION.CABLES&BOXES.PREVIEW' }),
			stepperComponent: <AreaPreview wizardRef={wizardRef} data={wizardData} />,
		},
		{
			stepperTitle: intl.formatMessage({ id: 'STEP.CABLES&BOXES.LOAD_KMZ' }),
			stepperDescription: intl.formatMessage({ id: 'STEP.DESCRIPTION.CABLES&BOXES.LOAD_KMZ' }),
			stepperComponent: (
				<AreaModConfirm
					hideModal={handleClose}
					actionButtonRef={actionButtonConfirmRef}
					wizardRef={wizardRef}
					data={wizardData}
				/>
			),
		},
	]

	return (
		<div>
			<WizardVertical content={contentArray} ref={wizardRef} />
		</div>
	)
}
export { ModAreaModalBody }
