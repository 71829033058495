import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons/faCircleExclamation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Image } from 'primereact/image'
import { FC } from 'react'
import { AqsError, EAqsErrorCode, toAbsoluteUrl } from 'ufinet-web-functions'

type Props = {
	error: AqsError | null
}
export const ErrorSection: FC<Props> = ({ error }: Props) => {
	const getError = () => {
		switch (error?.errorCode) {
			case EAqsErrorCode.KMZ_NOT_VALID:
				return printKmzNotValid()
			case EAqsErrorCode.KMZ_COUNTRY_NOT_VALID:
				return printKmzCountryNotValid()
			default:
				return <h3>{error?.error}</h3>
		}
	}
	const printKmzNotValid = () => (
		<div className="d-flex align-items-center justify-content-around mb-9 text-dark">
			<Image
				src={toAbsoluteUrl('/media/pictures/cables_cajas_structure_error.png')}
				className="pe-8"
				alt="Image"
				width="250"
				preview
				downloadable
			/>
			<div className="d-flex flex-column">
				<h2>El formato del KMZ no es valido</h2>
				<p>Favor recordar que debe seguir siempre el formato de la imagen.</p>
				<ol>
					<li>La raíz de la estructura debe ser el propio KMZ.</li>
					<li>La carpeta debe estar siempre después del archivo raíz.</li>
					<li>En la carpeta deben existir siempre los cables o cajas que se requieren cargar.</li>
				</ol>
				<p>Por ultimo recordar que se debe cargar por separado los cables y cajas, tanto aéreas como subterráneas</p>
			</div>
		</div>
	)
	const printKmzCountryNotValid = () => (
		<div className="d-flex mb-9 text-dark">
			<h3>El país no concuerda con el fichero KMZ</h3>
		</div>
	)
	return (
		<>
			{error !== null && error.error !== null && error.error !== '' && (
				<div className="alert alert-dismissible bg-light-danger d-flex flex-center flex-column py-10 px-10 px-lg-10 mb-10">
					<FontAwesomeIcon color="#F7BB49" size="4x" icon={faCircleExclamation} />
					<div className="text-center fs-4 pt-2">
						<h1 className="fw-bolder mb-5">Error</h1>
						<div className="separator separator-dashed border-danger opacity-25 mb-5" />
						{getError()}
					</div>
				</div>
			)}
		</>
	)
}
