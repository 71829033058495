import { ISelect } from 'ufinet-web-components'

export enum FormFieldType {
	SELECT = 'select',
	INPUT_NUMBER = 'input',
	INPUT_TEXT = 'input_text',
}

export type FormField = {
	text: string
	formActions: string
	options: ISelect[] | undefined
	isLoading: boolean
	inputType: FormFieldType
	className?: string
	defaultValue?: string
	isDisabled?: boolean
	noSortValues?: boolean
}

export type FormFields = {
	[key: string]: FormField
}
