import Swal from 'sweetalert2'
import { TranslatorFunction } from 'ufinet-web-functions'

export const ModalNoValidClient = (translate: TranslatorFunction) =>
	Swal.fire({
		titleText: translate('KAM.UNDEFINED.WARNING.TITLE'),
		text: translate('KAM.UNDEFINED.WARNING.DESCRIPTION'),
		icon: 'error',
		imageWidth: 400,
		imageHeight: 200,
		confirmButtonText: translate('BUTTON.ACCEPT'),
		customClass: {
			confirmButton: 'mx-auto d-block',
		},
	})
