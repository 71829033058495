import { forwardRef, useContext, useImperativeHandle, useMemo, useRef, useState } from 'react'
import {
	AreaTypeSelect,
	AreaTypeSelectHandle,
	IUfinetSelectOption,
	mkOptionFromAreaType,
	UfinetSelectOptionValue,
} from 'ufinet-web-components'
import { useTranslator } from 'ufinet-web-functions'
import { MapControlsAreasCablesBoxesContext } from './context/MapControlsAreasCablesBoxesContext'

interface IMapControlsAreasProps {
	map?: google.maps.Map
	onAreaTypesChange?: (areaTypes: IUfinetSelectOption[]) => void
	disabled?: boolean
}

export type MapControlsAreaTypeHandle = {
	clearSelect: (clearDefaults?: boolean) => void
}

const MapControlsAreaType = forwardRef(
	({ map: _map, onAreaTypesChange = () => {}, disabled = false }: IMapControlsAreasProps, ref) => {
		const translate = useTranslator()
		const { preSelectedAreaTypes } = useContext(MapControlsAreasCablesBoxesContext)
		const preSelectedOptions = useMemo(
			() => preSelectedAreaTypes.map((areaType) => mkOptionFromAreaType(areaType, translate)),
			[preSelectedAreaTypes, translate]
		)
		const [selectedAreaTypes, setSelectedAreaTypes] = useState<IUfinetSelectOption[]>([])
		const areaTypeSelectRef = useRef<AreaTypeSelectHandle>(null)

		useImperativeHandle(
			ref,
			() =>
				({
					clearSelect: areaTypeSelectRef.current?.clearSelect,
				} as MapControlsAreaTypeHandle)
		)

		const onChange = (areaTypes?: UfinetSelectOptionValue) => {
			const newAreas = (areaTypes as IUfinetSelectOption[]) || []
			setSelectedAreaTypes(newAreas)
			onAreaTypesChange(newAreas)
		}

		return (
			<AreaTypeSelect
				ref={areaTypeSelectRef}
				isMulti
				value={selectedAreaTypes}
				defaultValue={preSelectedOptions}
				shouldClearDefaultValue={false}
				onChange={onChange}
				tooltipTitle={translate('MAP.KMZ.AREA_TYPE_SELECTOR.TOOLTIP')}
				isDisabled={disabled}
			/>
		)
	}
)

export { MapControlsAreaType }
