import { mapDefaults } from 'modules/google-maps/mapDefaults'

export type MapWrapperOptions = {
	zoom: number
	center?: google.maps.LatLngLiteral | string
	mapType: google.maps.MapTypeId | string
	mapTypes: (google.maps.MapTypeId | string)[]
}

export const defaultMapWrapperOptions: MapWrapperOptions = {
	zoom: mapDefaults.zoom,
	mapType: 'roadmap', // roadmap, satellite, hybrid, terrain
	mapTypes: ['roadmap', 'satellite', 'hybrid'],
}
