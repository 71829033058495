import { FormikProps } from 'formik'
import { getDefaultOption } from 'modules/common/domain/utils/getDefaultOption'
import { CapacityRepository } from 'modules/product/repository/CapacityRepository'
import { InternetRepository } from 'modules/product/repository/InternetRepository'
import { FC } from 'react'
import { ISelect, UfinetSelect } from 'ufinet-web-components'
import { useInternetServiceForm } from './hooks/useInternetServiceForm'

type CapacityServiceFormProps = {
	formik: FormikProps<any>
	capacityRepository: CapacityRepository
	internetRepository: InternetRepository
}

export const InternetServiceForm: FC<CapacityServiceFormProps> = ({
	formik,
	capacityRepository,
	internetRepository,
}) => {
	const { formFields, translate, onChange } = useInternetServiceForm(capacityRepository, internetRepository)

	return (
		<div className="row col-11">
			{Object.keys(formFields).map((field) => {
				const { text, formActions, options = [], defaultValue, isDisabled } = formFields[field]
				return (
					<UfinetSelect
						key={field}
						requiredIcon
						menuPosition="fixed"
						className="col-6 pt-4 "
						labelTitle={translate(`SERVICE.${text}`)}
						tooltipTitle={translate(`SERVICE.${text}.TOOLTIP`)}
						defaultValue={getDefaultOption(options as ISelect[], defaultValue)}
						isClearable
						isDisabled={isDisabled}
						error={formik.errors[formActions]}
						onChange={onChange(formik, formActions)}
						value={formik.values[formActions]?.label && formik.values[formActions]}
						options={options}
						placeholder={translate(`SERVICE.${text}`)}
					/>
				)
			})}
		</div>
	)
}
