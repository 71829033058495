import { faBars, faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { IUfinetSelectOption } from 'ufinet-web-components'
import { AreaType, NetworkItemType } from 'ufinet-web-functions'
import { MapControlsAreasCablesBoxesContext } from './context/MapControlsAreasCablesBoxesContext'
import { MapControlsAreaType, MapControlsAreaTypeHandle } from './MapControlsAreaType'
import { MapControlsCablesAndBoxes, MapControlsCablesAndBoxesHandle } from './MapControlsCablesAndBoxes'

interface IMapControlsAreasCablesBoxesProps {
	map?: google.maps.Map
	countryId: string
	className?: string
	userStyles?: React.CSSProperties
	disabled?: boolean
	preSelectedAreaTypes?: AreaType[]
	preSelectedItemTypes?: NetworkItemType[]
	onAreaTypesChange?: (areaTypes: AreaType[]) => void
	onCablesAndBoxesChange?: (cablesAndBoxes: NetworkItemType[]) => void
	onClose?: (isNowClosed: boolean) => void
}

const baseStyles: React.CSSProperties = {
	position: 'relative',
	borderColor: 'black',
	borderWidth: '1px',
	borderStyle: 'solid',
}

export type MapControlsAreasCablesBoxesHandle = {
	clearAreaTypeSelect: (clearDefaults?: boolean) => void
	clearCablesBoxesSelect: (clearDefaults?: boolean) => void
}

const MapControlsAreasCablesBoxes = forwardRef(
	(
		{
			map,
			countryId: _countryId,
			className = '',
			userStyles = {},
			disabled = false,
			preSelectedAreaTypes: propsPreSelectedAreaTypes,
			preSelectedItemTypes: propsPreSelectedItemTypes,
			onAreaTypesChange: propsOnAreaTypesChange,
			onCablesAndBoxesChange: propsOnCablesAndBoxesChange,
			onClose = () => {},
		}: IMapControlsAreasCablesBoxesProps,
		ref
	) => {
		const areaTypeSelectRef = useRef<MapControlsAreaTypeHandle>()
		const cablesAndBoxesSelectRef = useRef<MapControlsCablesAndBoxesHandle>()

		const preSelectedAreaTypes = useMemo(() => propsPreSelectedAreaTypes || [], [propsPreSelectedAreaTypes])
		const preSelectedItemTypes = useMemo(() => propsPreSelectedItemTypes || [], [propsPreSelectedItemTypes])

		const [closed, setClosed] = useState<boolean>(false)

		const onToggleClose = useCallback(() => {
			setClosed(!closed)
			onClose(!closed)
		}, [closed, onClose])

		useImperativeHandle(
			ref,
			() =>
				({
					clearAreaTypeSelect: areaTypeSelectRef.current?.clearSelect,
					clearCablesBoxesSelect: cablesAndBoxesSelectRef.current?.clearSelect,
				} as MapControlsAreasCablesBoxesHandle)
		)

		const onAreaTypesChange = useCallback(
			(areas: IUfinetSelectOption[]) => {
				propsOnAreaTypesChange?.(areas.map((a) => a.value as AreaType))
			},
			[propsOnAreaTypesChange]
		)

		const onCablesAndBoxesChange = useCallback(
			(items: IUfinetSelectOption[]) => {
				propsOnCablesAndBoxesChange?.(items.map((it) => it.value as NetworkItemType))
			},
			[propsOnCablesAndBoxesChange]
		)

		return (
			<MapControlsAreasCablesBoxesContext.Provider value={{ preSelectedAreaTypes, preSelectedItemTypes }}>
				<div className={`position-relative bg-white p-4 m-3 ${className}`} style={{ ...baseStyles, ...userStyles }}>
					<div className={`${closed ? 'd-block' : 'd-none'}`}>
						<button type="button" className="bg-white border-0" style={{ fontSize: '1.2rem' }} onClick={onToggleClose}>
							<FontAwesomeIcon icon={faBars} />
						</button>
					</div>
					<div className={`${closed ? 'd-none' : 'd-flex justify-content-evenly'}`}>
						<div className="position-absolute top-0 end-0 p-2">
							<button
								type="button"
								className="bg-white border-0"
								style={{ fontSize: '1.2rem' }}
								onClick={onToggleClose}
							>
								<FontAwesomeIcon icon={faClose} />
							</button>
						</div>
						<div className="w-100" style={{ marginRight: '4px' }}>
							<MapControlsAreaType
								ref={areaTypeSelectRef}
								map={map}
								onAreaTypesChange={onAreaTypesChange}
								disabled={disabled}
							/>
						</div>
						<div className="w-100" style={{ marginLeft: '4px' }}>
							<MapControlsCablesAndBoxes
								ref={cablesAndBoxesSelectRef}
								map={map}
								onCablesAndBoxesChange={onCablesAndBoxesChange}
								disabled={disabled}
							/>
						</div>
					</div>
				</div>
			</MapControlsAreasCablesBoxesContext.Provider>
		)
	}
)
export { MapControlsAreasCablesBoxes }
