import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons'
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload'

import { FC, useCallback, useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Label, MultipleDeleter, PATH_DETAIL, Table, UfinetButton, UfinetModal } from 'ufinet-web-components'
import {
	AuthContext,
	Authority,
	bulkQuotationService,
	BulkQuotationStatus,
	convertToLocalTZ,
	IBulkQuotation,
	useAsync,
	useInternalUser,
	useModal,
	useTranslator,
} from 'ufinet-web-functions'

import { BulkModalBody } from './BulkModalBody'
import { colsExtMassive, colsIntMassive } from './BulkRecordsColData'

interface IBulkFormProps {}

// Bulk quotation states that allow browsing to the detail
const browsableStates: BulkQuotationStatus[] = [BulkQuotationStatus.SUCCESS, BulkQuotationStatus.WARNING]

const BulkForm: FC<IBulkFormProps> = () => {
	const translate = useTranslator()
	const authData = useContext(AuthContext)

	const roles = authData.userData?.authorities || []
	const permissions = Authority.getQuotationPermissions(roles)
	const internalUser = useInternalUser()

	const _bulkQuotationService = useMemo(() => bulkQuotationService(authData), [authData])

	const [loadModal, showLoadModal, hideLoadModal] = useModal()
	const [loadingRecords, setLoadingRecords] = useState<boolean>(false)
	const [selectedValues, setSelectedValues] = useState<IBulkQuotation[]>([])
	const [massiveRecords, setMassiveRecords] = useState<IBulkQuotation[]>([])
	const navigate = useNavigate()

	const getMassiveRecords = useCallback(
		async (clientId?: string) => {
			setLoadingRecords(true)
			return await _bulkQuotationService.findAll({ clientId })
		},
		[_bulkQuotationService]
	)

	const onMassiveRecordsFetched = (records: IBulkQuotation[]): void => {
		const formattedRecords = records.map((record) => ({
			...record,
			lastModifiedAt: convertToLocalTZ(record.lastModifiedAt),
		}))
		setMassiveRecords(formattedRecords)
	}

	const onMassiveRecordsFailedToFetch = (): void => {
		toast.error(translate('MASSIVE.FETCH.ERROR'))
	}

	const runFinally = () => {
		setLoadingRecords(false)
	}

	const fetchAndStore = useAsync(
		{
			asyncFn: getMassiveRecords,
			onSuccess: onMassiveRecordsFetched,
			onFailure: onMassiveRecordsFailedToFetch,
			runFinally: runFinally,
		},
		[]
	)

	const getHeaderButtons = () => (
		<>
			{permissions.canDelete && (
				<MultipleDeleter
					setSelectedValues={setSelectedValues}
					selectedValues={selectedValues}
					deleteEndpoint={() => {}}
					search={() => {}}
				/>
			)}
			<UfinetButton
				className={`me-3 ${loadingRecords ? 'edit-disabled' : ''}`}
				iconClassName={loadingRecords ? 'rotating' : ''}
				icon={faArrowsRotate}
				onClick={() => {
					!loadingRecords && fetchAndStore()
				}}
				content=""
			/>
			{permissions.canWrite && (
				<UfinetButton
					icon={faUpload}
					onClick={() => {
						showLoadModal()
					}}
					content={translate('BULK_LOAD')}
				/>
			)}
		</>
	)

	const navigateToDetail = useCallback(
		(rowData: IBulkQuotation) => {
			if (!browsableStates.includes(rowData.status)) return

			navigate(`${PATH_DETAIL}/${rowData.id}`, {
				state: { massiveRecordId: rowData.id, quotationRecordId: rowData.quotationRecordId },
				replace: false,
			})
		},
		[navigate]
	)

	const actionBodyTemplate = useCallback(
		(rowData: IBulkQuotation) => {
			const tooltipData: [string, 'info' | 'warning' | 'error' | 'processing'] =
				rowData.status === BulkQuotationStatus.SUCCESS
					? [translate('BULK_LOAD.DETAIL.SEE'), 'info']
					: rowData.status === BulkQuotationStatus.WARNING
					? internalUser
						? [translate('BULK_LOAD.WARNING'), 'warning']
						: [translate('BULK_LOAD.DETAIL.SEE'), 'info']
					: rowData.status === BulkQuotationStatus.PENDING
					? [translate('BULK_LOAD.PENDING'), 'processing']
					: rowData.status === BulkQuotationStatus.ERROR
					? [translate('BULK_LOAD.ERROR'), 'error']
					: [translate('BULK_LOAD.OTHER'), 'warning']

			return (
				<Label
					tooltipInfo={tooltipData[1] === 'info' ? tooltipData[0] : undefined}
					tooltipWarning={tooltipData[1] === 'warning' ? tooltipData[0] : undefined}
					tooltipError={tooltipData[1] === 'error' ? tooltipData[0] : undefined}
					tooltipProcessing={tooltipData[1] === 'processing' ? tooltipData[0] : undefined}
					onClick={() => navigateToDetail(rowData)}
				/>
			)
		},
		[internalUser, navigateToDetail]
	)

	return (
		<>
			<Table
				selectedValues={selectedValues}
				onSelectionChange={undefined}
				cols={internalUser ? colsIntMassive : colsExtMassive}
				content={massiveRecords}
				headerButtons={getHeaderButtons()}
				actionBodyTemplate={actionBodyTemplate}
				onRowDoubleClick={(e) => navigateToDetail(e.data)}
				emptyMessage={loadingRecords ? translate('LOADING_DOTS') : undefined}
				sortField="id"
				sortOrder={-1}
			/>
			<UfinetModal size="xl" show={loadModal} handleClose={hideLoadModal} title={translate('LOAD.TEMPLATE')}>
				<BulkModalBody afterSubmit={hideLoadModal} onFileSubmitted={fetchAndStore} />
			</UfinetModal>
		</>
	)
}
export { BulkForm }
