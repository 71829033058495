import { MapInfoWindow } from 'modules/google-maps/events/infowindow/MapInfoWindow'
import { IntlShape } from 'react-intl'
import { getCoordinatesCopyElement } from './getCoordinatesCopyElement'

export const infoWindowFromPOI = (
	place: google.maps.places.PlaceResult,
	coordinates: google.maps.LatLngLiteral,
	intl: IntlShape
): MapInfoWindow => {
	const content = `
	<div class="d-flex flex-column align-items-center gap-2">
		<div class="fw-bolder">
			${
				place.name ||
				intl.formatMessage({
					id: 'MAP.CUSTOM_MARKER',
				})
			}
		</div>
		<div class="d-flex flex-column align-items-start w-100">
		${place.adr_address && `${place.adr_address.split(/[,-](?=\s*<span)/).join('')}`}
		${
			place.url &&
			`
		<div class="fw-normal">
			<a target="_blank" href="${place.url}">
				<span>Google Maps</span>
			</a>
		</div>`
		}
		</div>

		${getCoordinatesCopyElement(coordinates, { tooltip: intl.formatMessage({ id: 'COPY.COORDINATES' }) })}
	</div>`
	return { options: { content, disableAutoPan: false } }
}
