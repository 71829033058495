import * as ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'

import 'ufinet-web-components/dist/index.css'

import './sass/style.scss'

import { AqsI18nProvider } from 'components/common/i18n/AqsI18nProvider'
import { QueryClient, QueryClientProvider } from 'react-query'
import { AppRoutes } from 'routing/AppRoutes'
import { worker } from 'tests/mocks/browser'
import { AuthProvider } from 'ufinet-web-components'
import { FrontEndApplication } from 'ufinet-web-functions'
import packageJson from '../package.json'

// Start the mocking conditionally.
if (process.env.REACT_APP_ENV === 'TEST') {
	worker.start()
}

console.info(packageJson.version)

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 300000, // 5 minute
			refetchOnWindowFocus: false,
		},
	},
})

ReactDOM.render(
	<>
		<AuthProvider application={FrontEndApplication.AQS}>
			<AqsI18nProvider>
				<QueryClientProvider client={queryClient}>
					<AppRoutes />
				</QueryClientProvider>
			</AqsI18nProvider>
		</AuthProvider>
	</>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
