export enum OverSubscription {
	_1_01 = '226530000',
	_1_02 = '226530001',
	_1_03 = '226530002',
	_1_05 = '226530003',
	_1_10 = '226530004',
	_1_15 = '226530005',
	_1_20 = '226530006',
	_1_25 = '226530007',
	_1_50 = '226530008',
}
