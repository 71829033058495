import React, { FC, PropsWithChildren, useEffect, useRef } from 'react'

interface IMapControlsContainerProps {
	map: google.maps.Map
	position: google.maps.ControlPosition
	className?: string
	styles?: React.CSSProperties
}

const MapControlsContainer: FC<PropsWithChildren<IMapControlsContainerProps>> = ({
	map,
	position,
	className = '',
	styles = {},
	children,
}) => {
	const controlsRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		const ref = controlsRef
		if (ref.current) {
			map.controls[position].push(ref.current)
		}
	}, [])

	return (
		<div style={styles} className={className} ref={controlsRef}>
			{children}
		</div>
	)
}

export { MapControlsContainer }
