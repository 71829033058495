import { ColData } from 'ufinet-web-components'
import { countryParamsFormattingSettings, formatCellNumber, ICountryParams } from 'ufinet-web-functions'

export const colsCountryParams: ColData[] = [
	{ field: 'countryName', header: 'COL.COUNTRY_PARAMS.COUNTRY_NAME', nonFilterable: false },
	{
		field: 'minimalDistanceBox',
		header: 'COL.COUNTRY_PARAMS.MIN_DISTANCE_BOX',
		nonFilterable: true,
		body: (row: ICountryParams) => (
			<div className="ufinet-table-cell">
				{formatCellNumber(row.minimalDistanceBox, countryParamsFormattingSettings)}
			</div>
		),
	},
	{
		field: 'minimalDistanceCost',
		header: 'COL.COUNTRY_PARAMS.MIN_DISTANCE_COST',
		nonFilterable: true,
		body: (row: ICountryParams) => (
			<div className="ufinet-table-cell">
				{formatCellNumber(row.minimalDistanceCost, countryParamsFormattingSettings)}
			</div>
		),
	},
	{
		field: 'maximumCapacity',
		header: 'COL.COUNTRY_PARAMS.MAX_CAPACITY',
		nonFilterable: true,
		body: (row: ICountryParams) => (
			<div className="ufinet-table-cell">{formatCellNumber(row.maximumCapacity, countryParamsFormattingSettings)}</div>
		),
	},
	{
		field: 'churnContract',
		header: 'COL.COUNTRY_PARAMS.CHURN_CONTRACT',
		nonFilterable: true,
		body: (row: ICountryParams) => (
			<div className="ufinet-table-cell">{formatCellNumber(row.churnContract, countryParamsFormattingSettings)}</div>
		),
	},
	{
		field: 'priceEvolution',
		header: 'COL.COUNTRY_PARAMS.PRICE_EVOLUTION',
		nonFilterable: true,
		body: (row: ICountryParams) => (
			<div className="ufinet-table-cell">{formatCellNumber(row.priceEvolution, countryParamsFormattingSettings)}</div>
		),
	},
	{
		field: 'taxPercentageBrasil1',
		header: 'COL.COUNTRY_PARAMS.TAX_PERCENTAGE_BRASIL_1',
		nonFilterable: true,
		body: (row: ICountryParams) => (
			<div className="ufinet-table-cell">
				{formatCellNumber(row.taxPercentageBrasil1, countryParamsFormattingSettings)}
			</div>
		),
	},
	{
		field: 'taxPercentageBrasil2',
		header: 'COL.COUNTRY_PARAMS.TAX_PERCENTAGE_BRASIL_2',
		nonFilterable: true,
		body: (row: ICountryParams) => (
			<div className="ufinet-table-cell">
				{formatCellNumber(row.taxPercentageBrasil2, countryParamsFormattingSettings)}
			</div>
		),
	},
	{
		field: 'taxPercentageGuatemala1',
		header: 'COL.COUNTRY_PARAMS.TAX_PERCENTAGE_GUATEMALA_1',
		nonFilterable: true,
		body: (row: ICountryParams) => (
			<div className="ufinet-table-cell">
				{formatCellNumber(row.taxPercentageGuatemala1, countryParamsFormattingSettings)}
			</div>
		),
	},
	{
		field: 'taxPercentageGuatemala2',
		header: 'COL.COUNTRY_PARAMS.TAX_PERCENTAGE_GUATEMALA_2',
		nonFilterable: true,
		body: (row: ICountryParams) => (
			<div className="ufinet-table-cell">
				{formatCellNumber(row.taxPercentageGuatemala2, countryParamsFormattingSettings)}
			</div>
		),
	},
	{
		field: 'ipc',
		header: 'COL.COUNTRY_PARAMS.IPC',
		nonFilterable: true,
		body: (row: ICountryParams) => (
			<div className="ufinet-table-cell">{formatCellNumber(row.ipc, countryParamsFormattingSettings)}</div>
		),
	},
]
