import { useFormik } from 'formik'
import {
	PrefeasibilityStatuses,
	PrefeasibilityStatusesLabel,
} from 'modules/prefeasibility/domain/models/prefeasibilityStatuses'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { PATH_QUOTATION_PENDING } from 'routing/protected/PrivateRoutes'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { IFilterState, IUfinetSelectOption, colorConstants } from 'ufinet-web-components'
import {
	ILocalService,
	IQuotation,
	IQuotationConfirmRequest,
	IQuotationRepository,
	IQuotationRequest,
	IQuotationResponse,
	IServerService,
	IServiceUpdateRequest,
	IServicesDeletionRequest,
	IServicesRepository,
	ProcessedService,
	QuotationConfirmationStatus,
	ServiceLocalState,
	ServiceServerStatus,
	initialQuotationIndividual,
	onFormikChanges,
	populateLocalServiceFromServerResponse,
	populateServiceFromUpdateResponse,
	quotationRequestFromServicesModel,
	serviceUpdateRequestFromServiceModel,
	silentFailureOptionsFetch,
	useInternalUser,
	useModal,
	useTranslator,
} from 'ufinet-web-functions'

export const usePendingQuotationForm = (
	aqsId: string,
	parentAqsId: string | undefined,
	_serviceService: IServicesRepository,
	_quotationService: IQuotationRepository,
	pendingQuotationDetails: IQuotationResponse,
	selectedCorporateGroup: IUfinetSelectOption,
	selectedClient: IUfinetSelectOption,
	initialSelectedContact: IUfinetSelectOption | undefined,
	selectedCountry: IUfinetSelectOption,
	duplicateQuotation: () => Promise<void>,
	updateQuotationDetails: () => void
) => {
	const intl = useIntl()
	const translate = useTranslator()
	const [resetFilter, setResetFilter] = useState(false)
	const onChange = useCallback(onFormikChanges, [])
	const resetFilterCallback = useCallback(() => {
		setResetFilter(false)
	}, [])

	const navigate = useNavigate()
	const ReactSwal = useMemo(() => withReactContent(Swal), [])

	const internalUser = useInternalUser()

	const [quotationData, setQuotationData] = useState<IQuotation>({
		...initialQuotationIndividual,
		...pendingQuotationDetails,
	})
	const [servicesModDate, setServicesModDate] = useState<Date>(new Date())

	const [selectedContact, setSelectedContact] = useState(initialSelectedContact)

	const [serviceModal, showServiceModal, hideServiceModal] = useModal()

	useEffect(() => {
		if (parentAqsId) showServiceModal()
	}, [parentAqsId])

	const filterInitialValues: IFilterState = useMemo(
		() => ({
			clientSelect: selectedClient,
			contactSelect: initialSelectedContact,
			corporateGroupSelect: selectedCorporateGroup,
			countrySelect: selectedCountry,
			reference: pendingQuotationDetails.referencia ?? '',
			finalClient: pendingQuotationDetails.finalClient ?? '',
		}),
		[]
	)

	const initialValues = useMemo(
		() => ({
			countrySelect: selectedCountry,
			corporateGroupSelect: selectedCorporateGroup,
			clientSelect: selectedClient,
			contactSelect: selectedContact,
			services:
				pendingQuotationDetails.services.map((service, idx) => ({
					...populateLocalServiceFromServerResponse(service, quotationData),
					pointNumber: idx,
				})) ?? [],
			reference: pendingQuotationDetails.referencia ?? '',
			finalClient: pendingQuotationDetails.finalClient ?? '',
		}),
		[]
	)

	const formik = useFormik({
		initialValues,
		onSubmit: () => {},
		validateOnChange: true,
		validateOnBlur: true,
		enableReinitialize: true,
	})

	const getServices = useCallback(() => [...formik.values.services], [formik.values.services])

	const isQuotationPending = useMemo(
		() => pendingQuotationDetails.prefeasibilityStatus === PrefeasibilityStatusesLabel[PrefeasibilityStatuses.PENDING],
		[pendingQuotationDetails.prefeasibilityStatus]
	)

	const isQuotationCalculated = useMemo(
		() =>
			pendingQuotationDetails.prefeasibilityStatus === PrefeasibilityStatusesLabel[PrefeasibilityStatuses.CALCULATED],
		[pendingQuotationDetails.prefeasibilityStatus]
	)

	const isQuotationNotCalculated = useMemo(
		() =>
			pendingQuotationDetails.prefeasibilityStatus ===
			PrefeasibilityStatusesLabel[PrefeasibilityStatuses.NOT_CALCULATED],
		[pendingQuotationDetails.prefeasibilityStatus]
	)

	const shouldShowPrefeasibilityButton = useMemo(() => {
		const services: IServerService[] = getServices()
		return (
			(isQuotationNotCalculated || isQuotationCalculated || parentAqsId) &&
			services.every(
				(s) => s.status === ServiceServerStatus.CALCULATED || s.status === ServiceServerStatus.NOT_CALCULATED
			)
		)
	}, [getServices, isQuotationCalculated, isQuotationNotCalculated, parentAqsId])

	const shouldShowQuotationButton = useMemo(() => {
		const services: IServerService[] = getServices()
		return (isQuotationCalculated || parentAqsId) && services.every((s) => s.status === ServiceServerStatus.CALCULATED)
	}, [getServices, isQuotationCalculated, parentAqsId])

	const shouldShowCancelButton = useMemo(
		() => isQuotationNotCalculated || isQuotationCalculated || parentAqsId,
		[isQuotationCalculated, isQuotationNotCalculated, parentAqsId]
	)

	const hiddenServiceTableButtons = useMemo(() => {
		return {
			prefeasibility: !shouldShowPrefeasibilityButton,
			quotation: !shouldShowQuotationButton,
			cancel: !shouldShowCancelButton,
		}
	}, [shouldShowCancelButton, shouldShowPrefeasibilityButton, shouldShowQuotationButton])

	const isDataManipulationEnabled = useMemo(
		() => ({
			isDataEditingDisabled: isQuotationPending,
			isDataDeletingDisabled: isQuotationPending,
		}),
		[isQuotationPending]
	)

	const navigateToPendingQuotationsPage = () => {
		navigate(`/${PATH_QUOTATION_PENDING}`)
	}

	useEffect(() => {
		if (!formik.values.services?.length) navigateToPendingQuotationsPage()
	}, [formik.values.services?.length])

	// Observe changes in services and auto-recompute quotation
	useEffect(() => {
		const services = getServices()
		setServicesModDate(new Date())
		setQuotationData({ ...quotationData, outdated: true })
		if (!services) return

		if (services.length === 0) {
			setQuotationData(initialQuotationIndividual)
		} else if (services.every((s) => s.state === ServiceLocalState.Computed)) {
			calculateProject(services)
		}
	}, [formik.values])

	const afterContactChange = (contact: IUfinetSelectOption | undefined) => {
		setSelectedContact(contact)
	}

	const setFilter = (filterData: IFilterState) => {
		if (!parentAqsId) {
			ReactSwal.fire({
				icon: 'warning',
				title: translate('PREFEASIBILITY.ADD_SERVICE.ALERT_TITLE'),
				html: translate('PREFEASIBILITY.ADD_SERVICE.ALERT_DESCRIPTION'),
				showCancelButton: true,
				showConfirmButton: true,
				cancelButtonText: translate('CANCEL'),
				confirmButtonText: translate('OK'),
				reverseButtons: true,
				confirmButtonColor: colorConstants.primaryColor,
				cancelButtonColor: colorConstants.dangerColor,
				preConfirm: async () => {
					await duplicateQuotation()
				},
			})
			return
		}

		onChange(formik, 'countrySelect')(filterData.countrySelect)
		onChange(formik, 'corporateGroupSelect')(filterData.corporateGroupSelect)
		onChange(formik, 'clientSelect')(filterData.clientSelect)
		onChange(formik, 'contactSelect')(filterData.contactSelect)
		onChange(formik, 'reference')(filterData.reference)
		onChange(formik, 'finalClient')(filterData.finalClient)
		setQuotationData({ ...quotationData, reference: filterData.reference, finalClient: filterData.finalClient })
		showServiceModal()
	}

	const deleteServices = (serviceIds: number[]): Promise<void> => {
		const newStateServices = getServices().map((service) => ({
			...service,
			state: serviceIds.includes(service.id) ? ServiceLocalState.Deleting : service.state,
		}))
		onChange(formik, 'services')(newStateServices)

		const req: IServicesDeletionRequest = { aqsId, calculateInBothContexts: true, serviceIds }
		return (
			_serviceService
				.deleteServices(req, silentFailureOptionsFetch)
				// eslint-disable-next-line promise/always-return
				.then(() => {
					onChange(
						formik,
						'services'
					)(
						getServices()
							.filter((s) => !serviceIds.includes(s.id))
							.map((s, idx) => ({ ...s, pointNumber: idx }))
					)
					toast.success(translate('SERVICE.DELETE.DELETED'))
				})
				.catch(() => {
					onChange(formik, 'services')(getServices().map((s) => ({ ...s, state: ServiceLocalState.Computed })))
					toast.error(translate('SERVICE.DELETE.ERROR'))
				})
				.finally(() => {
					updateQuotationDetails()
				})
		)
	}

	const calculateProject = (stateServices: ILocalService[]) => {
		const quotationStartDate = new Date()
		setQuotationData({ ...quotationData, calculating: true, startDate: quotationStartDate })
		const req: IQuotationRequest = quotationRequestFromServicesModel(aqsId, stateServices, true)

		_quotationService
			.calculateQuotation(req, silentFailureOptionsFetch)
			.then((res) => {
				// Quotation results may be outdated
				if (quotationStartDate >= servicesModDate)
					setQuotationData({
						...quotationData,
						...res,
						currency:
							`${res.moneda.name || ''}${res.moneda.symbol ? ` (${res.moneda.symbol})` : ''}` || translate('UNKNOWN'),
						calculating: false,
						outdated: false,
						reference: quotationData.reference,
						finalClient: quotationData.finalClient,
					})
				return res
			})
			.catch((_err) => {
				toast.error(translate('ERROR.QUOTATION'))
				setQuotationData({ ...quotationData, calculating: false })
			})
			.finally(() => {
				updateQuotationDetails()
			})
	}

	const confirmQuotation = async (): Promise<void> => {
		setQuotationData({ ...quotationData, confirmationStatus: QuotationConfirmationStatus.Confirming })
		const req: IQuotationConfirmRequest = {
			id: aqsId,
			contactId: formik.values?.contactSelect?.value || undefined,
			reference: formik.values.reference || undefined,
			finalClient: formik.values.finalClient || undefined,
		}
		try {
			await _quotationService.confirmQuotation(req, silentFailureOptionsFetch)
			toast.success(translate('QUOTATION.CONFIRM.SENT.SUCCESS'))
			navigateToPendingQuotationsPage()
		} catch (_err) {
			setQuotationData({ ...quotationData, confirmationStatus: QuotationConfirmationStatus.Unconfirmed })
			toast.error(translate('QUOTATION.CONFIRM.SENT.ERROR'))
		}
	}

	const updateService = (updatedService: ProcessedService, preUpdatedService?: ProcessedService) => {
		// Update the affected service in state
		const updatedServices: ILocalService[] = getServices().map((service) =>
			service.pointNumber === updatedService.pointNumber
				? { ...updatedService, state: ServiceLocalState.Computing }
				: service
		)
		onChange(formik, 'services')(updatedServices)

		// Send update request
		const req: IServiceUpdateRequest = serviceUpdateRequestFromServiceModel(
			aqsId,
			updatedService as ProcessedService,
			preUpdatedService as ProcessedService,
			true
		)

		return _serviceService
			.updateService(req, silentFailureOptionsFetch)
			.then((response) => {
				// Complete/overwrite service with server data
				const serverService: ProcessedService = {
					...populateServiceFromUpdateResponse(updatedService, response, intl, preUpdatedService),
					state: ServiceLocalState.Computed,
				}
				const currentServices = getServices()
				// Find the corresponding service and complete it in state
				const serviceIndex = currentServices.findIndex((service) => service.id === serverService.id)
				if (serviceIndex !== -1) {
					currentServices.splice(serviceIndex, 1, serverService)
					onChange(formik, 'services')(currentServices)
					if (serverService.status === ServiceServerStatus.CALCULATED) {
						if (!serverService.statusDetails) {
							toast.success(translate('SERVICE.CALCULATE.CALCULATED'))
						} else {
							toast.warn(translate('SERVICE.CALCULATE.WARNING'))
						}
					} else {
						if (internalUser) toast.warn(translate('SERVICE.CALCULATE.WARNING'))
					}
				} else {
					toast.warn(translate('SERVICE.UPDATE.ERROR'))
				}
				return response
			})
			.catch((err) => {
				console.error(err)
				// Return to base state before updates
				onChange(
					formik,
					'services'
				)(getServices().map((s) => (s.id === updatedService.id ? { ...s, state: ServiceLocalState.Computed } : s)))
				toast.error(translate('SERVICE.UPDATE.ERROR'))
			})
			.finally(() => {
				updateQuotationDetails()
			})
	}

	return {
		hiddenServiceTableButtons,
		isDataManipulationEnabled,
		filterInitialValues,
		isQuotationPending,
		formik,
		serviceModal,
		hideServiceModal,
		resetFilter,
		quotationData,
		selectedContact,
		resetFilterCallback,
		afterContactChange,
		setFilter,
		deleteServices,
		confirmQuotation,
		updateService,
		navigateToPendingQuotationsPage,
		getServices,
	}
}
