import { Color } from 'ufinet-web-functions'

export interface PolygonStyleSettings {
	strokeColor: Color
	strokeOpacity: number
	strokeWeight: number
	fillColor: Color
	fillOpacity: number
	clickable: boolean
}

export const defaultPolygonStyleSettings: PolygonStyleSettings = {
	strokeColor: '#008B37',
	strokeOpacity: 0.8,
	strokeWeight: 2,
	fillColor: '#008B37',
	fillOpacity: 0.35,
	clickable: true,
}
