import { deepEqual } from 'fast-equals'
import { FC, memo, useContext, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import Swal from 'sweetalert2'

import { colsEquipment } from 'pages/costs/common/CostsColData'
import { EquipmentAndConstructionTableBaseProps } from 'pages/costs/EquipmentAndConstruction/EquipmentAndConstructionPage'
import { UploadExcelModalBody } from 'pages/costs/modals/excel/UploadExcelModalBody'
import { NewEquipmentModalBody } from 'pages/costs/modals/NewEquipmentModalBody'

import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload'
import { colorConstants, MultipleDeleter, Table, UfinetButton, UfinetModal } from 'ufinet-web-components'
import {
	AuthContext,
	Authority,
	equipmentCostsService,
	ICostsEquipmentResponse,
	onSelectionChangePreventDefaultsRemoval,
	useModal,
	zoneTypeMappings,
} from 'ufinet-web-functions'

type EquipmentTableProps = EquipmentAndConstructionTableBaseProps & { content: ICostsEquipmentResponse[] }

const EquipmentTable: FC<EquipmentTableProps> = memo(
	({ className, content, findCosts, loadingRecords }: EquipmentTableProps) => {
		const intl = useIntl()
		const [modal, showModal, hideModal] = useModal()
		const [newModal, showNewModal, hideNewModal] = useModal()
		const [selectedValues, setSelectedValues] = useState<ICostsEquipmentResponse[]>()

		const authData = useContext(AuthContext)
		const roles = authData.userData?.authorities || []
		const permissions = Authority.getCostsPermissions(roles)

		const _equipmentCostsService = useMemo(() => equipmentCostsService(authData, intl), [authData])

		const getHeaderButtons = () => (
			<>
				{permissions.canDelete && (
					<MultipleDeleter
						setSelectedValues={setSelectedValues}
						selectedValues={selectedValues}
						deleteEndpoint={_equipmentCostsService.deleteByIdsList}
						search={findCosts}
					/>
				)}
				{permissions.canWrite && (
					<>
						<UfinetButton
							className="me-3"
							icon={faPlus}
							onClick={() => showNewModal()}
							content={intl.formatMessage({ id: 'NEW.REGISTER' })}
						/>
						<UfinetButton
							icon={faUpload}
							onClick={() => showModal()}
							content={intl.formatMessage({ id: 'BULK_LOAD' })}
						/>
					</>
				)}
			</>
		)

		const onRowEditComplete = (data: ICostsEquipmentResponse) => {
			if (
				deepEqual(
					data,
					content.find((it) => it.id === data.id)
				)
			)
				return

			Swal.fire({
				icon: 'warning',
				title: intl.formatMessage({ id: 'COUNTRY.REGISTRY.UPDATE' }, { countryName: data.countryName }),
				html: intl.formatMessage({ id: 'COUNTRY.REGISTRY.UPDATE.CONFIRM' }, { networkAreaName: data.networkAreaName }),
				showCancelButton: true,
				showConfirmButton: true,
				reverseButtons: true,
				confirmButtonColor: colorConstants.primaryColor,
				cancelButtonColor: colorConstants.dangerColor,
				preConfirm: () => {
					const {
						id,
						countryId,
						countryName,
						networkAreaName,
						maximumCapacity,
						minimumCapacity,
						cost,
						laborCost,
						clientId,
					} = data
					_equipmentCostsService
						.update(id, {
							countryId,
							countryName,
							networkAreaName,
							zoneType: zoneTypeMappings.get(data.zoneType) || data.zoneType,
							maximumCapacity,
							minimumCapacity,
							cost,
							laborCost,
							clientId,
						})
						.then(() => findCosts())
						.catch(console.error)
				},
			})
		}

		const onSelectionChange = (values: ICostsEquipmentResponse[]) => {
			onSelectionChangePreventDefaultsRemoval(
				values,
				selectedValues,
				content,
				setSelectedValues,
				intl.formatMessage({ id: 'BY_DEFAULT.DELETE.WARNING' })
			)
		}

		return (
			<>
				<Table
					selectedValues={selectedValues}
					onSelectionChange={permissions.canDelete ? onSelectionChange : undefined}
					onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
					className={className}
					cols={colsEquipment}
					headerButtons={getHeaderButtons()}
					content={content}
					emptyMessage={loadingRecords ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
				/>
				{/* Modals */}
				<UfinetModal
					size="xl"
					show={modal}
					handleClose={hideModal}
					title={intl.formatMessage({ id: 'MODAL.UPLOAD_EXCEL.TITLE' })}
				>
					<UploadExcelModalBody
						xlsEmptyDownloadCallback={_equipmentCostsService.downloadEmptyListXLS}
						xlsUploadCallback={_equipmentCostsService.uploadXLS}
						onXlsUploaded={findCosts}
						hideModal={hideModal}
					/>
				</UfinetModal>
				<UfinetModal
					size="lg"
					show={newModal}
					handleClose={hideNewModal}
					title={intl.formatMessage({ id: 'MODAL.NEW.REGISTER.TITLE' })}
				>
					<NewEquipmentModalBody findCosts={findCosts} hideModal={hideNewModal} />
				</UfinetModal>
			</>
		)
	},
	(prevProps, nextProps) => {
		return (
			deepEqual(prevProps.content, nextProps.content) && deepEqual(prevProps.loadingRecords, nextProps.loadingRecords)
		)
	}
)

export { EquipmentTable }
