import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { AccessAreaStatusArray, AccessAreaStatusElement, AreaAccessStatus, useTranslator } from 'ufinet-web-functions'

const getOpacity = (options: any, areaAccessStatus: AreaAccessStatus) => {
	if (options === areaAccessStatus) return 1
	return 0.35
}

type Props = {
	selected?: AreaAccessStatus
	onChange: (input: AreaAccessStatus) => void
}

const AccessAreaStatusComponent: FC<Props> = ({ selected, onChange }: Props) => {
	const translate = useTranslator()

	return (
		<>
			<div className="d-flex justify-content-center">
				{AccessAreaStatusArray.map((element: AccessAreaStatusElement, index: number) => (
					<OverlayTrigger
						key={`access-area-status-${index}`}
						placement="bottom"
						overlay={<Tooltip className="tooltip-dark">{translate(element.tooltipId)}</Tooltip>}
					>
						<div className="d-inline">
							<FontAwesomeIcon
								className="access-area-status"
								onClick={() => onChange(element.accessAreaStatus)}
								color={element.color}
								icon={faCircle}
								opacity={getOpacity(selected, element.accessAreaStatus)}
							/>
						</div>
					</OverlayTrigger>
				))}
			</div>
		</>
	)
}

export { AccessAreaStatusComponent }
