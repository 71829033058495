import { ICablesAndBoxes } from 'pages/loadKmz/common/LoadKmzTypesAndInterfaces'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { TableArrivalsStyle, loadTypesColorRed } from 'ufinet-web-components'
import { ITableArrivalsStyleContent } from 'ufinet-web-components/dist/components/tables/TableArrivalsStyle'

type Props = {
	data: ICablesAndBoxes
}
const PreviewSummaryTable: FC<Props> = ({ data }) => {
	const intl = useIntl()

	const header: string[] = [
		intl.formatMessage({ id: 'PREVIEW.COUNTRY' }),
		intl.formatMessage({ id: 'PREVIEW.LOAD_TYPE' }),
		intl.formatMessage({ id: 'PREVIEW.NUM_COORD' }),
		intl.formatMessage({ id: 'FILE' }),
	]

	const content: ITableArrivalsStyleContent[][] = [
		[
			{
				value: data?.country,
			},
			{
				value: data?.itemType.label,
				style: `${
					data?.itemType.color === loadTypesColorRed ? 'badge badge-light-danger' : 'badge badge-light-primary'
				}`,
			},
			{
				value: data?.numCoordinates,
			},
			{
				value: data?.filePath,
			},
		],
	]

	return <TableArrivalsStyle header={header} content={content} />
}
export { PreviewSummaryTable }
