import { FetchAPI, IAuthData, defaultOptionsFetch } from 'ufinet-web-functions'
import { ToweringListPrice } from '../queries/ToweringQueries'
import { ToweringRepository } from './ToweringRepository'

const url = `${process.env.REACT_APP_URL_BACK_AQS}api/product/towering`

export const HttpToweringRepository: (authData: IAuthData) => ToweringRepository = (authData) => {
	const fetchOptions = {
		...defaultOptionsFetch,
		notifyError: false,
		authenticationData: authData,
		sendApiKey: true,
	}
	const getStructureTypes = () => {
		return FetchAPI.get(url + '/structureTypes', fetchOptions)
	}

	const getColocationPositions = () => {
		return FetchAPI.get(url + '/colocationPositions', fetchOptions)
	}

	const getStructureHeights = (collocationListPrice: ToweringListPrice) => {
		const { colocationPositionId, corporateGroupId, destinationLatitude, destinationLongitude, structureTypeId } =
			collocationListPrice
		return FetchAPI.get(
			url +
				`/structureHeights?corporateGroupId=${corporateGroupId}
				&colocationPositionId=${colocationPositionId}&structureTypeId=${structureTypeId}
				&destinationLatitude=${destinationLatitude}&destinationLongitude=${destinationLongitude}`,
			fetchOptions
		)
	}

	return {
		getStructureTypes,
		getColocationPositions,
		getStructureHeights,
	}
}
