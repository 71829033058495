import { useClientByIdQuery } from 'modules/hub/application/queries/ClientQueries'
import { useContactByIdAndClientIdQuery } from 'modules/hub/application/queries/ContactQueries'
import { useCorporateGroupByIdAndCountryQuery } from 'modules/hub/application/queries/CorporateGroupQueries'
import { useCountryByIdQuery } from 'modules/hub/application/queries/CountryQueries'
import { usePendingQuotationDetailsQuery } from 'modules/prefeasibility/application/queries/PendingQuotationsQueries'
import { useTechnicalGroupByIdQuery } from 'modules/prefeasibility/application/queries/TechnicalGroupQueries'
import { HttpPrefeasibilityRepository } from 'modules/prefeasibility/infrastructure/HttpPrefeasibilityRepository'
import { HttpTechnicalGroupRepository } from 'modules/prefeasibility/infrastructure/HttpTechnicalGroupRepository'
import { FC, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { UfinetBox, UfinetSectionBox } from 'ufinet-web-components'
import {
	FrontEndApplication,
	clientService,
	contactsService,
	corporateGroupService,
	countriesService,
	useAuthData,
	useTranslator,
} from 'ufinet-web-functions'
import { HeaderCard, IPendingQuotationDetailsProps } from './components/HeaderCard'
import { PendingQuotationForm } from './components/PendingQuotationForm'

const PendingQuotationDetailsPage: FC = () => {
	const { quotationId = '' } = useParams()

	const [currentQuotationId, setCurrentQuotationId] = useState(quotationId)
	const translate = useTranslator()

	const authData = useAuthData()

	const clientRepository = useMemo(
		() => clientService(authData, { frontendApplication: FrontEndApplication.AQS }),
		[authData]
	)
	const prefeasibilityRepository = useMemo(() => HttpPrefeasibilityRepository(authData), [authData])
	const technicalGroupRepository = useMemo(() => HttpTechnicalGroupRepository(authData), [authData])

	const {
		data: pendingQuotationDetails,
		isLoading: isLoadingPendingQuotationDetails,
		refetch: refetchPendingQuotationDetails,
	} = usePendingQuotationDetailsQuery(prefeasibilityRepository, currentQuotationId !== '', currentQuotationId)
	const {
		data: client,
		isFetched: isFetchedClient,
		refetch: refetchClient,
	} = useClientByIdQuery(clientRepository, false, pendingQuotationDetails?.idCliente)
	const {
		data: corporateGroup,
		isFetched: isFetchedCorporateGroup,
		refetch: refetchCorporateGroup,
	} = useCorporateGroupByIdAndCountryQuery(
		corporateGroupService(authData),
		false,
		client?.corporateGroupId,
		client?.countryId
	)

	const {
		data: country,
		isFetched: isFetchedCountry,
		refetch: refetchCountry,
	} = useCountryByIdQuery(countriesService(authData), false, client?.countryId)

	const {
		data: contact,
		isFetched: isFetchedContact,
		refetch: refetchContact,
	} = useContactByIdAndClientIdQuery(
		contactsService(authData),
		false,
		pendingQuotationDetails?.services[0].prefeasibility?.contactId,
		pendingQuotationDetails?.idCliente
	)

	const duplicateQuotation = async () => {
		const duplicateQuotationResponse = await prefeasibilityRepository.duplicateQuotation(quotationId)
		setCurrentQuotationId(duplicateQuotationResponse.aqsId)
	}

	const updateQuotationDetails = () => {
		refetchPendingQuotationDetails()
	}

	useEffect(() => {
		if (!pendingQuotationDetails) return
		refetchClient()
		refetchContact()
	}, [pendingQuotationDetails])

	useEffect(() => {
		if (!client) return
		refetchCorporateGroup()
		refetchCountry()
	}, [client])

	const { data: technicalGroup } = useTechnicalGroupByIdQuery(
		pendingQuotationDetails?.services[0].prefeasibility?.technicalGroupId ?? '',
		Boolean(pendingQuotationDetails?.services[0]?.prefeasibility?.technicalGroupId),
		technicalGroupRepository
	)

	const headerPrefeasibilityData: IPendingQuotationDetailsProps = useMemo(
		() => ({
			id: pendingQuotationDetails?.aqsId ?? '-',
			quotationId: pendingQuotationDetails?.phxAqsId ?? '-',
			agreementDate: pendingQuotationDetails?.services[0].prefeasibility?.agreementDate ?? '-',
			originDate: pendingQuotationDetails?.services[0].prefeasibility?.requestDate ?? '-',
			department: pendingQuotationDetails?.services[0].prefeasibility?.technicalGroupId ?? '',
			departmentName: technicalGroup?.name ?? '-',
			quotationStatus: pendingQuotationDetails?.prefeasibilityStatus
				? translate(`PREFEASIBILITY.STATUSES.${pendingQuotationDetails?.prefeasibilityStatus}`)
				: '-',
			ticket: pendingQuotationDetails?.services[0].prefeasibility?.ticketId?.toString() ?? '-',
			clientInform: pendingQuotationDetails?.services[0].prefeasibility?.extraInformation ?? '-',
		}),
		[pendingQuotationDetails, technicalGroup, translate]
	)

	useEffect(() => {
		if (!technicalGroup) return
		headerPrefeasibilityData.departmentName = technicalGroup.name
	}, [technicalGroup, headerPrefeasibilityData])

	return (
		<UfinetBox limitHeightToPageBottom={false}>
			<HeaderCard pendingQuotationDetails={headerPrefeasibilityData} />
			<UfinetSectionBox>
				<div className="row justify-content-between align-items-end pb-5">
					{isLoadingPendingQuotationDetails ||
					!pendingQuotationDetails ||
					!isFetchedClient ||
					!isFetchedCorporateGroup ||
					!isFetchedCountry ||
					!isFetchedContact ? (
						<span className="d-flex p-input-icon-left" style={{ zIndex: 9 }}>
							<i className="pi pi-spin pi-spinner" />
						</span>
					) : (
						country &&
						corporateGroup &&
						client && (
							<PendingQuotationForm
								aqsId={currentQuotationId}
								parentAqsId={currentQuotationId !== quotationId ? quotationId : undefined}
								duplicateQuotation={duplicateQuotation}
								updateQuotationDetails={updateQuotationDetails}
								selectedCountry={{
									label: country.name,
									value: country.id,
								}}
								selectedClient={{
									label: client.name,
									value: client.id,
								}}
								selectedCorporateGroup={{
									label: corporateGroup.name,
									value: corporateGroup.id,
								}}
								initialSelectedContact={
									contact
										? {
												label: contact.name,
												value: contact.id,
										  }
										: undefined
								}
								pendingQuotationDetails={pendingQuotationDetails}
								prefeasibilityRepository={prefeasibilityRepository}
								technicalGroupRepository={technicalGroupRepository}
							/>
						)
					)}
				</div>
			</UfinetSectionBox>
		</UfinetBox>
	)
}

export { PendingQuotationDetailsPage }
