import { infoWindowFromPOI } from 'modules/google-maps/infowindow/functions/infoWindowFromPOI'
import { IntlShape } from 'react-intl'
import { v4 as uuidv4 } from 'uuid'
import { MapMarker } from '../MapMarker'
import { MapMarkerBaseOptions } from '../MapMarkerBaseOptions'
import { MapMarkerType } from '../MapMarkerType'

export const markerOptionsFromPOI = (place: google.maps.places.PlaceResult, intl: IntlShape): MapMarker => {
	const coordinates: google.maps.LatLngLiteral = {
		lat: place?.geometry?.location?.lat()!,
		lng: place?.geometry?.location?.lng()!,
	}
	return {
		id: place.place_id || uuidv4(),
		type: MapMarkerType.USER,
		options: { ...MapMarkerBaseOptions.get(MapMarkerType.USER), position: coordinates },
		infoWindow: infoWindowFromPOI(place, coordinates, intl),
	}
}
