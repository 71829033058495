import { MapsClickEvent } from './MapsClickEvent'
import { MapsClickEventType } from './MapsClickEventType'

export class UserClickEvent extends MapsClickEvent {
	nativeEvent: google.maps.MapMouseEvent | google.maps.IconMouseEvent
	constructor(input: { nativeEvent: google.maps.MapMouseEvent | google.maps.IconMouseEvent }) {
		super({ nativeEvent: input.nativeEvent, type: MapsClickEventType.USER })
		this.nativeEvent = input.nativeEvent
	}
}
