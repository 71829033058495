import React from 'react'
import { Table } from 'ufinet-web-components'
import { IAreaModal } from 'ufinet-web-functions'
import { colsAreaPreview } from './AreaPreviewColData'

type Props = {
	data: IAreaModal
}
const AreaPreviewTable: React.FC<Props> = ({ data }: Props) => {
	return <Table cols={colsAreaPreview} content={[data]} />
}
export { AreaPreviewTable }
