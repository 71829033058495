import { useContext } from 'react'
import { AqsModules, AuthContext, Authority, IPagePermissions } from 'ufinet-web-functions'

export const useGetPermission = (service: AqsModules, permission: keyof IPagePermissions): boolean => {
	const userRoles = useContext(AuthContext).userData?.authorities || []

	const domainPermissionsDictionary: { [key in AqsModules]: { [key in keyof IPagePermissions]: boolean } } = {
		QUOT: Authority.getQuotationPermissions(userRoles),
		KMZ: Authority.getKmzLoadPermissions(userRoles),
		COST: Authority.getCostsPermissions(userRoles),
		PRE: Authority.getAqsPrefeasibilityPermissions(userRoles),
		CONF: Authority.getAqsConfigPermissions(userRoles),
	}

	return domainPermissionsDictionary[service][permission]
}
