import { TechnicalGroup } from 'modules/prefeasibility/domain/models/technicalGroup'
import { TechnicalGroupRepository } from 'modules/prefeasibility/domain/repository/TechnicalGroupRepository'
import { FetchAPI, FetchOptions, IAuthData, defaultOptionsFetch } from 'ufinet-web-functions'

export const HttpTechnicalGroupRepository: (authData: IAuthData) => TechnicalGroupRepository = (
	authData: IAuthData
) => {
	const url = `${process.env.REACT_APP_URL_BACK_AQS}api/technicalGroups`

	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		notifyError: false,
		authenticationData: authData,
		sendApiKey: true,
	}

	const getTechnicalGroups = async (countryId: string) => {
		const data: TechnicalGroup[] = await FetchAPI.post(url, { countryId }, fetchOptions)
		return data
	}

	const getTechnicalGroupById = async (id: string) => {
		const data: TechnicalGroup = await FetchAPI.get(`${url}/${id}`, fetchOptions)
		return data
	}

	return {
		getTechnicalGroups,
		getTechnicalGroupById,
	}
}
