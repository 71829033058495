import { MapInfoWindow } from 'modules/google-maps/events/infowindow/MapInfoWindow'
import { IntlShape } from 'react-intl'
import { getCoordinatesCopyElement } from './getCoordinatesCopyElement'

export const infoWindowFromCoordinates = (coordinates: google.maps.LatLngLiteral, intl: IntlShape): MapInfoWindow => {
	const content = `
	<div class="d-flex flex-column align-items-center gap-2">
		<div class="fw-bolder">
			${intl.formatMessage({
				id: 'MAP.CUSTOM_MARKER',
			})}
		</div>
		${getCoordinatesCopyElement(coordinates, { tooltip: intl.formatMessage({ id: 'COPY.COORDINATES' }) })}
	</div>`
	return { options: { content, disableAutoPan: false } }
}
