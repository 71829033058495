import { FetchAPI, IAuthData, defaultOptionsFetch } from 'ufinet-web-functions'
import { CollocationListPrice } from '../queries/CollocationQueries'
import { CollocationRepository } from './CollocationRepository'

const url = `${process.env.REACT_APP_URL_BACK_AQS}api/product/collocation`

export const HttpCollocationRepository: (authData: IAuthData) => CollocationRepository = (authData) => {
	const fetchOptions = {
		...defaultOptionsFetch,
		notifyError: false,
		authenticationData: authData,
		sendApiKey: true,
	}
	const getEmplacementTypes = () => {
		return FetchAPI.get(url + '/emplacementTypes', fetchOptions)
	}

	const getCollocationUnits = () => {
		return FetchAPI.get(url + '/collocationUnits', fetchOptions)
	}

	const getCollocationQuantities = (collocationListPrice: CollocationListPrice) => {
		const { collocationUnitId, corporateGroupId, destinationLatitude, destinationLongitude, emplacementTypeId } =
			collocationListPrice
		return FetchAPI.get(
			url +
				`/quantity?corporateGroupId=${corporateGroupId}
				&collocationUnitId=${collocationUnitId}&emplacementTypeId=${emplacementTypeId}
				&destinationLatitude=${destinationLatitude}&destinationLongitude=${destinationLongitude}`,
			fetchOptions
		)
	}

	return {
		getEmplacementTypes,
		getCollocationUnits,
		getCollocationQuantities,
	}
}
