import { forwardRef, useContext, useImperativeHandle, useMemo, useRef, useState } from 'react'
import {
	IUfinetSelectOption,
	LoadTypeSelect,
	LoadTypeSelectHandle,
	mkOptionFromNetworkItemType,
	UfinetSelectOptionValue,
} from 'ufinet-web-components'
import { useTranslator } from 'ufinet-web-functions'
import { MapControlsAreasCablesBoxesContext } from './context/MapControlsAreasCablesBoxesContext'

interface IMapControlsCablesAndBoxesProps {
	map?: google.maps.Map
	onCablesAndBoxesChange?: (cablesAndBoxes: IUfinetSelectOption[]) => void
	disabled?: boolean
}

export type MapControlsCablesAndBoxesHandle = {
	clearSelect: (clearDefaults?: boolean) => void
}

const MapControlsCablesAndBoxes = forwardRef(
	({ map: _map, onCablesAndBoxesChange = () => {}, disabled }: IMapControlsCablesAndBoxesProps, ref) => {
		const translate = useTranslator()
		const { preSelectedItemTypes } = useContext(MapControlsAreasCablesBoxesContext)
		const preSelectedOptions = useMemo(
			() => preSelectedItemTypes.map((itemType) => mkOptionFromNetworkItemType(itemType, translate)),
			[preSelectedItemTypes, translate]
		)
		const [selectedItems, setSelectedItems] = useState<IUfinetSelectOption[]>([])
		const loadTypeSelectRef = useRef<LoadTypeSelectHandle>(null)

		useImperativeHandle(
			ref,
			() =>
				({
					clearSelect: loadTypeSelectRef.current?.clearSelect,
				} as MapControlsCablesAndBoxesHandle)
		)

		const onChange = (cablesAndBoxes?: UfinetSelectOptionValue) => {
			const newValues = (cablesAndBoxes as IUfinetSelectOption[]) || []
			setSelectedItems(newValues)
			onCablesAndBoxesChange(newValues)
		}

		return (
			<LoadTypeSelect
				ref={loadTypeSelectRef}
				isMulti
				value={selectedItems}
				defaultValue={preSelectedOptions}
				shouldClearDefaultValue={false}
				onChange={(cbx) => onChange(cbx as IUfinetSelectOption[])}
				tooltipTitle={translate('MAP.KMZ.LOAD_TYPE_SELECTOR.TOOLTIP')}
				isDisabled={disabled}
			/>
		)
	}
)

export { MapControlsCablesAndBoxes }
