import { PolygonClickEvent } from 'modules/google-maps/polygon/PolygonClickEvent'
import { FC, useEffect, useMemo } from 'react'

type PolygonProps = {
	id: string
	options: google.maps.PolygonOptions
	onClick?: (event: PolygonClickEvent) => void
}

const Polygon: FC<PolygonProps> = ({ id, options, onClick }) => {
	const polygon = useMemo(() => new google.maps.Polygon(options), [options])

	useEffect(() => {
		polygon.setMap(options.map || null)
		polygon.addListener('click', (e: google.maps.MapMouseEvent) => {
			const polygonClickEvent = new PolygonClickEvent({ origin: { id, value: polygon }, nativeEvent: e })
			onClick?.(polygonClickEvent)
		})

		return () => {
			google.maps.event.clearListeners(polygon, 'click')
			polygon.setMap(null)
		}
	}, [id, onClick, options.map, polygon])

	return null
}

export { Polygon }
