import { FormikProps } from 'formik'
import { CollocationRepository } from 'modules/product/repository/CollocationRepository'
import { FC } from 'react'
import { FormBuilder } from '../FormBuilder'
import { useCollocationServiceForm } from './hooks/useCollocationServiceForm'

type CollocationServiceFormProps = {
	formik: FormikProps<any>
	collocationRepository: CollocationRepository
	corporateGroupId: string
}

export const CollocationServiceForm: FC<CollocationServiceFormProps> = ({
	formik,
	collocationRepository,
	corporateGroupId,
}) => {
	const { formFields, translate, onChange } = useCollocationServiceForm(formik, collocationRepository, corporateGroupId)

	return (
		<div className="row col-11">
			{Object.keys(formFields).map((field) => {
				return (
					<FormBuilder
						key={field}
						formField={formFields[field]}
						translate={translate}
						formik={formik}
						onChange={onChange}
					/>
				)
			})}
		</div>
	)
}
