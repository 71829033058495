import { ICalculationPoint } from 'ufinet-web-functions'

export const buildPaths = (coordinatesList: ICalculationPoint[][]) => {
	const paths: google.maps.LatLngLiteral[][] = []

	coordinatesList.forEach((coordinates) => {
		const path: google.maps.LatLngLiteral[] = coordinates.map(
			(coordinate) => ({ lat: coordinate.latitude, lng: coordinate.longitude } as google.maps.LatLngLiteral)
		)
		paths.push(path)
	})
	return paths
}
