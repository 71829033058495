import { FC } from 'react'
import { UfinetSectionBox } from 'ufinet-web-components'
import { useTranslator } from 'ufinet-web-functions'
import { CardText } from './CardText'

export interface IPendingQuotationDetailsProps {
	id: string
	quotationId: string
	agreementDate: string
	originDate: string
	department: string
	departmentName: string
	ticket: string
	quotationStatus: string
	clientInform: string
	[key: string]: string
}

const HeaderCard: FC<{
	pendingQuotationDetails: IPendingQuotationDetailsProps
}> = ({ pendingQuotationDetails }) => {
	const translate = useTranslator()

	const rows = {
		firstColumn: [
			{
				label: 'QUOTATION.ID',
				keyOfValue: 'id',
			},
			{
				label: 'PHX.QUOTATION.ID',
				keyOfValue: 'quotationId',
			},
		],
		secondColumn: [
			{
				label: 'ASSIGNED.DEPARTMENT',
				keyOfValue: 'departmentName',
			},
			{
				label: 'COMMITMENT.DATE',
				keyOfValue: 'agreementDate',
			},
			{
				label: 'REQUEST.DATE',
				keyOfValue: 'originDate',
			},
		],
		thirdColumn: [
			{
				label: 'TICKET.NUMBER.GENERATED',
				keyOfValue: 'ticket',
			},
			{
				label: 'CLIENT.INFORM',
				keyOfValue: 'clientInform',
			},
			{
				label: 'QUOTATION.STATUS',
				keyOfValue: 'quotationStatus',
			},
		],
	}

	return (
		<UfinetSectionBox className="mb-3 d-flex justify-content-between pe-20">
			<div className="flex-column">
				<h1 className="mb-0">{translate('PENDING.QUOTATIONS')}</h1>
				{rows.firstColumn.map((row) => (
					<CardText key={row.keyOfValue} label={row.label} pendingValueData={pendingQuotationDetails[row.keyOfValue]} />
				))}
			</div>
			<div className="d-flex">
				<div className="me-10">
					{rows.secondColumn.map((row) => (
						<CardText
							key={row.keyOfValue}
							label={row.label}
							pendingValueData={pendingQuotationDetails[row.keyOfValue]}
						/>
					))}
				</div>
				<div>
					{rows.thirdColumn.map((row) => (
						<CardText
							key={row.keyOfValue}
							label={row.label}
							pendingValueData={pendingQuotationDetails[row.keyOfValue]}
						/>
					))}
				</div>
			</div>
		</UfinetSectionBox>
	)
}

export { HeaderCard }
