import { FC } from 'react'
import { UfinetBox, UfinetSectionBox } from 'ufinet-web-components'
import { BulkForm } from './components/BulkForm'

const BulkQuotationPage: FC = () => {
	return (
		<UfinetBox>
			<UfinetSectionBox>
				<BulkForm />
			</UfinetSectionBox>
		</UfinetBox>
	)
}

export { BulkQuotationPage }
