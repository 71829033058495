import { ColData, DivWrapped } from 'ufinet-web-components'
import { BulkQuotationStatus, IBulkQuotation } from 'ufinet-web-functions'
import TableCellServicePoints from './TableCellServicePoints'

export const colsIntMassive: ColData[] = [
	{ field: 'id', header: 'MASSIVE.ID' },
	{
		field: 'statusMessage',
		header: 'MASSIVE.STATUS',
		body: (row: IBulkQuotation) => {
			return row.statusMessage || row.status || '-'
		},
	},
	{
		field: 'totalServices',
		header: 'MASSIVE.TOTAL',
		body: (row: IBulkQuotation) => row.totalServices || '-',
	},
	{
		field: 'errorServices',
		header: 'MASSIVE.ERRORS',
		body: (row: IBulkQuotation) =>
			row.errorServicesDetail?.length ? (
				<TableCellServicePoints services={row.errorServicesDetail} />
			) : row.status !== BulkQuotationStatus.PENDING ? (
				row.errorServices
			) : (
				'-'
			),
	},
	{
		field: 'successServices',
		header: 'MASSIVE.SUCCESS',
		body: (row: IBulkQuotation) =>
			row.successServicesDetail?.length ? (
				<TableCellServicePoints services={row.successServicesDetail} />
			) : row.status !== BulkQuotationStatus.PENDING ? (
				row.successServices
			) : (
				'-'
			),
	},
	{
		field: 'lastModifiedBy',
		header: 'COL.ACT_BY',
		body: (row: IBulkQuotation) => (
			<DivWrapped className="ufinet-table-cell" input={row.lastModifiedBy} separator="@" />
		),
	},
	{ field: 'lastModifiedAt', header: 'COL.ACT_AT' },
]

export const colsExtMassive: ColData[] = [
	{ field: 'id', header: 'MASSIVE.ID' },
	{ field: 'totalServices', header: 'MASSIVE.TOTAL' },
	{ field: 'lastModifiedAt', header: 'COL.ACT_AT' },
]
