import { faClose, faSave } from '@fortawesome/free-solid-svg-icons'
import { PrefeasibilityRepository } from 'modules/prefeasibility/domain/repository/PrefeasibilityRepository'
import { TechnicalGroupRepository } from 'modules/prefeasibility/domain/repository/TechnicalGroupRepository'
import {
	DatePicker,
	DatePickerTypeEnum,
	IUfinetSelectOption,
	UfinetAsyncButton,
	UfinetButton,
	UfinetInput,
	UfinetSelect,
} from 'ufinet-web-components'
import { useTranslator } from 'ufinet-web-functions'
import { usePrefeasibilityForm } from './hooks/PrefeasibilityFormHook'

type PrefeasibilityFormProps = {
	aqsId: string
	contactId?: string
	reference?: string
	finalClient?: string
	countryId: string
	onFormClosed: () => void
	confirmPrefeasibility: () => void
	prefeasibilityRepository: PrefeasibilityRepository
	technicalGroupRepository: TechnicalGroupRepository
}

const PrefeasibilityForm = ({
	aqsId,
	contactId,
	reference,
	finalClient,
	countryId,
	onFormClosed,
	confirmPrefeasibility,
	prefeasibilityRepository,
	technicalGroupRepository,
}: PrefeasibilityFormProps) => {
	const {
		formik,
		technicalGroupOptions,
		today,
		onChangeTextField,
		onChangeTechnicalGroupSelectField,
		onChangeDateField,
	} = usePrefeasibilityForm(
		aqsId,
		contactId,
		reference,
		finalClient,
		countryId,
		confirmPrefeasibility,
		onFormClosed,
		prefeasibilityRepository,
		technicalGroupRepository
	)

	const translate = useTranslator()

	return (
		<form onSubmit={formik.handleSubmit} data-testid="PrefeasibilityForm-form">
			<div className="d-flex flex-column justify-content-between">
				<div className="row p-5">
					<div className="row">
						<UfinetSelect
							requiredIcon
							className="col-6"
							labelTitle={translate('PREFEASIBILITY.FORM.FIELD.TECHNICAL_GROUP_ID')}
							tooltipTitle={translate('PREFEASIBILITY.FORM.FIELD.TECHNICAL_GROUP_ID')}
							error={formik.errors.technicalGroupId}
							isClearable
							onChange={(value) => onChangeTechnicalGroupSelectField(value as IUfinetSelectOption | undefined)}
							value={formik.values.technicalGroupSelect}
							options={technicalGroupOptions}
							placeholder={translate('PREFEASIBILITY.FORM.FIELD.TECHNICAL_GROUP_ID')}
						/>
						<UfinetInput
							error={formik.errors.extraInformation}
							type="text"
							labelTitle={translate('PREFEASIBILITY.FORM.FIELD.EXTRA_INFORMATION')}
							tooltipTitle={translate('PREFEASIBILITY.FORM.FIELD.EXTRA_INFORMATION')}
							className="col-6"
							onChange={(e) => onChangeTextField('extraInformation', e)}
							value={formik.values.extraInformation ?? ''}
							solid={false}
							placeholder={translate('PREFEASIBILITY.FORM.FIELD.EXTRA_INFORMATION')}
						/>
					</div>
					<div className="row mt-4">
						<DatePicker
							id="agreementDate"
							className="col-6 col-lg-3"
							label={translate('PREFEASIBILITY.FORM.FIELD.AGREEMENTDATE')}
							tooltip={translate('PREFEASIBILITY.FORM.FIELD.AGREEMENTDATE')}
							type={DatePickerTypeEnum.DATE_ONLY}
							value={formik.values.agreementDate}
							onChange={(e) => {
								onChangeDateField('agreementDate', e.value as Date)
							}}
							min={today}
							error={formik.errors.agreementDate}
							labelIconRequired
							showCalendarIcon
						/>
					</div>
				</div>
				<div className="row mt-5 mb-5 p-5">
					<div className="mt-5 ms-3 p-0 w-auto" data-testid="PrefeasibilityForm-sendButtonContainer">
						<UfinetAsyncButton
							content={translate('BUTTON.ACCEPT')}
							icon={faSave}
							onClick={() => formik.submitForm()}
							isDisabled={formik.isValidating || !formik.isValid || formik.isSubmitting || !formik.dirty}
							showSpinner
						/>
					</div>
					<div className="mt-5 ms-3 p-0 w-auto" data-testid="PrefeasibilityForm-cancelButtonContainer">
						<UfinetButton
							content={translate('BUTTON.CANCEL')}
							icon={faClose}
							secondaryButton
							onClick={onFormClosed}
							isDisabled={formik.isSubmitting}
						/>
					</div>
				</div>
			</div>
		</form>
	)
}

export { PrefeasibilityForm }
