import React from 'react'

export const mapPickerDefaults = {
	mapPickerBackgroundStyle: {
		pointerEvents: 'none',
		opacity: 0.6,
	} as React.CSSProperties,
	mapPickerHighlightStyle: {
		backgroundColor: 'black',
	} as React.CSSProperties,
}
