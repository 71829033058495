/* eslint-disable promise/always-return */
/* eslint-disable promise/catch-or-return */
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons/faCircleExclamation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FC, useContext } from 'react'
import { useIntl } from 'react-intl'

import Swal from 'sweetalert2'
import { colorConstants, WizardButtonNext, WizardButtonPrev, WizardButtonsWrapper } from 'ufinet-web-components'
import { AuthContext, IAreaModal, kmzAreaService } from 'ufinet-web-functions'
import { AreaPreviewTable } from '../../common/preview/AreaPreviewTable'

type Props = {
	data: IAreaModal
	hideModal: Function
	wizardRef: any
	actionButtonRef: any
}
const AreaAddConfirm: FC<Props> = ({ data, hideModal, wizardRef, actionButtonRef }: Props) => {
	const intl = useIntl()

	const authData = useContext(AuthContext)
	const _kmzAreaService = kmzAreaService(authData)

	const preConfirmKmz = (e: any) => {
		e.preventDefault()
		Swal.fire({
			title: intl.formatMessage({ id: 'STEP.CABLES&BOXES.LOAD_KMZ.SWEET_ALERT.TITLE' }),
			icon: 'warning',
			showCancelButton: true,
			showConfirmButton: true,
			reverseButtons: true,
			confirmButtonText: intl.formatMessage({ id: 'SAVE' }),
			cancelButtonText: intl.formatMessage({ id: 'CANCEL' }),
			showLoaderOnConfirm: true,
			preConfirm: () => {
				confirmKmz()
					.then(() => {
						Swal.fire({
							title: intl.formatMessage({ id: 'STEP.CABLES&BOXES.LOAD_KMZ.SWEET_ALERT.TITLE.SUCCESS' }),
							text: intl.formatMessage({ id: 'STEP.CABLES&BOXES.LOAD_KMZ.SWEET_ALERT.TEXT.SUCCESS' }),
							showConfirmButton: true,
							reverseButtons: true,
							confirmButtonColor: '#0065A4',
							allowOutsideClick: false,
							icon: 'success',
							preConfirm: () => {
								hideModal()
							},
						})
					})
					.catch(() => {
						Swal.fire({
							title: 'Error',
							text: 'something goes wrong',
							reverseButtons: true,
							showConfirmButton: true,
							confirmButtonColor: '#0065A4',
							allowOutsideClick: false,
							icon: 'error',
							preConfirm: () => {
								hideModal()
							},
						})
					})
			},
			confirmButtonColor: colorConstants.primaryColor,
			cancelButtonColor: colorConstants.dangerColor,
			allowOutsideClick: false,
		}).then((result) => {
			/* Read more about isConfirmed, isDenied below */
			if (result.isConfirmed) {
				Swal.fire({
					title: intl.formatMessage({ id: 'STEP.CABLES&BOXES.LOAD_KMZ.SWEET_ALERT.TITLE.SUCCESS' }),
					text: intl.formatMessage({ id: 'STEP.CABLES&BOXES.LOAD_KMZ.SWEET_ALERT.TEXT.SUCCESS' }),
					showConfirmButton: true,
					confirmButtonColor: '#0065A4',
					allowOutsideClick: false,
					icon: 'success',
					preConfirm: () => {
						hideModal()
					},
				})
			}
		})
	}

	const confirmKmz = async () => {
		await _kmzAreaService
			.confirmKmz({
				kmzStorageUrls: data.storageUrls || [''],
			})
			.then((response) => {
				return response
			})
			.catch(console.error)
	}

	return (
		<form onSubmit={preConfirmKmz} className="h-100 p-15 d-flex flex-column">
			<div className="alert alert-dismissible bg-light-warning d-flex flex-center flex-column py-10 px-10 px-lg-10 mb-10">
				<FontAwesomeIcon color="#F7BB49" size="4x" icon={faCircleExclamation} />
				<div className="text-center pt-2">
					<h1 className="fw-bolder mb-5">{intl.formatMessage({ id: 'ATTENTION' })}</h1>
					<div className="separator separator-dashed border-danger opacity-25 mb-5" />
					<div className="mb-9 text-dark">{intl.formatMessage({ id: 'STEP.CABLES&BOXES.LOAD_KMZ.TEXT' })}</div>
				</div>
			</div>
			<AreaPreviewTable data={data} />
			<WizardButtonsWrapper>
				<WizardButtonPrev wizardRef={wizardRef} />
				<WizardButtonNext wizardRef={wizardRef} actionButtonRef={actionButtonRef} submitForm />
			</WizardButtonsWrapper>
		</form>
	)
}
export { AreaAddConfirm }
