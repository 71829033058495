import { FC, useCallback, useState } from 'react'
import { UfinetBox, UfinetSectionBox } from 'ufinet-web-components'
import { useInternalUser, useTranslator } from 'ufinet-web-functions'
import { v4 as uuidv4 } from 'uuid'
import { IndividualQuotationForm } from './components/IndividualQuotationForm'

const IndividualQuotationPage: FC = () => {
	const translate = useTranslator()
	const internalUser = useInternalUser()

	const [aqsId, setAqsId] = useState(uuidv4())

	const onIndividualQuotationReset = useCallback(() => {
		setAqsId(uuidv4())
	}, [])

	return (
		<UfinetBox limitHeightToPageBottom={false}>
			<UfinetSectionBox className="mb-3">
				<h2 className="mb-0">{translate('MENU.QUOTATION.INDIVIDUAL')}</h2>
				{internalUser && (
					<h5 className="mb-0 mt-2">
						{translate('QUOTATION.ID')}: {aqsId}
					</h5>
				)}
			</UfinetSectionBox>
			<UfinetSectionBox className="mb-5">
				<div className="row justify-content-between align-items-end pb-5">
					<IndividualQuotationForm aqsId={aqsId} onReset={onIndividualQuotationReset} />
				</div>
			</UfinetSectionBox>
		</UfinetBox>
	)
}

export { IndividualQuotationPage }
