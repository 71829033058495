import { infoWindowFromCoordinates } from 'modules/google-maps/infowindow/functions/infoWindowFromCoordinates'
import { IntlShape } from 'react-intl'
import { v4 as uuidv4 } from 'uuid'
import { MapMarker } from '../MapMarker'
import { MapMarkerBaseOptions } from '../MapMarkerBaseOptions'
import { MapMarkerType } from '../MapMarkerType'

export const markerOptionsFromCoordinates = (coordinates: google.maps.LatLngLiteral, intl?: IntlShape): MapMarker => {
	return {
		id: uuidv4(),
		type: MapMarkerType.USER,
		options: {
			...MapMarkerBaseOptions.get(MapMarkerType.USER),
			position: coordinates,
		},
		infoWindow: intl ? infoWindowFromCoordinates(coordinates, intl) : undefined,
	}
}
