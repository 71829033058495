export enum MapControlPositions {
	BottomCenter = 1,
	BottomLeft = 2,
	BottomRigth = 3,
	LeftBottom = 4,
	LeftCenter = 5,
	LeftTop = 6,
	RightBottom = 7,
	RightCenter = 8,
	RightTop = 9,
	TopCenter = 10,
	TopLeft = 11,
	TopRight = 12,
}
