import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { FormikValues } from 'formik'
import { LocationInputMethod } from 'modules/google-maps/controls/picker/LocationInputMethod'
import { LocationType } from 'modules/google-maps/controls/picker/LocationType'
import { MapPickerMode } from 'modules/google-maps/controls/picker/MapPickerMode'
import { mapPickerDefaults } from 'modules/google-maps/controls/picker/mapPickerDefaults'
import { addressFromCoordinates } from 'modules/google-maps/functions/addressFromCoordinates'
import { coordinateToString } from 'modules/google-maps/functions/coordinateToString'
import { makeBounds } from 'modules/google-maps/functions/makeBounds'
import { stringToCoordinate } from 'modules/google-maps/functions/stringToCoordinate'
import { mapDefaults } from 'modules/google-maps/mapDefaults'
import { MapMarker } from 'modules/google-maps/markers/MapMarker'
import { MapMarkerType } from 'modules/google-maps/markers/MapMarkerType'
import { markerOptionsFromCoordinates } from 'modules/google-maps/markers/functions/markerOptionsFromCoordinates'
import { useCallback, useEffect } from 'react'
import { useIntl } from 'react-intl'
import {
	IUfinetSelectOption,
	PopSelect,
	UfinetButton,
	UfinetInput,
	emptyUfinetSelectOption,
} from 'ufinet-web-components'
import {
	ILocalService,
	isValidCoordinate,
	onFormikChanges,
	onFormikTextChanges,
	onFormikTrimField,
	useDebouncedCallback,
	useTranslator,
} from 'ufinet-web-functions'
import {
	useCoordinatesSelectorContext,
	useCoordinatesSelectorMapActions,
	useCoordinatesSelectorMapState,
} from './CoordinatesSelectorContext'

type CoordinatesSelectorProps = {
	hasOrigin: boolean
	formik: FormikValues
	fillBandwidths: (service?: Partial<ILocalService>) => void
	formRef: React.MutableRefObject<HTMLFormElement | null>
}

export const CoordinatesSelector = ({ hasOrigin, formik, fillBandwidths, formRef }: CoordinatesSelectorProps) => {
	const translate = useTranslator()
	const intl = useIntl()
	const onTextChange = useCallback(onFormikTextChanges, [])
	const onTrimField = useCallback(onFormikTrimField, [])
	const onChange = useCallback(onFormikChanges, [])

	const {
		destinationInputMethod,
		originInputMethod,
		popOriginRef,
		popDestinationRef,
		originAddressInputRef,
		destinationAddressInputRef,
		setDestinationInputMethod,
		setOriginInputMethod,
		clearOriginLocation,
	} = useCoordinatesSelectorContext()

	const { mapWrapperRef, mapPickerMode } = useCoordinatesSelectorMapState()

	const { setMapPickerMode, setMapMarkers, setMapShown } = useCoordinatesSelectorMapActions()

	const escFunction = useCallback((e: globalThis.KeyboardEvent) => {
		if (e.key === 'Escape') {
			e.stopPropagation()
			setMapPickerMode(MapPickerMode.NONE)
		}
	}, [])

	useEffect(() => {
		const form = formRef.current
		form?.addEventListener('keydown', escFunction)
		return () => {
			form?.removeEventListener('keydown', escFunction)
		}
	}, [escFunction, mapPickerMode])

	const getCoordinatesFromFormData = useCallback(() => {
		const origin = { lat: formik.values.originLatitude, lng: formik.values.originLongitude }
		const destination = { lat: formik.values.destinationLatitude, lng: formik.values.destinationLongitude }

		return {
			origin: isValidCoordinate(origin),
			destination: isValidCoordinate(destination),
		}
	}, [
		formik.values.destinationLatitude,
		formik.values.destinationLongitude,
		formik.values.originLatitude,
		formik.values.originLongitude,
	])

	// Update map markers on coordinate changes
	useEffect(() => {
		// Parse coordinates
		const { origin, destination } = getCoordinatesFromFormData()

		setMapMarkers((oldMarkers) => {
			let newOriginMarker = !origin ? undefined : { ...markerOptionsFromCoordinates(origin, intl) }
			if (newOriginMarker) {
				newOriginMarker = {
					...newOriginMarker,
					options: { ...newOriginMarker.options, label: '1' },
					infoWindow: newOriginMarker.infoWindow ? { ...newOriginMarker.infoWindow, open: false } : undefined,
				}
			}

			let newDestinationMarker = !destination ? undefined : { ...markerOptionsFromCoordinates(destination, intl) }
			if (newDestinationMarker) {
				newDestinationMarker = {
					...newDestinationMarker,
					options: { ...newDestinationMarker.options, label: '2' },
					infoWindow: newDestinationMarker.infoWindow ? { ...newDestinationMarker.infoWindow, open: false } : undefined,
				}
			}

			const newMarkers: MapMarker[] = [newOriginMarker, newDestinationMarker].filter((marker): marker is MapMarker =>
				Boolean(marker)
			)

			return [...oldMarkers.filter((marker) => marker.type !== MapMarkerType.USER), ...newMarkers]
		})

		// Fit map to bounds when map picker mode is exited
		if (mapPickerMode === MapPickerMode.NONE && (origin || destination)) {
			// @ts-expect-error Values are filtered to be non-nullish
			const newBounds = makeBounds([origin, destination].filter(Boolean))
			mapWrapperRef.current?.map?.fitBounds(newBounds, mapDefaults.mapBoundingPadding)
			setMapMarkers((oldMarkers) =>
				oldMarkers.map((marker) => ({
					...marker,
					infoWindow: marker.infoWindow ? { ...marker.infoWindow, open: false } : undefined,
				}))
			)
		}
	}, [getCoordinatesFromFormData, intl, mapPickerMode])

	const updateAddressFromCoordinates = () => {
		if (!mapWrapperRef.current?.geocoder) return
		const { origin, destination } = getCoordinatesFromFormData()

		originInputMethod !== LocationInputMethod.Address &&
			addressFromCoordinates(mapWrapperRef.current.geocoder, origin)
				.then((res) => {
					formik.setFieldValue('originAddress', res)
					return res
				})
				.catch((_err) => {
					formik.setFieldValue('originAddress', '')
				})

		destinationInputMethod !== LocationInputMethod.Address &&
			addressFromCoordinates(mapWrapperRef.current.geocoder, destination)
				.then((res) => {
					formik.setFieldValue('destinationAddress', res)
					return res
				})
				.catch((_err) => {
					formik.setFieldValue('destinationAddress', '')
				})
	}

	const debouncedUpdateAddresses = useDebouncedCallback(updateAddressFromCoordinates, 1000, {
		leading: true,
		trailing: true,
	})

	// Update addresses on coordinate changes
	useEffect(() => {
		debouncedUpdateAddresses()
	}, [
		formik.values.originLongitude,
		formik.values.originLatitude,
		formik.values.destinationLatitude,
		formik.values.destinationLongitude,
		debouncedUpdateAddresses,
	])

	const onMapPickerButtonPress = useCallback((targetMapPickerMode: MapPickerMode) => {
		setMapPickerMode((oldMapPickerMode) =>
			oldMapPickerMode === targetMapPickerMode ? MapPickerMode.NONE : targetMapPickerMode
		)
	}, [])

	const clearDestinationLocation = () => {
		setDestinationInputMethod(null)
		onChange(formik, 'destinationPopSelect')(emptyUfinetSelectOption)
		formik.setFieldValue('destinationLatLng', '')
		formik.setFieldValue('destinationLatitude', '')
		formik.setFieldValue('destinationLongitude', '')
		formik.setFieldValue('destinationAddress', '')
	}

	const onOriginPopChange = (newPop?: any) => {
		onChange(formik, 'originPopSelect')(newPop)

		if (newPop !== undefined) {
			setOriginInputMethod(LocationInputMethod.Pop)
			const { latitude: lat, longitude: lng } = newPop
			formik.setFieldValue('originLatitude', lat)
			formik.setFieldValue('originLongitude', lng)
			formik.setFieldValue('originLatLng', coordinateToString({ lat, lng }))
			formik.setFieldValue('originSite', '')
			fillBandwidths({ ...formik.values, originLatitude: lat, originLongitude: lng })
		} else {
			clearOriginLocation(formik)
		}
	}

	const onDestinationPopChange = (newPop: any) => {
		onChange(formik, 'destinationPopSelect')(newPop)

		if (newPop !== undefined) {
			setDestinationInputMethod(LocationInputMethod.Pop)
			const { latitude: lat, longitude: lng } = newPop
			formik.setFieldValue('destinationLatitude', lat)
			formik.setFieldValue('destinationLongitude', lng)
			formik.setFieldValue('destinationLatLng', coordinateToString({ lat, lng }))
			formik.setFieldValue('destinationSite', '')
			fillBandwidths({ ...formik.values, destinationLatitude: lat, destinationLongitude: lng })
		} else {
			clearDestinationLocation()
		}
	}

	const onLatitudeChange = (newLatitude: string | number, type: LocationType, fetchBandwidths = true): void => {
		if (type === LocationType.Origin) {
			setOriginInputMethod(LocationInputMethod.Coordinates)
			onChange(formik, 'originLatitude')(newLatitude)
			fetchBandwidths && fillBandwidths({ ...formik.values, originLatitude: +newLatitude })
		} else {
			setDestinationInputMethod(LocationInputMethod.Coordinates)
			onChange(formik, 'destinationLatitude')(newLatitude)
			fetchBandwidths && fillBandwidths({ ...formik.values, destinationLatitude: +newLatitude })
		}
	}

	const onLongitudeChange = (newLongitude: string | number, type: LocationType, fetchBandwidths = true): void => {
		if (type === LocationType.Origin) {
			setOriginInputMethod(LocationInputMethod.Coordinates)
			onChange(formik, 'originLongitude')(newLongitude)
			fetchBandwidths && fillBandwidths({ ...formik.values, originLongitude: +newLongitude })
		} else {
			setDestinationInputMethod(LocationInputMethod.Coordinates)
			onChange(formik, 'destinationLongitude')(newLongitude)
			fetchBandwidths && fillBandwidths({ ...formik.values, destinationLongitude: +newLongitude })
		}
	}

	const onLatLngChange = (newLatLng: string, type: LocationType): void => {
		onChange(formik, type === LocationType.Origin ? 'originLatLng' : 'destinationLatLng')(newLatLng)

		const newOriginLatLng = type === LocationType.Origin ? newLatLng : formik.values.originLatLng
		const newDestinationLatLng = type === LocationType.Destination ? newLatLng : formik.values.destinationLatLng

		if (newLatLng.trim()) {
			const { lat, lng } = stringToCoordinate(newLatLng)
			onLatitudeChange(lat, type, false)
			onLongitudeChange(lng, type, false)

			// Fetch bandwidths
			if (type === LocationType.Origin) {
				fillBandwidths({ ...formik.values, originLatitude: lat, originLongitude: lng })
			} else {
				fillBandwidths({ ...formik.values, destinationLatitude: lat, destinationLongitude: lng })
			}

			// Hide map on invalid addresses
			if (
				!isValidCoordinate({ lat: formik.values.originLatitude, lng: formik.values.originLongitude }) &&
				!isValidCoordinate({ lat: formik.values.destinationLatitude, lng: formik.values.destinationLongitude })
			)
				setMapShown(false)
		} else {
			if (type === LocationType.Origin && !newOriginLatLng?.trim()) clearOriginLocation(formik)
			else if (type === LocationType.Destination && !newDestinationLatLng?.trim()) clearDestinationLocation()
		}
	}

	return (
		<div className="row pt-4">
			<div className="col-12 col-md-6" style={{ display: !hasOrigin ? 'none' : 'block' }}>
				<h4>{translate('SERVICE.ORIGIN.DATA')}</h4>
				<div
					className="row mt-4"
					style={mapPickerMode !== MapPickerMode.NONE ? mapPickerDefaults.mapPickerBackgroundStyle : undefined}
				>
					<PopSelect
						origin
						ref={popOriginRef}
						className="col-10"
						value={formik.values.originPopSelect}
						error={formik.errors.originPopSelect?.label}
						onChange={(newOptions) => onOriginPopChange(newOptions as IUfinetSelectOption | undefined)}
						isDisabled={originInputMethod !== LocationInputMethod.Pop && originInputMethod != null}
					/>
				</div>
				<div
					className="row mt-4"
					style={mapPickerMode !== MapPickerMode.NONE ? mapPickerDefaults.mapPickerBackgroundStyle : undefined}
				>
					<UfinetInput
						ref={originAddressInputRef}
						className="col-10"
						error={formik.errors.originAddress}
						type="text"
						placeholder={translate('SERVICE.ADDRESS.INPUT')}
						labelTitle={translate('SERVICE.ADDRESS.ORIGIN')}
						tooltipTitle={translate('SERVICE.ADDRESS.ORIGIN.TOOLTIP')}
						onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
							if (ev?.target?.value) {
								setOriginInputMethod(LocationInputMethod.Address)
								onTextChange(formik, 'originAddress')(ev)
							} else clearOriginLocation(formik)
						}}
						value={formik.values.originAddress}
						solid={false}
						isDisabled={originInputMethod !== LocationInputMethod.Address && originInputMethod != null}
					/>
				</div>
				<div className="row mt-4">
					<UfinetInput
						className="col-10"
						error={formik.errors.originSite}
						type="text"
						placeholder={translate('SERVICE.ORIGIN.SITE.PLACEHOLDER')}
						labelTitle={translate('SERVICE.ORIGIN.SITE')}
						tooltipTitle={translate('SERVICE.ORIGIN.SITE')}
						onChange={(ev: React.ChangeEvent<HTMLInputElement>) => onTextChange(formik, 'originSite')(ev)}
						value={formik.values.originSite}
						solid={false}
						isDisabled={originInputMethod !== LocationInputMethod.Coordinates && originInputMethod != null}
					/>
				</div>
				<div className="row mt-4">
					<UfinetInput
						requiredIcon
						className="col-10"
						error={formik.errors.originLatLng || formik.errors.originLatitude || formik.errors.originLongitude}
						type="text"
						labelTitle={translate('SERVICE.COORDINATES')}
						tooltipTitle={translate('SERVICE.COORDINATES.ORIGIN.TOOLTIP')}
						onChange={(newLatLng: React.ChangeEvent<HTMLInputElement>) => {
							onLatLngChange(newLatLng.target.value, LocationType.Origin)
						}}
						onBlur={() => onTrimField(formik, 'originLatLng')}
						value={formik.values.originLatLng || ''}
						placeholder={translate('SERVICE.COORDINATES.PLACEHOLDER')}
						solid={false}
						style={mapPickerMode === MapPickerMode.DESTINATION ? mapPickerDefaults.mapPickerBackgroundStyle : undefined}
						isDisabled={
							(originInputMethod !== LocationInputMethod.Coordinates && originInputMethod != null) ||
							mapPickerMode === MapPickerMode.ORIGIN
						}
					/>
					<UfinetButton
						className="w-auto mt-9 px-5 col-1"
						content=""
						icon={faLocationDot}
						style={mapPickerMode === MapPickerMode.ORIGIN ? mapPickerDefaults.mapPickerHighlightStyle : undefined}
						onClick={() => onMapPickerButtonPress(MapPickerMode.ORIGIN)}
						isDisabled={originInputMethod !== LocationInputMethod.Coordinates && originInputMethod !== null}
					/>
				</div>
			</div>
			<div className="col-12 col-md-6">
				<h4>{translate('SERVICE.DESTINATION.DATA')}</h4>
				<div
					className="row mt-4"
					style={mapPickerMode !== MapPickerMode.NONE ? mapPickerDefaults.mapPickerBackgroundStyle : undefined}
				>
					<PopSelect
						origin={false}
						ref={popDestinationRef}
						className="col-10"
						value={formik.values.destinationPopSelect}
						error={formik.errors.destinationPopSelect?.label}
						onChange={(newOptions) => onDestinationPopChange(newOptions as IUfinetSelectOption | undefined)}
						isDisabled={destinationInputMethod !== LocationInputMethod.Pop && destinationInputMethod != null}
					/>
				</div>
				<div
					className="row mt-4"
					style={mapPickerMode !== MapPickerMode.NONE ? mapPickerDefaults.mapPickerBackgroundStyle : undefined}
				>
					<UfinetInput
						ref={destinationAddressInputRef}
						className="col-10"
						error={formik.errors.destinationAddress}
						type="text"
						placeholder={translate('SERVICE.ADDRESS.INPUT')}
						labelTitle={translate('SERVICE.ADDRESS.DESTINATION')}
						tooltipTitle={translate('SERVICE.ADDRESS.DESTINATION.TOOLTIP')}
						onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
							if (ev?.target?.value) {
								setDestinationInputMethod(LocationInputMethod.Address)
								onTextChange(formik, 'destinationAddress')(ev)
							} else clearDestinationLocation()
						}}
						value={formik.values.destinationAddress}
						solid={false}
						isDisabled={destinationInputMethod !== LocationInputMethod.Address && destinationInputMethod != null}
					/>
				</div>
				<div className="row mt-4">
					<UfinetInput
						labelTitle={translate('SERVICE.DESTINATION.SITE')}
						tooltipTitle={translate('SERVICE.DESTINATION.SITE')}
						className="col-10"
						solid={false}
						type="text"
						placeholder={translate('SERVICE.DESTINATION.SITE.PLACEHOLDER')}
						onChange={(ev: React.ChangeEvent<HTMLInputElement>) => onTextChange(formik, 'destinationSite')(ev)}
						value={formik.values.destinationSite}
						isDisabled={destinationInputMethod !== LocationInputMethod.Coordinates && destinationInputMethod != null}
					/>
				</div>
				<div className="row mt-4">
					<UfinetInput
						requiredIcon
						className="col-10"
						error={
							formik.errors.destinationLatLng || formik.errors.destinationLatitude || formik.errors.destinationLongitude
						}
						type="text"
						labelTitle={translate('SERVICE.COORDINATES')}
						tooltipTitle={translate('SERVICE.COORDINATES.DESTINATION.TOOLTIP')}
						onChange={(newLatLng: React.ChangeEvent<HTMLInputElement>) => {
							onLatLngChange(newLatLng.target.value, LocationType.Destination)
						}}
						placeholder={translate('SERVICE.COORDINATES.PLACEHOLDER')}
						onBlur={() => onTrimField(formik, 'destinationLatLng', '')}
						value={formik.values.destinationLatLng || ''}
						solid={false}
						style={mapPickerMode === MapPickerMode.ORIGIN ? mapPickerDefaults.mapPickerBackgroundStyle : undefined}
						isDisabled={
							(destinationInputMethod !== LocationInputMethod.Coordinates && destinationInputMethod != null) ||
							mapPickerMode === MapPickerMode.DESTINATION
						}
					/>
					<UfinetButton
						className="w-auto mt-9 px-5 col-1"
						content=""
						icon={faLocationDot}
						style={mapPickerMode === MapPickerMode.DESTINATION ? mapPickerDefaults.mapPickerHighlightStyle : undefined}
						onClick={() => onMapPickerButtonPress(MapPickerMode.DESTINATION)}
						isDisabled={destinationInputMethod !== LocationInputMethod.Coordinates && destinationInputMethod != null}
					/>
				</div>
			</div>
		</div>
	)
}
