import { AreaAccessStatus, AreaType, IAreaResponse } from 'ufinet-web-functions'

export const getAreasMock: IAreaResponse[] = [
	{
		id: '03017e91-8b24-4f91-a3e0-186aa9eb08c8',
		name: 'Interplaza Jutiapa2',
		typeId: AreaType.ACCESS_AREA,
		countryId: '3A9B9F0F-C6BD-E511-80FB-3863BB358F90',
		countryName: 'Guatemala',
		lastModifiedBy: 'CargaMasiva',
		lastModifiedAt: '2022-06-15T15:21:15.597',
		networkAccessAreaStatus: AreaAccessStatus.NOT_CONNECTED,
		visible: false,
	},
	{
		id: '0636994f-9750-4fc4-9fae-ee5f4db426a1',
		name: 'Design Center',
		typeId: AreaType.ACCESS_AREA,
		countryId: '3A9B9F0F-C6BD-E511-80FB-3863BB358F90',
		countryName: 'Guatemala',
		lastModifiedBy: 'CargaMasiva',
		lastModifiedAt: '2022-06-15T15:21:15.597',
		networkAccessAreaStatus: AreaAccessStatus.NOT_CONNECTED,
		visible: false,
	},
	{
		id: '0656d331-639b-4478-a39b-2d83f2c548ff',
		name: 'Portales',
		typeId: AreaType.ACCESS_AREA,
		countryId: '3A9B9F0F-C6BD-E511-80FB-3863BB358F90',
		countryName: 'Guatemala',
		lastModifiedBy: 'CargaMasiva',
		lastModifiedAt: '2022-06-15T15:21:15.597',
		networkAccessAreaStatus: AreaAccessStatus.NOT_CONNECTED,
		visible: false,
	},
	{
		id: '0cc6731c-24f6-47c7-a453-c3a4fa1b30b7',
		name: 'Dubai Center',
		typeId: AreaType.ACCESS_AREA,
		countryId: '3A9B9F0F-C6BD-E511-80FB-3863BB358F90',
		countryName: 'Guatemala',
		lastModifiedBy: 'CargaMasiva',
		lastModifiedAt: '2022-06-15T15:21:15.597',
		networkAccessAreaStatus: AreaAccessStatus.NOT_CONNECTED,
		visible: false,
	},
]
