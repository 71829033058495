import { FormikProps } from 'formik'
import { FormFields, FormFieldType } from 'modules/common/domain/model/FormFields'
import {
	useColocationPositionsQuery,
	useStructureHeightsQuery,
	useStructureTypesQuery,
} from 'modules/product/queries/ToweringQueries'
import { ToweringRepository } from 'modules/product/repository/ToweringRepository'
import { useCallback, useEffect, useMemo } from 'react'
import { emptyUfinetSelectOption } from 'ufinet-web-components'
import { ICalculationPoint, localServiceCoordinates, onFormikChanges, useTranslator } from 'ufinet-web-functions'

export const useToweringServiceForm = (
	formik: FormikProps<any>,
	toweringRepository: ToweringRepository,
	corporateGroupId: string
) => {
	const { data: structureTypes, isLoading: isLoadingStructureTypes } = useStructureTypesQuery(toweringRepository)

	const { data: colocationPositions, isLoading: isLoadingColocationPositions } =
		useColocationPositionsQuery(toweringRepository)

	const getDestinationCoordinates = () => {
		const { destination } = localServiceCoordinates(formik.values) as { destination: ICalculationPoint }

		return {
			destinationLatitude: destination?.latitude,
			destinationLongitude: destination?.longitude,
		}
	}

	const {
		data: structureHeights,
		isLoading: isLoadingStructureHeights,
		isSuccess: isSuccessStructureHeights,
	} = useStructureHeightsQuery(
		Boolean(
			formik.values.towering?.structureTypeSelect?.value &&
				formik.values.towering?.colocationPositionSelect?.value &&
				formik.values.destinationLatitude &&
				formik.values.destinationLongitude
		),
		toweringRepository,
		{
			structureTypeId: formik.values.towering?.structureTypeSelect?.value,
			corporateGroupId: corporateGroupId,
			...getDestinationCoordinates(),
			colocationPositionId: formik.values.towering?.colocationPositionSelect?.value,
		}
	)

	const formFields: FormFields = useMemo(
		() => ({
			structureType: {
				text: 'TOWERING.STRUCTURE_TYPE',
				formActions: 'towering.structureTypeSelect',
				inputType: FormFieldType.SELECT,
				isLoading: isLoadingStructureTypes,
				options: structureTypes,
			},
			colocationPosition: {
				text: 'TOWERING.COLOCATION_POSITION',
				formActions: 'towering.colocationPositionSelect',
				inputType: FormFieldType.SELECT,
				isLoading: isLoadingColocationPositions,
				options: colocationPositions,
			},
			structureHeight: {
				text: 'TOWERING.STRUCTURE_HEIGHT',
				formActions: 'towering.structureHeightSelect',
				inputType: FormFieldType.SELECT,
				isLoading: isLoadingStructureHeights,
				options: structureHeights,
				isDisabled: !isSuccessStructureHeights,
			},
		}),
		[structureTypes, colocationPositions, structureHeights]
	)

	useEffect(() => {
		onFormikChanges(formik, formFields.colocationPosition.formActions)(emptyUfinetSelectOption)
	}, [formik.values.towering?.structureTypeSelect?.value])

	useEffect(() => {
		onFormikChanges(formik, formFields.structureHeight.formActions)(emptyUfinetSelectOption)
	}, [formik.values.towering?.colocationPositionSelect?.value])

	const translate = useTranslator()

	const onChange = useCallback(onFormikChanges, [])
	return {
		formFields,
		onChange,
		translate,
	}
}
