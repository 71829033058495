import { HttpPrefeasibilityRepository } from 'modules/prefeasibility/infrastructure/HttpPrefeasibilityRepository'
import { FC, useContext, useMemo } from 'react'
import { UfinetBox, UfinetSectionBox } from 'ufinet-web-components'
import { AuthContext } from 'ufinet-web-functions'
import { PendingQuotationsTable } from './PendingQuotationsTable'

const PendingQuotationsPage: FC = () => {
	const authData = useContext(AuthContext)
	const prefeasibilityRepository = useMemo(() => HttpPrefeasibilityRepository(authData), [authData])

	return (
		<UfinetBox>
			<UfinetSectionBox>
				<PendingQuotationsTable prefeasibilityRepository={prefeasibilityRepository} />
			</UfinetSectionBox>
		</UfinetBox>
	)
}

export { PendingQuotationsPage }
