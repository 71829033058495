import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC, useEffect, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { AccessAreaStatusArray, AccessAreaStatusElement, AreaAccessStatus, useTranslator } from 'ufinet-web-functions'

type Props = {
	selected?: AreaAccessStatus
}
const AccessAreaStatusBodyComponent: FC<Props> = ({ selected }: Props) => {
	const translate = useTranslator()
	const [actual, setActual] = useState<AccessAreaStatusElement>()

	useEffect(() => {
		const element = AccessAreaStatusArray.find((element) => element.accessAreaStatus === selected)
		setActual(element)
	}, [selected])

	return !actual ? (
		<></>
	) : (
		<>
			<div className="d-flex justify-content-center">
				<OverlayTrigger
					placement="bottom"
					overlay={<Tooltip className="tooltip-dark">{translate(actual.tooltipId)}</Tooltip>}
				>
					<div className="d-inline">
						<FontAwesomeIcon className="accessAreaStatus" color={actual.color} icon={faCircle} />
					</div>
				</OverlayTrigger>
			</div>
		</>
	)
}

export { AccessAreaStatusBodyComponent }
