import { MapMarkerType } from 'modules/google-maps/markers/MapMarkerType'
import { IdAndValue } from 'ufinet-web-functions'
import { MapsInfoWindowEvent } from './MapsInfoWindowEvent'
import { MapsInfoWindowEventType } from './MapsInfoWindowEventType'

export class MapsInfoWindowCloseEvent extends MapsInfoWindowEvent {
	constructor(input: {
		marker: IdAndValue<string, google.maps.Marker> & { type: MapMarkerType }
		infoWindow: google.maps.InfoWindow
	}) {
		super({ type: MapsInfoWindowEventType.CLOSE, marker: input.marker, infoWindow: input.infoWindow })
	}
}
