import { FormikProps } from 'formik'
import { FC } from 'react'
import { FormBuilder } from '../FormBuilder'
import { useDarkFiberServiceForm } from './hooks/useDarkFiberServiceForm'

type DarkFiberServiceFormProps = {
	formik: FormikProps<any>
}

export const DarkFiberServiceForm: FC<DarkFiberServiceFormProps> = ({ formik }) => {
	const { formFields, translate, onChange } = useDarkFiberServiceForm()

	return (
		<div className="row col-11">
			{Object.keys(formFields).map((field) => {
				return (
					<FormBuilder
						key={field}
						formField={formFields[field]}
						translate={translate}
						formik={formik}
						onChange={onChange}
					/>
				)
			})}
		</div>
	)
}
