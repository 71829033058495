import { DirectionsRequest } from 'modules/google-maps/directions/typesAndValues'
import { ProcessedService } from 'ufinet-web-functions'
import { isEmptyDirection } from './isEmptyDirection'
import { mapDirectionsFromService } from './mapDirectionsFromService'

export const enganchesFromServices = (services: ProcessedService[]): DirectionsRequest[] => {
	const directions = services.flatMap((service) => mapDirectionsFromService(service))
	// Prevent including directions devoid of data,such as those created from services still lacking the server calculated data
	return directions.filter((dir) => !isEmptyDirection(dir))
}
