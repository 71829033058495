import { faSave } from '@fortawesome/free-solid-svg-icons'
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import { useFormik } from 'formik'

import { FC, memo, useCallback, useRef } from 'react'
import { useIntl } from 'react-intl'
import Swal from 'sweetalert2'
import {
	CountrySelect,
	emptyUfinetSelectOption,
	UfinetActionButton,
	UfinetActionButtonHandle,
	UfinetButton,
	UfinetDropzone,
	WizardButtonsWrapper,
} from 'ufinet-web-components'
import { emptyFileUploadData, formDataFromObject, onFormikChanges } from 'ufinet-web-functions'
import {
	IXLSDownloadRequest,
	IXLSUploadRequest,
} from 'ufinet-web-functions/dist/model/common/networking/typesAndValues'
import * as Yup from 'yup'

type Props = {
	hideModal: Function
	xlsUploadCallback: (uploadData: FormData) => Promise<any>
	xlsEmptyDownloadCallback: (downloadReq: IXLSDownloadRequest) => Promise<any>
	onXlsUploaded: () => void
}

const UploadExcelModalBody: FC<Props> = memo(
	({ xlsEmptyDownloadCallback, xlsUploadCallback, onXlsUploaded, hideModal }) => {
		const actionButtonRef = useRef<UfinetActionButtonHandle>(null)
		const intl = useIntl()
		/*
		Current User Date Time
		*/

		const getCurrentDateTime = () => {
			const date = new Date()
			const daysNumeric = date.getDate()
			const monthsNumeric = date.getMonth() + 1
			const hoursNumeric = date.getHours()
			const minutesNumeric = date.getMinutes()
			// formats
			const daysStringified = daysNumeric < 10 ? '0' + daysNumeric : daysNumeric
			const monthsStringified = monthsNumeric < 10 ? '0' + monthsNumeric : monthsNumeric
			const hoursStringified = hoursNumeric < 10 ? '0' + hoursNumeric : hoursNumeric
			const minutesStringified = minutesNumeric < 10 ? '0' + minutesNumeric : minutesNumeric

			return (
				daysStringified +
				'/' +
				monthsStringified + // Note: January is 0, February is 1, and so on
				'/' +
				date.getFullYear() +
				' ' +
				hoursStringified +
				':' +
				minutesStringified
			)
		}
		/*
		 ******************************
		 *************Formik***********
		 ******************************
		 */
		const dataFormSchema = Yup.object().shape({
			countrySelect: Yup.object()
				.nullable()
				.required(intl.formatMessage({ id: 'ERROR.REQUIRED' }))
				.shape({
					label: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
					value: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
				}),
			file: Yup.mixed()
				.test('file required', intl.formatMessage({ id: 'ERROR.REQUIRED' }), (value) => value.size > 0)
				.test('file type', intl.formatMessage({ id: 'ERROR.FILE.EXTENSION' }), (value) => {
					const fileExtension = value?.path?.split('.').pop()
					return fileExtension === undefined || fileExtension.includes(['xlsx']) || fileExtension.includes(['xls'])
				}),
		})

		const formik = useFormik({
			initialValues: {
				countrySelect: emptyUfinetSelectOption,
				file: emptyFileUploadData,
			},
			validationSchema: dataFormSchema,
			onSubmit: (values) => {
				Swal.fire({
					title: intl.formatMessage({ id: 'STEP.UPLOAD_EXCEL.SWEET_ALERT.TITLE' }),
					icon: 'warning',
					showCancelButton: true,
					showConfirmButton: true,
					reverseButtons: true,
					confirmButtonText: intl.formatMessage({ id: 'SAVE' }),
					cancelButtonText: intl.formatMessage({ id: 'CANCEL' }),
					showLoaderOnConfirm: true,
					preConfirm: () => {
						uploadExcel(values)
							// eslint-disable-next-line promise/always-return
							.then(() => {
								hideModal()
							})
							.catch(console.error)
					},
					confirmButtonColor: '#0065A4',
					cancelButtonColor: '#f64e60',
					allowOutsideClick: false,
				})
			},
			validateOnChange: false,
			validateOnBlur: false,
		})
		/*
		 * end Formik
		 * */
		const uploadExcel = (values: any): Promise<any> => {
			actionButtonRef.current?.changeActionStatus()

			const formData = formDataFromObject({
				countryId: values.countrySelect.value,
				currentDateTime: getCurrentDateTime(),
				file: values.file,
			} as IXLSUploadRequest)

			return xlsUploadCallback(formData)
				.then(onXlsUploaded)
				.finally(() => actionButtonRef.current?.changeActionStatus())
		}

		const onChange = useCallback(onFormikChanges, [])

		const downloadEmptyXLS = () => {
			const data: IXLSDownloadRequest = {
				countries: [formik.values.countrySelect.value],
				networkAreas: [],
			}
			xlsEmptyDownloadCallback(data)
		}

		return (
			<form onSubmit={formik.handleSubmit} className="container p-15 h-100 d-flex flex-column justify-content-center">
				<div className="row pb-10">
					<CountrySelect
						requiredIcon
						className="col-12"
						isMulti={false}
						onChange={onChange(formik, 'countrySelect')}
						value={formik.values.countrySelect}
						error={formik.errors.countrySelect?.label}
					/>
				</div>
				<div className="row pb-10">
					<div className="col-12">
						<UfinetButton
							isDisabled={!formik.values.countrySelect.value}
							onClick={downloadEmptyXLS}
							className="w-100"
							content={intl.formatMessage({ id: 'DOWNLOAD.TEMPLATE.EMPTY' })}
							icon={faDownload}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<UfinetDropzone
							onChange={onChange(formik, 'file')}
							value={formik.values.file}
							error={formik.errors.file}
							text={intl.formatMessage({ id: 'DROPZONE.UPLOAD_EXCEL.TITLE' })}
							accept={{ 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx', '.xls'] }}
							isDisabled={!formik.values.countrySelect.value}
						/>
					</div>
				</div>
				<WizardButtonsWrapper>
					<div />
					<UfinetActionButton
						ref={actionButtonRef}
						className="w-100"
						content={intl.formatMessage({ id: 'SAVE' })}
						icon={faSave}
						isDisabled={!formik.isValid}
					/>
				</WizardButtonsWrapper>
			</form>
		)
	},
	(prevProps, nextProps) => {
		return prevProps.hideModal === nextProps.hideModal
	}
)
export { UploadExcelModalBody }
