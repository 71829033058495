import { IdAndValue } from 'ufinet-web-functions'
import { MapsClickEvent } from '../events/MapsClickEvent'
import { MapsClickEventType } from '../events/MapsClickEventType'

export class PolygonClickEvent extends MapsClickEvent {
	origin: IdAndValue<string, google.maps.Polygon>
	constructor(input: { origin: IdAndValue<string, google.maps.Polygon>; nativeEvent: google.maps.MapMouseEvent }) {
		super({ nativeEvent: input.nativeEvent, type: MapsClickEventType.POLYGON })
		this.origin = input.origin
	}
}
