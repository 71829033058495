import { IServerService } from 'ufinet-web-functions'

export const defaultMapsTravelMode = () => google.maps.TravelMode.WALKING
export const defaultMapsUnitSystem = () => google.maps.UnitSystem.METRIC
export const defaultMapsDoStopOver = true
// https://developers.google.com/maps/documentation/javascript/directions#DirectionsRequests
export type DirectionItem = google.maps.LatLng // | google.maps.Place | string
export type Waypoint = { location: DirectionItem; stopover?: boolean }
export type DirectionsRequestOptions = {
	travelMode?: google.maps.TravelMode
	unitSystem?: google.maps.UnitSystem
}
export type DirectionsRequest = {
	origin: DirectionItem
	destination: DirectionItem
	waypoints?: Waypoint[]
	service?: IServerService // service associated to this directions
} & DirectionsRequestOptions

export type DirectionsData = {
	request: DirectionsRequest
} & google.maps.DirectionsRendererOptions
