import { Card } from 'primereact/card'
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { ClockLoader } from 'react-spinners'
import {
	formatCellNumber,
	IQuotation,
	QuotationConfirmationStatus,
	tirFormattingSettings,
	totalsFormattingSettings,
} from 'ufinet-web-functions'

type Props = {
	quotationData: IQuotation
}
const QuotationCards: FC<Props> = ({ quotationData }) => {
	const intl = useIntl()

	const quotationConfirmed = quotationData.confirmationStatus === QuotationConfirmationStatus.Confirmed

	const quotationLoadingComponent = (
		<div className="position-absolute" style={{ top: '8px', right: '8px' }}>
			<ClockLoader color="black" size={20} />
		</div>
	)

	const cardStyle: React.CSSProperties = {
		backgroundColor:
			quotationData.calculating || quotationData.outdated
				? 'rgb(235, 235, 228)'
				: quotationConfirmed
				? 'aliceblue'
				: 'white',
		cursor: quotationData.calculating ? 'wait' : 'auto',
	}

	const cardNumberStyle = {
		fontStyle: quotationData.outdated ? 'italic' : 'normal',
		fontWeight: quotationConfirmed ? 'bold' : 'normal',
		cursor: quotationData.outdated ? 'help' : 'auto',
	}

	const viabilityMappings = new Map<number, string>([
		[1, intl.formatMessage({ id: 'YES' })],
		[2, intl.formatMessage({ id: 'NO' })],
	])

	return (
		<div className="row px-4 justify-content-center align-items-baseline mb-5 mt-5">
			<Card
				title={intl.formatMessage({ id: 'RENTABILITY_STANDARD' })}
				className="flex-grow-1 col-3 me-4 position-relative"
				style={cardStyle}
			>
				<div className="d-flex flex-column">
					<div className="d-flex flex-row justify-content-between gap-10">
						<div className="mb-2">
							<b>{intl.formatMessage({ id: 'QUOTATION.VAN' })}: </b>
							<span style={cardNumberStyle}>
								{quotationData.vanRentabilidadEstandarAqs
									? formatCellNumber(quotationData.vanRentabilidadEstandarAqs, totalsFormattingSettings)
									: '-'}
							</span>
						</div>
						<div>
							<b>{intl.formatMessage({ id: 'QUOTATION.TIR' })}: </b>
							<span style={cardNumberStyle}>
								{formatCellNumber(quotationData.tirRentabilidadEstandarAqs, tirFormattingSettings)}
							</span>
						</div>
					</div>
					<div className="d-flex flex-row justify-content-between gap-10">
						<div>
							<b>{intl.formatMessage({ id: 'QUOTATION.PAYBACK' })}: </b>
							<span style={cardNumberStyle}>
								{formatCellNumber(quotationData.paybackRentabilidadEstandarAqs, totalsFormattingSettings)}
							</span>
						</div>
						<div>
							<b>{intl.formatMessage({ id: 'QUOTATION.VIABILITY' })}: </b>
							<span style={cardNumberStyle}>
								{viabilityMappings.get(+quotationData.estadoRentabilidadEstandarAqs) || '-'}
							</span>
						</div>
					</div>
				</div>
				{quotationData.calculating && quotationLoadingComponent}
			</Card>
			<Card
				title={intl.formatMessage({ id: 'RENTABILITY_RISK' })}
				className="flex-grow-1 col-3 m-4 position-relative"
				style={cardStyle}
			>
				<div className="d-flex flex-column">
					<div className="d-flex flex-row justify-content-between gap-10">
						<div className="mb-2">
							<b>{intl.formatMessage({ id: 'QUOTATION.VAN' })}: </b>
							<span style={cardNumberStyle}>
								{quotationData.vanRentabilidadEstandarAqs
									? formatCellNumber(quotationData.vanRentabilidadRiesgoAqs, totalsFormattingSettings)
									: '-'}
							</span>
						</div>
						<div>
							<b>{intl.formatMessage({ id: 'QUOTATION.TIR' })}: </b>
							<span style={cardNumberStyle}>
								{formatCellNumber(quotationData.tirRentabilidadRiesgoAqs, tirFormattingSettings)}
							</span>
						</div>
					</div>
					<div className="d-flex flex-row justify-content-between gap-10">
						<div>
							<b>{intl.formatMessage({ id: 'QUOTATION.PAYBACK' })}: </b>
							<span style={cardNumberStyle}>
								{formatCellNumber(quotationData.paybackRentabilidadRiesgoAqs, totalsFormattingSettings)}
							</span>
						</div>
						<div>
							<b>{intl.formatMessage({ id: 'QUOTATION.VIABILITY' })}: </b>
							<span style={cardNumberStyle}>
								{viabilityMappings.get(+quotationData.estadoRentabilidadRiesgoAqs) || '-'}
							</span>
						</div>
					</div>
				</div>
				{quotationData.calculating && quotationLoadingComponent}
			</Card>
			<Card
				title={intl.formatMessage({ id: 'CURRENCY' })}
				className="flex-grow-1 col-3 m-4 position-relative"
				style={{ ...cardStyle, alignSelf: 'stretch' }}
			>
				<div className="d-flex flex-column" style={cardNumberStyle}>
					{quotationData.currency}
				</div>
				{quotationData.calculating && quotationLoadingComponent}
			</Card>
		</div>
	)
}
export { QuotationCards }
