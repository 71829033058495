import { deepEqual } from 'fast-equals'
import { FC, useContext, useMemo, useState } from 'react'
import { Table as BootstrapTable } from 'react-bootstrap'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { colorConstants, Table } from 'ufinet-web-components'
import {
	AuthContext,
	Authority,
	formatCellNumber,
	ILocalService,
	IQuotation,
	isNumeric,
	ObjectStr,
	ProcessedService,
	QuotationConfirmationStatus,
	ServiceLocalState,
	serviceProductFromStateService,
	ServiceServerStatus,
	tableDataFormattingSettings,
	useTranslator,
} from 'ufinet-web-functions'

import { PrefeasibilityRepository } from 'modules/prefeasibility/domain/repository/PrefeasibilityRepository'
import { TechnicalGroupRepository } from 'modules/prefeasibility/domain/repository/TechnicalGroupRepository'
import { colsExtService, colsIntService } from '../../table/ServiceColData'
import { ServiceTableHeaderButtons } from './ServiceTableHeaderButtons'

type ServiceTableProps = {
	aqsId: string
	contactId?: string
	internalUser: boolean
	services: ProcessedService[]
	quotationData: IQuotation
	prefeasibilityRepository: PrefeasibilityRepository
	technicalGroupRepository: TechnicalGroupRepository
	setModifiedService?: (modifiedService: ProcessedService, unmodifiedService?: ProcessedService) => void
	deleteServices?: (indexes: number[]) => Promise<void>
	confirmQuotation?: () => Promise<void>
	confirmPrefeasibility?: () => void
	resetForm?: () => void
	hideButtons?: { prefeasibility?: boolean; quotation?: boolean; cancel?: boolean }
	countryId: string
	isDataManipulationEnabled?: { isDataEditingDisabled: boolean; isDataDeletingDisabled: boolean }
	isPendingQuotation?: boolean
}

const ServiceTable: FC<ServiceTableProps> = ({
	aqsId: _aqsId,
	contactId,
	internalUser,
	services,
	quotationData,
	prefeasibilityRepository,
	technicalGroupRepository,
	setModifiedService = () => {},
	deleteServices = () => new Promise(() => {}),
	confirmQuotation = () => new Promise(() => {}),
	confirmPrefeasibility = () => {},
	resetForm = () => {},
	countryId,
	isDataManipulationEnabled = { isDataEditingDisabled: false, isDataDeletingDisabled: false },
	isPendingQuotation = false,
	hideButtons,
}: ServiceTableProps) => {
	const authData = useContext(AuthContext)
	const { isDataEditingDisabled, isDataDeletingDisabled } = isDataManipulationEnabled
	const roles = authData.userData?.authorities || []
	const permissions = Authority.getQuotationPermissions(roles)

	const [selectedValues, setSelectedValues] = useState<ILocalService[]>([])

	const { confirmationStatus: quotationConfirmationStatus } = quotationData

	const quotationConfirmed = quotationConfirmationStatus === QuotationConfirmationStatus.Confirmed

	const [isEditingService, setIsEditingService] = useState<boolean>(false)

	// Prevent selecting services that are being operated on server
	const setValidSelectedValues = (servicesProcessed: ILocalService[]): void => {
		const validSelectedServices =
			quotationConfirmed || isDataDeletingDisabled || services.some((s) => s.state === ServiceLocalState.Created)
				? []
				: isPendingQuotation
				? servicesProcessed.filter((service) => service.status === ServiceServerStatus.NOT_VIABLE)
				: servicesProcessed.filter((s) => s.state === ServiceLocalState.Computed)
		setSelectedValues(validSelectedServices)
	}
	const translate = useTranslator()
	const ReactSwal = useMemo(() => withReactContent(Swal), [])

	const getHeaderButtons = () => (
		<ServiceTableHeaderButtons
			aqsId={_aqsId}
			hideButtons={hideButtons}
			contactId={contactId}
			internalUser={internalUser}
			services={services}
			quotationData={quotationData}
			isEditingService={isEditingService}
			selectedValues={selectedValues}
			prefeasibilityRepository={prefeasibilityRepository}
			technicalGroupRepository={technicalGroupRepository}
			setSelectedValues={setSelectedValues}
			setValidSelectedValues={setValidSelectedValues}
			deleteServices={deleteServices}
			confirmQuotation={confirmQuotation}
			confirmPrefeasibility={confirmPrefeasibility}
			resetForm={resetForm}
			countryId={countryId}
		/>
	)

	const onRowEditComplete = (newServiceData: ProcessedService) => {
		const oldData: ProcessedService = services[newServiceData.pointNumber!]
		const formattedOldServiceData: ProcessedService = {
			...oldData,
			nrcAdjustment: oldData.nrcAdjustment || oldData.nrc,
			mrcAdjustment: oldData.mrcAdjustment || oldData.mrc,
		}
		const formattedNewServiceData: ProcessedService = {
			...newServiceData,
			bandwidthSelect: { ...newServiceData.bandwidthSelect, value: oldData.bandwidthSelect.value }, // Set old ID to no confuse deep-equals unnecessarily
			bandwidthUpdated: +newServiceData.bandwidthSelect.bandwidth,
			bandwidthUnit: +formattedOldServiceData.bandwidthSelect.unit,
			bandwidthUnitUpdated: +newServiceData.bandwidthSelect.unit,
			deadlineUpdated:
				newServiceData.deadlineUpdated === (oldData.deadlineUpdated || oldData.deadline)
					? undefined
					: newServiceData.deadlineUpdated,
			nrcAdjustment: newServiceData.nrcAdjustment?.toString().trim()
				? newServiceData.nrcAdjustment
				: newServiceData.nrc,
			mrcAdjustment: newServiceData.mrcAdjustment?.toString().trim()
				? newServiceData.mrcAdjustment
				: newServiceData.mrc,
		}

		// No need to fire the update mechanism if no (valid) changes were made
		const changesTableComponent = mkChangesTable(oldData, formattedNewServiceData)
		formattedNewServiceData.deadlineUpdated = formattedNewServiceData.deadlineUpdated
			? formattedNewServiceData.deadlineUpdated
			: formattedOldServiceData.deadlineUpdated
		if (!deepEqual(formattedNewServiceData, formattedOldServiceData) && changesTableComponent != null) {
			ReactSwal.fire({
				icon: 'warning',
				title: translate('UPDATE'),
				width: '33vw',
				html: changesTableComponent,
				showCancelButton: true,
				showConfirmButton: true,
				cancelButtonText: translate('CANCEL'),
				confirmButtonText: translate('OK'),
				reverseButtons: true,
				confirmButtonColor: colorConstants.primaryColor,
				cancelButtonColor: colorConstants.dangerColor,
				preConfirm: () => {
					setSelectedValues([])
					const isBandwidthAmountUnchanged =
						formattedNewServiceData.bandwidthUpdated === formattedNewServiceData.bandwidth
					const isBandwidthUnitUnchanged =
						formattedNewServiceData.bandwidthUnitUpdated === formattedNewServiceData.bandwidthUnit

					const updatedService: ProcessedService = {
						...formattedNewServiceData,
						product:
							isBandwidthAmountUnchanged && isBandwidthUnitUnchanged
								? undefined
								: serviceProductFromStateService(formattedNewServiceData),
						bandwidthSelect: formattedNewServiceData.bandwidthSelect,
						bandwidthUpdated: isBandwidthAmountUnchanged ? undefined : formattedNewServiceData.bandwidthUpdated,
						bandwidthUnitUpdated: isBandwidthUnitUnchanged ? undefined : formattedNewServiceData.bandwidthUnitUpdated,
						deadlineUpdated:
							formattedNewServiceData.deadline === formattedNewServiceData.deadlineUpdated
								? undefined
								: formattedNewServiceData.deadlineUpdated,
						nrcAdjustment:
							formattedNewServiceData.nrc === formattedNewServiceData.nrcAdjustment
								? undefined
								: formattedNewServiceData.nrcAdjustment,
						mrcAdjustment:
							formattedNewServiceData.mrc === formattedNewServiceData.mrcAdjustment
								? undefined
								: formattedNewServiceData.mrcAdjustment,
					}

					setModifiedService(updatedService, formattedOldServiceData)
				},
			})
		}

		setIsEditingService(false)
	}

	const mkChangesTable = (oldData: ObjectStr, newData: ObjectStr): JSX.Element | null => {
		// Preformat old data for adjusted updatable data
		const formattedOldData: ObjectStr = {
			...oldData,
			nrcAdjustment: (oldData.nrcAdjustment || oldData.nrc)?.toString().trim(),
			mrcAdjustment: (oldData.mrcAdjustment || oldData.mrc)?.toString().trim(),
		}
		const formattedNewData: ObjectStr = {
			...newData,
			nrcAdjustment: (newData.nrcAdjustment || newData.nrc)?.toString().trim(),
			mrcAdjustment: (newData.mrcAdjustment || newData.mrc)?.toString().trim(),
		}

		const allChanges = Object.fromEntries(
			Object.entries(formattedNewData).filter(([key, value]) => !deepEqual(formattedOldData[key], value))
		)
		const validChanges = Object.fromEntries(
			Object.entries(allChanges).filter(([key, value]) => {
				return !changesTableExclusions.includes(key) && value?.toString().length
			})
		)

		if (Object.keys(validChanges).length === 0) return null
		else
			return (
				<div>
					<BootstrapTable hover responsive size="xs">
						<thead className="fw-bolder">
							<tr>
								<th>{translate('UPDATE.FIELD')}</th>
								<th>{translate('UPDATE.BEFORE')}</th>
								<th>{translate('UPDATE.AFTER')}</th>
							</tr>
						</thead>
						<tbody>
							{Object.keys(validChanges)
								.map<string[]>((key) => {
									const baseOldValue: string | undefined =
										formattedOldData[key]?.label ||
										formattedOldData[key] ||
										formattedOldData[`${key}`.split('Updated')[0]]

									const baseNewValue: string | undefined =
										newData[key]?.label || newData[key] || newData[`${key}`.split('Updated')[0]]

									return [
										key,
										...[baseOldValue, baseNewValue].map((v) =>
											isNumeric(v) ? formatCellNumber(v, tableDataFormattingSettings) : v || '-'
										),
									]
								})
								.map(([key, oldValue, newValue], idx) => (
									<tr key={idx}>
										<td className="fst-italic">{changesTableMappings.get(key) || key}</td>
										<td>{oldValue}</td>
										<td>{newValue}</td>
									</tr>
								))}
						</tbody>
					</BootstrapTable>
				</div>
			)
	}

	const changesTableMappings = new Map<string, string>([
		['bandwidthSelect', translate('SERVICE.BANDWIDTH')],
		['deadlineUpdated', translate('SERVICE.DEADLINE')],
		['nrcAdjustment', translate('SERVICE.NRC')],
		['mrcAdjustment', translate('SERVICE.MRC')],
	])
	// Properties we want to hide from the update service modal, either because they are irrelevant or can be deduced from other properties
	const changesTableExclusions: string[] = ['bandwidthUpdated', 'bandwidthUnitUpdated']

	const onSelectionChange = (values: ILocalService[]) => {
		setValidSelectedValues(values)
	}

	return (
		<>
			<Table
				dataKey="pointNumber"
				className="fs-4 service-table"
				cols={internalUser ? colsIntService : colsExtService}
				selectedValues={selectedValues}
				onSelectionChange={!permissions.canDelete ? undefined : onSelectionChange}
				onRowEditComplete={(newServiceData) =>
					!permissions.canUpdate || !internalUser ? undefined : onRowEditComplete(newServiceData as ProcessedService)
				}
				editDisabled={
					quotationConfirmed || isDataEditingDisabled || services.some((s) => s.state !== ServiceLocalState.Computed)
				}
				content={services}
				headerButtons={getHeaderButtons()}
				onRowEditInit={() => setIsEditingService(true)}
				onRowEditCancel={() => setIsEditingService(false)}
			/>
		</>
	)
}

export { ServiceTable }
