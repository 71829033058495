import { SelectOptionMapper } from 'modules/common/domain/mapper/SelectOptionMapper'
import { useQuery } from 'react-query'
import { ICalculationPoint } from 'ufinet-web-functions'
import { DarkFiberRepository } from '../repository/DarkFiberRepository'

export interface DarkFiberListPrice {
	corporateGroupId: string
	origin?: ICalculationPoint
	destination?: ICalculationPoint
}

export const useFiberNumberQuery = (
	isEnabled = true,
	repository: DarkFiberRepository,
	darkFiberListPrice: DarkFiberListPrice
) => {
	const { getFiberNumber } = repository
	const { corporateGroupId, origin, destination } = darkFiberListPrice
	return useQuery(
		`fiber-number-query-${corporateGroupId}
		-${origin?.longitude}-${origin?.latitude}
		-${destination?.longitude}-${destination?.latitude}`,
		() => getFiberNumber(darkFiberListPrice),
		{
			enabled: isEnabled,
			select: SelectOptionMapper,
		}
	)
}
