import { ICountry } from 'ufinet-web-functions'

export const getAllCountriesResponseMock: ICountry[] = [
	{
		id: '349B9F0F-C6BD-E511-80FB-3863BB358F90',
		name: 'Costa Rica',
		isoShort: 'CR',
		isoLong: 'CRI',
	},
	{
		id: '369B9F0F-C6BD-E511-80FB-3863BB358F90',
		name: 'Panamá',
		isoShort: 'PA',
		isoLong: 'PAN',
	},
	{
		id: '3A9B9F0F-C6BD-E511-80FB-3863BB358F90',
		name: 'Guatemala',
		isoShort: 'GT',
		isoLong: 'GTM',
	},
	{
		id: '3E9B9F0F-C6BD-E511-80FB-3863BB358F90',
		name: 'Honduras',
		isoShort: 'HN',
		isoLong: 'HND',
	},
]
