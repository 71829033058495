import { IntlShape } from 'react-intl'
import { AreaType, getAreaStatusTranslation, IKmzAreaFindResponse } from 'ufinet-web-functions'
import { getBadgeVariant } from './getBadgeVariant'
import { getCoordinatesCopyElement } from './getCoordinatesCopyElement'

export const infoWindowFromAreaForMarker = (
	{ areaName, areaTypeId, accessAreaStatus }: IKmzAreaFindResponse,
	coordinates: google.maps.LatLngLiteral,
	intl: IntlShape
): google.maps.InfoWindowOptions => {
	const statusText = getAreaStatusTranslation(accessAreaStatus, intl)

	const content = `
	<div class="d-flex flex-column align-items-center gap-2">
		<div class="fw-bolder">${areaName}</div>
		${
			areaTypeId === AreaType.ACCESS_AREA
				? `<div class="status-badge ${getBadgeVariant(accessAreaStatus)}">${statusText}</div>`
				: ''
		}
		${getCoordinatesCopyElement(coordinates, { tooltip: intl.formatMessage({ id: 'COPY.COORDINATES' }) })}
	</div>`
	return { content, disableAutoPan: false }
}
