import { deepEqual } from 'fast-equals'
import { FC, memo, useContext, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import Swal from 'sweetalert2'

import { colsFO } from 'pages/costs/common/CostsColData'
import { EquipmentAndConstructionTableBaseProps } from 'pages/costs/EquipmentAndConstruction/EquipmentAndConstructionPage'
import { UploadExcelModalBody } from 'pages/costs/modals/excel/UploadExcelModalBody'
import { NewFOModalBody } from 'pages/costs/modals/NewFOModalBody'

import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload'
import { colorConstants, MultipleDeleter, Table, UfinetButton, UfinetModal } from 'ufinet-web-components'
import {
	AuthContext,
	Authority,
	foCostsService,
	ICostsFOResponse,
	infrastructureTypeMappings,
	onSelectionChangePreventDefaultsRemoval,
	useModal,
	zoneTypeMappings,
} from 'ufinet-web-functions'

type FOTableProps = EquipmentAndConstructionTableBaseProps & { content: ICostsFOResponse[] }
const FOTable: FC<FOTableProps> = memo(
	({ className, content, findCosts, loadingRecords }) => {
		const [modal, showModal, hideModal] = useModal()
		const [newModal, showNewModal, hideNewModal] = useModal()
		const [selectedValues, setSelectedValues] = useState<ICostsFOResponse[]>()

		const intl = useIntl()

		const authData = useContext(AuthContext)
		const roles = authData.userData?.authorities || []
		const permissions = Authority.getCostsPermissions(roles)

		const _FOCostsService = useMemo(() => foCostsService(authData, intl), [authData, intl])

		const getHeaderButtons = () => (
			<>
				{permissions.canDelete && (
					<MultipleDeleter
						setSelectedValues={setSelectedValues}
						selectedValues={selectedValues}
						deleteEndpoint={_FOCostsService.deleteByIdsList}
						search={findCosts}
					/>
				)}
				{permissions.canWrite && (
					<>
						<UfinetButton
							className="me-3"
							icon={faPlus}
							onClick={() => showNewModal()}
							content={intl.formatMessage({ id: 'NEW.REGISTER' })}
						/>
						<UfinetButton
							icon={faUpload}
							onClick={() => showModal()}
							content={intl.formatMessage({ id: 'BULK_LOAD' })}
						/>
					</>
				)}
			</>
		)

		const onRowEditComplete = (data: ICostsFOResponse) => {
			if (
				deepEqual(
					data,
					content.find((it) => it.id === data.id)
				)
			)
				return

			Swal.fire({
				icon: 'warning',
				title: intl.formatMessage({ id: 'COUNTRY.REGISTRY.UPDATE' }, { countryName: data.countryName }),
				html: intl.formatMessage({ id: 'COUNTRY.REGISTRY.UPDATE.CONFIRM' }, { networkAreaName: data.networkAreaName }),
				showCancelButton: true,
				showConfirmButton: true,
				reverseButtons: true,
				confirmButtonColor: colorConstants.primaryColor,
				cancelButtonColor: colorConstants.dangerColor,
				preConfirm: () => {
					const {
						id,
						countryId,
						countryName,
						networkAreaName,
						clientId,
						minimalDistance,
						maximumDistance,
						opticalFactor,
						punctualCost,
						laborPunctualCost,
						punctualCostPerMeter,
						laborPunctualCostPerMeter,
					} = data
					_FOCostsService
						.update(id, {
							countryId,
							countryName,
							networkAreaName,
							clientId,
							zoneType: zoneTypeMappings.get(data.zoneType) || data.zoneType,
							infrastructureType: infrastructureTypeMappings.get(data.infrastructureType) || data.infrastructureType,
							minimalDistance,
							maximumDistance,
							opticalFactor,
							punctualCost,
							laborPunctualCost,
							punctualCostPerMeter,
							laborPunctualCostPerMeter,
						})
						.then(() => findCosts())
						.catch(console.error)
				},
			})
		}

		const onSelectionChange = (values: ICostsFOResponse[]) => {
			onSelectionChangePreventDefaultsRemoval(
				values,
				selectedValues,
				content,
				setSelectedValues,
				intl.formatMessage({ id: 'BY_DEFAULT.DELETE.WARNING' })
			)
		}

		return (
			<>
				<Table
					className={className}
					selectedValues={selectedValues}
					onSelectionChange={permissions.canDelete ? onSelectionChange : undefined}
					onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
					cols={colsFO}
					content={content}
					headerButtons={getHeaderButtons()}
					emptyMessage={loadingRecords ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
				/>
				{/* Modals */}
				<UfinetModal
					size="xl"
					show={modal}
					handleClose={hideModal}
					title={intl.formatMessage({ id: 'MODAL.UPLOAD_EXCEL.TITLE' })}
				>
					<UploadExcelModalBody
						xlsEmptyDownloadCallback={_FOCostsService.downloadEmptyListXLS}
						xlsUploadCallback={_FOCostsService.uploadXLS}
						onXlsUploaded={findCosts}
						hideModal={hideModal}
					/>
				</UfinetModal>
				<UfinetModal
					size="lg"
					show={newModal}
					handleClose={hideNewModal}
					title={intl.formatMessage({ id: 'MODAL.NEW.REGISTER.TITLE' })}
				>
					<NewFOModalBody findCosts={findCosts} hideModal={hideNewModal} />
				</UfinetModal>
			</>
		)
	},
	(prevProps, nextProps) => {
		return (
			deepEqual(prevProps.content, nextProps.content) && deepEqual(prevProps.loadingRecords, nextProps.loadingRecords)
		)
	}
)

export { FOTable }
