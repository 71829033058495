import { isLatLngLiteral } from '@googlemaps/typescript-guards'
import { MapWrapperHandle } from 'components/google-maps/wrapper/MapWrapper'
import { deepEqual } from 'fast-equals'
import { FormikValues } from 'formik'
import { LocationInputMethod } from 'modules/google-maps/controls/picker/LocationInputMethod'
import { LocationType } from 'modules/google-maps/controls/picker/LocationType'
import { MapPickerMode } from 'modules/google-maps/controls/picker/MapPickerMode'
import { MapsClickEvent } from 'modules/google-maps/events/MapsClickEvent'
import { UserClickEvent } from 'modules/google-maps/events/UserClickEvent'
import { MapsInfoWindowCloseEvent } from 'modules/google-maps/events/infowindow/MapsInfoWindowCloseEvent'
import { coordinateToString } from 'modules/google-maps/functions/coordinateToString'
import { stringToCoordinate } from 'modules/google-maps/functions/stringToCoordinate'
import { mapsAutocompleteOptions } from 'modules/google-maps/mapsAutocompleteOptions'
import { MapMarker } from 'modules/google-maps/markers/MapMarker'
import { MapMarkerType } from 'modules/google-maps/markers/MapMarkerType'
import { MarkerClickEvent } from 'modules/google-maps/markers/MarkerClickEvent'
import { markerOptionsFromCoordinates } from 'modules/google-maps/markers/functions/markerOptionsFromCoordinates'
import { MapPolygon } from 'modules/google-maps/polygon/MapPolygon'
import { PolygonClickEvent } from 'modules/google-maps/polygon/PolygonClickEvent'
import { markersAndPolygonFromFromKmzAreas } from 'modules/google-maps/polygon/functions/markersAndPolygonFromFromKmzAreas'
import { PropsWithChildren, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { PopSelectHandle, emptyUfinetSelectOption } from 'ufinet-web-components'
import {
	AreaType,
	AuthContext,
	ILocalService,
	isValidCoordinate,
	kmzAreasService,
	onFormikChanges,
	useInternalUser,
} from 'ufinet-web-functions'

type CoordinatesSelectorContextType = {
	originInputMethod: LocationInputMethod | null
	destinationInputMethod: LocationInputMethod | null
	popOriginRef: React.RefObject<PopSelectHandle>
	popDestinationRef: React.RefObject<PopSelectHandle>
	originAddressInputRef: React.MutableRefObject<HTMLInputElement | null>
	destinationAddressInputRef: React.MutableRefObject<HTMLInputElement | null>
	originAddressAutocompleteWidget: google.maps.places.Autocomplete | undefined
	destinationAddressAutocompleteWidget: google.maps.places.Autocomplete | undefined
	setOriginInputMethod: React.Dispatch<React.SetStateAction<LocationInputMethod | null>>
	setDestinationInputMethod: React.Dispatch<React.SetStateAction<LocationInputMethod | null>>
	clearOriginLocation: (formik: FormikValues) => void
	hanldePlaceChangeListeners: (
		formik: FormikValues,
		fillBandwidths: (service?: Partial<ILocalService>) => void
	) => () => void
	fetchAccessAreas: (countryId: string) => void
} & CoordinatesSelectorMapActionsType &
	CoordinatesSelectorMapStateType

type CoordinatesSelectorMapStateType = {
	mapStyles: React.CSSProperties
	mapPickerMode: MapPickerMode
	mapWrapperRef: React.RefObject<MapWrapperHandle>
	userMapPress: google.maps.LatLngLiteral | null
	mapMarkers: MapMarker[]
	mapPolygons: MapPolygon[]
	mapShown: boolean
}

type CoordinatesSelectorMapActionsType = {
	handleUserMapPress: (formik: FormikValues, fillBandwidths: (service?: Partial<ILocalService>) => void) => void
	setMapShown: React.Dispatch<React.SetStateAction<boolean>>
	setMapMarkers: React.Dispatch<React.SetStateAction<MapMarker[]>>
	setMapPolygons: React.Dispatch<React.SetStateAction<MapPolygon[]>>
	setMapPickerMode: React.Dispatch<React.SetStateAction<MapPickerMode>>
	resetMap: () => void
	onUserMapPress: (latLng: google.maps.LatLng | google.maps.LatLngLiteral) => void
	onMapClick: (event: MapsClickEvent, _map: google.maps.Map) => void
	onMapUnfolded: () => void
	handleMapFoldUnfold: (formik: FormikValues) => void
	onMapInfoWindowClose: ({ marker }: MapsInfoWindowCloseEvent, _map: google.maps.Map) => void
}

type CoordinatesSelectorContextProviderProps = {}

const CoordinatesSelectorContext = createContext<CoordinatesSelectorContextType>({} as CoordinatesSelectorContextType)

export const CoordinatesSelectorContextProvider = ({
	children,
}: PropsWithChildren<CoordinatesSelectorContextProviderProps>) => {
	const intl = useIntl()
	const authData = useContext(AuthContext)

	const internalUser = useInternalUser()
	const _kmzAreaService = useMemo(() => kmzAreasService(authData), [authData])
	const [originAddressAutocompleteWidget, setOriginAddressAutocompleteWidget] =
		useState<google.maps.places.Autocomplete>()
	const [destinationAddressAutocompleteWidget, setDestionationAddressAutocompleteWidget] =
		useState<google.maps.places.Autocomplete>()

	const [mapMarkers, setMapMarkers] = useState<MapMarker[]>([])
	const [mapPolygons, setMapPolygons] = useState<MapPolygon[]>([])

	const popOriginRef = useRef<PopSelectHandle>(null)
	const popDestinationRef = useRef<PopSelectHandle>(null)

	const [userMapPress, setUserMapPress] = useState<google.maps.LatLngLiteral | null>(null)
	const mapWrapperRef = useRef<MapWrapperHandle>(null)
	const [mapShown, setMapShown] = useState<boolean>(true)
	const [mapPickerMode, setMapPickerMode] = useState<MapPickerMode>(MapPickerMode.NONE)

	const [originInputMethod, setOriginInputMethod] = useState<LocationInputMethod | null>(null)
	const [destinationInputMethod, setDestinationInputMethod] = useState<LocationInputMethod | null>(null)
	const originAddressInputRef = useRef<HTMLInputElement | null>(null)
	const destinationAddressInputRef = useRef<HTMLInputElement | null>(null)

	const onMapUnfolded = useCallback(() => {
		mapPickerMode !== MapPickerMode.NONE && mapWrapperRef.current?.scrollIntoView()
	}, [mapPickerMode])

	const onUserMapPress = useCallback((latLng: google.maps.LatLng | google.maps.LatLngLiteral): void => {
		const newUserMapPress = isLatLngLiteral(latLng) ? latLng : { lat: latLng.lat(), lng: latLng.lng() }
		setUserMapPress(newUserMapPress)
	}, [])

	const onUserClickEvent = useCallback(
		({ nativeEvent }: UserClickEvent) => {
			if (mapPickerMode === MapPickerMode.NONE) return

			setMapMarkers((oldMarkers) =>
				oldMarkers
					.filter((marker) => marker.type !== MapMarkerType.USER)
					.map((marker) => ({
						...marker,
						infoWindow: marker.infoWindow ? { ...marker.infoWindow, open: false } : undefined,
					}))
			)
			const newCoordinates: google.maps.LatLngLiteral = {
				lat: nativeEvent.latLng!.lat(),
				lng: nativeEvent.latLng!.lng(),
			}
			const newMarker: MapMarker = markerOptionsFromCoordinates(newCoordinates, intl)
			setMapMarkers((oldMarkers) => [...oldMarkers, newMarker])

			nativeEvent.latLng && onUserMapPress(nativeEvent.latLng)
		},
		[intl, mapPickerMode, onUserMapPress]
	)

	const updateCoordinatesFromAddress = (
		type: LocationType,
		formik: FormikValues,
		fillBandwidths: (service?: Partial<ILocalService>) => void
	) => {
		const newPlace =
			type === LocationType.Origin
				? originAddressAutocompleteWidget?.getPlace()
				: destinationAddressAutocompleteWidget?.getPlace()

		if (!newPlace) return

		const location = newPlace.geometry!.location

		if (type === LocationType.Origin) {
			const originAddress = newPlace.formatted_address!
			const originLatitude = location?.lat()
			const originLongitude = location?.lng()
			const originLatLng = coordinateToString({ lat: originLatitude!, lng: originLongitude! })

			const newValues = {
				...formik.values,
				originAddress,
				originLatitude,
				originLongitude,
				originLatLng,
			}
			formik.setValues(newValues)
			fillBandwidths(newValues)
		} else {
			const destinationAddress = newPlace.formatted_address!
			const destinationLatitude = location?.lat()
			const destinationLongitude = location?.lng()
			const destinationLatLng = coordinateToString({ lat: destinationLatitude!, lng: destinationLongitude! })

			const newValues = {
				...formik.values,
				destinationAddress,
				destinationLatitude,
				destinationLongitude,
				destinationLatLng,
			}
			formik.setValues(newValues)
			fillBandwidths(newValues)
		}
	}

	useEffect(() => {
		if (originAddressInputRef.current) {
			const autocomplete = new google.maps.places.Autocomplete(originAddressInputRef.current, mapsAutocompleteOptions)
			setOriginAddressAutocompleteWidget(autocomplete)
		}
		if (destinationAddressInputRef.current) {
			const autocomplete = new google.maps.places.Autocomplete(
				destinationAddressInputRef.current,
				mapsAutocompleteOptions
			)
			setDestionationAddressAutocompleteWidget(autocomplete)
		}
	}, [originAddressInputRef, destinationAddressInputRef])

	const hanldePlaceChangeListeners = (
		formik: FormikValues,
		fillBandwidths: (service?: Partial<ILocalService>) => void
	) => {
		if (originAddressAutocompleteWidget) {
			originAddressAutocompleteWidget.addListener('place_changed', () =>
				updateCoordinatesFromAddress(LocationType.Origin, formik, fillBandwidths)
			)
		}
		if (destinationAddressAutocompleteWidget) {
			destinationAddressAutocompleteWidget.addListener('place_changed', () =>
				updateCoordinatesFromAddress(LocationType.Destination, formik, fillBandwidths)
			)
		}

		return () => {
			if (originAddressAutocompleteWidget) {
				google.maps.event.clearListeners(originAddressAutocompleteWidget, 'place_changed')
			}
			if (destinationAddressAutocompleteWidget) {
				google.maps.event.clearListeners(destinationAddressAutocompleteWidget, 'place_changed')
			}
		}
	}

	const handleUserMapPress = (formik: FormikValues, fillBandwidths: (service?: Partial<ILocalService>) => void) => {
		if (!userMapPress) return

		const currentCoordinates =
			mapPickerMode === MapPickerMode.ORIGIN
				? stringToCoordinate(formik.values.originLatLng || '')
				: stringToCoordinate(formik.values.destinationLatLng || '')
		const { lat, lng } = userMapPress

		if (deepEqual(userMapPress, currentCoordinates)) return

		// Set form and markers data
		if (mapPickerMode === MapPickerMode.ORIGIN) {
			setOriginInputMethod(LocationInputMethod.Coordinates)
			const newValues = {
				...formik.values,
				originLatitude: lat,
				originLongitude: lng,
				originLatLng: coordinateToString({ lat, lng }),
			}
			formik.setValues(newValues)
			fillBandwidths(newValues)
		} else if (mapPickerMode === MapPickerMode.DESTINATION) {
			setDestinationInputMethod(LocationInputMethod.Coordinates)
			const newValues = {
				...formik.values,
				destinationLatitude: lat,
				destinationLongitude: lng,
				destinationLatLng: coordinateToString({ lat, lng }),
			}
			formik.setValues(newValues)
			fillBandwidths(newValues)
		}
	}

	const onMarkerClickEvent = useCallback(
		({ origin, nativeEvent }: MarkerClickEvent) => {
			switch (origin.type) {
				case MapMarkerType.BUILDING:
					setMapMarkers((oldMarkers) => {
						return oldMarkers
							.filter((marker) => (mapPickerMode !== MapPickerMode.NONE ? marker.type !== MapMarkerType.USER : true))
							.map((marker) => ({
								...marker,
								infoWindow: marker.infoWindow
									? { options: marker.infoWindow.options, open: marker.id === origin.id }
									: undefined,
							}))
					})
					break
				default:
					break
			}

			mapPickerMode !== MapPickerMode.NONE && nativeEvent.latLng && onUserMapPress(nativeEvent.latLng)
		},
		[mapPickerMode, onUserMapPress]
	)

	const onPolygonClickEvent = useCallback(
		({ origin, nativeEvent }: PolygonClickEvent) => {
			setMapMarkers((oldMarkers) => {
				const polygonAssociatedMarker = oldMarkers.find((marker) => marker.polygonId === origin.id)

				let newMarkers: MapMarker[] = oldMarkers
					.filter((marker) => marker.type !== MapMarkerType.USER)
					.map((marker) => ({
						...marker,
						infoWindow: marker.infoWindow ? { ...marker.infoWindow, open: false } : undefined,
					}))

				if (polygonAssociatedMarker) {
					newMarkers = newMarkers.map((marker) => ({
						...marker,
						infoWindow: marker.infoWindow
							? { options: marker.infoWindow.options, open: marker.polygonId === origin.id }
							: undefined,
					}))
				}

				const pressEventOrigin = polygonAssociatedMarker?.options.position || nativeEvent.latLng
				mapPickerMode !== MapPickerMode.NONE && pressEventOrigin && onUserMapPress(pressEventOrigin)

				return newMarkers
			})
		},
		[mapPickerMode, onUserMapPress]
	)

	const onMapClick = useCallback(
		(event: MapsClickEvent, _map: google.maps.Map): void => {
			if (event instanceof UserClickEvent) {
				onUserClickEvent(event)
			} else if (event instanceof MarkerClickEvent) {
				onMarkerClickEvent(event)
			} else if (event instanceof PolygonClickEvent) {
				onPolygonClickEvent(event)
			}
		},
		[onMarkerClickEvent, onPolygonClickEvent, onUserClickEvent]
	)

	const clearOriginLocation = (formik: FormikValues) => {
		setOriginInputMethod(null)
		onFormikChanges(formik, 'originPopSelect')(emptyUfinetSelectOption)
		formik.setFieldValue('originLatLng', '')
		formik.setFieldValue('originLatitude', '')
		formik.setFieldValue('originLongitude', '')
		formik.setFieldValue('originAddress', '')
	}

	const mapStyles = useMemo<React.CSSProperties>(
		() => ({
			minHeight: '35vh',
			borderWidth: mapPickerMode !== MapPickerMode.NONE ? '4px' : '1px',
		}),
		[mapPickerMode]
	)

	const resetMap = () => {
		setMapMarkers((oldMarkers) => [...oldMarkers.filter((marker) => !(marker.type !== MapMarkerType.BUILDING))])
		setMapShown(false)
		setMapPickerMode(MapPickerMode.NONE)
	}

	const onMapInfoWindowClose = useCallback(({ marker }: MapsInfoWindowCloseEvent, _map: google.maps.Map): void => {
		if (marker.type === MapMarkerType.USER) {
			setMapMarkers((oldMarkers) => oldMarkers.filter((m) => m.id !== marker.id))
		}
	}, [])

	const handleMapFoldUnfold = (formik: FormikValues) => {
		if (mapPickerMode !== MapPickerMode.NONE) {
			setMapShown(true)
		} else {
			if (
				originInputMethod !== LocationInputMethod.Coordinates &&
				destinationInputMethod !== LocationInputMethod.Coordinates
			) {
				setMapShown(false)
			} else if (
				isValidCoordinate({ lat: formik.values.originLatitude, lng: formik.values.originLongitude }) ||
				isValidCoordinate({ lat: formik.values.destinationLatitude, lng: formik.values.destinationLongitude })
			) {
				setMapShown(true)
			}
		}
	}

	const fetchAccessAreas = useCallback(
		(countryId: string) => {
			setMapMarkers((oldMarkers) => oldMarkers.filter((marker) => marker.type !== MapMarkerType.BUILDING))
			_kmzAreaService
				.findArea({
					countryId: countryId,
					networkAreaTypes: [AreaType.ACCESS_AREA],
				})
				.then((kmzAreasData) => kmzAreasData.filter((area) => internalUser || area.visible))
				.then((kmzAreasData) => {
					const newPolygonsAndMarkers = markersAndPolygonFromFromKmzAreas(kmzAreasData, intl)
					setMapPolygons([...newPolygonsAndMarkers.polygons])
					setMapMarkers((oldMarkers) => [...oldMarkers, ...newPolygonsAndMarkers.markers])
					return kmzAreasData
				})
				.catch(console.error)
		},
		[_kmzAreaService, internalUser, intl]
	)

	const mapActions = useMemo<CoordinatesSelectorMapActionsType>(
		() => ({
			setMapShown,
			setMapMarkers,
			setMapPickerMode,
			resetMap,
			setMapPolygons,
			onUserMapPress,
			onMapClick,
			onMapUnfolded,
			handleMapFoldUnfold,
			handleUserMapPress,
			onMapInfoWindowClose,
		}),
		[onUserMapPress, onMapClick, onMapUnfolded, handleMapFoldUnfold, handleUserMapPress, onMapInfoWindowClose]
	)

	const mapState = useMemo<CoordinatesSelectorMapStateType>(
		() => ({
			mapWrapperRef,
			mapStyles,
			mapPickerMode,
			userMapPress,
			mapMarkers,
			mapPolygons,
			mapShown,
		}),
		[mapWrapperRef, mapStyles, mapPickerMode, userMapPress, mapMarkers, mapPolygons, mapShown]
	)

	const contextValue = useMemo<CoordinatesSelectorContextType>(
		() => ({
			originInputMethod,
			destinationInputMethod,
			popOriginRef,
			popDestinationRef,
			originAddressInputRef,
			destinationAddressInputRef,
			originAddressAutocompleteWidget,
			destinationAddressAutocompleteWidget,
			setOriginInputMethod,
			setDestinationInputMethod,
			clearOriginLocation,
			hanldePlaceChangeListeners,
			fetchAccessAreas,
			...mapActions,
			...mapState,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			originInputMethod,
			destinationInputMethod,
			popOriginRef,
			popDestinationRef,
			originAddressInputRef,
			destinationAddressInputRef,
			originAddressAutocompleteWidget,
			destinationAddressAutocompleteWidget,
			fetchAccessAreas,
			mapActions,
			mapState,
		]
	)
	return <CoordinatesSelectorContext.Provider value={contextValue}>{children}</CoordinatesSelectorContext.Provider>
}

export const useCoordinatesSelectorContext = () => {
	const {
		originInputMethod,
		destinationInputMethod,
		popOriginRef,
		popDestinationRef,
		originAddressInputRef,
		destinationAddressInputRef,
		originAddressAutocompleteWidget,
		destinationAddressAutocompleteWidget,
		setOriginInputMethod,
		setDestinationInputMethod,
		clearOriginLocation,
		hanldePlaceChangeListeners,
		fetchAccessAreas,
	} = useContext(CoordinatesSelectorContext)

	return {
		originInputMethod,
		destinationInputMethod,
		popOriginRef,
		popDestinationRef,
		originAddressInputRef,
		destinationAddressInputRef,
		originAddressAutocompleteWidget,
		destinationAddressAutocompleteWidget,
		setOriginInputMethod,
		setDestinationInputMethod,
		clearOriginLocation,
		hanldePlaceChangeListeners,
		fetchAccessAreas,
	}
}

export const useCoordinatesSelectorMapActions: () => CoordinatesSelectorMapActionsType = () => {
	const {
		setMapShown,
		setMapMarkers,
		setMapPickerMode,
		resetMap,
		setMapPolygons,
		onUserMapPress,
		onMapClick,
		onMapUnfolded,
		handleMapFoldUnfold,
		handleUserMapPress,
		onMapInfoWindowClose,
	} = useContext(CoordinatesSelectorContext)

	return {
		setMapShown,
		setMapMarkers,
		setMapPickerMode,
		resetMap,
		setMapPolygons,
		onUserMapPress,
		onMapClick,
		onMapUnfolded,
		handleMapFoldUnfold,
		handleUserMapPress,
		onMapInfoWindowClose,
	}
}

export const useCoordinatesSelectorMapState: () => CoordinatesSelectorMapStateType = () => {
	const { mapWrapperRef, mapStyles, mapPickerMode, userMapPress, mapMarkers, mapPolygons, mapShown } =
		useContext(CoordinatesSelectorContext)

	return {
		mapWrapperRef,
		mapStyles,
		mapPickerMode,
		userMapPress,
		mapMarkers,
		mapPolygons,
		mapShown,
	}
}
