export const makeBounds = (coords: (google.maps.LatLngLiteral | google.maps.LatLng)[]): google.maps.LatLngBounds => {
	const latitudes = coords.map((c) => (typeof c.lat === 'function' ? c.lat() : c.lat))
	const longitudes = coords.map((c) => (typeof c.lng === 'function' ? c.lng() : c.lng))

	const northEast: google.maps.LatLngLiteral = {
		lat: Math.max(...latitudes),
		lng: Math.max(...longitudes),
	}
	const southWest: google.maps.LatLngLiteral = {
		lat: Math.min(...latitudes),
		lng: Math.min(...longitudes),
	}
	return new google.maps.LatLngBounds(southWest, northEast)
}
