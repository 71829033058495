import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { MapWrapper } from 'components/google-maps/wrapper/MapWrapper'

import { FC, useMemo } from 'react'
import { Filter, IUfinetSelectOption } from 'ufinet-web-components'
import {
	IQuotationResponse,
	quotationService,
	servicesService,
	useAuthData,
	useInternalUser,
	useTranslator,
} from 'ufinet-web-functions'

import { useMapWrapper } from 'components/google-maps/wrapper/MapWrapperHook'
import { PrefeasibilityRepository } from 'modules/prefeasibility/domain/repository/PrefeasibilityRepository'
import { TechnicalGroupRepository } from 'modules/prefeasibility/domain/repository/TechnicalGroupRepository'
import { NewServiceModal } from 'pages/quotation/common/components/NewServiceModal'
import { QuotationCards } from 'pages/quotation/common/components/QuotationCards'
import { ServiceTable } from 'pages/quotation/common/components/service-table/ServiceTable'
import { usePendingQuotationForm } from 'pages/quotation/pending/components/hooks/pendingQuotationFormHook'

interface IPenndingQuotationFormProps {
	aqsId: string
	parentAqsId: string | undefined
	duplicateQuotation: () => Promise<void>
	updateQuotationDetails: () => void
	pendingQuotationDetails: IQuotationResponse
	prefeasibilityRepository: PrefeasibilityRepository
	technicalGroupRepository: TechnicalGroupRepository
	selectedCountry: IUfinetSelectOption
	selectedCorporateGroup: IUfinetSelectOption
	selectedClient: IUfinetSelectOption
	initialSelectedContact: IUfinetSelectOption | undefined
}

const PendingQuotationForm: FC<IPenndingQuotationFormProps> = ({
	aqsId,
	parentAqsId,
	duplicateQuotation,
	updateQuotationDetails,
	pendingQuotationDetails,
	prefeasibilityRepository,
	technicalGroupRepository,
	selectedCountry,
	selectedCorporateGroup,
	selectedClient,
	initialSelectedContact,
}) => {
	const translate = useTranslator()
	const authData = useAuthData()
	const _serviceService = useMemo(() => servicesService(authData), [authData])
	const _quotationService = useMemo(() => quotationService(authData), [authData])

	const {
		hiddenServiceTableButtons,
		isDataManipulationEnabled,
		formik,
		serviceModal,
		resetFilter,
		quotationData,
		selectedContact,
		filterInitialValues,
		isQuotationPending,
		hideServiceModal,
		resetFilterCallback,
		afterContactChange,
		setFilter,
		deleteServices,
		confirmQuotation,
		updateService,
		navigateToPendingQuotationsPage,
		getServices,
	} = usePendingQuotationForm(
		aqsId,
		parentAqsId,
		_serviceService,
		_quotationService,
		pendingQuotationDetails,
		selectedCorporateGroup,
		selectedClient,
		initialSelectedContact,
		selectedCountry,
		duplicateQuotation,
		updateQuotationDetails
	)

	const internalUser = useInternalUser()

	const {
		mapWrapperRef,
		mapControls,
		mapMarkers,
		mapKmzUrls,
		mapPolygons,
		mapOptions,
		mapDirections,
		onMapLoaded,
		onMapClick,
		onMapInfoWindowClose,
	} = useMapWrapper(formik, selectedCountry.value, selectedCountry.name)

	return (
		<div className="container p-15 pt-5 h-100 d-flex flex-column justify-content-center">
			<Filter
				initialValues={filterInitialValues}
				internalUser={internalUser}
				resetFilter={resetFilter}
				resetFilterCallback={resetFilterCallback}
				setFilter={setFilter}
				afterCountryChange={() => {}}
				afterCorporateGroupChange={() => {}}
				afterClientChange={() => {}}
				afterContactChange={afterContactChange}
				disabled={{
					allowCountrySelection: false,
					allowCorporateGroupSelection: false,
					allowClientSelection: false,
					allowSubmit: !isQuotationPending && process.env.REACT_APP_ENV !== 'prod',
					allowContact: !initialSelectedContact,
					allowReference: true,
					allowFinalClient: true,
				}}
				required={{ requiredCountry: true, requiredCorporateGroup: true, requiredClient: true }}
				hidden={{ hideSubmit: isQuotationPending || process.env.REACT_APP_ENV === 'prod', hideFinalClient: false }}
				icon={faPlus}
				submitButtonContent={translate('SERVICE.NEW')}
			/>
			<NewServiceModal
				aqsId={aqsId}
				parentAqsId={parentAqsId}
				formik={formik}
				isOpen={serviceModal}
				hideModal={hideServiceModal}
				getServices={getServices}
				serviceService={_serviceService}
			/>
			<form onSubmit={formik.handleSubmit}>
				{internalUser && (
					<div className="mt-10">
						<QuotationCards quotationData={quotationData} />
					</div>
				)}
				<div className="mt-10">
					<ServiceTable
						aqsId={aqsId}
						isDataManipulationEnabled={isDataManipulationEnabled}
						contactId={selectedContact?.value}
						hideButtons={hiddenServiceTableButtons}
						internalUser={internalUser}
						services={formik.values.services}
						quotationData={{ ...quotationData, isBulk: false }}
						prefeasibilityRepository={prefeasibilityRepository}
						technicalGroupRepository={technicalGroupRepository}
						deleteServices={deleteServices}
						resetForm={navigateToPendingQuotationsPage}
						confirmQuotation={confirmQuotation}
						countryId={selectedCountry.value ?? ''}
						isPendingQuotation={!parentAqsId}
						setModifiedService={(newServiceData, preUpdateServiceData) => {
							updateService({ ...newServiceData }, preUpdateServiceData)
						}}
					/>
				</div>
				<div className="mt-10">
					<MapWrapper
						ref={mapWrapperRef}
						additionalStyles={{ height: '50vh' }}
						mapOptions={mapOptions}
						markers={mapMarkers}
						kmzUrls={mapKmzUrls}
						polygons={mapPolygons}
						onLoad={onMapLoaded}
						onClick={onMapClick}
						onInfoWindowClose={onMapInfoWindowClose}
						directions={mapDirections}
						controls={mapControls}
						centerOnOpen={false}
					/>
				</div>
			</form>
		</div>
	)
}
export { PendingQuotationForm }
