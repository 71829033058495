import { ColData } from 'ufinet-web-components'

export const colsAreaPreview: ColData[] = [
	{ field: 'countryName', header: 'COL.COUNTRY', width: '150px', nonFilterable: true },
	{ field: 'typeId', header: 'COL.AREA_TYPE', width: '250px', nonFilterable: true },
	{
		field: 'areas',
		header: 'COL.AREAS',
		width: '700px',
		nonFilterable: true,
		body: (rowData: any) => (
			<div className="d-flex flex-wrap  gap-2">
				{rowData?.areas?.map((area: string, index: number) => (
					<span key={index} className="badge badge-primary">
						{area}
					</span>
				))}
			</div>
		),
	},
	{ field: 'filePath', header: 'COL.FILEPATH', width: '250px', nonFilterable: true },
]
