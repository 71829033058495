import { useFormik } from 'formik'
import { FC, memo, useCallback, useContext } from 'react'
import { useIntl } from 'react-intl'
import {
	CountrySelect,
	emptyUfinetSelectOption,
	LoadTypeSelect,
	UfinetDropzone,
	WizardButtonNext,
	WizardButtonsWrapper,
} from 'ufinet-web-components'
import {
	AuthContext,
	IFileUploadData,
	IKmzItemResponse,
	kmzItemsService,
	KmzMaxSize,
	onFormikChanges,
	useAqsError,
} from 'ufinet-web-functions'
import * as Yup from 'yup'
import { ErrorSection } from './ErrorSection'

type Props = {
	settingData: Function
	wizardRef: any
	actionButtonRef: any
}
const DataForm: FC<Props> = memo(
	({ settingData, wizardRef, actionButtonRef }) => {
		const authData = useContext(AuthContext)
		const _kmzItemService = kmzItemsService(authData)

		const [error, setError] = useAqsError()
		const sendData = (values: any) => {
			actionButtonRef.current.changeActionStatus()
			const formData = new FormData()

			formData.append('country', values.countrySelect.value)
			formData.append('itemType', values.loadTypeSelect.value)
			formData.append('file', values.file)

			_kmzItemService
				.uploadKmz(formData)
				.then((response: IKmzItemResponse) => {
					settingData({
						country: formik.values.countrySelect.label,
						itemType: {
							label: formik.values.loadTypeSelect.label,
							color: formik.values.loadTypeSelect.color,
						},
						filePath: formik.values.file.path.toString(),
						numCoordinates: response.numCoordinates,
						storageUrls: response.storageUrls,
					})
					setError(null)
					wizardRef.current.goNext()
					return response
				})
				.catch((error) => {
					setError(error)
				})
				.finally(() => {
					actionButtonRef.current.changeActionStatus()
				})
		}

		const intl = useIntl()
		/*
		 ******************************
		 *************Formik***********
		 ******************************
		 */
		const dataFormSchema = Yup.object().shape({
			countrySelect: Yup.object().shape({
				label: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
			}),
			loadTypeSelect: Yup.object().shape({
				label: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
			}),
			file: Yup.mixed()
				.test('file size', `${intl.formatMessage({ id: 'ERROR.FILE.SIZE' })} 10MB`, (value) => {
					return value?.size <= KmzMaxSize
				})
				.test('file type', intl.formatMessage({ id: 'ERROR.FILE.EXTENSION' }), (value) => {
					return !value?.path?.split('.').pop()?.includes(['kmz', 'kml'])
				})
				.test('file required', intl.formatMessage({ id: 'ERROR.REQUIRED' }), (value) => value.size !== 0),
		})

		const formik = useFormik({
			initialValues: {
				countrySelect: emptyUfinetSelectOption,
				loadTypeSelect: { ...emptyUfinetSelectOption, color: '' },
				file: {
					path: '',
					size: 0,
				} as IFileUploadData,
			},
			validationSchema: dataFormSchema,
			onSubmit: sendData,
			validateOnChange: false,
			validateOnBlur: false,
		})
		/*
		 * end Formik
		 * */

		const onChange = useCallback(onFormikChanges, [])

		return (
			<form onSubmit={formik.handleSubmit} className="container p-15 h-100 d-flex flex-column justify-content-center">
				<div className="row pb-10">
					<div className="col-xl-6 col-12">
						<CountrySelect
							onChange={onChange(formik, 'countrySelect')}
							value={formik.values.countrySelect}
							error={formik.errors.countrySelect?.label}
						/>
					</div>
					<div className="col-xl-6 col-12">
						<LoadTypeSelect
							onChange={onChange(formik, 'loadTypeSelect')}
							value={formik.values.loadTypeSelect}
							error={formik.errors.loadTypeSelect?.label}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<UfinetDropzone
							onChange={(e: any) => {
								setError(null)
								onChange(formik, 'file')(e)
							}}
							value={formik.values.file}
							error={formik.errors.file}
							text={intl.formatMessage({ id: 'STEPPER.LOAD_KMZ.DROPZONE' })}
							accept={{ 'application/vnd.google-earth': ['.kmz', '.kml'] }}
						/>
					</div>
				</div>
				<ErrorSection error={error} />
				<WizardButtonsWrapper>
					<div />
					<WizardButtonNext
						wizardRef={wizardRef}
						actionButtonRef={actionButtonRef}
						isDisabled={!formik.isValid}
						submitForm
					/>
				</WizardButtonsWrapper>
			</form>
		)
	},
	(prevProps, nextProps) => {
		return prevProps.settingData === nextProps.settingData
	}
)

export { DataForm }
