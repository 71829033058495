import { FC } from 'react'
import { I18nProvider, Ufineti18nProvider } from 'ufinet-web-components'
import { aqsI18nMessages } from '../../../modules/i18n/domain/messages'

const AqsI18nProvider: FC = ({ children }) => {
	return (
		<Ufineti18nProvider>
			<I18nProvider messagesByLanguage={aqsI18nMessages}>{children}</I18nProvider>
		</Ufineti18nProvider>
	)
}

export { AqsI18nProvider }
