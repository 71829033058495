import { MapWrapper } from 'components/google-maps/wrapper/MapWrapper'
import { ICablesAndBoxes } from 'pages/loadKmz/common/LoadKmzTypesAndInterfaces'
import { FC } from 'react'
import { WizardButtonNext, WizardButtonPrev, WizardButtonsWrapper } from 'ufinet-web-components'
import { PreviewSummaryTable } from './PreviewSummaryTable'

type Props = {
	data: ICablesAndBoxes
	wizardRef: any
}
const PreviewSummary: FC<Props> = ({ data, wizardRef }) => {
	return (
		<div className="h-100 p-15">
			<PreviewSummaryTable data={data} />
			<MapWrapper kmzUrls={data.storageUrls} additionalStyles={{ height: '600px' }} />
			<WizardButtonsWrapper>
				<WizardButtonPrev wizardRef={wizardRef} />
				<WizardButtonNext wizardRef={wizardRef} />
			</WizardButtonsWrapper>
		</div>
	)
}

export { PreviewSummary }
