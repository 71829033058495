import { FormFields, FormFieldType } from 'modules/common/domain/model/FormFields'
import { Redundancie, TransmissionMean } from 'modules/product/capacity/domain'
import {
	useRedundanceQuery,
	useStandardQuery,
	useSubstandardQuery,
	useTransmissionMeanQuery,
} from 'modules/product/queries/CapacityQueries'
import { CapacityRepository } from 'modules/product/repository/CapacityRepository'
import { useGetPermission } from 'modules/security/useGetPermission'
import { useCallback, useMemo } from 'react'
import { AqsModules, onFormikChanges, useTranslator } from 'ufinet-web-functions'

export const useCapacityServiceForm = (capacityRepository: CapacityRepository) => {
	const { data: redundanceSelectOption, isLoading: isLoadingRedundance } = useRedundanceQuery(capacityRepository)
	const { data: transmissionMeanSelectOption, isLoading: isLoadingTransmissionMean } =
		useTransmissionMeanQuery(capacityRepository)
	const { data: standardSelectOption, isLoading: isLoadingStandard } = useStandardQuery(capacityRepository)
	const { data: subStandardSelectOption, isLoading: isLoadingSubstandard } = useSubstandardQuery(capacityRepository)

	const canWritePrefeasibility = useGetPermission(AqsModules.PREFEASIBILITY, 'canWrite')

	const formFields: FormFields = useMemo(() => {
		const result: FormFields = {
			redundancy: {
				text: 'REDUNDANCY',
				formActions: 'redundancySelect',
				isLoading: isLoadingRedundance,
				options: redundanceSelectOption,
				defaultValue: Redundancie.Lineal,
				isDisabled: !canWritePrefeasibility,
				inputType: FormFieldType.SELECT,
			},
			transmission: {
				text: 'TRANSMISSION',
				formActions: 'transmissionSelect',
				isLoading: isLoadingTransmissionMean,
				options: transmissionMeanSelectOption,
				defaultValue: TransmissionMean.OpticalFiber,
				isDisabled: !canWritePrefeasibility,
				inputType: FormFieldType.SELECT,
			},
			standard: {
				text: 'STANDARD',
				formActions: 'standardSelect',
				isLoading: isLoadingStandard,
				options: standardSelectOption,
				inputType: FormFieldType.SELECT,
			},
			subStandard: {
				text: 'SUBSTANDARD',
				formActions: 'subStandardSelect',
				isLoading: isLoadingSubstandard,
				options: subStandardSelectOption,
				inputType: FormFieldType.SELECT,
			},
		}

		return result
	}, [
		redundanceSelectOption,
		transmissionMeanSelectOption,
		standardSelectOption,
		subStandardSelectOption,
		isLoadingRedundance,
		isLoadingTransmissionMean,
		isLoadingStandard,
		isLoadingSubstandard,
		canWritePrefeasibility,
	])
	const translate = useTranslator()

	const onChange = useCallback(onFormikChanges, [
		redundanceSelectOption,
		transmissionMeanSelectOption,
		standardSelectOption,
		subStandardSelectOption,
	])
	return {
		formFields,
		onChange,
		translate,
	}
}
