export const getPolygonCenter = (polygonOptions: google.maps.PolygonOptions): google.maps.LatLngLiteral => {
	const bounds = new google.maps.LatLngBounds()
	const polygon = new google.maps.Polygon(polygonOptions)

	polygon.getPaths().forEach((path) => {
		path.forEach((coordinate) => bounds.extend(coordinate))
	})
	const center = bounds.getCenter()

	return { lat: center.lat(), lng: center.lng() } as google.maps.LatLngAltitudeLiteral
}

export const getPolygonCenterFromPaths = (
	paths: google.maps.MVCArray<google.maps.MVCArray<google.maps.LatLng>>
): google.maps.LatLngLiteral => {
	const bounds = new google.maps.LatLngBounds()

	paths.forEach((path) => {
		path.forEach((coordinate) => bounds.extend(coordinate))
	})
	const center = bounds.getCenter()

	return { lat: center.lat(), lng: center.lng() } as google.maps.LatLngAltitudeLiteral
}
