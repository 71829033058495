import { ClientEditSelect, ColData } from 'ufinet-web-components'
import {
	ICostsBuildingAccessResponse,
	ICostsEconomicModelResponse,
	ICostsEquipmentResponse,
	ICostsFOResponse,
	ICostsInfrastructureResponse,
	convertToLocalTZ,
} from 'ufinet-web-functions'

export const colsFO: ColData[] = [
	{ field: 'countryName', header: 'COST.COUNTRY' },
	{ field: 'networkAreaName', header: 'COST.AREA', width: '250px' },
	{
		field: 'clientId',
		header: 'COST.CLIENT',
		width: '250px',
		editable: true,
		customEditableField: (col: ColData, options: any) => {
			const client: ICostsFOResponse = options.rowData
			return (
				<ClientEditSelect
					noLabel
					unclearable
					placeholder={options.rowData.clientName}
					onChange={(e: any) => {
						!!e && options.editorCallback(e.value)
					}}
					className="w-100"
					clientRequest={{
						countries: [client.countryId],
						corporateGroups: [],
					}}
				/>
			)
		},
		body: (row: ICostsFOResponse) => {
			return <div className="ufinet-table-cell overflow">{row.clientName || '-'}</div>
		},
	},
	{
		field: 'infrastructureType',
		header: 'COST.INFRASTRUCTURETYPE',
		width: '250px',
		editable: false,
	},
	{
		field: 'zoneType',
		header: 'COST.SCOPE',
		width: '250px',
		editable: false,
	},
	{ field: 'minimalDistance', header: 'COST.MINIMALDISTANCE', editable: true, type: 'decimal', nonFilterable: true },
	{ field: 'maximumDistance', header: 'COST.MAXIMUMDISTANCE', editable: true, type: 'decimal', nonFilterable: true },
	{ field: 'opticalFactor', header: 'COST.OPTICALFACTOR', editable: true, type: 'decimal', nonFilterable: true },
	{ field: 'punctualCost', header: 'COST.PUNCTUALCOST', editable: true, type: 'decimal', nonFilterable: true },
	{ field: 'laborPunctualCost', header: 'COST.PUNCTUALCOSTMO', editable: true, type: 'decimal', nonFilterable: true },
	{
		field: 'punctualCostPerMeter',
		header: 'COST.PUNCTUALCOSTPERMETER',
		editable: true,
		type: 'decimal',
		nonFilterable: true,
	},
	{
		field: 'laborPunctualCostPerMeter',
		header: 'COST.PUNCTUALCOSTPERMETERMO',
		editable: true,
		width: '150px',
		type: 'decimal',
		nonFilterable: true,
	},
	{ field: 'lastModifiedBy', header: 'COST.UPDATEDBY', editable: true, width: '250px' },
	{
		field: 'lastModifiedAt',
		header: 'COST.UPDATEDAT',
		editable: true,
		width: '150px',
		nonFilterable: true,
		body: (row: ICostsEconomicModelResponse) => convertToLocalTZ(row.lastModifiedAt),
	},
]

export const colsEquipment: ColData[] = [
	{ field: 'countryName', header: 'COST.COUNTRY' },
	{ field: 'networkAreaName', header: 'COST.AREA', width: '250px' },
	{
		field: 'clientId',
		header: 'COST.CLIENT',
		width: '250px',
		editable: true,
		customEditableField: (col: ColData, options: any) => {
			const client: ICostsEquipmentResponse = options.rowData
			return (
				<ClientEditSelect
					noLabel
					unclearable
					placeholder={options.rowData.clientName}
					onChange={(e: any) => {
						!!e && options.editorCallback(e.value)
					}}
					className="w-100"
					clientRequest={{
						countries: [client.countryId],
						corporateGroups: [],
					}}
				/>
			)
		},
		body: (row: ICostsEquipmentResponse) => {
			return <div className="ufinet-table-cell overflow">{row.clientName || '-'}</div>
		},
	},
	{
		field: 'zoneType',
		header: 'COST.SCOPE',
		editable: false,
		width: '250px',
	},
	{ field: 'minimumCapacity', header: 'COST.MINIMALCAPACITY', editable: true, type: 'decimal', nonFilterable: true },
	{ field: 'maximumCapacity', header: 'COST.MAXIMUMCAPACITY', editable: true, type: 'decimal', nonFilterable: true },
	{ field: 'cost', header: 'COST.COST', editable: true, type: 'decimal', nonFilterable: true },
	{ field: 'laborCost', header: 'COST.COSTMO', editable: true, type: 'decimal', nonFilterable: true },
	{ field: 'lastModifiedBy', header: 'COST.UPDATEDBY', width: '250px' },
	{
		field: 'lastModifiedAt',
		header: 'COST.UPDATEDAT',
		width: '155px',
		nonFilterable: true,
		body: (row: ICostsEquipmentResponse) => convertToLocalTZ(row.lastModifiedAt),
	},
]

export const colsBuildingAccess: ColData[] = [
	{ field: 'countryName', header: 'COST.COUNTRY' },
	{ field: 'networkAreaName', header: 'COST.AREA', width: '250px' },
	{
		field: 'clientId',
		header: 'COST.CLIENT',
		width: '250px',
		editable: true,
		customEditableField: (col: ColData, options: any) => {
			const client: ICostsBuildingAccessResponse = options.rowData
			return (
				<ClientEditSelect
					unclearable
					placeholder={options.rowData.clientName}
					onChange={(e: any) => {
						!!e && options.editorCallback(e.value)
					}}
					className="w-100"
					clientRequest={{
						countries: client.countryId ? [client.countryId] : [],
						corporateGroups: [],
					}}
				/>
			)
		},
		body: (row: ICostsBuildingAccessResponse) => {
			return <div className="ufinet-table-cell overflow">{row.clientName || '-'}</div>
		},
	},
	{ field: 'punctualCost', header: 'COST.PUNCTUALCOST', editable: true, type: 'decimal', nonFilterable: true },
	{ field: 'monthlyCost', header: 'COST.MONTHLYCOST', editable: true, type: 'decimal', nonFilterable: true },
	{
		field: 'monthlyCostPerCapacity',
		header: 'COST.PUNCTUALCOSTPERMETER',
		editable: true,
		type: 'decimal',
		nonFilterable: true,
	},
	{
		field: 'ceConnect',
		header: 'COST.CE_CONNECT',
		editable: true,
		width: '150px',
		type: 'decimal',
		nonFilterable: true,
	},
	{
		field: 'distanceFo',
		header: 'COST.DISTANCE_FO',
		editable: true,
		width: '150px',
		type: 'decimal',
		nonFilterable: true,
	},
	{ field: 'lastModifiedBy', header: 'COST.UPDATEDBY', width: '250px' },
	{
		field: 'lastModifiedAt',
		header: 'COST.UPDATEDAT',
		width: '155px',
		nonFilterable: true,
		body: (row: ICostsBuildingAccessResponse) => convertToLocalTZ(row.lastModifiedAt),
	},
]

export const colsInfrastructure: ColData[] = [
	{ field: 'countryName', header: 'COST.COUNTRY' },
	{ field: 'networkAreaName', header: 'COST.AREA', width: '250px' },
	{
		field: 'clientId',
		header: 'COST.CLIENT',
		width: '250px',
		editable: true,
		customEditableField: (col: ColData, options: any) => {
			const client: ICostsInfrastructureResponse = options.rowData
			return (
				<ClientEditSelect
					noLabel
					unclearable
					placeholder={options.rowData.clientName}
					onChange={(e: any) => {
						!!e && options.editorCallback(e.value)
					}}
					className="w-100"
					clientRequest={{
						countries: [client.countryId],
						corporateGroups: [],
					}}
				/>
			)
		},
		body: (row: ICostsInfrastructureResponse) => {
			return <div className="ufinet-table-cell overflow">{row.clientName || '-'}</div>
		},
	},
	{ field: 'infrastructureType', header: 'COST.INFRASTRUCTURETYPE', width: '250px' },
	{
		field: 'monthlyCostPerMeter',
		header: 'COST.MONTHLYCOSTPERMETER',
		width: '150px',
		editable: true,
		type: 'decimal',
		nonFilterable: true,
	},
	{ field: 'punctualCost', header: 'COST.PUNCTUALCOST', editable: true, type: 'decimal', nonFilterable: true },
	{ field: 'lastModifiedBy', header: 'COST.UPDATEDBY', width: '250px' },
	{
		field: 'lastModifiedAt',
		header: 'COST.UPDATEDAT',
		width: '180px',
		nonFilterable: true,
		body: (row: ICostsInfrastructureResponse) => convertToLocalTZ(row.lastModifiedAt),
	},
]

export const colsEconomicModel: ColData[] = [
	{ field: 'countryName', header: 'COST.COUNTRY' },
	{ field: 'networkAreaName', header: 'COST.AREA', width: '250px' },
	{ field: 'clientName', header: 'COST.CLIENT', width: '250px' },
	{
		field: 'investmentMargin',
		header: 'COST.INVESTMARGIN',
		width: '140px',
		editable: true,
		type: 'decimal',
		nonFilterable: true,
	},
	{
		field: 'recurringMargin',
		header: 'COST.RECURRMARGIN',
		width: '150px',
		editable: true,
		type: 'decimal',
		nonFilterable: true,
	},
	{
		field: 'incrementNrc',
		header: 'COST.INCREMENT_NRC',
		width: '120px',
		editable: true,
		type: 'decimal',
		nonFilterable: true,
	},
	{
		field: 'incrementMrc',
		header: 'COST.INCREMENT_MRC',
		width: '120px',
		editable: true,
		type: 'decimal',
		nonFilterable: true,
	},
	{ field: 'lastModifiedBy', header: 'COST.UPDATEDBY', width: '250px' },
	{
		field: 'lastModifiedAt',
		header: 'COST.UPDATEDAT',
		width: '150px',
		nonFilterable: true,
		body: (row: ICostsEconomicModelResponse) => convertToLocalTZ(row.lastModifiedAt),
	},
]
