import React, { memo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { AreaAddConfirm } from './steppers/AreaAddConfirm'
import { AreaAddForm } from './steppers/AreaAddForm'

import { IWizardInterface, UfinetActionButtonHandle, WizardVertical, WizardVerticalHandle } from 'ufinet-web-components'
import { AreaAccessStatus, AreaType, IAreaModal } from 'ufinet-web-functions'
import { AreaPreview } from '../common/preview/AreaPreview'

type Props = {
	hideModal: Function
}

const AddAreaModalBody: React.FC<Props> = memo(
	({ hideModal }) => {
		const intl = useIntl()
		const wizardRef = useRef<WizardVerticalHandle>()
		const actionButtonRef = useRef<UfinetActionButtonHandle>()
		const actionButtonConfirmRef = useRef<UfinetActionButtonHandle>()
		const [data, setData] = useState<IAreaModal>({
			countryName: '',
			countryId: '',
			lastModifiedAt: '',
			lastModifiedBy: '',
			name: '',
			id: '',
			typeId: AreaType.ACCESS_AREA,
			networkAccessAreaStatus: AreaAccessStatus.NOT_CONNECTED,
			storageUrls: [''],
			areas: [''],
			visible: false,
		})

		const contentArray: IWizardInterface[] = [
			{
				stepperTitle: intl.formatMessage({ id: 'STEP.CABLES&BOXES.FILL_DATA' }),
				stepperDescription: intl.formatMessage({ id: 'STEP.DESCRIPTION.CABLES&BOXES.FILL_DATA' }),
				stepperComponent: <AreaAddForm actionButtonRef={actionButtonRef} wizardRef={wizardRef} settingData={setData} />,
			},
			{
				stepperTitle: intl.formatMessage({ id: 'STEP.CABLES&BOXES.PREVIEW' }),
				stepperDescription: intl.formatMessage({ id: 'STEP.DESCRIPTION.CABLES&BOXES.PREVIEW' }),
				stepperComponent: <AreaPreview wizardRef={wizardRef} data={data} />,
			},
			{
				stepperTitle: intl.formatMessage({ id: 'STEP.CABLES&BOXES.LOAD_KMZ' }),
				stepperDescription: intl.formatMessage({ id: 'STEP.DESCRIPTION.CABLES&BOXES.LOAD_KMZ' }),
				stepperComponent: (
					<AreaAddConfirm
						actionButtonRef={actionButtonConfirmRef}
						wizardRef={wizardRef}
						data={data}
						hideModal={hideModal}
					/>
				),
			},
		]

		return (
			<div>
				<WizardVertical content={contentArray} ref={wizardRef} />
			</div>
		)
	},
	(prevProps, nextProps) => {
		return prevProps.hideModal === nextProps.hideModal
	}
)
export { AddAreaModalBody }
