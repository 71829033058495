import { defaultMarkerIconOptions, IMarkerIconOptions } from '../MarkerIconOptions'
import { MarkerIconRequest } from '../MarkerIconRequest'
import { PinContentType } from '../pin/PinContentType'

export const mkIcon = (options?: IMarkerIconOptions): string => {
	if (!options) {
		options = defaultMarkerIconOptions
	} else {
		Object.keys(defaultMarkerIconOptions).forEach((key) => {
			if (!!options && !options[key]) options[key] = defaultMarkerIconOptions[key]
		})
	}

	const reqData: MarkerIconRequest =
		options.contentType === PinContentType.LETTER
			? {
					chst: `${options.pinType!}_${options.contentType!}${options.shadow!}`,
					chld: `${options.textContent!}|${options.fillColor!}|${options.textColor!}`,
			  }
			: {
					chst: `${options.pinType!}_${options.contentType!}${options.shadow!}`,
					chld: `${options.iconString!}|${options.fillColor!}`,
			  }

	return mkIconUrl(reqData)
}

const chartsUrl = 'https://chart.apis.google.com/chart'
const mkIconUrl = (criteria: MarkerIconRequest): string => `${chartsUrl}?chst=${criteria.chst}&chld=${criteria.chld}`

export const assetsUrl = '/media/icons/duotune/maps/'
