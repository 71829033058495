import { MapControlPositions } from 'modules/google-maps/controls/MapControlPositions'
import { MapControlsDefinition } from 'modules/google-maps/controls/MapControlsDefinition'
import { UserClickEvent } from 'modules/google-maps/events/UserClickEvent'
import React, {
	Children,
	cloneElement,
	FC,
	isValidElement,
	PropsWithChildren,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react'
import { MapControlsContainer } from './controls/MapControlsContainer'
import { useDeepCompareEffectForMaps } from './hooks/useDeepCompareEffectForMaps'

interface IMapProps extends google.maps.MapOptions {
	style?: React.CSSProperties
	controls: MapControlsDefinition[]
	onLoad: (e: Event, map: google.maps.Map) => void
	onClick: (e: UserClickEvent) => void
}

const Map: FC<PropsWithChildren<IMapProps>> = ({ onLoad, onClick, children, controls, style = {}, ...options }) => {
	const mapRef = useRef<HTMLDivElement>(null)

	const [map, setMap] = useState<google.maps.Map>()
	const [loaded, setLoaded] = useState<boolean>(false)

	const apiControlsPositionFromDomain = useCallback(
		(domainPosition: MapControlPositions): google.maps.ControlPosition => {
			switch (domainPosition) {
				case MapControlPositions.BottomCenter:
					return google.maps.ControlPosition.BOTTOM_CENTER
				case MapControlPositions.BottomLeft:
					return google.maps.ControlPosition.BOTTOM_LEFT
				case MapControlPositions.BottomRigth:
					return google.maps.ControlPosition.BOTTOM_RIGHT
				case MapControlPositions.LeftBottom:
					return google.maps.ControlPosition.LEFT_BOTTOM
				case MapControlPositions.LeftCenter:
					return google.maps.ControlPosition.LEFT_CENTER
				case MapControlPositions.LeftTop:
					return google.maps.ControlPosition.LEFT_TOP
				case MapControlPositions.RightBottom:
					return google.maps.ControlPosition.RIGHT_BOTTOM
				case MapControlPositions.RightCenter:
					return google.maps.ControlPosition.RIGHT_CENTER
				case MapControlPositions.RightTop:
					return google.maps.ControlPosition.RIGHT_TOP
				case MapControlPositions.TopCenter:
					return google.maps.ControlPosition.TOP_CENTER
				case MapControlPositions.TopLeft:
					return google.maps.ControlPosition.TOP_LEFT
				case MapControlPositions.TopRight:
					return google.maps.ControlPosition.TOP_RIGHT
				default:
					return google.maps.ControlPosition.TOP_CENTER
			}
		},
		[]
	)

	const onMapLoad = useCallback(
		(e: Event, map: google.maps.Map) => {
			setLoaded(true)
			onLoad(e, map)
		},
		[onLoad]
	)

	useEffect(() => {
		if (mapRef.current && !map) {
			setMap(new window.google.maps.Map(mapRef.current, {}))
		}
	}, [mapRef, map])

	useDeepCompareEffectForMaps(() => {
		if (map) {
			map.setOptions(options)

			google.maps.event.addListenerOnce(map, 'tilesloaded', (e: Event) => onMapLoad(e, map))
			map.addListener('click', (mapMouseEvent: google.maps.MapMouseEvent) => {
				onClick(new UserClickEvent({ nativeEvent: mapMouseEvent }))
			})
		}

		return () => {
			if (map) {
				google.maps.event.clearListeners(map, 'tilesloaded')
				google.maps.event.clearListeners(map, 'click')
			}
		}
	}, [map, onClick, onMapLoad, options])

	return (
		<>
			<div id={options.mapId ?? undefined} ref={mapRef} style={style} />
			{Children.map(children, (child) => {
				if (isValidElement(child)) {
					// set the map prop on the child component
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					return cloneElement(child, { map })
				}
			})}
			{loaded &&
				controls.map((control, idx) => (
					<MapControlsContainer
						key={idx}
						map={map!}
						position={apiControlsPositionFromDomain(control.position)}
						className={control.className}
						styles={control.styles}
					>
						{/* // set the map prop on the child component */}
						{cloneElement(control.body, { map })}
					</MapControlsContainer>
				))}
		</>
	)
}

export { Map }
