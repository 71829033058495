import { PinColor } from './pin/PinColor'
import { PinContentType } from './pin/PinContentType'
import { PinType } from './pin/PinType'
import { ShadowOption } from './pin/ShadowOption'

export interface IMarkerIconOptions {
	[key: string]: string | undefined

	pinType?: PinType
	contentType?: PinContentType
	shadow?: ShadowOption

	textContent?: string
	iconString?: string // https://developers.google.com/chart/infographics/docs/dynamic_icons?csw=1#basic-icons
	fillColor?: PinColor
	textColor?: PinColor
}

export const defaultMarkerIconOptions: IMarkerIconOptions = {
	pinType: PinType.PLAIN,
	contentType: PinContentType.LETTER,
	shadow: ShadowOption.NO_SHADOW,

	textContent: ' ',
	iconString: 'location',
	fillColor: 'FF0000',
	textColor: 'FFFFFF',
}
