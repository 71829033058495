import { FormikProps, useFormik } from 'formik'
import { useTechnicalGroupsQuery } from 'modules/prefeasibility/application/queries/TechnicalGroupQueries'
import { PrefeasibilityRepository } from 'modules/prefeasibility/domain/repository/PrefeasibilityRepository'
import { TechnicalGroupRepository } from 'modules/prefeasibility/domain/repository/TechnicalGroupRepository'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { IUfinetSelectOption, colorConstants, emptyUfinetSelectOption } from 'ufinet-web-components'
import { onFormikTextChanges, useTranslator } from 'ufinet-web-functions'
import * as Yup from 'yup'
type PrefeasibilityFormData = {
	agreementDate?: Date
	extraInformation?: string
	technicalGroupSelect?: IUfinetSelectOption
	technicalGroupId?: string
}

type PrefeasibilityFormHook = {
	formik: FormikProps<PrefeasibilityFormData>
	technicalGroupOptions: IUfinetSelectOption[]
	today: Date
	onChangeTextField: (field: string, value: any) => void
	onChangeTechnicalGroupSelectField: (value: IUfinetSelectOption | undefined) => void
	onChangeDateField: (field: string, value: Date) => void
}

export const usePrefeasibilityForm: (
	aqsId: string,
	contactId: string | undefined,
	reference: string | undefined,
	finalClient: string | undefined,
	countryId: string,
	confirmPrefeasibility: () => void,
	onFormClosed: () => void,
	prefeasibilityRepository: PrefeasibilityRepository,
	technicalGroupRepository: TechnicalGroupRepository
) => PrefeasibilityFormHook = (
	aqsId: string,
	contactId: string | undefined,
	reference: string | undefined,
	finalClient: string | undefined,
	countryId: string,
	confirmPrefeasibility: () => void,
	onFormClosed: () => void,
	prefeasibilityRepository: PrefeasibilityRepository,
	technicalGroupRepository: TechnicalGroupRepository
) => {
	const { data: technicalGroups } = useTechnicalGroupsQuery(countryId, true, technicalGroupRepository)
	const translate = useTranslator()

	const today = useMemo(() => {
		const auxDate = new Date()
		auxDate.setHours(0, 0, 0, 0)
		return auxDate
	}, [])

	const initialValues: PrefeasibilityFormData = useMemo<PrefeasibilityFormData>(
		() => ({
			agreementDate: undefined,
			extraInformation: undefined,
			technicalGroupSelect: emptyUfinetSelectOption,
			technicalGroupId: undefined,
		}),
		[]
	)

	const validationSchema = Yup.object().shape({
		agreementDate: Yup.date()
			.required(translate('ERROR.REQUIRED'))
			.typeError(translate('INVALID_DATE'))
			.min(today, translate('ERROR.INVALID.MINIMUM', { min: today })),
		extraInformation: Yup.string(),
		technicalGroupId: Yup.string().required(translate('ERROR.REQUIRED')),
	})

	const onSubmit = async (values: PrefeasibilityFormData) => {
		Swal.fire({
			icon: 'warning',
			title: translate('PREFEASIBILITY.FORM.CONFIRM.TITLE'),
			html: translate('PREFEASIBILITY.FORM.CONFIRM.MESSAGE'),
			showCancelButton: true,
			showConfirmButton: true,
			reverseButtons: true,
			confirmButtonColor: colorConstants.primaryColor,
			cancelButtonColor: colorConstants.dangerColor,
			preConfirm: async () => {
				try {
					await prefeasibilityRepository.requestPrefeasibility({
						agreementDate: values.agreementDate,
						technicalGroupId: values.technicalGroupId,
						extraInformation: values.extraInformation,
						aqsId: aqsId,
						contactId: contactId,
						reference: reference,
						finalClient: finalClient,
					})
					toast.success(`${translate('PREFEASIBILITY.FORM.SUCCESS')}`)
					confirmPrefeasibility()
				} catch {
					toast.error(translate('PREFEASIBILITY.FORM.ERROR'))
				}
			},
		})
	}

	const formik: FormikProps<PrefeasibilityFormData> = useFormik({
		initialValues,
		validationSchema,
		onSubmit,
		validateOnChange: true,
		validateOnBlur: false,
		enableReinitialize: true,
		validateOnMount: false,
	})

	const onChangeTextField = (field: string, e: React.ChangeEvent<HTMLInputElement>) => {
		onFormikTextChanges(formik, field)(e)
	}

	const onChangeTechnicalGroupSelectField = (e: IUfinetSelectOption | undefined) => {
		formik.setFieldValue('technicalGroupSelect', e)
		formik.setFieldValue('technicalGroupId', e?.value)
	}

	const onChangeDateField = (field: string, e: Date) => {
		formik.setFieldValue(field, e)
	}

	const [technicalGroupOptions, setTechnicalGroupOptions] = useState<IUfinetSelectOption[]>([])

	useEffect(() => {
		setTechnicalGroupOptions(
			technicalGroups?.map((t) => {
				return {
					label: t.name,
					value: t.id,
				}
			}) ?? []
		)
	}, [technicalGroups])

	return {
		formik,
		technicalGroupOptions,
		today,
		onChangeTextField,
		onChangeTechnicalGroupSelectField,
		onChangeDateField,
	}
}
