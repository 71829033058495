import { FormikErrors, FormikProps, FormikState } from 'formik'
import { FormField, FormFieldType } from 'modules/common/domain/model/FormFields'
import { getDefaultOption } from 'modules/common/domain/utils/getDefaultOption'
import { FC, useCallback } from 'react'
import { ISelect, UfinetInput, UfinetSelect } from 'ufinet-web-components'
import { TranslatorFunction, onFormikNumberChanges } from 'ufinet-web-functions'

interface FormBuilderProps {
	formField: FormField
	translate: TranslatorFunction
	formik: FormikProps<any>
	onChange: <T>(formik: any, field: string, orElse?: T | undefined) => (e: any) => void
}

export const FormBuilder: FC<FormBuilderProps> = ({ formField, translate, formik, onChange }) => {
	const getNestedValues = (nestedElement: FormikState<any> | FormikErrors<any>, fieldName: string) => {
		if (!nestedElement || typeof nestedElement !== 'object') return null

		const parts = fieldName.split('.')
		if (!parts) return null

		let currentObject: any = nestedElement
		for (const part of parts) {
			if (!currentObject || typeof currentObject !== 'object') return null

			currentObject = currentObject[part]
		}

		return currentObject
	}

	const {
		text,
		formActions,
		options = [],
		defaultValue,
		isDisabled,
		inputType,
		className = 'col-6 pt-4',
		noSortValues = true,
	} = formField
	const onNumberChange = useCallback(onFormikNumberChanges, [])

	switch (inputType) {
		case FormFieldType.SELECT:
			return (
				<UfinetSelect
					requiredIcon
					menuPosition="fixed"
					className={className}
					labelTitle={translate(`SERVICE.${text}`)}
					tooltipTitle={translate(`SERVICE.${text}.TOOLTIP`)}
					defaultValue={getDefaultOption(options as ISelect[], defaultValue)}
					isClearable
					isDisabled={isDisabled}
					error={getNestedValues(formik.errors, `${formActions}.label`)}
					onChange={onChange(formik, formActions)}
					value={getNestedValues(formik.values, formActions)?.label && getNestedValues(formik.values, formActions)}
					options={options}
					placeholder={translate(`SERVICE.${text}`)}
					noSortValues={noSortValues}
				/>
			)
		case FormFieldType.INPUT_NUMBER:
			return (
				<UfinetInput
					requiredIcon
					className={className}
					labelTitle={translate(`SERVICE.${text}`)}
					tooltipTitle={translate(`SERVICE.${text}.TOOLTIP`)}
					error={getNestedValues(formik.errors, formActions)}
					type="decimal"
					solid={false}
					onChange={onNumberChange(formik, formActions)}
					value={getNestedValues(formik.values, formActions) ?? 0}
				/>
			)
		case FormFieldType.INPUT_TEXT:
			return (
				<UfinetInput
					requiredIcon
					className={className}
					labelTitle={translate(`SERVICE.${text}`)}
					tooltipTitle={translate(`SERVICE.${text}.TOOLTIP`)}
					error={getNestedValues(formik.errors, formActions)}
					type="text"
					solid={false}
					onChange={onChange(formik, formActions)}
					value={formik.values[formActions] ?? ''}
				/>
			)
		default:
			return <></>
	}
}
