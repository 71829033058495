import { FetchAPI, FetchOptions, IAuthData, defaultOptionsFetch } from 'ufinet-web-functions'
import { InternetRepository } from './InternetRepository'

const url = `${process.env.REACT_APP_URL_BACK_AQS}api/product/internet`

export const HttpInternetRepository: (authData: IAuthData) => InternetRepository = (authData) => {
	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		notifyError: false,
		authenticationData: authData,
		sendApiKey: true,
	}

	const getAccess = async () => await FetchAPI.get(url + '/access', fetchOptions)

	const getSubscriptions = async () => await FetchAPI.get(url + '/oversubscriptions', fetchOptions)

	return {
		getAccess,
		getSubscriptions,
	}
}
