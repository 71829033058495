import { FormFields, FormFieldType } from 'modules/common/domain/model/FormFields'
import { useCallback, useMemo } from 'react'
import { onFormikChanges, useTranslator } from 'ufinet-web-functions'

export const useDarkFiberServiceForm = () => {
	const formFields: FormFields = useMemo(() => {
		const result: FormFields = {
			sectionLength: {
				text: 'SECTION.LENGTH',
				formActions: 'darkFiberService.sectionLengthInput',
				inputType: FormFieldType.INPUT_NUMBER,
				isLoading: false,
				options: undefined,
			},
		}

		return result
	}, [])

	const translate = useTranslator()

	const onChange = useCallback(onFormikChanges, [])
	return {
		formFields,
		onChange,
		translate,
	}
}
