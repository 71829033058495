import { SelectOptionMapper, SelectOptionTranslateMapper } from 'modules/common/domain/mapper/SelectOptionMapper'
import { useQuery } from 'react-query'
import { IdAndValue, useTranslator } from 'ufinet-web-functions'
import { ToweringRepository } from '../repository/ToweringRepository'

export interface ToweringListPrice {
	corporateGroupId: string
	destinationLatitude: number
	destinationLongitude: number
	structureTypeId: string
	colocationPositionId: number
}

export const useStructureTypesQuery = (repository: ToweringRepository) => {
	const { getStructureTypes } = repository
	const translate = useTranslator()
	return useQuery(`towering-structure-types-query`, () => getStructureTypes(), {
		select: (data: IdAndValue<string, string>[]) =>
			SelectOptionTranslateMapper(data, translate, 'SERVICE.TOWERING.STRUCTURE_TYPES'),
	})
}

export const useColocationPositionsQuery = (repository: ToweringRepository) => {
	const { getColocationPositions } = repository
	return useQuery(`towering-colocotaion-positions-query`, () => getColocationPositions(), {
		select: SelectOptionMapper,
	})
}

export const useStructureHeightsQuery = (
	isEnabled = true,
	repository: ToweringRepository,
	toweringListPrice: ToweringListPrice
) => {
	const { getStructureHeights } = repository
	const { corporateGroupId, colocationPositionId, destinationLatitude, destinationLongitude, structureTypeId } =
		toweringListPrice
	return useQuery(
		[
			`towering-structure-heights-query`,
			`corporateGroupId-${corporateGroupId}`,
			`colocationPositionId-${colocationPositionId}`,
			`structureTypeId-${structureTypeId}`,
			`destinationLatitude-${destinationLatitude}`,
			`destinationLongitude-${destinationLongitude}`,
		],
		() => getStructureHeights(toweringListPrice),
		{
			enabled: isEnabled,
			select: SelectOptionMapper,
		}
	)
}
