import { FetchAPI, FetchOptions, IAuthData, defaultOptionsFetch } from 'ufinet-web-functions'
import { CapacityRepository } from './CapacityRepository'

const url = `${process.env.REACT_APP_URL_BACK_AQS}api/product/capacity`

export const HttpCapacityRepository: (authData: IAuthData) => CapacityRepository = (authData) => {
	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		notifyError: false,
		authenticationData: authData,
		sendApiKey: true,
	}

	const getTechnologies = async () => await FetchAPI.get(url + '/technologies', fetchOptions)

	const getBandwidthTypes = async () => await FetchAPI.get(url + '/bandwithTypes', fetchOptions)

	const getTransportLayer = async () => await FetchAPI.get(url + '/transportLayer', fetchOptions)

	const getRedundance = async () => await FetchAPI.get(url + '/redundancies', fetchOptions)

	const getTransmissionMean = async () => await FetchAPI.get(url + '/transmissionMeans', fetchOptions)

	const getStandard = async () => await FetchAPI.get(url + '/standardTypes', fetchOptions)

	const getSubstandard = async () => await FetchAPI.get(url + '/standardSubtypes', fetchOptions)

	return {
		getTechnologies,
		getBandwidthTypes,
		getTransportLayer,
		getRedundance,
		getTransmissionMean,
		getStandard,
		getSubstandard,
	}
}
