import { FormikValues } from 'formik'
import { NewServiceForm } from 'pages/quotation/individual/components/NewServiceForm'
import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import { UfinetModal } from 'ufinet-web-components'
import {
	ILocalService,
	IServicesRepository,
	ProcessedService,
	ServiceLocalState,
	ServiceServerStatus,
	onFormikChanges,
	populateServiceFromCalculateResponse,
	serviceCalculateRequestFromServiceModel,
	serviceProductFromStateService,
	silentFailureOptionsFetch,
	useInternalUser,
	useTranslator,
} from 'ufinet-web-functions'
import { v4 as uuidv4 } from 'uuid'

type NewServiceModalProps = {
	aqsId: string
	parentAqsId?: string
	formik: FormikValues
	isOpen: boolean
	hideModal: () => void
	getServices: () => ProcessedService[]
	serviceService: IServicesRepository
}

export const NewServiceModal = ({
	aqsId,
	parentAqsId,
	formik,
	isOpen,
	hideModal,
	getServices,
	serviceService,
}: NewServiceModalProps) => {
	const intl = useIntl()
	const translate = useTranslator()
	const internalUser = useInternalUser()
	const onChange = useCallback(onFormikChanges, [])

	// Calculate single service
	const postService = (newService: ILocalService) => {
		const req = serviceCalculateRequestFromServiceModel(aqsId, newService, Boolean(parentAqsId))

		serviceService
			.calculateService(req, silentFailureOptionsFetch)
			.then((response) => {
				// Complete/overwrite service with server data
				const serverService: ProcessedService = {
					...populateServiceFromCalculateResponse(newService, response, intl),
					state: ServiceLocalState.Computed,
				}
				const currentServices = [...getServices(), newService]
				// Find the corresponding service and complete it in state
				const serviceIndex = currentServices.findIndex((service) => service.temporaryId === serverService.temporaryId)
				if (serviceIndex !== -1) {
					currentServices.splice(serviceIndex, 1, serverService)
					onChange(formik, `services[${serviceIndex}]`)(serverService)
					if (serverService.status === ServiceServerStatus.CALCULATED) {
						if (!serverService.statusDetails) {
							toast.success(translate('SERVICE.CALCULATE.CALCULATED'))
						} else {
							toast.warn(translate('SERVICE.CALCULATE.WARNING'))
						}
					} else {
						if (internalUser) toast.warn(translate('SERVICE.CALCULATE.WARNING'))
					}
				} else {
					console.warn(`Could not update service ${newService} with server response`)
				}
				return response
			})
			.catch((err) => {
				console.error(err)
				// Remove from state
				const currentServices = [...getServices(), newService]
				onChange(formik, 'services')(currentServices.filter((s) => s.temporaryId !== newService.temporaryId))
				toast.error(translate('SERVICE.CALCULATE.ERROR'))
			})
	}

	const setNewService = (newServiceData: ILocalService, closeModal: boolean) => {
		const currentServices: ILocalService[] = getServices()
		const newService: ILocalService = {
			...newServiceData,
			state: ServiceLocalState.Created,
			temporaryId: newServiceData.temporaryId || uuidv4(),
			countrySelect: { ...formik.values.countrySelect },
			corporateGroupSelect: { ...formik.values.corporateGroupSelect },
			clientSelect: { ...formik.values.clientSelect },
			contactSelect: { ...formik.values.contactSelect },
			pointNumber: currentServices.length,
		}
		newService.product = serviceProductFromStateService(newService)

		// Update UI with the data we already have
		currentServices.push(newService)
		onChange(formik, 'services')(currentServices)
		if (closeModal) hideModal()

		// Request calculation in server
		postService(newService)
	}

	return (
		<UfinetModal
			size="xxl"
			show={isOpen}
			handleClose={hideModal}
			title={translate('SERVICE.NEW')}
			preventCloseOnEscapeKey
		>
			<NewServiceForm
				setNewService={setNewService}
				corporateGroupId={formik.values.corporateGroupSelect.value}
				country={formik.values.countrySelect}
				clientId={formik.values.clientSelect.value}
			/>
		</UfinetModal>
	)
}
