import { MapWrapper } from 'components/google-maps/wrapper/MapWrapper'
import React from 'react'
import { WizardButtonNext, WizardButtonPrev, WizardButtonsWrapper } from 'ufinet-web-components'
import { IAreaModal } from 'ufinet-web-functions'
import { AreaPreviewTable } from './AreaPreviewTable'

type Props = {
	data: IAreaModal
	wizardRef: any
}
const AreaPreview: React.FC<Props> = ({ data, wizardRef }: Props) => {
	return (
		<div className="h-100 p-15">
			<AreaPreviewTable data={data} />
			<MapWrapper kmzUrls={data.storageUrls} centerOnOpen={false} additionalStyles={{ height: '600px' }} />
			<WizardButtonsWrapper>
				<WizardButtonPrev wizardRef={wizardRef} />
				<WizardButtonNext wizardRef={wizardRef} />
			</WizardButtonsWrapper>
		</div>
	)
}

export { AreaPreview }
