import { FormFields, FormFieldType } from 'modules/common/domain/model/FormFields'
import { Access, OverSubscription, Redundancie, TransmissionMean } from 'modules/product/capacity/domain'
import { useRedundanceQuery, useTransmissionMeanQuery } from 'modules/product/queries/CapacityQueries'
import { useAccessQuery, useSubscriptionQuery } from 'modules/product/queries/InternetQueries'
import { CapacityRepository } from 'modules/product/repository/CapacityRepository'
import { InternetRepository } from 'modules/product/repository/InternetRepository'
import { useGetPermission } from 'modules/security/useGetPermission'
import { useCallback, useMemo } from 'react'
import { AqsModules, onFormikChanges, useTranslator } from 'ufinet-web-functions'

export const useInternetServiceForm = (
	capacityRepository: CapacityRepository,
	internetRepository: InternetRepository
) => {
	const { data: redundanceSelectOption, isLoading: isLoadingRedundance } = useRedundanceQuery(capacityRepository)
	const { data: transmissionMeanSelectOption, isLoading: isLoadingTransmissionMean } =
		useTransmissionMeanQuery(capacityRepository)
	const { data: accessSelectOption, isLoading: isLoadingAccess } = useAccessQuery(internetRepository)
	const { data: oversubscriptionSelectOption, isLoading: isLoadingOversubscription } =
		useSubscriptionQuery(internetRepository)

	const canWritePrefeasibility = useGetPermission(AqsModules.PREFEASIBILITY, 'canWrite')

	const formFields: FormFields = useMemo(() => {
		const result: FormFields = {
			redundancy: {
				text: 'REDUNDANCY',
				formActions: 'redundancySelect',
				isLoading: isLoadingRedundance,
				options: redundanceSelectOption,
				defaultValue: Redundancie.Lineal,
				isDisabled: !canWritePrefeasibility,
				inputType: FormFieldType.SELECT,
			},
			transmission: {
				text: 'TRANSMISSION',
				formActions: 'transmissionSelect',
				isLoading: isLoadingTransmissionMean,
				options: transmissionMeanSelectOption,
				defaultValue: TransmissionMean.OpticalFiber,
				isDisabled: !canWritePrefeasibility,
				inputType: FormFieldType.SELECT,
			},
			access: {
				text: 'ACCESS',
				formActions: 'accessSelect',
				isLoading: isLoadingAccess,
				options: accessSelectOption,
				defaultValue: Access.DIA,
				inputType: FormFieldType.SELECT,
			},
			subscription: {
				text: 'SUBSCRIPTION',
				formActions: 'oversubscriptionSelect',
				isLoading: isLoadingOversubscription,
				options: oversubscriptionSelectOption,
				defaultValue: OverSubscription._1_01,
				inputType: FormFieldType.SELECT,
			},
		}

		return result
	}, [
		redundanceSelectOption,
		transmissionMeanSelectOption,
		accessSelectOption,
		oversubscriptionSelectOption,
		isLoadingRedundance,
		isLoadingTransmissionMean,
		isLoadingAccess,
		isLoadingOversubscription,
		canWritePrefeasibility,
	])
	const translate = useTranslator()

	const onChange = useCallback(onFormikChanges, [
		redundanceSelectOption,
		transmissionMeanSelectOption,
		accessSelectOption,
		oversubscriptionSelectOption,
	])
	return {
		formFields,
		onChange,
		translate,
	}
}
