import { SelectOptionMapper } from 'modules/common/domain/mapper/SelectOptionMapper'
import { useQuery } from 'react-query'
import { InternetRepository } from '../repository/InternetRepository'

export const useAccessQuery = (internetRepository: InternetRepository, isEnabled = true) => {
	const { getAccess } = internetRepository
	return useQuery('access-query', () => getAccess(), {
		enabled: isEnabled,
		select: SelectOptionMapper,
	})
}

export const useSubscriptionQuery = (internetRepository: InternetRepository, isEnabled = true) => {
	const { getSubscriptions } = internetRepository
	return useQuery('subscriptions-query', () => getSubscriptions(), {
		enabled: isEnabled,
		select: SelectOptionMapper,
	})
}
