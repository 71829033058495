/* eslint-disable react/jsx-no-target-blank */
import { FC, useContext, useState } from 'react'
import { useIntl } from 'react-intl'

import {
	PATH_AREAS,
	PATH_BUILDING_ACCESS,
	PATH_CABLE_BOX,
	PATH_COSTS_BUILDING_ACCESS,
	PATH_COSTS_COUNTRY_PARAMS,
	PATH_COSTS_ECONOMIC_MODEL,
	PATH_COSTS_EQUIPMENT_CONSTRUCTIONS,
	PATH_COSTS_INFRASTRUCTURE,
	PATH_COUNTRY_PARAMS,
	PATH_ECONOMIC_MODEL,
	PATH_EQUIPMENT_CONSTRUCTIONS,
	PATH_INFRASTRUCTURE,
	PATH_LOAD_KMZ_AREAS,
	PATH_LOAD_KMZ_CABLE_BOX,
	PATH_QUOTATION_INDIVIDUAL,
	PATH_QUOTATION_MASSIVE,
	PATH_QUOTATION_PENDING,
	routerNameMap,
} from 'routing/protected/PrivateRoutes'

import {
	faArrowUpFromBracket,
	faCircle,
	faCircleDollarToSlot,
	faCog,
	faFileInvoiceDollar,
	IconDefinition,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation } from 'react-router-dom'
import { AsideMenuItem, AsideMenuItemWithSub } from 'ufinet-web-components'
import { AuthContext, Authority, useInternalUser } from 'ufinet-web-functions'

enum AsideMenu {
	LOAD_KMZ,
	COSTS,
	QUOTATION,
	CONFIGURATION,
	NONE,
}

interface IMenuItem {
	to: string
	title?: string
	icon?: IconDefinition | string
	hidden?: boolean
}

const AsideMenuMain: FC = () => {
	const location = useLocation()
	const intl = useIntl()
	const { userData } = useContext(AuthContext)
	const internalUser = useInternalUser()

	const [active, setActive] = useState(AsideMenu.NONE)

	const isLoadKmzActive = () => active === AsideMenu.LOAD_KMZ
	const isCostsActive = () => active === AsideMenu.COSTS
	const isQuotationActive = () => active === AsideMenu.QUOTATION
	const isConfigurationActive = () => active === AsideMenu.CONFIGURATION
	const activeMenu = (menu: AsideMenu) => () => setActive(menu)

	const roles = userData?.authorities || []
	const quotationPermissions = Authority.getQuotationPermissions(roles)
	const prefeasibilityPermissions = Authority.getAqsPrefeasibilityPermissions(roles)
	const kmzPermissions = Authority.getKmzLoadPermissions(roles)
	const costsPermissions = Authority.getCostsPermissions(roles)
	const configurationPermissions = Authority.getAqsConfigPermissions(roles)

	const menuQuotationItems: IMenuItem[] = [
		{
			to: PATH_QUOTATION_INDIVIDUAL,
			title: routerNameMap.get(PATH_QUOTATION_INDIVIDUAL),
			icon: faCircle,
			hidden: !quotationPermissions.canRead,
		},
		{
			to: PATH_QUOTATION_PENDING,
			title: routerNameMap.get(PATH_QUOTATION_PENDING),
			icon: faCircle,
			hidden: !internalUser || !quotationPermissions.canRead || !prefeasibilityPermissions.canRead,
		},
		{
			to: PATH_QUOTATION_MASSIVE,
			title: routerNameMap.get(PATH_QUOTATION_MASSIVE),
			icon: faCircle,
			hidden: !quotationPermissions.canRead,
		},
	]

	const menuLoadKMZItems: IMenuItem[] = [
		{ to: PATH_LOAD_KMZ_CABLE_BOX, title: routerNameMap.get(PATH_CABLE_BOX), icon: faCircle },
		{ to: PATH_LOAD_KMZ_AREAS, title: routerNameMap.get(PATH_AREAS), icon: faCircle },
	].map((it) => ({ ...it, hidden: !kmzPermissions.canRead }))

	const menuCostsItems: IMenuItem[] = [
		{ to: PATH_COSTS_EQUIPMENT_CONSTRUCTIONS, title: routerNameMap.get(PATH_EQUIPMENT_CONSTRUCTIONS), icon: faCircle },
		{ to: PATH_COSTS_BUILDING_ACCESS, title: routerNameMap.get(PATH_BUILDING_ACCESS), icon: faCircle },
		{ to: PATH_COSTS_INFRASTRUCTURE, title: routerNameMap.get(PATH_INFRASTRUCTURE), icon: faCircle },
		{ to: PATH_COSTS_ECONOMIC_MODEL, title: routerNameMap.get(PATH_ECONOMIC_MODEL), icon: faCircle },
		{ to: PATH_COSTS_COUNTRY_PARAMS, title: routerNameMap.get(PATH_COUNTRY_PARAMS), icon: faCircle },
	].map((it) => ({ ...it, hidden: !costsPermissions.canRead }))

	const menuConfigItems: IMenuItem[] = [].map((it: any) => ({ ...it, hidden: !configurationPermissions.canRead }))

	return (
		<>
			{quotationPermissions.canRead && (
				<AsideMenuItemWithSub
					icon={<FontAwesomeIcon icon={faFileInvoiceDollar} className="fs-2x" />}
					title={intl.formatMessage({ id: 'MENU.QUOTATION' })}
					active={isQuotationActive()}
				>
					{menuQuotationItems
						.filter((item) => !item.hidden)
						.map((item, _index) => (
							<AsideMenuItem
								routerLocation={location}
								key={item.to}
								to={item.to}
								icon={item.icon}
								title={intl.formatMessage({ id: item.title })}
								onClick={activeMenu(AsideMenu.QUOTATION)}
							/>
						))}
				</AsideMenuItemWithSub>
			)}

			{kmzPermissions.canRead && (
				<AsideMenuItemWithSub
					icon={<FontAwesomeIcon icon={faArrowUpFromBracket} className="fs-2x" />}
					title={intl.formatMessage({ id: 'MENU.LOAD_KMZ' })}
					active={isLoadKmzActive()}
				>
					{menuLoadKMZItems
						.filter((item) => !item.hidden)
						.map((item, index) => (
							<AsideMenuItem
								routerLocation={location}
								key={index}
								to={item.to}
								icon={item.icon}
								title={intl.formatMessage({ id: item.title })}
								onClick={activeMenu(AsideMenu.LOAD_KMZ)}
							/>
						))}
				</AsideMenuItemWithSub>
			)}

			{costsPermissions.canRead && (
				<AsideMenuItemWithSub
					icon={<FontAwesomeIcon icon={faCircleDollarToSlot} className="fs-2x" />}
					title={intl.formatMessage({ id: 'MENU.COSTS' })}
					active={isCostsActive()}
				>
					{menuCostsItems
						.filter((item) => !item.hidden)
						.map((item, index) => (
							<AsideMenuItem
								routerLocation={location}
								key={index}
								to={item.to}
								icon={item.icon}
								title={intl.formatMessage({ id: item.title })}
								onClick={activeMenu(AsideMenu.COSTS)}
							/>
						))}
				</AsideMenuItemWithSub>
			)}

			{configurationPermissions.canRead && menuConfigItems.length > 0 && (
				<AsideMenuItemWithSub
					icon={<FontAwesomeIcon icon={faCog} className="fs-2x" />}
					title={intl.formatMessage({ id: 'MENU.CONFIG' })}
					active={isConfigurationActive()}
				>
					{menuConfigItems
						.filter((item) => !item.hidden)
						.map((item, index) => (
							<AsideMenuItem
								routerLocation={location}
								key={index}
								to={item.to}
								icon={item.icon}
								title={intl.formatMessage({ id: item.title })}
								onClick={activeMenu(AsideMenu.CONFIGURATION)}
							/>
						))}
				</AsideMenuItemWithSub>
			)}
		</>
	)
}

export { AsideMenuMain }
