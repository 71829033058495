import { faSave } from '@fortawesome/free-solid-svg-icons'
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import { useFormik } from 'formik'
import { FC, memo, useCallback, useContext, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import {
	ClientSelectHandle,
	CorporateGroupSelectHandle,
	Filter,
	IUfinetSelectOption,
	UfinetActionButton,
	UfinetActionButtonHandle,
	UfinetButton,
	UfinetDropzone,
	emptyUfinetSelectOption,
} from 'ufinet-web-components'
import {
	AuthContext,
	IBulkQuotationXlsDownloadRequest,
	IBulkQuotationXlsImportRequest,
	IBulkQuotationXlsImportResponse,
	IFileUploadData,
	UfinetErrorCode,
	bulkQuotationXlsService,
	fillOnChangeCountry,
	fillOnClientChange,
	fillOnCorporateGroupChange,
	onFormikChanges,
	useInternalUser,
	useTranslator,
} from 'ufinet-web-functions'

import * as Yup from 'yup'

type BulkModalBodyProps = {
	afterSubmit?: (values: IBulkModalFormData) => void
	onFileSubmitted?: (res: IBulkQuotationXlsImportResponse) => void
}

interface IBulkModalFormData {
	countrySelect: IUfinetSelectOption
	corporateGroupSelect: IUfinetSelectOption
	clientSelect: IUfinetSelectOption
	file: IFileUploadData
	finalClient: string | undefined
}

const BulkModalBody: FC<BulkModalBodyProps> = memo(({ afterSubmit, onFileSubmitted }) => {
	const intl = useIntl()
	const authData = useContext(AuthContext)
	const internalUser = useInternalUser()
	const translate = useTranslator()

	const actionButtonRef = useRef<UfinetActionButtonHandle>(null)

	const clientRef = useRef<ClientSelectHandle>(null)
	const corporateGroupRef = useRef<CorporateGroupSelectHandle>(null)

	const _bulkQuotationXlsService = useMemo(() => bulkQuotationXlsService(authData, intl), [authData, intl])

	const [downloading, setDownloading] = useState(false)

	const onChange = useCallback(onFormikChanges, [])

	const dataFormSchema = Yup.object().shape({
		countrySelect: Yup.object().shape({
			label: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
		}),
		corporateGroupSelect: Yup.object().shape({
			label: internalUser ? Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })) : Yup.string(),
		}),
		clientSelect: Yup.object().shape({
			label: internalUser ? Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })) : Yup.string(),
		}),
		reference: Yup.string().notRequired(),
		finalClient: Yup.string().notRequired(),
		file: Yup.mixed()
			.test('file required', intl.formatMessage({ id: 'ERROR.REQUIRED' }), (value) => value.size > 0)
			.test('file type', intl.formatMessage({ id: 'ERROR.FILE.EXTENSION' }), (value) => {
				const fileExtension = value?.path?.split('.').pop()
				return fileExtension === undefined || fileExtension.includes(['xlsx']) || fileExtension.includes(['xls'])
			}),
	})

	const onCountryChange = (country: IUfinetSelectOption) => {
		fillOnChangeCountry(formik, country)
		country && corporateGroupRef.current?.fillByCountries([country.value])
	}
	const onCorporateGroupChange = (cg: IUfinetSelectOption) => {
		fillOnCorporateGroupChange(formik, cg)
		cg && clientRef.current?.fillSelect([formik.values.countrySelect.value], [cg.value])
	}
	const onClientChange = (client: IUfinetSelectOption) => {
		fillOnClientChange(formik, client)
	}

	const downloadTemplate = () => {
		setDownloading(true)

		const req: IBulkQuotationXlsDownloadRequest = { clientId: formik.values.clientSelect.value }
		_bulkQuotationXlsService
			.download(req, formik.values.clientSelect.label)
			.catch(console.error)
			.finally(() => {
				setDownloading(false)
			})
	}

	const uploadExcel = (values: IBulkModalFormData) => {
		actionButtonRef.current?.changeActionStatus(true)

		const req: IBulkQuotationXlsImportRequest = {
			countryId: values.countrySelect.value,
			clientId: values.clientSelect.value,
			corporateGroupId: values.corporateGroupSelect.value,
			// @ts-expect-error accept partial file
			file: values.file,
			finalClient: values.finalClient,
		}

		_bulkQuotationXlsService
			.upload(req, { notifyError: false })
			.then((res) => onFileSubmitted && onFileSubmitted(res))
			.catch((e) => {
				if (e.ufinetErrorCode === UfinetErrorCode.QUOTATION_POINTS_EXCEEDS_LENGTH) {
					toast.error(
						translate('UPLOAD_EXCEL.MAX_QUOTATION_POINTS_ERROR', { maxQuotationPoints: e.maxQuotationPoints })
					)
				} else {
					toast.warn(e.message ? e.message : e.errors[0])
				}
			})
			.finally(() => {
				actionButtonRef.current?.changeActionStatus(false)
			})
	}

	const formik = useFormik<IBulkModalFormData>({
		initialValues: {
			countrySelect: emptyUfinetSelectOption,
			corporateGroupSelect: emptyUfinetSelectOption,
			clientSelect: emptyUfinetSelectOption,
			file: {
				path: '',
				size: 0,
			},
			finalClient: '',
		},
		validationSchema: dataFormSchema,
		onSubmit: (values: IBulkModalFormData) => {
			uploadExcel(values)
			afterSubmit && afterSubmit(values)
		},
		validateOnChange: false,
		validateOnBlur: false,
		enableReinitialize: true,
	})

	return (
		<form onSubmit={formik.handleSubmit} className="d-flex flex-column justify-content-center p-0 m-0">
			<div className="row">
				<div className="col">
					<Filter
						internalUser={internalUser}
						afterCountryChange={(country) => onCountryChange(country as IUfinetSelectOption)}
						afterCorporateGroupChange={(cg) => onCorporateGroupChange(cg as IUfinetSelectOption)}
						afterClientChange={(client) => onClientChange(client as IUfinetSelectOption)}
						afterFinalClientChange={(finalClient) => onChange(formik, 'finalClient')(finalClient)}
						disabled={false}
						hidden={{
							hideContact: true,
							hideReference: true,
							hideFinalClient: false,
							hideSubmit: true,
						}}
						required={{ requiredCountry: true, requiredCorporateGroup: true, requiredClient: true }}
						multi={false}
						preSelected={false}
						setFilter={() => {}}
					/>
				</div>
			</div>
			<div className="row py-10">
				<div className="col-12">
					<UfinetButton
						isDisabled={!formik.values.clientSelect.value || downloading}
						onClick={downloadTemplate}
						className="w-100"
						content={intl.formatMessage({ id: 'DOWNLOAD.TEMPLATE' })}
						icon={faDownload}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<UfinetDropzone
						isDisabled={!formik.values.clientSelect.value}
						onChange={onChange(formik, 'file')}
						value={formik.values.file}
						error={formik.errors.file}
						text={intl.formatMessage({ id: 'DROPZONE.UPLOAD_EXCEL.TITLE' })}
						accept={{ 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx', '.xls'] }}
					/>
				</div>
			</div>
			<div className="row">
				<UfinetActionButton
					ref={actionButtonRef}
					className="mt-10"
					content={intl.formatMessage({ id: 'SAVE' })}
					icon={faSave}
				/>
			</div>
		</form>
	)
})

export { BulkModalBody }
