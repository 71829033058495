import { clsx } from 'clsx'
import { MapWrapper } from 'components/google-maps/wrapper/MapWrapper'
import { defaultMapWrapperOptions } from 'components/google-maps/wrapper/MapWrapperOptions'
import { FormikValues } from 'formik'
import { useMemo } from 'react'
import { Collapse } from 'react-bootstrap'
import { isValidCoordinate } from 'ufinet-web-functions'
import { useMediaQuery } from 'usehooks-ts'
import { useCoordinatesSelectorMapActions, useCoordinatesSelectorMapState } from './CoordinatesSelectorContext'

type CoordinatesMapSelectorProps = {
	formik: FormikValues
	countryName: string
}

export const CoordinatesMapSelector = ({ countryName, formik }: CoordinatesMapSelectorProps) => {
	const { onMapInfoWindowClose, onMapClick, onMapUnfolded } = useCoordinatesSelectorMapActions()
	const { mapWrapperRef, mapStyles, mapMarkers, mapPolygons, mapShown } = useCoordinatesSelectorMapState()

	const alignMapVertically = useMediaQuery('(max-width: 1200px)')
	const alignMapHorizontally = useMemo(() => !alignMapVertically, [alignMapVertically])

	const mapOptions = useMemo(() => ({ ...defaultMapWrapperOptions, center: countryName }), [countryName])

	return (
		<div className={clsx('col-12 col-xl-5', 'mt-10 mt-xl-0')}>
			<Collapse
				in={
					alignMapHorizontally ||
					mapShown ||
					!!isValidCoordinate({ lat: formik.values.originLatitude, lng: formik.values.originLongitude }) ||
					!!isValidCoordinate({
						lat: formik.values.destinationLatitude,
						lng: formik.values.destinationLongitude,
					})
				}
				onEntered={onMapUnfolded}
				className={clsx(alignMapHorizontally && 'h-100 w-100')}
			>
				<div className={clsx('row')}>
					<MapWrapper
						ref={mapWrapperRef}
						additionalStyles={mapStyles}
						mapOptions={mapOptions}
						markers={mapMarkers}
						polygons={mapPolygons}
						onClick={onMapClick}
						onInfoWindowClose={onMapInfoWindowClose}
					/>
				</div>
			</Collapse>
		</div>
	)
}
