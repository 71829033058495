export const getCopyElement: (content: string, options?: { tooltip?: string }) => string = (
	content,
	{ tooltip } = {}
) => {
	return `<div class="d-flex align-items-center gap-2">
	<div>${content}</div>
	<img src="/media/icons/duotune/general/gen054.svg" class="btn-hoverable" onclick="navigator.clipboard.writeText('${content}')" title="${
		tooltip || ''
	}">
</div>`
}
