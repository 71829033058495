import { FetchAPI, IAuthData, defaultOptionsFetch } from 'ufinet-web-functions'
import { DarkFiberListPrice } from '../queries/DarkFiberQueries'
import { DarkFiberRepository } from './DarkFiberRepository'

const url = `${process.env.REACT_APP_URL_BACK_AQS}api/product/darkFiber`

export const HttpDarkFiberRepository: (authData: IAuthData) => DarkFiberRepository = (authData) => {
	const fetchOptions = {
		...defaultOptionsFetch,
		notifyError: false,
		authenticationData: authData,
		sendApiKey: true,
	}

	const getFiberNumber = async (darkFiberListPrice: DarkFiberListPrice) => {
		const { origin, destination, corporateGroupId } = darkFiberListPrice
		return FetchAPI.get(
			url +
				`/fiberNumber?corporateGroupId=${corporateGroupId}
				&originLongitude=${origin?.longitude}&originLatitude=${origin?.latitude}
				&destinationLongitude=${destination?.longitude}&destinationLatitude=${destination?.latitude}`,
			fetchOptions
		)
	}

	return {
		getFiberNumber,
	}
}
