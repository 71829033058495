import { FC, useCallback, useContext, useMemo, useRef, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import Swal from 'sweetalert2'

import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NewBuildingAccessModalBody } from 'pages/costs/modals/NewBuildingAccessModalBody'
import { colsBuildingAccess } from './common/CostsColData'
import { UploadExcelModalBody } from './modals/excel/UploadExcelModalBody'

import { deepEqual } from 'fast-equals'

import { toast } from 'react-toastify'
import {
	colorConstants,
	HardFilterWrapper,
	HardFilterWrapperHandle,
	MultipleDeleter,
	Table,
	UfinetActionButtonHandle,
	UfinetBox,
	UfinetButton,
	UfinetModal,
	UfinetSectionBox,
} from 'ufinet-web-components'
import {
	AreaType,
	AuthContext,
	Authority,
	buildingAccessCostsService,
	getTableTranslations,
	ICostsBuildingAccessResponse,
	ICostsQueryCriteria,
	onSelectionChangePreventDefaultsRemoval,
	useAsync,
	useModal,
} from 'ufinet-web-functions'

const BuildingAccessPage: FC = () => {
	const intl = useIntl()
	const searchButtonRef = useRef<UfinetActionButtonHandle>()
	const [buildingAccessResponse, setBuildingAccessResponse] = useState<ICostsBuildingAccessResponse[]>([])
	const [modal, showModal, hideModal] = useModal()
	const [newModal, showNewModal, hideNewModal] = useModal()
	const [selectedValues, setSelectedValues] = useState<ICostsBuildingAccessResponse[]>()
	const [loadingRecords, setLoadingRecords] = useState<boolean>(false)
	const authData = useContext(AuthContext)
	const roles = authData.userData?.authorities || []
	const permissions = Authority.getCostsPermissions(roles)
	const _buildingAccessCostsService = useMemo(() => buildingAccessCostsService(authData, intl), [authData, intl])

	const upperFilterRef = useRef<HardFilterWrapperHandle>(null)

	const getBuildingAccessCosts = useCallback(async () => {
		setLoadingRecords(true)
		searchButtonRef.current?.changeActionStatus(true)

		const criteria: ICostsQueryCriteria = {
			countries: upperFilterRef.current?.getCountries().map((e) => e.value) || [],
			networkAreas: upperFilterRef.current?.getAreas().map((e) => e.value) || [],
		}
		return await _buildingAccessCostsService.findByCriteria(criteria)
	}, [_buildingAccessCostsService])

	const onBuildingAccessFetched = (costs: ICostsBuildingAccessResponse[]): void => {
		const parsedCosts = costs.map((cost: ICostsBuildingAccessResponse) => ({
			...cost,
			networkAreaName: getTableTranslations(cost.networkAreaName, intl),
		}))
		setBuildingAccessResponse(parsedCosts)
	}

	const onBuildingAccessFailedToFetch = (): void => {
		toast.error(intl.formatMessage({ id: 'COSTS.FETCH.ERROR' }))
	}

	const runFinally = () => {
		setLoadingRecords(false)
		searchButtonRef.current?.changeActionStatus(false)
	}

	const fetchAndStore = useAsync(
		{
			asyncFn: getBuildingAccessCosts,
			onSuccess: onBuildingAccessFetched,
			onFailure: onBuildingAccessFailedToFetch,
			runFinally: runFinally,
		},
		[]
	)

	const getActionButtons = () => {
		return (
			<>
				<OverlayTrigger
					placement="bottom"
					delay={{ show: 250, hide: 400 }}
					overlay={<Tooltip className="tooltip-dark">{intl.formatMessage({ id: 'DOWNLOAD_EXCEL' })}</Tooltip>}
				>
					<div className="d-inline">
						<FontAwesomeIcon
							className="fa-icon-primary"
							size="1x"
							icon={faDownload}
							onClick={() => {
								_buildingAccessCostsService.downloadXLS({
									countries: upperFilterRef.current?.getCountries().map((e) => e.value) || [],
									networkAreas: upperFilterRef.current?.getAreas().map((e) => e.value) || [],
								})
							}}
						/>
					</div>
				</OverlayTrigger>
			</>
		)
	}

	const getHeaderButtons = () => (
		<>
			{permissions.canDelete && (
				<MultipleDeleter
					setSelectedValues={setSelectedValues}
					selectedValues={selectedValues}
					deleteEndpoint={_buildingAccessCostsService.deleteByIdsList}
					search={fetchAndStore}
				/>
			)}
			{permissions.canWrite && (
				<>
					<UfinetButton
						className="me-3"
						icon={faPlus}
						onClick={() => {
							showNewModal()
						}}
						content={intl.formatMessage({ id: 'NEW.REGISTER' })}
					/>
					<UfinetButton
						icon={faUpload}
						onClick={() => {
							showModal()
						}}
						content={intl.formatMessage({ id: 'BULK_LOAD' })}
					/>
				</>
			)}
		</>
	)

	const onRowEditComplete = (data: ICostsBuildingAccessResponse) => {
		if (
			deepEqual(
				data,
				buildingAccessResponse.find((it) => it.id === data.id)
			)
		)
			return

		Swal.fire({
			icon: 'warning',
			title: intl.formatMessage({ id: 'COUNTRY.REGISTRY.UPDATE' }, { countryName: data.countryName }),
			html: intl.formatMessage({ id: 'COUNTRY.REGISTRY.UPDATE.CONFIRM' }, { networkAreaName: data.networkAreaName }),
			showCancelButton: true,
			showConfirmButton: true,
			reverseButtons: true,
			confirmButtonColor: colorConstants.primaryColor,
			cancelButtonColor: colorConstants.dangerColor,
			preConfirm: () => {
				const {
					id,
					countryName,
					networkAreaName,
					punctualCost,
					monthlyCost,
					monthlyCostPerCapacity,
					clientId,
					ceConnect,
					distanceFo,
				} = data
				_buildingAccessCostsService
					.update(id, {
						countryName,
						networkAreaName,
						punctualCost,
						monthlyCost,
						monthlyCostPerCapacity,
						clientId,
						ceConnect,
						distanceFo,
					})
					.then(fetchAndStore)
					.catch(console.error)
			},
		})
	}

	const onSelectionChange = (values: ICostsBuildingAccessResponse[]) =>
		onSelectionChangePreventDefaultsRemoval(
			values,
			selectedValues,
			buildingAccessResponse,
			setSelectedValues,
			intl.formatMessage({ id: 'BY_DEFAULT.DELETE.WARNING' })
		)

	/// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	/// //////////////////////////////////// EQUIPMENT AND CONSTRUCTION PAGE BODY ////////////////////////////////////////
	/// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	return (
		<UfinetBox>
			<HardFilterWrapper
				ref={upperFilterRef}
				actionButtonRef={searchButtonRef}
				search={fetchAndStore}
				networkAreaType={AreaType.ACCESS_AREA}
				actionButtons={getActionButtons()}
			/>
			<UfinetSectionBox>
				<Table
					selectedValues={selectedValues}
					onSelectionChange={permissions.canDelete ? onSelectionChange : undefined}
					onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
					cols={colsBuildingAccess}
					content={buildingAccessResponse}
					headerButtons={getHeaderButtons()}
					emptyMessage={loadingRecords ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
				/>
			</UfinetSectionBox>
			{/* Modals */}
			<UfinetModal
				size="xl"
				show={modal}
				handleClose={hideModal}
				title={intl.formatMessage({ id: 'MODAL.UPLOAD_EXCEL.TITLE' })}
			>
				<UploadExcelModalBody
					xlsEmptyDownloadCallback={_buildingAccessCostsService.downloadEmptyListXLS}
					xlsUploadCallback={_buildingAccessCostsService.uploadXLS}
					onXlsUploaded={fetchAndStore}
					hideModal={hideModal}
				/>
			</UfinetModal>
			<UfinetModal
				size="lg"
				show={newModal}
				handleClose={hideNewModal}
				title={intl.formatMessage({ id: 'MODAL.NEW.REGISTER.TITLE' })}
			>
				<NewBuildingAccessModalBody findCosts={fetchAndStore} hideModal={hideNewModal} />
			</UfinetModal>
		</UfinetBox>
	)
}
export { BuildingAccessPage }
