import { i18nMessages } from 'ufinet-web-components'
import aqsEnMessages from './en.json'
import aqsEsMessages from './es.json'
import aqsPtMessages from './pt.json'

export const aqsI18nMessages: i18nMessages = {
	en: {
		...aqsEnMessages,
	},
	es: {
		...aqsEsMessages,
	},
	pt: {
		...aqsPtMessages,
	},
}
