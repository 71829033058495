export enum PrefeasibilityStatuses {
	PENDING = 10,
	NOT_CALCULATED = 20,
	CALCULATED = 30,
	CANCELED = 40,
	FINISHED = 50,
	ERROR = 60,
}

export const PrefeasibilityStatusesLabel: {
	[key in PrefeasibilityStatuses]: string
} = {
	[PrefeasibilityStatuses.PENDING]: 'PENDING',
	[PrefeasibilityStatuses.NOT_CALCULATED]: 'NOT_CALCULATED',
	[PrefeasibilityStatuses.CALCULATED]: 'CALCULATED',
	[PrefeasibilityStatuses.CANCELED]: 'CANCELED',
	[PrefeasibilityStatuses.FINISHED]: 'FINISHED',
	[PrefeasibilityStatuses.ERROR]: 'ERROR',
}

export const getPrefeasibilityStatusFromLabel: (label: string) => PrefeasibilityStatuses | undefined = (
	label: string
) => {
	let status: PrefeasibilityStatuses | undefined
	for (const k in PrefeasibilityStatusesLabel) {
		const key = k as keyof typeof PrefeasibilityStatuses
		if (PrefeasibilityStatusesLabel[PrefeasibilityStatuses[key]] !== label) continue
		status = PrefeasibilityStatuses[key]
		break
	}
	return status
}
