import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons/faCircleExclamation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ICablesAndBoxes } from 'pages/loadKmz/common/LoadKmzTypesAndInterfaces'
import { FC, useContext } from 'react'
import { useIntl } from 'react-intl'
import Swal from 'sweetalert2'
import { WizardButtonNext, WizardButtonPrev, WizardButtonsWrapper } from 'ufinet-web-components'
import { AuthContext, kmzItemsService } from 'ufinet-web-functions'
import { PreviewSummaryTable } from './preview/PreviewSummaryTable'

type Props = {
	data: ICablesAndBoxes
	wizardRef: any
	actionButtonRef: any
}
const Confirm: FC<Props> = ({ data, wizardRef, actionButtonRef }) => {
	const intl = useIntl()

	const authData = useContext(AuthContext)
	const _kmzItemService = kmzItemsService(authData)

	const preConfirmKmz = (e: any) => {
		e.preventDefault()
		Swal.fire({
			title: intl.formatMessage({
				id: 'STEP.CABLES&BOXES.LOAD_KMZ.SWEET_ALERT.TITLE',
			}),
			icon: 'warning',
			showCancelButton: true,
			showConfirmButton: true,
			reverseButtons: true,
			confirmButtonText: intl.formatMessage({ id: 'SAVE' }),
			cancelButtonText: intl.formatMessage({ id: 'CANCEL' }),
			showLoaderOnConfirm: true,
			preConfirm: () => {
				confirmKmz()
					// eslint-disable-next-line promise/always-return
					.then(() => {
						Swal.fire({
							title: intl.formatMessage({
								id: 'STEP.CABLES&BOXES.LOAD_KMZ.SWEET_ALERT.TITLE.SUCCESS',
							}),
							text: intl.formatMessage({
								id: 'STEP.CABLES&BOXES.LOAD_KMZ.SWEET_ALERT.TEXT.SUCCESS',
							}),
							reverseButtons: true,
							showConfirmButton: true,
							confirmButtonColor: '#0065A4',
							allowOutsideClick: false,
							icon: 'success',
							preConfirm: () => {
								wizardRef.current.gotoStep(0)
							},
						})
					})
					.catch(() => {
						Swal.fire({
							title: 'Error',
							text: 'something goes wrong',
							reverseButtons: true,
							showConfirmButton: true,
							confirmButtonColor: '#0065A4',
							allowOutsideClick: false,
							icon: 'error',
							preConfirm: () => {
								wizardRef.current.gotoStep(0)
							},
						})
					})
			},
			confirmButtonColor: '#0065A4',
			cancelButtonColor: '#f64e60',
			allowOutsideClick: false,
		})
	}

	const confirmKmz = async () => {
		_kmzItemService
			.confirm({
				kmzStorageUrls: data.storageUrls,
			})
			.then((response) => {
				return response
			})
			.catch(console.error)
	}

	return (
		<form onSubmit={preConfirmKmz} className="h-100 p-15 d-flex flex-column">
			<div className="alert alert-dismissible bg-light-warning d-flex flex-center flex-column py-10 px-10 px-lg-10 mb-10">
				<FontAwesomeIcon color="#F7BB49" size="4x" icon={faCircleExclamation} />
				<div className="text-center fs-1 pt-2">
					<h1 className="fw-bolder mb-5">{intl.formatMessage({ id: 'ATTENTION' })}</h1>
					<div className="separator separator-dashed border-danger opacity-25 mb-5" />
					<div className="mb-9 text-dark">{intl.formatMessage({ id: 'STEP.CABLES&BOXES.LOAD_KMZ.TEXT' })}</div>
				</div>
			</div>
			<PreviewSummaryTable data={data} />
			<WizardButtonsWrapper>
				<WizardButtonPrev wizardRef={wizardRef} />
				<WizardButtonNext wizardRef={wizardRef} actionButtonRef={actionButtonRef} submitForm />
			</WizardButtonsWrapper>
		</form>
	)
}
export { Confirm }
