import { buildPaths } from 'modules/google-maps/functions/buildPaths'
import { AreaTypeZindex, Color, IKmzAreaFindResponse } from 'ufinet-web-functions'
import { v4 as uuidv4 } from 'uuid'
import { MapPolygon } from '../MapPolygon'
import { defaultPolygonStyleSettings, PolygonStyleSettings } from '../PolygonStyleSettings'

export const polygonOptionsFromKmzAreaResponse = (
	area: IKmzAreaFindResponse,
	style: PolygonStyleSettings | Color = defaultPolygonStyleSettings.strokeColor
): MapPolygon => {
	const paths = buildPaths(area.coordinates)
	const polygonStyle: PolygonStyleSettings =
		typeof style === 'object'
			? { ...defaultPolygonStyleSettings, ...style }
			: { ...defaultPolygonStyleSettings, strokeColor: style, fillColor: style }

	return {
		id: uuidv4(),
		options: {
			paths,
			...polygonStyle,
			zIndex: AreaTypeZindex.get(area.areaTypeId),
		},
		area,
	}
}
