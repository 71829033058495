import { faPlus, faRefresh, faTrash } from '@fortawesome/free-solid-svg-icons'

import { deepEqual } from 'fast-equals'
import { useFormik } from 'formik'

import { FC, useCallback, useContext, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import {
	CountrySelect,
	IUfinetSelectOption,
	Loading,
	UfinetActionButton,
	UfinetActionButtonHandle,
	UfinetButton,
	UfinetInput,
} from 'ufinet-web-components'
import {
	AuthContext,
	CountryParamsForm,
	FetchOptions,
	ICountryParams,
	SpecialCountries,
	compareStringIgnoreCase,
	countryParamsService,
	formInitialCountryParams,
	nanToValue,
	onFormikNumberChanges,
	useAsync,
} from 'ufinet-web-functions'

import * as Yup from 'yup'

type Props = {
	countryParamsId?: number
	onSubmitSuccess: (countryParams: Required<ICountryParams>) => void
	onFetchError?: (params: { countryParamsId?: number; error?: any }) => void
	onSubmitError?: (params: { countryParamsId?: number; error?: any }) => void
}

// Multi-purpose (create and edit)
export const CountryParamsModal: FC<Props> = ({
	countryParamsId,
	onSubmitSuccess = () => {},
	onFetchError = () => {},
	onSubmitError = () => {},
}) => {
	const intl = useIntl()
	const submitButtonRef = useRef<UfinetActionButtonHandle>(null)

	const authData = useContext(AuthContext)
	const _countryParamsService = useMemo(() => countryParamsService(authData), [authData])

	const [initialData, setInitialData] = useState<CountryParamsForm>()
	const [loading, setLoading] = useState<boolean>(false)

	const onNumberChange = useCallback(onFormikNumberChanges, [])

	const dataFormSchema = Yup.object().shape({
		id: countryParamsId ? Yup.number().required() : Yup.number().notRequired(),
		countryId: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
		countryName: Yup.string().notRequired(),
		minimalDistanceBox: Yup.number()
			.required(intl.formatMessage({ id: 'ERROR.REQUIRED' }))
			.min(0, intl.formatMessage({ id: 'ERROR.INVALID.MINIMUM' }, { min: 0 })),
		minimalDistanceCost: Yup.number()
			.required(intl.formatMessage({ id: 'ERROR.REQUIRED' }))
			.min(0, intl.formatMessage({ id: 'ERROR.INVALID.MINIMUM' }, { min: 0 })),
		maximumCapacity: Yup.number()
			.required(intl.formatMessage({ id: 'ERROR.REQUIRED' }))
			.min(0, intl.formatMessage({ id: 'ERROR.INVALID.MINIMUM' }, { min: 0 })),
		churnContract: Yup.number().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
		// .min(0, intl.formatMessage({ id: 'ERROR.INVALID.MINIMUM' }, { min: 0 })),
		priceEvolution: Yup.number().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
		// .min(0, intl.formatMessage({ id: 'ERROR.INVALID.MINIMUM' }, { min: 0 })),
		ipc: Yup.number()
			.required(intl.formatMessage({ id: 'ERROR.REQUIRED' }))
			.min(0, intl.formatMessage({ id: 'ERROR.INVALID.MINIMUM' }, { min: 0 }))
			.max(100, intl.formatMessage({ id: 'ERROR.INVALID.MAXIMUM' }, { max: 100 })),

		taxPercentageBrasil1: Yup.number()
			.transform(nanToValue)
			.when('countryName', {
				is: 'Brasil',
				then: (schema) =>
					schema
						.required(intl.formatMessage({ id: 'ERROR.REQUIRED' }))
						.min(0, intl.formatMessage({ id: 'ERROR.INVALID.MINIMUM' }, { min: 0 }))
						.max(100, intl.formatMessage({ id: 'ERROR.INVALID.MAXIMUM' }, { max: 100 })),
				otherwise: (schema) => schema.notRequired(),
			}),
		taxPercentageBrasil2: Yup.number()
			.transform(nanToValue)
			.when('countryName', {
				is: 'Brasil',
				then: (schema) =>
					schema
						.required(intl.formatMessage({ id: 'ERROR.REQUIRED' }))
						.min(0, intl.formatMessage({ id: 'ERROR.INVALID.MINIMUM' }, { min: 0 }))
						.max(100, intl.formatMessage({ id: 'ERROR.INVALID.MAXIMUM' }, { max: 100 })),
				otherwise: (schema) => schema.notRequired(),
			}),
		taxPercentageGuatemala1: Yup.number()
			.transform(nanToValue)
			.when('countryName', {
				is: 'Guatemala',
				then: (schema) =>
					schema
						.required(intl.formatMessage({ id: 'ERROR.REQUIRED' }))
						.min(0, intl.formatMessage({ id: 'ERROR.INVALID.MINIMUM' }, { min: 0 }))
						.max(100, intl.formatMessage({ id: 'ERROR.INVALID.MAXIMUM' }, { max: 100 })),
				otherwise: (schema) => schema.notRequired(),
			}),
		taxPercentageGuatemala2: Yup.number()
			.transform(nanToValue)
			.when('countryName', {
				is: 'Guatemala',
				then: (schema) =>
					schema
						.required(intl.formatMessage({ id: 'ERROR.REQUIRED' }))
						.min(0, intl.formatMessage({ id: 'ERROR.INVALID.MINIMUM' }, { min: 0 }))
						.max(100, intl.formatMessage({ id: 'ERROR.INVALID.MAXIMUM' }, { max: 100 })),
				otherwise: (schema) => schema.notRequired(),
			}),
	})

	const formik = useFormik<Partial<ICountryParams>>({
		initialValues: formInitialCountryParams,
		validationSchema: dataFormSchema,
		onSubmit: (countryParams) => {
			submitCountryParams(countryParams)
		},
		validateOnChange: false,
		validateOnBlur: false,
	})

	const getInitialCountryParams = useCallback(async (): Promise<CountryParamsForm> => {
		setLoading(true)
		if (countryParamsId) return await _countryParamsService.findById(countryParamsId)
		else return formInitialCountryParams
	}, [_countryParamsService, countryParamsId])

	const submitCountryParams = (countryParams: CountryParamsForm): void => {
		const reqOptions: FetchOptions = { notifyError: false }
		const reqData: ICountryParams = countryParams as ICountryParams
		const req = countryParamsId
			? _countryParamsService.updateCountryParams(reqData, reqOptions)
			: _countryParamsService.createCountryParams(reqData, reqOptions)

		submitButtonRef.current?.changeActionStatus(true)
		setLoading(true)

		req
			.then(() => {
				onSubmitSuccess(reqData)
			})
			.catch((error) => {
				onSubmitError({ countryParamsId, error })
			})
			.finally(() => {
				submitButtonRef.current?.changeActionStatus(false)
				setLoading(false)
			})
	}

	useAsync(
		{
			asyncFn: () => {
				return getInitialCountryParams()
			},
			onSuccess: (formData) => {
				formik.setValues(formData)
				setInitialData(formData)
			},
			onFailure: onFetchError,
			runFinally: () => setLoading(false),
		},
		[getInitialCountryParams]
	)

	return (
		<form
			onSubmit={formik.handleSubmit}
			className={`position-relative d-flex flex-column justify-content-center p-0 m-0 ${
				loading ? 'form-disabled' : ''
			}`}
		>
			<div className="row">
				<div className="row">
					<CountrySelect
						requiredIcon
						labelTitle={intl.formatMessage({ id: 'COUNTRY_PARAMS.MODAL.COUNTRY.LABEL' })}
						tooltipTitle={intl.formatMessage({ id: 'COUNTRY_PARAMS.MODAL.COUNTRY.TOOLTIP' })}
						isMulti={false}
						className="col-12"
						onChange={(selection) => {
							formik.setFieldValue('countryId', (selection as IUfinetSelectOption)?.value)
							formik.setFieldValue('countryName', (selection as IUfinetSelectOption)?.label)
						}}
						value={
							formik.values.countryName
								? { label: formik.values.countryName!, value: formik.values.countryId! }
								: undefined
						}
						error={formik.errors.countryId}
						isDisabled={!!countryParamsId}
					/>
				</div>

				{/* Special tax fields */}
				{SpecialCountries.find((it) => it.id === formik.values.countryId!) && (
					<div className="row pt-4">
						{compareStringIgnoreCase(formik.values.countryName, 'Brasil') ? (
							<>
								<UfinetInput
									requiredIcon
									className="col-6"
									error={formik.errors.taxPercentageBrasil1}
									type="decimal"
									solid={false}
									labelTitle={intl.formatMessage({ id: 'COUNTRY_PARAMS.MODAL.TAX_PERCENTAGE_BRASIL_1.LABEL' })}
									tooltipTitle={intl.formatMessage({ id: 'COUNTRY_PARAMS.MODAL.TAX_PERCENTAGE_BRASIL_1.TOOLTIP' })}
									onChange={onNumberChange(formik, 'taxPercentageBrasil1')}
									value={formik.values.taxPercentageBrasil1 ?? ''}
								/>
								<UfinetInput
									requiredIcon
									className="col-6"
									error={formik.errors.taxPercentageBrasil2}
									type="decimal"
									solid={false}
									labelTitle={intl.formatMessage({ id: 'COUNTRY_PARAMS.MODAL.TAX_PERCENTAGE_BRASIL_2.LABEL' })}
									tooltipTitle={intl.formatMessage({ id: 'COUNTRY_PARAMS.MODAL.TAX_PERCENTAGE_BRASIL_2.TOOLTIP' })}
									onChange={onNumberChange(formik, 'taxPercentageBrasil2')}
									value={formik.values.taxPercentageBrasil2 ?? ''}
								/>
							</>
						) : compareStringIgnoreCase(formik.values.countryName, 'Guatemala') ? (
							<>
								<UfinetInput
									requiredIcon
									className="col-12 col-sm-6 col-md-3"
									error={formik.errors.taxPercentageGuatemala1}
									type="decimal"
									solid={false}
									labelTitle={intl.formatMessage({ id: 'COUNTRY_PARAMS.MODAL.TAX_PERCENTAGE_GUATEMALA_1.LABEL' })}
									tooltipTitle={intl.formatMessage({ id: 'COUNTRY_PARAMS.MODAL.TAX_PERCENTAGE_GUATEMALA_1.TOOLTIP' })}
									onChange={onNumberChange(formik, 'taxPercentageGuatemala1')}
									value={formik.values.taxPercentageGuatemala1 ?? ''}
								/>
								<UfinetInput
									requiredIcon
									className="col-12 col-sm-6 col-md-3"
									error={formik.errors.taxPercentageGuatemala2}
									type="decimal"
									solid={false}
									labelTitle={intl.formatMessage({ id: 'COUNTRY_PARAMS.MODAL.TAX_PERCENTAGE_GUATEMALA_2.LABEL' })}
									tooltipTitle={intl.formatMessage({ id: 'COUNTRY_PARAMS.MODAL.TAX_PERCENTAGE_GUATEMALA_2.TOOLTIP' })}
									onChange={onNumberChange(formik, 'taxPercentageGuatemala2')}
									value={formik.values.taxPercentageGuatemala2 ?? ''}
								/>
							</>
						) : (
							<></>
						)}
					</div>
				)}

				<div className="row pt-4">
					<UfinetInput
						requiredIcon
						className="col-4"
						error={formik.errors.minimalDistanceBox}
						type="decimal"
						solid={false}
						labelTitle={intl.formatMessage({ id: 'COUNTRY_PARAMS.MODAL.MIN_DISTANCE_BOX.LABEL' })}
						tooltipTitle={intl.formatMessage({ id: 'COUNTRY_PARAMS.MODAL.MIN_DISTANCE_BOX.TOOLTIP' })}
						onChange={onNumberChange(formik, 'minimalDistanceBox')}
						value={formik.values.minimalDistanceBox ?? ''}
					/>
					<UfinetInput
						requiredIcon
						className="col-4"
						error={formik.errors.minimalDistanceCost}
						type="decimal"
						solid={false}
						labelTitle={intl.formatMessage({ id: 'COUNTRY_PARAMS.MODAL.MIN_DISTANCE_COST.LABEL' })}
						tooltipTitle={intl.formatMessage({ id: 'COUNTRY_PARAMS.MODAL.MIN_DISTANCE_COST.TOOLTIP' })}
						onChange={onNumberChange(formik, 'minimalDistanceCost')}
						value={formik.values.minimalDistanceCost ?? ''}
					/>

					<UfinetInput
						requiredIcon
						className="col-4"
						error={formik.errors.maximumCapacity}
						type="decimal"
						solid={false}
						labelTitle={intl.formatMessage({ id: 'COUNTRY_PARAMS.MODAL.MAX_CAPACITY.LABEL' })}
						tooltipTitle={intl.formatMessage({ id: 'COUNTRY_PARAMS.MODAL.MAX_CAPACITY.TOOLTIP' })}
						onChange={onNumberChange(formik, 'maximumCapacity')}
						value={formik.values.maximumCapacity ?? ''}
					/>
				</div>
				<div className="row pt-4">
					<UfinetInput
						requiredIcon
						className="col-4"
						error={formik.errors.churnContract}
						type="decimal"
						solid={false}
						labelTitle={intl.formatMessage({ id: 'COUNTRY_PARAMS.MODAL.CHURN_CONTRACT.LABEL' })}
						tooltipTitle={intl.formatMessage({ id: 'COUNTRY_PARAMS.MODAL.CHURN_CONTRACT.TOOLTIP' })}
						onChange={onNumberChange(formik, 'churnContract')}
						value={formik.values.churnContract ?? ''}
					/>

					<UfinetInput
						requiredIcon
						className="col-4"
						error={formik.errors.priceEvolution}
						type="decimal"
						solid={false}
						labelTitle={intl.formatMessage({ id: 'COUNTRY_PARAMS.MODAL.PRICE_EVOLUTION.LABEL' })}
						tooltipTitle={intl.formatMessage({ id: 'COUNTRY_PARAMS.MODAL.PRICE_EVOLUTION.TOOLTIP' })}
						onChange={onNumberChange(formik, 'priceEvolution')}
						value={formik.values.priceEvolution ?? ''}
					/>
					<UfinetInput
						requiredIcon
						className="col-4"
						error={formik.errors.ipc}
						type="decimal"
						solid={false}
						labelTitle={intl.formatMessage({ id: 'COUNTRY_PARAMS.MODAL.IPC.LABEL' })}
						tooltipTitle={intl.formatMessage({ id: 'COUNTRY_PARAMS.MODAL.IPC.TOOLTIP' })}
						onChange={onNumberChange(formik, 'ipc')}
						value={formik.values.ipc ?? ''}
					/>
				</div>
			</div>
			<div className="row pb-4">
				<UfinetActionButton
					ref={submitButtonRef}
					className="mt-5 ms-3 p-5 w-auto"
					content={intl.formatMessage({ id: countryParamsId ? 'COUNTRY_PARAMS.UPDATE' : 'COUNTRY_PARAMS.ADD' })}
					icon={faPlus}
					onClick={() => formik.handleSubmit()}
					isDisabled={!!countryParamsId && deepEqual(formik.values, initialData)}
				/>
				<UfinetButton
					secondaryButton
					className="mt-5 ms-3 p-5 w-auto"
					content={intl.formatMessage({ id: countryParamsId ? 'RESET' : 'CLEAR' })}
					icon={countryParamsId ? faRefresh : faTrash}
					onClick={() => formik.resetForm({ values: { ...initialData } })}
					isDisabled={false}
				/>
			</div>
			<input className="d-none" type="submit" />

			{loading && <Loading />}
		</form>
	)
}
