import { FC } from 'react'
import { ClockLoader } from 'react-spinners'

import { MultipleDeleter, UfinetAsyncButton, UfinetButton } from 'ufinet-web-components'
import {
	ILocalService,
	IQuotation,
	ProcessedService,
	QuotationConfirmationStatus,
	useTranslator,
} from 'ufinet-web-functions'

import { PrefeasibilityRepository } from 'modules/prefeasibility/domain/repository/PrefeasibilityRepository'
import { TechnicalGroupRepository } from 'modules/prefeasibility/domain/repository/TechnicalGroupRepository'
import { PrefeasibilityModal } from '../prefeasibility-modal/PrefeasibilityModal'
import { useServiceTableHeaderButtons } from './hooks/ServiceTableHeaderButtonsHook'

type ServiceTableHeaderButtonsProps = {
	aqsId: string
	contactId?: string
	isEditingService: boolean
	internalUser: boolean
	services: ProcessedService[]
	quotationData: IQuotation
	prefeasibilityRepository: PrefeasibilityRepository
	technicalGroupRepository: TechnicalGroupRepository
	setValidSelectedValues: (services: ILocalService[]) => void
	selectedValues: ILocalService[]
	setSelectedValues: React.Dispatch<React.SetStateAction<ILocalService[]>>
	deleteServices?: (indexes: number[]) => Promise<void>
	confirmQuotation?: () => Promise<void>
	confirmPrefeasibility: () => void
	resetForm?: () => void
	countryId: string
	hideButtons?: { prefeasibility?: boolean; quotation?: boolean; cancel?: boolean }
}

const ServiceTableHeaderButtons: FC<ServiceTableHeaderButtonsProps> = ({
	aqsId,
	contactId,
	isEditingService,
	internalUser,
	services,
	quotationData,
	prefeasibilityRepository,
	technicalGroupRepository,
	setValidSelectedValues,
	selectedValues,
	setSelectedValues,
	deleteServices = () => new Promise(() => {}),
	confirmQuotation = () => new Promise(() => {}),
	confirmPrefeasibility = () => {},
	resetForm = () => {},
	countryId,
	hideButtons = { prefeasibility: false, quotation: false, cancel: false },
}: ServiceTableHeaderButtonsProps) => {
	const translate = useTranslator()

	const {
		submitQuotation,
		cancelQuotation,
		hasUnquotableProducts,
		openPrefeasibilityModal,
		openPrefeasibilityModalCallback,
		permissions,
		prefeasibilityPermissions,
		quotationCalculating,
		quotationConfirmationStatus,
		shouldButtonsBeDisabled,
		handleQutoationIIButtonClicked,
	} = useServiceTableHeaderButtons(
		aqsId,
		services,
		quotationData,
		prefeasibilityRepository,
		setSelectedValues,
		deleteServices,
		confirmQuotation,
		confirmPrefeasibility,
		resetForm
	)

	return (
		<div className="ignore-header-size">
			{permissions.canDelete && (
				<MultipleDeleter
					setSelectedValues={setValidSelectedValues}
					selectedValues={selectedValues}
					deleteEndpoint={(deleteEvent: any) => {
						setSelectedValues([])
						return deleteServices(deleteEvent.ids || [])
					}}
					search={() => {}}
				/>
			)}
			<>
				{internalUser && prefeasibilityPermissions && !hideButtons.prefeasibility && (
					<UfinetButton
						id="PrefeasibilityRequestButton"
						className="me-3"
						content={translate('QUOTATION.REQUEST.PREFEASIBILITY')}
						isDisabled={shouldButtonsBeDisabled(isEditingService, services) || quotationCalculating}
						onClick={() => {
							openPrefeasibilityModal?.()
						}}
					/>
				)}
				{!internalUser && !hideButtons.quotation && prefeasibilityPermissions && hasUnquotableProducts() && (
					<UfinetAsyncButton
						id="PrefeasibilityQuotationButton"
						className="me-3"
						content={translate('QUOTE')}
						isDisabled={shouldButtonsBeDisabled(isEditingService, services) || quotationCalculating}
						onClick={handleQutoationIIButtonClicked}
						showSpinner
					/>
				)}
				{permissions.canWrite &&
					!hideButtons.quotation &&
					(!prefeasibilityPermissions || quotationData.isBulk || !hasUnquotableProducts()) && (
						<UfinetButton
							id="QuotationButton"
							className="me-3"
							isDisabled={shouldButtonsBeDisabled(isEditingService, services) || quotationCalculating}
							content={
								<div className="d-flex flex-row justify-content-between">
									{translate('QUOTE')}
									{quotationConfirmationStatus === QuotationConfirmationStatus.Confirming && (
										<span style={{ marginLeft: '6px' }}>
											<ClockLoader color="white" size={20} />
										</span>
									)}
								</div>
							}
							onClick={submitQuotation}
						/>
					)}
				{!hideButtons.cancel && (
					<UfinetButton
						className="me-3"
						secondaryButton
						isDisabled={shouldButtonsBeDisabled(isEditingService, services)}
						content={translate('CANCEL')}
						onClick={cancelQuotation}
					/>
				)}
			</>
			{prefeasibilityPermissions && internalUser && (
				<PrefeasibilityModal
					aqsId={aqsId}
					contactId={contactId}
					reference={quotationData.reference}
					finalClient={quotationData.finalClient}
					countryId={countryId}
					openModalCallback={openPrefeasibilityModalCallback}
					confirmPrefeasibility={confirmPrefeasibility}
					prefeasibilityRepository={prefeasibilityRepository}
					technicalGroupRepository={technicalGroupRepository}
				/>
			)}
		</div>
	)
}

export { ServiceTableHeaderButtons }
