import { isNumeric } from 'ufinet-web-functions'

export const stringToCoordinate = (input: string): google.maps.LatLngLiteral => {
	const [lat, lng] = input
		.split(',')
		.map((p) => p.trim())
		.map((n) => (isNumeric(n) ? n : ''))
		.map(parseFloat)
	return { lat, lng }
}
