// Given a service, return the maps directions for drawing the routes:
// 1. Origin coordinates => origin coupling

import { deepEqual } from 'fast-equals'
import {
	defaultMapsDoStopOver,
	defaultMapsTravelMode,
	defaultMapsUnitSystem,
	DirectionItem,
	DirectionsRequest,
	DirectionsRequestOptions,
	Waypoint,
} from 'modules/google-maps/directions/typesAndValues'
import { ICalculationPoint, INetworkItemInfo, IServerService } from 'ufinet-web-functions'

const directionsBetweenPoints = (
	points: (ICalculationPoint | INetworkItemInfo)[],
	options?: DirectionsRequestOptions & { stopOvers?: boolean }
): DirectionsRequest => {
	const allCoordinates: google.maps.LatLngLiteral[] = points.map(
		(it) => ({ lat: it?.latitude, lng: it?.longitude } as google.maps.LatLngLiteral)
	)

	// Remove duplicated points (same coordinates) to create the eventual waypoints
	const allWaypoints: Waypoint[] = allCoordinates
		.filter((coord, index, self) => self.findIndex((t) => deepEqual(t, coord)) === index)
		.map(
			(latLngLiteral) =>
				({
					location: new google.maps.LatLng(latLngLiteral),
					stopover: options?.stopOvers || defaultMapsDoStopOver,
				} as Waypoint)
		)

	// Get the origin/destination point, and remove it from the array if necessary to just leave the intermediate points
	const origin: DirectionItem = allWaypoints.length === 1 ? allWaypoints[0].location : allWaypoints.shift()?.location!
	const destination: DirectionItem =
		allWaypoints.length === 1 ? allWaypoints[0].location : allWaypoints.pop()?.location!
	const intermediateWaypoints = allWaypoints.length === 1 ? [] : [...allWaypoints]

	return {
		origin,
		destination,
		waypoints: intermediateWaypoints,
		travelMode: options?.travelMode || defaultMapsTravelMode(),
		unitSystem: options?.unitSystem || defaultMapsUnitSystem(),
	}
}

// 2. Destination coordinates => destination coupling
export const mapDirectionsFromService = (
	service: IServerService,
	options?: DirectionsRequestOptions & { stopOvers?: boolean }
): DirectionsRequest[] => {
	const directions = []

	// Origin to coupling
	if (service.originPoint && service.originNetworkItem) {
		const originDirections: DirectionsRequest = {
			...directionsBetweenPoints([service.originPoint, service.originNetworkItem], options),
			service,
		}
		directions.push(originDirections)
	}

	// Destination to coupling
	if (service.destinationPoint && service.destinationNetworkItem) {
		const destinationDirections: DirectionsRequest = {
			...directionsBetweenPoints([service.destinationPoint, service.destinationNetworkItem], options),
			service,
		}
		directions.push(destinationDirections)
	}

	return directions
}
