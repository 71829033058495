import { buildPaths } from 'modules/google-maps/functions/buildPaths'
import { infoWindowFromAreaForMarker } from 'modules/google-maps/infowindow/functions/infoWindowFromAreaForMarker'
import { getPolygonCenter } from 'modules/google-maps/polygon/functions/getPolygonCenter'
import { IntlShape } from 'react-intl'
import { AreaAccessStatus, AreaType, IKmzAreaFindResponse } from 'ufinet-web-functions'
import { v4 as uuidv4 } from 'uuid'
import { markerIconFromAreaAccessStatus } from '../icon/functions/markerIconFromAreaAccessStatus'
import { MapMarker } from '../MapMarker'
import { MapMarkerBaseOptions } from '../MapMarkerBaseOptions'
import { MapMarkerType } from '../MapMarkerType'

export const markerOptionsFromKmzAccessArea = (area: IKmzAreaFindResponse, intl: IntlShape): MapMarker => {
	const polygonCenter = getPolygonCenter({
		paths: buildPaths(area.coordinates),
	})
	return {
		id: uuidv4(),
		type: MapMarkerType.BUILDING,
		options: {
			...MapMarkerBaseOptions.get(MapMarkerType.BUILDING),
			position: polygonCenter,
			optimized: true,
			icon: markerIconFromAreaAccessStatus(area.accessAreaStatus),
			zIndex: AccessAreaStatusExtraZindex.get(area.accessAreaStatus),
		},
		infoWindow: {
			options: {
				...infoWindowFromAreaForMarker(area, polygonCenter, intl),
			},
			open:
				area.visible &&
				area.areaTypeId === AreaType.ACCESS_AREA &&
				area.accessAreaStatus === AreaAccessStatus.CONNECTED,
		},
		area,
	}
}

const baseZIndex = MapMarkerBaseOptions.get(MapMarkerType.BUILDING)?.zIndex || 0

export const AccessAreaStatusExtraZindex = new Map<AreaAccessStatus, number>([
	[AreaAccessStatus.NOT_CONNECTED, baseZIndex + 0], // Acceso edificio
	[AreaAccessStatus.NEAR_NETWORK, baseZIndex + 1],
	[AreaAccessStatus.CONNECTED, baseZIndex + 2],
])
