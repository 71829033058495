import { useQuery } from 'react-query'
import { ICountriesRepository } from 'ufinet-web-functions'

export const useCountryByIdQuery = (
	repository: ICountriesRepository,
	isEnabled = true,
	countryId: string | undefined
) => {
	return useQuery(
		`country-${countryId}`,
		async () => {
			if (!countryId) return
			const countries = await repository.getAllCountries()
			return countries.find((c) => c.id === countryId)
		},
		{
			enabled: isEnabled,
		}
	)
}
