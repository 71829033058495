export enum MassiveStatuses {
	NOT_VIABLE = 100000001,
	IN_ELABORATION = 1,
	PENDINGAUTHORIZATION = 226530003,
	SUBMITTED = 3,
	AWARD = 4,
}

export const MassiveStatusesLabel: {
	[key in MassiveStatuses]: string
} = {
	[MassiveStatuses.NOT_VIABLE]: 'NOT_VIABLE',
	[MassiveStatuses.IN_ELABORATION]: 'IN_ELABORATION',
	[MassiveStatuses.PENDINGAUTHORIZATION]: 'PENDINGAUTHORIZATION',
	[MassiveStatuses.SUBMITTED]: 'SUBMITTED',
	[MassiveStatuses.AWARD]: 'AWARD',
}
