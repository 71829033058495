import { FormikProps } from 'formik'
import { ToweringRepository } from 'modules/product/repository/ToweringRepository'
import { FC } from 'react'
import { FormBuilder } from '../FormBuilder'
import { useToweringServiceForm } from './hooks/useToweringServiceForm'

type ToweringServiceFormProps = {
	formik: FormikProps<any>
	toweringRepository: ToweringRepository
	corporateGroupId: string
}

export const ToweringServiceForm: FC<ToweringServiceFormProps> = ({ formik, toweringRepository, corporateGroupId }) => {
	const { formFields, translate, onChange } = useToweringServiceForm(formik, toweringRepository, corporateGroupId)

	return (
		<div className="row col-11">
			{Object.keys(formFields).map((field) => {
				return (
					<FormBuilder
						key={field}
						formField={formFields[field]}
						translate={translate}
						formik={formik}
						onChange={onChange}
					/>
				)
			})}
		</div>
	)
}
