import { deepEqual } from 'fast-equals'
import { DirectionsRequest } from 'modules/google-maps/directions/typesAndValues'

export const isEmptyDirection = (input: DirectionsRequest): boolean => {
	const hasWaypoints = !(!input.waypoints || input.waypoints.length === 0)

	return (
		(!input.origin && !input.destination && !hasWaypoints) ||
		(deepEqual(input.origin, input.destination) && !hasWaypoints)
	)
}
