import { faSave } from '@fortawesome/free-solid-svg-icons'
import { useFormik } from 'formik'
import { FC, useCallback, useContext, useMemo, useRef } from 'react'
import { useIntl } from 'react-intl'
import {
	AreaSelect,
	AreaSelectHandle,
	ClientSelect,
	ClientSelectHandle,
	CountrySelect,
	IUfinetSelectOption,
	UfinetActionButton,
	UfinetActionButtonHandle,
	UfinetInput,
	emptyUfinetSelectOption,
} from 'ufinet-web-components'
import {
	AreaType,
	AuthContext,
	buildingAccessCostsService,
	onFormikChanges,
	onFormikNumberChanges,
} from 'ufinet-web-functions'
import * as Yup from 'yup'

type Props = {
	hideModal: Function
	findCosts: Function
}

type BuildingAccessModalData = {
	countrySelect: IUfinetSelectOption
	areaSelect: IUfinetSelectOption
	clientSelect: IUfinetSelectOption
	punctualCost: string
	monthlyCost: string
	monthlyCostPerCapacity: string
	ceConnect: string
	distanceFo: string
}

const NewBuildingAccessModalBody: FC<Props> = ({ hideModal, findCosts }) => {
	const intl = useIntl()
	const areaRef = useRef<AreaSelectHandle>(null)
	const clientRef = useRef<ClientSelectHandle>(null)
	const actionButtonRef = useRef<UfinetActionButtonHandle>(null)

	const authData = useContext(AuthContext)
	const _buildingAccessCostsService = useMemo(() => buildingAccessCostsService(authData, intl), [authData, intl])

	const sendData = (value: BuildingAccessModalData) => {
		actionButtonRef.current?.changeActionStatus(true)
		_buildingAccessCostsService
			.create({
				countryId: value.countrySelect.value,
				countryName: value.countrySelect.label,
				networkAreaName: value.areaSelect.value,
				punctualCost: value.punctualCost,
				monthlyCost: value.monthlyCost,
				monthlyCostPerCapacity: value.monthlyCostPerCapacity,
				clientId: value.clientSelect.value,
				ceConnect: value.ceConnect,
				distanceFo: value.distanceFo,
			})
			.then(() => {
				findCosts()
				hideModal()
			})
			.catch(() => {})
			.finally(() => actionButtonRef.current?.changeActionStatus(false))
	}
	const dataFormSchema = Yup.object().shape({
		countrySelect: Yup.object().shape({ label: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })) }),
		areaSelect: Yup.object().shape({ label: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })) }),
		clientSelect: Yup.object().shape({ label: Yup.string().notRequired() }),
		punctualCost: Yup.number()
			.test('', intl.formatMessage({ id: 'ERROR.POSITIVE' }), (value: any) => value >= 0)
			.required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
		monthlyCost: Yup.number()
			.test('', intl.formatMessage({ id: 'ERROR.POSITIVE' }), (value: any) => value >= 0)
			.required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
		monthlyCostPerCapacity: Yup.number()
			.test('', intl.formatMessage({ id: 'ERROR.POSITIVE' }), (value: any) => value >= 0)
			.required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
		ceConnect: Yup.number()
			.test('', intl.formatMessage({ id: 'ERROR.POSITIVE' }), (value: any) => value >= 0)
			.required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
		distanceFo: Yup.number()
			.test('', intl.formatMessage({ id: 'ERROR.POSITIVE' }), (value: any) => value >= 0)
			.required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
	})

	const formInitialValues = useMemo<BuildingAccessModalData>(
		() => ({
			countrySelect: emptyUfinetSelectOption,
			areaSelect: emptyUfinetSelectOption,
			clientSelect: emptyUfinetSelectOption,
			punctualCost: '',
			monthlyCost: '',
			monthlyCostPerCapacity: '',
			ceConnect: '',
			distanceFo: '',
		}),
		[]
	)

	const formik = useFormik<BuildingAccessModalData>({
		initialValues: formInitialValues,
		validationSchema: dataFormSchema,
		onSubmit: sendData,
		validateOnChange: false,
		validateOnBlur: false,
	})

	const onCountryChange = (country?: IUfinetSelectOption) => {
		onChange(formik, 'countrySelect')(country || emptyUfinetSelectOption)
		onChange(formik, 'areaSelect')(emptyUfinetSelectOption)
		onChange(formik, 'clientSelect')(emptyUfinetSelectOption)
		if (country) {
			areaRef.current?.fillSelect([country.value])
			clientRef.current?.fillSelect([country.value], [])
		}
	}
	const onChange = useCallback(onFormikChanges, [])
	const onNumberChange = useCallback(onFormikNumberChanges, [])
	return (
		<form onSubmit={formik.handleSubmit} className="container p-15 h-100 d-flex flex-column justify-content-center">
			<div className="row">
				<CountrySelect
					requiredIcon
					className="col-4"
					onChange={(c) => onCountryChange(c as IUfinetSelectOption)}
					value={formik.values.countrySelect}
					error={formik.errors.countrySelect?.label}
				/>
				<AreaSelect
					ref={areaRef}
					requiredIcon
					className="col-4"
					areaType={AreaType.ACCESS_AREA}
					value={formik.values.areaSelect}
					error={formik.errors.areaSelect?.label}
					isDisabled={!formik.values.countrySelect.value}
					onChange={onChange(formik, 'areaSelect')}
				/>
				<ClientSelect
					ref={clientRef}
					requiredIcon={false}
					className="col-4"
					value={formik.values.clientSelect}
					error={formik.errors.clientSelect}
					isDisabled={!formik.values.countrySelect.value}
					onChange={onChange(formik, 'clientSelect')}
				/>
			</div>
			<div className="row pt-4">
				<UfinetInput
					requiredIcon
					error={formik.errors.punctualCost}
					type="decimal"
					labelTitle={intl.formatMessage({ id: 'COST.PUNCTUALCOST_2' })}
					tooltipTitle={intl.formatMessage({ id: 'COST.PUNCTUALCOST_2' })}
					className="col-3"
					onChange={onNumberChange(formik, 'punctualCost')}
					value={formik.values.punctualCost}
					solid={false}
				/>
				<UfinetInput
					requiredIcon
					error={formik.errors.monthlyCost}
					type="decimal"
					labelTitle={intl.formatMessage({ id: 'COST.MONTHLYCOST_2' })}
					tooltipTitle={intl.formatMessage({ id: 'COST.MONTHLYCOST_2' })}
					className="col-4"
					onChange={onNumberChange(formik, 'monthlyCost')}
					value={formik.values.monthlyCost}
					solid={false}
				/>
				<UfinetInput
					requiredIcon
					error={formik.errors.monthlyCostPerCapacity}
					type="decimal"
					labelTitle={intl.formatMessage({ id: 'COST.MONTHLYCAPACITY_2' })}
					tooltipTitle={intl.formatMessage({ id: 'COST.MONTHLYCAPACITY_2' })}
					className="col-5"
					onChange={onNumberChange(formik, 'monthlyCostPerCapacity')}
					value={formik.values.monthlyCostPerCapacity}
					solid={false}
				/>
				<UfinetInput
					requiredIcon
					error={formik.errors.ceConnect}
					type="decimal"
					labelTitle={intl.formatMessage({ id: 'COST.CE_CONNECT' })}
					tooltipTitle={intl.formatMessage({ id: 'COST.CE_CONNECT' })}
					className="col-5"
					onChange={onNumberChange(formik, 'ceConnect')}
					value={formik.values.ceConnect}
					solid={false}
				/>
				<UfinetInput
					requiredIcon
					error={formik.errors.distanceFo}
					type="decimal"
					labelTitle={intl.formatMessage({ id: 'COST.DISTANCE_FO' })}
					tooltipTitle={intl.formatMessage({ id: 'COST.DISTANCE_FO' })}
					className="col-5"
					onChange={onNumberChange(formik, 'distanceFo')}
					value={formik.values.distanceFo}
					solid={false}
				/>
			</div>
			<UfinetActionButton
				ref={actionButtonRef}
				className="mt-10"
				content={intl.formatMessage({ id: 'SAVE' })}
				icon={faSave}
			/>
		</form>
	)
}
export { NewBuildingAccessModalBody }
