import { useContext, useEffect, useRef } from 'react'
import { Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { LayoutProvider, MasterInit, TOAST_CONTAINER_ID } from 'ufinet-web-components'
import { AuthContext, authService } from 'ufinet-web-functions'

const App = () => {
	const { setAuthFrame } = useContext(AuthContext)

	const authFrameRef = useRef<HTMLIFrameElement>(null)

	useEffect(() => {
		authFrameRef.current && setAuthFrame(authFrameRef.current)
	}, [])
	return (
		<LayoutProvider>
			<Outlet />
			<MasterInit />
			<ToastContainer containerId={TOAST_CONTAINER_ID} />
			<iframe ref={authFrameRef} hidden title="Iframe login SSO" src={authService.getSSOLoginUrl()} />
		</LayoutProvider>
	)
}

export { App }
