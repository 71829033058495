import { MapInfoWindow } from 'modules/google-maps/events/infowindow/MapInfoWindow'
import { MapsInfoWindowCloseEvent } from 'modules/google-maps/events/infowindow/MapsInfoWindowCloseEvent'
import { MapsInfoWindowOpenEvent } from 'modules/google-maps/events/infowindow/MapsInfoWindowOpenEvent'
import { MapMarkerType } from 'modules/google-maps/markers/MapMarkerType'
import { MarkerClickEvent } from 'modules/google-maps/markers/MarkerClickEvent'
import { FC, useEffect, useMemo } from 'react'

type MarkerProps = {
	id: string
	type: MapMarkerType
	options: google.maps.MarkerOptions
	onClick?: (event: MarkerClickEvent) => void
	infoWindow?: MapInfoWindow
	onInfoWindowOpen?: (event: MapsInfoWindowOpenEvent) => void
	onInfoWindowClose?: (event: MapsInfoWindowCloseEvent) => void
}

const Marker: FC<MarkerProps> = ({
	id,
	type,
	options,
	onClick,
	infoWindow: mapInfoWindow,
	onInfoWindowOpen,
	onInfoWindowClose,
}) => {
	const marker = useMemo(() => new google.maps.Marker(options), [options])
	const infoWindow = useMemo(() => {
		if (!mapInfoWindow) return
		const infoWindow = new google.maps.InfoWindow(mapInfoWindow.options)
		infoWindow.addListener('domready', () =>
			onInfoWindowOpen?.(new MapsInfoWindowOpenEvent({ infoWindow, marker: { id, value: marker, type } }))
		)
		infoWindow.addListener('closeclick', () =>
			onInfoWindowClose?.(new MapsInfoWindowCloseEvent({ infoWindow, marker: { id, value: marker, type } }))
		)
		return infoWindow
	}, [id, mapInfoWindow, marker, onInfoWindowClose, onInfoWindowOpen, type])

	useEffect(() => {
		marker.setMap(options.map || null)
		marker.addListener('click', (e: google.maps.MapMouseEvent) =>
			onClick?.(new MarkerClickEvent({ nativeEvent: e, origin: { id, value: marker, type } }))
		)

		if (mapInfoWindow?.open) {
			infoWindow?.open({ anchor: marker, map: options.map, shouldFocus: false })
		}

		return () => {
			infoWindow?.close()
			google.maps.event.clearListeners(marker, 'click')
			marker.setMap(null)
		}
	}, [id, infoWindow, mapInfoWindow?.open, marker, onClick, options.map, type])

	return null
}

export { Marker }
