import { useFormik } from 'formik'
import { FC, useCallback, useContext, useMemo, useRef } from 'react'
import { useIntl } from 'react-intl'
import {
	AreaSelect,
	AreaSelectHandle,
	ClientSelect,
	ClientSelectHandle,
	CountrySelect,
	IUfinetSelectOption,
	UfinetActionButton,
	UfinetActionButtonHandle,
	UfinetInput,
	emptyUfinetSelectOption,
} from 'ufinet-web-components'
import {
	AreaType,
	AuthContext,
	economicModelCostsService,
	onFormikChanges,
	onFormikNumberChanges,
} from 'ufinet-web-functions'
import * as Yup from 'yup'

type Props = {
	hideModal: Function
	findCosts: Function
}

type EconomicModelModalData = {
	countrySelect: IUfinetSelectOption
	clientSelect: IUfinetSelectOption
	areaSelect: IUfinetSelectOption
	invMargin: string
	reMargin: string
	incNrc: string
	incMrc: string
}

const NewEconomicModelModalBody: FC<Props> = ({ hideModal, findCosts }: Props) => {
	const intl = useIntl()
	const clientRef = useRef<ClientSelectHandle>(null)
	const actionButtonRef = useRef<UfinetActionButtonHandle>(null)
	const areaRef = useRef<AreaSelectHandle>(null)

	const authData = useContext(AuthContext)
	const _economicModelCostsService = useMemo(() => economicModelCostsService(authData, intl), [authData, intl])

	const sendData = (value: EconomicModelModalData) => {
		actionButtonRef.current?.changeActionStatus(true)
		_economicModelCostsService
			.create({
				countryId: value.countrySelect.value,
				countryName: value.countrySelect.label,
				networkAreaName: value.areaSelect.value,
				clientId: value.clientSelect.value,
				investmentMargin: value.invMargin,
				recurringMargin: value.reMargin,
				incrementNrc: value.incNrc,
				incrementMrc: value.incMrc,
			})
			.then(() => {
				findCosts()
				hideModal()
			})
			.catch(() => {})
			.finally(() => actionButtonRef.current?.changeActionStatus(false))
	}
	const dataFormSchema = Yup.object().shape({
		countrySelect: Yup.object().shape({ label: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })) }),
		clientSelect: Yup.object().shape({ label: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })) }),
		areaSelect: Yup.object().shape({ label: Yup.string().required(intl.formatMessage({ id: 'ERROR.REQUIRED' })) }),
		invMargin: Yup.number()
			.test('', intl.formatMessage({ id: 'ERROR.POSITIVE' }), (value: any) => value >= 0)
			.required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
		reMargin: Yup.number()
			.test('', intl.formatMessage({ id: 'ERROR.POSITIVE' }), (value: any) => value >= 0)
			.required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
		incNrc: Yup.number()
			.test('', intl.formatMessage({ id: 'ERROR.POSITIVE' }), (value: any) => value >= 0)
			.required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
		incMrc: Yup.number()
			.test('', intl.formatMessage({ id: 'ERROR.POSITIVE' }), (value: any) => value >= 0)
			.required(intl.formatMessage({ id: 'ERROR.REQUIRED' })),
	})

	const formInitialValues = useMemo<EconomicModelModalData>(
		() => ({
			countrySelect: emptyUfinetSelectOption,
			clientSelect: emptyUfinetSelectOption,
			areaSelect: emptyUfinetSelectOption,
			invMargin: '',
			reMargin: '',
			incNrc: '',
			incMrc: '',
		}),
		[]
	)

	const formik = useFormik<EconomicModelModalData>({
		initialValues: formInitialValues,
		validationSchema: dataFormSchema,
		onSubmit: sendData,
		validateOnChange: false,
		validateOnBlur: false,
	})

	const onCountryChange = (country?: IUfinetSelectOption) => {
		onChange(formik, 'countrySelect')(country || emptyUfinetSelectOption)
		onChange(formik, 'clientSelect')(emptyUfinetSelectOption)
		onChange(formik, 'areaSelect')(emptyUfinetSelectOption)
		if (country) {
			areaRef.current?.fillSelect([country.value])
			clientRef.current?.fillSelect([country.value], [])
		}
	}
	const onChange = useCallback(onFormikChanges, [])
	const onNumberChange = useCallback(onFormikNumberChanges, [])

	return (
		<form onSubmit={formik.handleSubmit} className="container p-15 h-100 d-flex flex-column justify-content-center">
			<div className="row">
				<CountrySelect
					requiredIcon
					className="col-4"
					onChange={(c) => onCountryChange(c as IUfinetSelectOption)}
					value={formik.values.countrySelect}
					error={formik.errors.countrySelect?.label}
				/>
				<AreaSelect
					ref={areaRef}
					requiredIcon
					className="col-4"
					areaType={AreaType.ECONOMIC_MODEL_AREA}
					value={formik.values.areaSelect}
					error={formik.errors.areaSelect?.label}
					onChange={onChange(formik, 'areaSelect')}
					isDisabled={!formik.values.countrySelect.value}
				/>
				<ClientSelect
					ref={clientRef}
					requiredIcon={false}
					className="col-4"
					value={formik.values.clientSelect}
					error={formik.errors.clientSelect?.label}
					onChange={onChange(formik, 'clientSelect')}
					isDisabled={!formik.values.countrySelect.value}
				/>
			</div>

			<div className="row pt-4">
				<UfinetInput
					requiredIcon
					error={formik.errors.invMargin}
					type="decimal"
					labelTitle={intl.formatMessage({ id: 'COST.INVESTMARGIN_2' })}
					tooltipTitle={intl.formatMessage({ id: 'COST.INVESTMARGIN_2' })}
					className="col-6"
					onChange={onNumberChange(formik, 'invMargin')}
					value={formik.values.invMargin}
					solid={false}
				/>
				<UfinetInput
					requiredIcon
					error={formik.errors.reMargin}
					type="decimal"
					labelTitle={intl.formatMessage({ id: 'COST.RECURRMARGIN_2' })}
					tooltipTitle={intl.formatMessage({ id: 'COST.RECURRMARGIN_2' })}
					className="col-6"
					onChange={onNumberChange(formik, 'reMargin')}
					value={formik.values.reMargin}
					solid={false}
				/>
			</div>
			<div className="row pt-4">
				<UfinetInput
					requiredIcon
					error={formik.errors.incNrc}
					type="decimal"
					labelTitle={intl.formatMessage({ id: 'COST.INCREMENT_NRC_2' })}
					tooltipTitle={intl.formatMessage({ id: 'COST.INCREMENT_NRC_2' })}
					className="col-6"
					onChange={onNumberChange(formik, 'incNrc')}
					value={formik.values.incNrc}
					solid={false}
				/>
				<UfinetInput
					requiredIcon
					error={formik.errors.incMrc}
					type="decimal"
					labelTitle={intl.formatMessage({ id: 'COST.INCREMENT_MRC_2' })}
					tooltipTitle={intl.formatMessage({ id: 'COST.INCREMENT_MRC_2' })}
					className="col-6"
					onChange={onNumberChange(formik, 'incMrc')}
					value={formik.values.incMrc}
					solid={false}
				/>
			</div>
			<UfinetActionButton ref={actionButtonRef} className="mt-10" content={intl.formatMessage({ id: 'SAVE' })} />
		</form>
	)
}
export { NewEconomicModelModalBody }
