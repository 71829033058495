import { FC, useCallback, useMemo } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { BulkQuotationServiceSummary } from 'ufinet-web-functions'

interface ITableCellServicePointsProps extends React.HTMLAttributes<HTMLDivElement> {
	services: BulkQuotationServiceSummary[]
}

const TableCellServicePoints: FC<ITableCellServicePointsProps> = ({ services, ...divProps }) => {
	// Service points, added 1 to match UI row, which counts from 1 instead of from 0
	const servicePoints = useMemo(() => services.map((s) => s.pointNumber + 1), [services])

	const renderTooltip = useCallback(
		(props) =>
			servicePoints.length && (
				<Tooltip id="button-tooltip" {...props}>
					<div className="table-cell-tooltip-inner" {...divProps}>
						{servicePoints.map((it) => `#${it}`).join(', ')}
					</div>
				</Tooltip>
			),
		[servicePoints, divProps]
	)

	return (
		<>
			{servicePoints.length ? (
				<OverlayTrigger placement="right" delay={{ show: 100, hide: 300 }} overlay={renderTooltip}>
					<div className="table-cell-tooltip">{services.length}</div>
				</OverlayTrigger>
			) : (
				0
			)}
		</>
	)
}

export default TableCellServicePoints
