import { IdAndValue, TranslatorFunction } from 'ufinet-web-functions'
import { LabelAndValue } from '../model/labelAndValue'

export const SelectOptionMapper = <Tid = number, TVal = string>(data: IdAndValue<Tid, TVal>[]): LabelAndValue[] => {
	return data.map((item) => {
		return {
			label: `${item.value}`,
			value: `${item.id}`,
		}
	})
}

export const SelectOptionTranslateMapper = <Tid = number, TVal = string>(
	data: IdAndValue<Tid, TVal>[],
	translate: TranslatorFunction,
	translationBaseKey: string
): LabelAndValue[] => {
	return data.map((item) => {
		return {
			label: translate(`${translationBaseKey}.${item.value}`),
			value: `${item.id}`,
		}
	})
}
