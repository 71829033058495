import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'

import { FC, useCallback, useContext, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import {
	getEditPencilBodyTemplate,
	MultipleDeleter,
	Table,
	UfinetBox,
	UfinetButton,
	UfinetModal,
	UfinetSectionBox,
} from 'ufinet-web-components'
import { AuthContext, Authority, countryParamsService, ICountryParams, useAsync, useModal } from 'ufinet-web-functions'

import { colsCountryParams } from './CountryParamsColsData'
import { CountryParamsModal } from './modal/CountryParamsModalBody'

export const CountryParamsPage: FC = () => {
	const intl = useIntl()
	const [countryParams, setCountryParams] = useState<ICountryParams[]>()
	const [countryParamsId, setCountryParamsId] = useState<number>()
	const [selectedValues, setSelectedValues] = useState<Object[]>([])
	const [modal, showModal, hideModal] = useModal()

	const [loadingRecords, setLoadingRecords] = useState<boolean>(false)

	const authData = useContext(AuthContext)
	const _countryParamsService = useMemo(() => countryParamsService(authData), [authData])

	const roles = authData.userData?.authorities || []
	const permissions = Authority.getCostsPermissions(roles)

	const getCountryParams = useCallback(async () => {
		setLoadingRecords(true)
		return await _countryParamsService.getAllCountryParams()
	}, [_countryParamsService])

	const onCountryParamsFetched = (countryParams: ICountryParams[]): void => {
		const parsedParams = countryParams.map((p) => ({
			...p,
			countryName: p.countryName || `[${intl.formatMessage({ id: 'UNKNOWN' })}]`.toLocaleUpperCase(),
		}))
		setCountryParams(parsedParams)
	}

	const onCountryParamsFailedToFetch = (): void => {
		toast.error(intl.formatMessage({ id: 'COUNTRY_PARAMS.FETCH.ERROR' }))
	}

	const runFinally = () => {
		setLoadingRecords(false)
	}

	const fetchAndStore = useAsync(
		{
			asyncFn: getCountryParams,
			onSuccess: onCountryParamsFetched,
			onFailure: onCountryParamsFailedToFetch,
			runFinally: runFinally,
		},
		[]
	)

	const onModalSubmitSuccess = (countryParams: ICountryParams) => {
		toast.success(
			intl.formatMessage(
				{ id: countryParamsId ? 'COUNTRY_PARAMS.UPDATE.SUCCESS' : 'COUNTRY_PARAMS.NEW.SUCCESS' },
				{ country: countryParams.countryName }
			)
		)
		fetchAndStore()
		hideModal()
	}

	const onModalSubmitError = () => {
		toast.error(
			intl.formatMessage({
				id: countryParamsId ? 'COUNTRY_PARAMS.UPDATE.FAILURE' : 'COUNTRY_PARAMS.NEW.FAILURE',
			})
		)
	}

	const onModalFetchError = () => {
		toast.error(intl.formatMessage({ id: 'COUNTRY_PARAMS.FETCH.ERROR' }))
		hideModal()
	}

	const onSelectionChange = (values: Object[]) => {
		setSelectedValues(values)
	}

	const getHeadersButtons = () => (
		<>
			{permissions.canDelete && (
				<MultipleDeleter
					title={intl.formatMessage({ id: 'COUNTRY_PARAMS.DELETE' })}
					text={intl.formatMessage({ id: 'COUNTRY_PARAMS.DELETE.CONFIRM' })}
					setSelectedValues={setSelectedValues}
					selectedValues={selectedValues}
					deleteEndpoint={_countryParamsService.deleteManyCountryParams}
					search={() => {
						toast.success(
							intl.formatMessage(
								{ id: 'SECTION.DELETE.MASSIVE.SUCCESS' },
								{ section: intl.formatMessage({ id: 'COUNTRY_PARAMS' }) }
							)
						)
						fetchAndStore()
					}}
				/>
			)}
			{permissions.canWrite && (
				<UfinetButton
					className="me-3"
					icon={faPlus}
					onClick={() => {
						setCountryParamsId(undefined)
						showModal()
					}}
					content={intl.formatMessage({ id: 'NEW.REGISTER' })}
				/>
			)}
		</>
	)

	return (
		<UfinetBox>
			<UfinetSectionBox>
				<Table
					selectedValues={selectedValues}
					onSelectionChange={permissions.canDelete ? onSelectionChange : undefined}
					headerButtons={getHeadersButtons()}
					actionBodyTemplate={
						permissions.canUpdate
							? (countryParams: ICountryParams) =>
									getEditPencilBodyTemplate(countryParams, () => {
										setCountryParamsId(countryParams.id)
										showModal()
									})
							: undefined
					}
					content={countryParams}
					cols={colsCountryParams}
					emptyMessage={loadingRecords ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
				/>
			</UfinetSectionBox>
			<UfinetModal
				size="lg"
				show={modal}
				handleClose={hideModal}
				title={
					!countryParamsId
						? intl.formatMessage({ id: 'COUNTRY_PARAMS.MODAL.CREATE.TITLE' })
						: intl.formatMessage({ id: 'COUNTRY_PARAMS.MODAL.EDIT.TITLE' })
				}
			>
				<CountryParamsModal
					countryParamsId={countryParamsId}
					onSubmitSuccess={(params) => onModalSubmitSuccess(params)}
					onSubmitError={onModalSubmitError}
					onFetchError={onModalFetchError}
				/>
			</UfinetModal>
		</UfinetBox>
	)
}
