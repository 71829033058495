import { AreaAccessStatus, AreaStatusColors } from 'ufinet-web-functions'
import { assetsUrl, mkIcon } from './mkIcon'

export const markerIconFromAreaAccessStatusOld = (status: AreaAccessStatus): string => {
	return mkIcon({ fillColor: AreaStatusColors.get(status || AreaAccessStatus.NOT_CONNECTED)?.replace('#', '') })
}

const AreaStatusMapPin = new Map<AreaAccessStatus, string>([
	[AreaAccessStatus.CONNECTED, `${assetsUrl}green-map-pin.png`],
	[AreaAccessStatus.NEAR_NETWORK, `${assetsUrl}orange-map-pin.png`],
	[AreaAccessStatus.NOT_CONNECTED, `${assetsUrl}purple-map-pin.png`],
])

export const markerIconFromAreaAccessStatus = (status: AreaAccessStatus): string =>
	AreaStatusMapPin.get(status || AreaAccessStatus.NOT_CONNECTED) ?? `${assetsUrl}red-map-pin.png`
