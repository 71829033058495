import { FC, useEffect, useMemo } from 'react'

type KmlLayerProps = {
	options: google.maps.KmlLayerOptions
}

const KmlLayer: FC<KmlLayerProps> = ({ options }) => {
	const kmlLayer = useMemo(() => new google.maps.KmlLayer(options), [options])

	useEffect(() => {
		return () => {
			kmlLayer.setMap(null)
		}
	}, [kmlLayer])

	return null
}

export { KmlLayer }
