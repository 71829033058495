import { FC, useCallback, useContext, useMemo, useRef, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import Swal from 'sweetalert2'

import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload'

import { deepEqual } from 'fast-equals'
import { NewInfrastructureModalBody } from 'pages/costs/modals/NewInfrastructureModalBody'
import { toast } from 'react-toastify'
import {
	colorConstants,
	HardFilterWrapper,
	HardFilterWrapperHandle,
	MultipleDeleter,
	Table,
	UfinetActionButtonHandle,
	UfinetBox,
	UfinetButton,
	UfinetModal,
	UfinetSectionBox,
} from 'ufinet-web-components'
import {
	AreaType,
	AuthContext,
	Authority,
	getTableTranslations,
	ICostsInfrastructureResponse,
	ICostsQueryCriteria,
	infrastructureCostsService,
	infrastructureTypeMappings,
	onSelectionChangePreventDefaultsRemoval,
	useAsync,
	useModal,
} from 'ufinet-web-functions'
import { colsInfrastructure } from './common/CostsColData'
import { UploadExcelModalBody } from './modals/excel/UploadExcelModalBody'

const InfrastructurePage: FC = () => {
	const intl = useIntl()
	const actionButtonRef = useRef<UfinetActionButtonHandle>()
	const [infrastructureResponse, setInfrastructureResponse] = useState<ICostsInfrastructureResponse[]>([])
	const [modal, showModal, hideModal] = useModal()
	const [newModal, showNewModal, hideNewModal] = useModal()
	const [selectedValues, setSelectedValues] = useState<ICostsInfrastructureResponse[]>()

	const [loadingRecords, setLoadingRecords] = useState<boolean>(false)

	const authData = useContext(AuthContext)
	const roles = authData.userData?.authorities || []
	const permissions = Authority.getCostsPermissions(roles)

	const _infrastructureCostsService = useMemo(() => infrastructureCostsService(authData, intl), [authData, intl])

	const upperFilterRef = useRef<HardFilterWrapperHandle>(null)

	const getInfrastructureCosts = useCallback(async () => {
		setLoadingRecords(true)
		actionButtonRef.current?.changeActionStatus(true)

		const criteria: ICostsQueryCriteria = {
			countries: upperFilterRef.current?.getCountries().map((e) => e.value) || [],
			networkAreas: upperFilterRef.current?.getAreas().map((e) => e.value) || [],
		}

		return await _infrastructureCostsService.findByCriteria(criteria)
	}, [_infrastructureCostsService])

	const onInfrastructureCostsFetched = (costs: ICostsInfrastructureResponse[]): void => {
		const parsedCosts = costs.map((cost) => ({
			...cost,
			networkAreaName: getTableTranslations(cost.networkAreaName, intl),
			infrastructureType: intl.formatMessage({ id: cost.infrastructureType }),
		}))

		setInfrastructureResponse(parsedCosts)
	}

	const onInfrastructureCostsFailedToFetch = (): void => {
		toast.error(intl.formatMessage({ id: 'COSTS.FETCH.ERROR' }))
	}

	const runFinally = () => {
		setLoadingRecords(false)
		actionButtonRef.current?.changeActionStatus(false)
	}

	const fetchAndStore = useAsync(
		{
			asyncFn: getInfrastructureCosts,
			onSuccess: onInfrastructureCostsFetched,
			onFailure: onInfrastructureCostsFailedToFetch,
			runFinally: runFinally,
		},
		[]
	)

	/// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	/// /////////////////////////////////////// HEADER TABLE AND ACTION BUTTONS //////////////////////////////////////////
	/// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const getActionButtons = () => {
		return (
			<>
				<OverlayTrigger
					placement="bottom"
					delay={{ show: 250, hide: 400 }}
					overlay={<Tooltip className="tooltip-dark">{intl.formatMessage({ id: 'DOWNLOAD_EXCEL' })}</Tooltip>}
				>
					<div className="d-inline">
						<FontAwesomeIcon
							className="fa-icon-primary"
							size="1x"
							icon={faDownload}
							onClick={() => {
								_infrastructureCostsService.downloadXLS({
									countries: upperFilterRef.current?.getCountries().map((e) => e.value) || [],
									networkAreas: upperFilterRef.current?.getAreas().map((e) => e.value) || [],
								})
							}}
						/>
					</div>
				</OverlayTrigger>
			</>
		)
	}

	const getHeaderButtons = () => (
		<>
			{permissions.canDelete && (
				<MultipleDeleter
					setSelectedValues={setSelectedValues}
					selectedValues={selectedValues}
					deleteEndpoint={_infrastructureCostsService.deleteByIdsList}
					search={fetchAndStore}
				/>
			)}
			{permissions.canWrite && (
				<>
					<UfinetButton
						className="me-3"
						icon={faPlus}
						onClick={() => showNewModal()}
						content={intl.formatMessage({ id: 'NEW.REGISTER' })}
					/>
					<UfinetButton icon={faUpload} onClick={() => showModal()} content={intl.formatMessage({ id: 'BULK_LOAD' })} />
				</>
			)}
		</>
	)

	const onRowEditComplete = (data: ICostsInfrastructureResponse) => {
		if (
			deepEqual(
				data,
				infrastructureResponse.find((it) => it.id === data.id)
			)
		)
			return

		Swal.fire({
			icon: 'warning',
			title: intl.formatMessage(
				{ id: 'COUNTRY.REGISTRY.UPDATE.TYPE' },
				{ countryName: data.countryName, infrastructureType: data.infrastructureType }
			),
			html: intl.formatMessage({ id: 'COUNTRY.REGISTRY.UPDATE.CONFIRM' }, { networkAreaName: data.networkAreaName }),
			showCancelButton: true,
			showConfirmButton: true,
			reverseButtons: true,
			confirmButtonColor: colorConstants.primaryColor,
			cancelButtonColor: colorConstants.dangerColor,
			preConfirm: () =>
				_infrastructureCostsService
					.update(data.id, {
						countryName: data.countryName,
						networkAreaName: data.networkAreaName,
						client: data.clientId,
						infrastructureType: infrastructureTypeMappings.get(data.infrastructureType) || data.infrastructureType,
						monthlyCostPerMeter: data.monthlyCostPerMeter,
						punctualCost: data.punctualCost,
					})
					.then(fetchAndStore),
		})
	}

	const onSelectionChange = (values: ICostsInfrastructureResponse[]) => {
		onSelectionChangePreventDefaultsRemoval(
			values,
			selectedValues,
			infrastructureResponse,
			setSelectedValues,
			intl.formatMessage({ id: 'BY_DEFAULT.DELETE.WARNING' })
		)
	}

	return (
		<UfinetBox>
			<HardFilterWrapper
				ref={upperFilterRef}
				actionButtonRef={actionButtonRef}
				search={fetchAndStore}
				networkAreaType={AreaType.INFRASTRUCTURE_AREA}
				actionButtons={getActionButtons()}
			/>
			<UfinetSectionBox>
				<Table
					selectedValues={selectedValues}
					onSelectionChange={permissions.canDelete ? onSelectionChange : undefined}
					onRowEditComplete={permissions.canUpdate ? onRowEditComplete : undefined}
					cols={colsInfrastructure}
					content={infrastructureResponse}
					headerButtons={getHeaderButtons()}
					emptyMessage={loadingRecords ? intl.formatMessage({ id: 'LOADING_DOTS' }) : undefined}
				/>
			</UfinetSectionBox>
			{/* Modals */}
			<UfinetModal
				size="xl"
				show={modal}
				handleClose={hideModal}
				title={intl.formatMessage({ id: 'MODAL.UPLOAD_EXCEL.TITLE' })}
			>
				<UploadExcelModalBody
					xlsEmptyDownloadCallback={_infrastructureCostsService.downloadEmptyListXLS}
					xlsUploadCallback={_infrastructureCostsService.uploadXLS}
					onXlsUploaded={fetchAndStore}
					hideModal={hideModal}
				/>
			</UfinetModal>
			<UfinetModal
				size="lg"
				show={newModal}
				handleClose={hideNewModal}
				title={intl.formatMessage({ id: 'MODAL.NEW.REGISTER.TITLE' })}
			>
				<NewInfrastructureModalBody findCosts={fetchAndStore} hideModal={hideNewModal} />
			</UfinetModal>
		</UfinetBox>
	)
}

export { InfrastructurePage }
