import { useQuery } from 'react-query'
import { IContactRepository } from 'ufinet-web-functions'

export const useContactByIdAndClientIdQuery = (
	repository: IContactRepository,
	isEnabled = true,
	contactId: string | undefined,
	clientId: string | undefined
) => {
	return useQuery(
		`contact-${contactId}-${clientId}`,
		async () => {
			if (!contactId || !clientId) return
			const contacts = await repository.findContactsByClientId({ clientIds: [clientId] })
			return contacts.find((c) => c.id === contactId)
		},
		{
			enabled: isEnabled,
		}
	)
}
