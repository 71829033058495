import { FC } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import { AreasPage } from 'pages/loadKmz/areas/AreasPage'
import { CablesAndBoxesPage } from 'pages/loadKmz/cables-boxes/CablesAndBoxesPage'

import { BuildingAccessPage } from 'pages/costs/BuildingAccessPage'
import { EconomicModelPage } from 'pages/costs/EconomicModelPage'
import { EquipmentAndConstructionPage } from 'pages/costs/EquipmentAndConstruction/EquipmentAndConstructionPage'
import { InfrastructurePage } from 'pages/costs/InfrastructurePage'

import { CountryParamsPage } from 'pages/config/countryParams/CountryParamsPage'

import { AsideDefault } from 'components/layout/aside/AsideDefault'
import { AsideMobile } from 'components/layout/aside/AsideMobile'
import { IndividualQuotationPage } from 'pages/quotation/individual/IndividualQuotationPage'
import { BulkQuotationDetailPage } from 'pages/quotation/massive/BulkQuotationDetailPage'
import { BulkQuotationPage } from 'pages/quotation/massive/BulkQuotationPage'
import { PendingQuotationDetailsPage } from 'pages/quotation/pending/PendingQuotationsDetailsPage'
import { PendingQuotationsPage } from 'pages/quotation/pending/PendingQuotationsPage'
import { useIntl } from 'react-intl'
import { AuthRoute, MasterLayout, PATH_DETAIL, WelcomePage } from 'ufinet-web-components'
import { Authority, UserTypesEnum } from 'ufinet-web-functions'

export const PATH_HOME = ''

export const PATH_LOAD_KMZ = 'load_kmz'
export const PATH_CABLE_BOX = 'cables_boxes'
export const PATH_AREAS = 'areas'
export const PATH_LOAD_KMZ_CABLE_BOX = `${PATH_LOAD_KMZ}/${PATH_CABLE_BOX}`
export const PATH_LOAD_KMZ_AREAS = `${PATH_LOAD_KMZ}/${PATH_AREAS}`

export const PATH_COSTS = 'costs'
export const PATH_EQUIPMENT_CONSTRUCTIONS = 'equipment_construction'
export const PATH_BUILDING_ACCESS = 'building_access'
export const PATH_INFRASTRUCTURE = 'infrastructure'
export const PATH_ECONOMIC_MODEL = 'economic_model'

export const PATH_COSTS_BUILDING_ACCESS = `${PATH_COSTS}/${PATH_BUILDING_ACCESS}`
export const PATH_COSTS_EQUIPMENT_CONSTRUCTIONS = `${PATH_COSTS}/${PATH_EQUIPMENT_CONSTRUCTIONS}`
export const PATH_COSTS_INFRASTRUCTURE = `${PATH_COSTS}/${PATH_INFRASTRUCTURE}`
export const PATH_COSTS_ECONOMIC_MODEL = `${PATH_COSTS}/${PATH_ECONOMIC_MODEL}`

export const PATH_QUOTATION = 'quotation'
export const PATH_Q_INDIVIDUAL = 'individual'
export const PATH_Q_PENDING = 'pending'
export const PATH_Q_MASSIVE = 'masive'
export const PATH_QUOTATION_INDIVIDUAL = `${PATH_QUOTATION}/${PATH_Q_INDIVIDUAL}`
export const PATH_QUOTATION_PENDING = `${PATH_QUOTATION}/${PATH_Q_PENDING}`
export const PATH_QUOTATION_PENDING_DETAIL = `${PATH_QUOTATION}/${PATH_Q_PENDING}/${PATH_DETAIL}`
export const PATH_QUOTATION_MASSIVE = `${PATH_QUOTATION}/${PATH_Q_MASSIVE}`
export const PATH_QUOTATION_MASSIVE_DETAIL = `${PATH_QUOTATION}/${PATH_Q_MASSIVE}/${PATH_DETAIL}`

export const PATH_CONFIG = 'config'
export const PATH_COUNTRY_PARAMS = 'country_params'

export const PATH_COSTS_COUNTRY_PARAMS = `${PATH_COSTS}/${PATH_COUNTRY_PARAMS}`

// routerMap: traduccion de cada elemento del la url que se quiere mostrar en el menu de forma individual
// si tenemos un PATH_X="ejemplo/ejemplo2", en nuestro routerMap metemos una clave para ejemplo y otra para ejemplo2
export const routerNameMap = new Map<string, string>([
	[PATH_HOME, 'MENU.HOME'],
	[PATH_LOAD_KMZ, 'MENU.LOAD_KMZ'],
	[PATH_CABLE_BOX, 'MENU.LOAD_KMZ.CABLES&BOXES'],
	[PATH_AREAS, 'MENU.LOAD_KMZ.AREAS'],
	[PATH_COSTS, 'MENU.COSTS'],
	[PATH_EQUIPMENT_CONSTRUCTIONS, 'MENU.COSTS.EQUIPMENT&CONSTRUCTION'],
	[PATH_BUILDING_ACCESS, 'MENU.COSTS.BUILDING_ACCESS'],
	[PATH_INFRASTRUCTURE, 'MENU.COSTS.INFRASTRUCTURE'],
	[PATH_ECONOMIC_MODEL, 'MENU.COSTS.ECONOMIC_MODEL'],
	[PATH_QUOTATION, 'MENU.QUOTATION'],
	[PATH_QUOTATION_INDIVIDUAL, 'MENU.QUOTATION.INDIVIDUAL'],
	[PATH_Q_INDIVIDUAL, 'MENU.QUOTATION.INDIVIDUAL'],
	[PATH_QUOTATION_PENDING, 'MENU.QUOTATION.PENDING'],
	[PATH_Q_PENDING, 'MENU.QUOTATION.PENDING'],
	[PATH_QUOTATION_MASSIVE, 'MENU.QUOTATION.MASSIVE'],
	[PATH_Q_MASSIVE, 'MENU.QUOTATION.MASSIVE'],
	[PATH_DETAIL, 'PATH_DETAIL'],
	[PATH_CONFIG, 'MENU.CONFIG'],
	[PATH_COUNTRY_PARAMS, 'MENU.CONFIG.COUNTRY_PARAMS'],
])

export const routePermissionMap = new Map<string, Authority[]>([
	[PATH_LOAD_KMZ_CABLE_BOX, [Authority.kmzLoadModuleRead]],
	[PATH_LOAD_KMZ_AREAS, [Authority.kmzLoadModuleRead]],

	[PATH_COSTS_BUILDING_ACCESS, [Authority.costsModuleRead]],
	[PATH_COSTS_EQUIPMENT_CONSTRUCTIONS, [Authority.costsModuleRead]],
	[PATH_COSTS_INFRASTRUCTURE, [Authority.costsModuleRead]],
	[PATH_COSTS_ECONOMIC_MODEL, [Authority.costsModuleRead]],

	[PATH_QUOTATION_INDIVIDUAL, [Authority.quotationModuleRead]],
	[PATH_QUOTATION_PENDING, [Authority.aqsPrefeasibilityModuleRead]],
	[PATH_QUOTATION_PENDING_DETAIL, [Authority.aqsPrefeasibilityModuleRead]],
	[PATH_QUOTATION_MASSIVE, [Authority.quotationModuleRead]],
	[PATH_QUOTATION_MASSIVE_DETAIL, [Authority.quotationModuleRead]],

	[PATH_COSTS_COUNTRY_PARAMS, [Authority.aqsConfigurationModuleRead]],
])

export const routerLinkMap = new Map<string, string>([])

const PrivateRoutes: FC = () => {
	const intl = useIntl()
	const location = useLocation()

	return (
		<Routes>
			<Route
				element={
					<MasterLayout
						routerLocation={location}
						asideDefault={<AsideDefault />}
						routerNameMap={routerNameMap}
						asideMobile={<AsideMobile />}
					/>
				}
			>
				<Route path={PATH_HOME} element={<WelcomePage appName={intl.formatMessage({ id: 'AQS.NAME' })} />} />

				<Route
					path={PATH_LOAD_KMZ_CABLE_BOX}
					element={
						<AuthRoute authorizedRoles={routePermissionMap.get(PATH_LOAD_KMZ_CABLE_BOX)!}>
							<CablesAndBoxesPage />
						</AuthRoute>
					}
				/>

				<Route
					path={PATH_LOAD_KMZ_AREAS}
					element={
						<AuthRoute
							authorizedUsers={[UserTypesEnum.INTERNAL_USER]}
							authorizedRoles={routePermissionMap.get(PATH_LOAD_KMZ_AREAS)!}
						>
							<AreasPage />
						</AuthRoute>
					}
				/>

				<Route
					path={PATH_COSTS_BUILDING_ACCESS}
					element={
						<AuthRoute authorizedRoles={routePermissionMap.get(PATH_COSTS_BUILDING_ACCESS)!}>
							<BuildingAccessPage />
						</AuthRoute>
					}
				/>

				<Route path={PATH_COSTS_EQUIPMENT_CONSTRUCTIONS} element={<EquipmentAndConstructionPage />} />

				<Route
					path={PATH_COSTS_INFRASTRUCTURE}
					element={
						<AuthRoute authorizedRoles={routePermissionMap.get(PATH_COSTS_INFRASTRUCTURE)!}>
							<InfrastructurePage />
						</AuthRoute>
					}
				/>

				<Route
					path={PATH_COSTS_ECONOMIC_MODEL}
					element={
						<AuthRoute authorizedRoles={routePermissionMap.get(PATH_COSTS_ECONOMIC_MODEL)!}>
							<EconomicModelPage />
						</AuthRoute>
					}
				/>

				<Route
					path={PATH_QUOTATION_INDIVIDUAL}
					element={
						<AuthRoute authorizedRoles={routePermissionMap.get(PATH_QUOTATION_INDIVIDUAL)!}>
							<IndividualQuotationPage />
						</AuthRoute>
					}
				/>

				<Route
					path={PATH_QUOTATION_PENDING}
					element={
						<AuthRoute
							authorizedRoles={routePermissionMap.get(PATH_QUOTATION_PENDING)!}
							authorizedUsers={[UserTypesEnum.INTERNAL_USER]}
						>
							<PendingQuotationsPage />
						</AuthRoute>
					}
				/>

				<Route
					path={`${PATH_QUOTATION_PENDING_DETAIL}/:quotationId`}
					element={
						<AuthRoute
							authorizedRoles={routePermissionMap.get(PATH_QUOTATION_PENDING)!}
							authorizedUsers={[UserTypesEnum.INTERNAL_USER]}
						>
							<PendingQuotationDetailsPage />
						</AuthRoute>
					}
				/>

				<Route
					path={PATH_QUOTATION_MASSIVE}
					element={
						<AuthRoute authorizedRoles={routePermissionMap.get(PATH_QUOTATION_MASSIVE)!}>
							<BulkQuotationPage />
						</AuthRoute>
					}
				/>

				<Route
					path={`${PATH_QUOTATION_MASSIVE_DETAIL}/:massiveRecordId`}
					element={
						<AuthRoute authorizedRoles={routePermissionMap.get(PATH_QUOTATION_MASSIVE_DETAIL)!}>
							<BulkQuotationDetailPage />
						</AuthRoute>
					}
				/>

				<Route
					path={PATH_COSTS_COUNTRY_PARAMS}
					element={
						<AuthRoute authorizedRoles={routePermissionMap.get(PATH_COSTS_COUNTRY_PARAMS)!}>
							<CountryParamsPage />
						</AuthRoute>
					}
				/>

				{/* Default route path */}
				<Route path="*" element={<Navigate to={PATH_HOME} replace />} />
			</Route>
		</Routes>
	)
}

export { PrivateRoutes }
