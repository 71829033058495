/* eslint-disable handle-callback-err */
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'

import { useFormik } from 'formik'

import { clsx } from 'clsx'
import { ProductSelect } from 'components/common/selects/ProductSelect'

import { QuotationsContants } from 'constants/quotations'
import { FormField, FormFieldType } from 'modules/common/domain/model/FormFields'
import { MapPickerMode } from 'modules/google-maps/controls/picker/MapPickerMode'
import { mapPickerDefaults } from 'modules/google-maps/controls/picker/mapPickerDefaults'
import { Redundancie, Technology, TransmissionMean } from 'modules/product/capacity/domain'
import { useTechnologiesQuery } from 'modules/product/queries/CapacityQueries'
import { useFiberNumberQuery } from 'modules/product/queries/DarkFiberQueries'
import { HttpCapacityRepository } from 'modules/product/repository/HttpCapacityRepository'
import { HttpCollocationRepository } from 'modules/product/repository/HttpCollocationRepository'
import { HttpDarkFiberRepository } from 'modules/product/repository/HttpDarkFiberRepository'
import { HttpInternetRepository } from 'modules/product/repository/HttpInternetRepository'
import { HttpToweringRepository } from 'modules/product/repository/HttpToweringRepository'
import { useGetPermission } from 'modules/security/useGetPermission'
import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import {
	BandwidthSelect,
	BandwidthSelectHandle,
	IUfinetSelectOption,
	UfinetActionButton,
	UfinetButton,
	UfinetInput,
	UfinetSelect,
	emptyUfinetSelectOption,
} from 'ufinet-web-components'
import {
	AqsModules,
	AuthContext,
	ICalculationPoint,
	ILocalService,
	ProductId,
	TransportLayerSelectOptions,
	initialServiceIndividual,
	isValidCoordinate,
	localServiceCoordinates,
	onFormikChanges,
	onFormikNumberChanges,
	serviceProductFromStateService,
	useTranslator,
} from 'ufinet-web-functions'
import * as Yup from 'yup'
import { ObjectShape } from 'yup/lib/object'
import { FormBuilder } from './FormBuilder'
import { CoordinatesMapSelector } from './coordinates/CoordinatesMapSelector'
import { CoordinatesSelector } from './coordinates/CoordinatesSelector'
import {
	CoordinatesSelectorContextProvider,
	useCoordinatesSelectorContext,
	useCoordinatesSelectorMapActions,
	useCoordinatesSelectorMapState,
} from './coordinates/CoordinatesSelectorContext'
import { CapacityServiceForm } from './services/CapacityServiceForm'
import { CollocationServiceForm } from './services/CollocationServiceForm'
import { DarkFiberServiceForm } from './services/DarkFiberServiceForm'
import { InternetServiceForm } from './services/InternetServiceForm'
import { ToweringServiceForm } from './services/ToweringServiceForm'

type NewServiceFormProps = {
	country: { value: string; label: string }
	corporateGroupId: string
	clientId: string
	setNewService: (filterState: ILocalService, closeModal: boolean) => void
}

const mkTest = (test: (value: any) => boolean, message: string, allowNullish = false): Yup.TestConfig => ({
	test: (value) => (allowNullish ? !value || test(value) : test(value)),
	message,
})

const NewServiceFormInternal: FC<NewServiceFormProps> = ({
	country: { label: countryName, value: countryId },
	corporateGroupId,
	clientId,
	setNewService,
}) => {
	const authData = useContext(AuthContext)
	const translate = useTranslator()

	const {
		destinationInputMethod,
		originInputMethod,
		originAddressInputRef,
		destinationAddressInputRef,
		popOriginRef,
		popDestinationRef,
		originAddressAutocompleteWidget,
		destinationAddressAutocompleteWidget,
		hanldePlaceChangeListeners,
		setDestinationInputMethod,
		setOriginInputMethod,
		fetchAccessAreas,
		clearOriginLocation,
	} = useCoordinatesSelectorContext()

	const { handleUserMapPress, handleMapFoldUnfold, resetMap } = useCoordinatesSelectorMapActions()
	const { mapWrapperRef, mapPickerMode, userMapPress } = useCoordinatesSelectorMapState()

	const bandwidthRef = useRef<BandwidthSelectHandle>(null)
	const onChange = useCallback(onFormikChanges, [])

	const capacityRepository = useMemo(() => HttpCapacityRepository(authData), [authData])

	const internetRepository = useMemo(() => HttpInternetRepository(authData), [authData])

	const darkFiberRepository = useMemo(() => HttpDarkFiberRepository(authData), [authData])

	const collocationRepository = useMemo(() => HttpCollocationRepository(authData), [authData])

	const toweringRepository = useMemo(() => HttpToweringRepository(authData), [authData])

	const formRef = useRef<HTMLFormElement | null>(null)

	const [hasNoOriginService, setHasNoOriginService] = useState(true)
	const [currentService, setCurrentService] = useState<ProductId | undefined>()
	const [closeModal, setCloseModal] = useState(false)

	const latitudeTest = mkTest((lat: number) => lat >= -90 && lat <= 90, translate('SERVICE.COORD.LATITUDE.ERROR'), true)
	const longitudeTest = mkTest(
		(lng: number) => lng >= -180 && lng <= 180,
		translate('SERVICE.COORD.LONGITUDE.ERROR'),
		true
	)

	const isThisCurrentService = (service: ProductId) => currentService === service
	const canWritePrefeasibility = useGetPermission(AqsModules.PREFEASIBILITY, 'canWrite')

	const defaultSchemaValidator = (isRequired: boolean) =>
		isRequired ? Yup.object().nullable().required(translate('ERROR.REQUIRED')) : Yup.object().nullable()

	const defaultSelectSchemaValidator = (isRequired: boolean) =>
		isRequired
			? Yup.object()
					.nullable()
					.shape({
						label: Yup.string().nullable().required(translate('ERROR.REQUIRED')),
					})
					.required(translate('ERROR.REQUIRED'))
			: Yup.object().nullable().shape({
					label: Yup.string().nullable(),
			  })

	const dataFormSchemaType = (currentService?: ProductId): ObjectShape => {
		switch (currentService) {
			case ProductId.FIBRA_OSCURA_ID:
				return {
					darkFiberService: Yup.object()
						.shape({
							fiberNumberSelect: defaultSelectSchemaValidator(canWritePrefeasibility),
							sectionLengthInput: Yup.number()
								.required(translate('ERROR.REQUIRED'))
								.positive(translate('ERROR.POSITIVE'))
								.integer(),
						})
						.required(translate('ERROR.REQUIRED')),
					originLatitude: Yup.string().required(translate('ERROR.REQUIRED')),
					originLongitude: Yup.string().required(translate('ERROR.REQUIRED')),
				}
			case ProductId.COLOCATION_ID:
				return {
					collocationService: Yup.object()
						.shape({
							emplacementTypeSelect: defaultSelectSchemaValidator(true),
							collocationUnitSelect: defaultSelectSchemaValidator(true),
							collocationQuantitySelect: defaultSelectSchemaValidator(true),
						})
						.required(translate('ERROR.REQUIRED')),
				}
			case ProductId.TOWERING_ID:
				return {
					towering: Yup.object()
						.shape({
							structureTypeSelect: defaultSelectSchemaValidator(true),
							colocationPositionSelect: defaultSelectSchemaValidator(true),
							structureHeightSelect: defaultSelectSchemaValidator(true),
						})
						.required(translate('ERROR.REQUIRED')),
				}
			case ProductId.INTERNET:
				return {
					accessSelect: defaultSelectSchemaValidator(canWritePrefeasibility),
					oversubscriptionSelect: defaultSelectSchemaValidator(canWritePrefeasibility),
					transmissionSelect: defaultSelectSchemaValidator(canWritePrefeasibility),
					redundancySelect: defaultSelectSchemaValidator(canWritePrefeasibility),
					bandwidthSelect: defaultSelectSchemaValidator(true),
				}
			case ProductId.ELINE:
				return {
					standardSelect: defaultSelectSchemaValidator(canWritePrefeasibility),
					subStandardSelect: defaultSelectSchemaValidator(canWritePrefeasibility),
					transmissionSelect: defaultSelectSchemaValidator(canWritePrefeasibility),
					redundancySelect: defaultSelectSchemaValidator(canWritePrefeasibility),
					transportLayerSelect: defaultSelectSchemaValidator(canWritePrefeasibility),
					technologySelect: defaultSchemaValidator(canWritePrefeasibility),
					bandwidthSelect: defaultSelectSchemaValidator(true),
					originLatitude: Yup.string().required(translate('ERROR.REQUIRED')).test(latitudeTest),
					originLongitude: Yup.string().required(translate('ERROR.REQUIRED')).test(longitudeTest),
				}
			default:
				return {}
		}
	}

	const dataFormSchema = Yup.object().shape({
		serviceTypeSelect: Yup.object().shape({
			label: Yup.string().required(translate('ERROR.REQUIRED')),
		}),
		destinationLatitude: Yup.string().required(translate('ERROR.REQUIRED')).test(latitudeTest),
		destinationLongitude: Yup.string().required(translate('ERROR.REQUIRED')).test(longitudeTest),
		deadline: Yup.number()
			.required(translate('ERROR.REQUIRED'))
			.positive(translate('ERROR.POSITIVE'))
			.integer(translate('ERROR.INTEGER'))
			.max(QuotationsContants.MAX_PERIOD, translate('ERROR.MAX.VALUE', { maxValue: QuotationsContants.MAX_PERIOD })),
		...dataFormSchemaType(currentService),
	})

	const sendData = async (values: ILocalService) => {
		setNewService({ ...values, product: serviceProductFromStateService(values) }, closeModal)
		await resetForm()
	}

	const formik = useFormik<ILocalService>({
		initialValues: {
			...initialServiceIndividual,
			deadline: 1,
		},
		validationSchema: dataFormSchema,
		onSubmit: sendData,
		validateOnChange: false,
		validateOnBlur: false,
		enableReinitialize: true,
	})

	const getServiceFormComponentByCurrentService = (currentService?: ProductId) => {
		switch (currentService) {
			case ProductId.ELINE:
				return <CapacityServiceForm formik={formik} capacityRepository={capacityRepository} />
			case ProductId.INTERNET:
				return (
					<InternetServiceForm
						formik={formik}
						capacityRepository={capacityRepository}
						internetRepository={internetRepository}
					/>
				)
			case ProductId.FIBRA_OSCURA_ID:
				return <DarkFiberServiceForm formik={formik} />
			case ProductId.COLOCATION_ID:
				return (
					<CollocationServiceForm
						formik={formik}
						collocationRepository={collocationRepository}
						corporateGroupId={corporateGroupId}
					/>
				)
			case ProductId.TOWERING_ID:
				return (
					<ToweringServiceForm
						formik={formik}
						toweringRepository={toweringRepository}
						corporateGroupId={corporateGroupId}
					/>
				)
			default:
				return <></>
		}
	}

	useEffect(() => {
		return hanldePlaceChangeListeners(formik, fillBandwidths)
	}, [originAddressAutocompleteWidget, destinationAddressAutocompleteWidget, formik.values])

	// Control map fold-unfold
	useEffect(() => {
		handleMapFoldUnfold(formik)
	}, [
		originInputMethod,
		destinationInputMethod,
		mapPickerMode,
		formik.values.originLatitude,
		formik.values.originLongitude,
		formik.values.destinationLatitude,
		formik.values.destinationLongitude,
	])

	const shouldBandwidthCapacitySelectBeDisabled = () =>
		isThisCurrentService(ProductId.ELINE) && !formik.values.technologySelect?.value

	const resetForm = async () => {
		formik.resetForm()
		onChange(formik, 'originPopSelect')(emptyUfinetSelectOption)
		onChange(formik, 'destinationPopSelect')(emptyUfinetSelectOption)
		resetMap()
		setOriginInputMethod(null)
		setDestinationInputMethod(null)
		setHasNoOriginService(false)
		setCloseModal(false)
	}

	const fillBandwidths = useCallback(
		(service: Partial<ILocalService> = formik.values) => {
			const { origin, destination } = localServiceCoordinates(service)
			if (!destination || shouldBandwidthCapacitySelectBeDisabled()) return
			bandwidthRef.current?.fillBandwidthSelect({
				countryId,
				corporateGroupId,
				clientId,
				productId: service.serviceTypeSelect?.value ?? '',
				technologyId:
					service.serviceTypeSelect?.value === ProductId.INTERNET
						? Technology.Ethernet
						: service.technologySelect?.value || '',
				origin,
				destination,
				accessId: service.accessSelect?.value || '',
			})
		},
		[clientId, corporateGroupId, countryId, formik.values]
	)

	const onProductChange = async (productSelected?: IUfinetSelectOption) => {
		if (productSelected?.label === formik.values.serviceTypeSelect?.label) return
		await resetForm()
		formik.setFieldValue('serviceTypeSelect', productSelected)
		formik.initialValues = {
			...formik.initialValues,
			technologySelect: isThisCurrentService(ProductId.ELINE)
				? { label: translate('SERVICE.CAPACITY.TECHNOLOGY.ETHERNET'), value: Technology.Ethernet }
				: emptyUfinetSelectOption,
			redundancySelect: isThisCurrentService(ProductId.ELINE)
				? { label: translate('SERVICE.CAPACITY.REDUNDANCIE.LINEAL'), value: Redundancie.Lineal }
				: emptyUfinetSelectOption,
			transmissionSelect: isThisCurrentService(ProductId.ELINE)
				? { label: translate('SERVICE.CAPACITY.TRANSMISSION.FIBER'), value: TransmissionMean.OpticalFiber }
				: emptyUfinetSelectOption,
		}

		popOriginRef.current?.fillPopSelect?.({
			countryId,
			corporateGroupId,
		})
		popDestinationRef.current?.fillPopSelect?.({
			countryId,
			corporateGroupId,
		})
		setCurrentService(productSelected?.value as ProductId)
		const isSubtypeCapacity = true // TODO: VSAT product subtype
		const hasNoOrigin =
			productSelected !== null &&
			!(
				productSelected?.value === ProductId.ELINE ||
				productSelected?.value === ProductId.FIBRA_OSCURA_ID ||
				productSelected?.value === ProductId.CROSS_CONNECTION_ID ||
				(productSelected?.value === ProductId.VSAT_ID && isSubtypeCapacity)
			)
		setHasNoOriginService(hasNoOrigin)
		if (hasNoOrigin) {
			clearOriginLocation(formik)
			onChange(formik, 'transportLayerSelect')(null)
		}

		onChange(formik, 'bandwidthSelect')(null)
		bandwidthRef.current?.emptyBandwidthSelect()
	}

	const shouldFiberNumberSelectBeFetched = () => {
		const { originAddress, destinationAddress } = formik.values

		return Boolean(isThisCurrentService(ProductId.FIBRA_OSCURA_ID) && originAddress && destinationAddress)
	}

	const { data: fiberNumberSelectOption, isLoading: isLoadingFiberNumber } = useFiberNumberQuery(
		shouldFiberNumberSelectBeFetched(),
		darkFiberRepository,
		{
			corporateGroupId: corporateGroupId,
			...(localServiceCoordinates(formik.values) as { origin?: ICalculationPoint; destination?: ICalculationPoint }),
		}
	)

	const { data: technologiesSelectOption, isLoading: isLoadingTechnologies } = useTechnologiesQuery(
		isThisCurrentService(ProductId.ELINE),
		capacityRepository
	)

	const sendAndHide = () => {
		formik
			.validateForm()
			.then((errors) => {
				if (errors && Object.keys(errors).length === 0 && Object.getPrototypeOf(errors) === Object.prototype) {
					setCloseModal(true)
					formik.handleSubmit()
				}
				return errors
			})
			.catch(console.error)
	}

	useEffect(() => {
		formik.setFieldValue('bandwidthSelect', emptyUfinetSelectOption)
		fillBandwidths({
			...formik.values,
		})
	}, [formik.values.technologySelect, formik.values.accessSelect])

	useEffect(() => {
		// Scroll to the form or the map to help the user
		if (mapPickerMode === MapPickerMode.NONE) formRef.current?.scrollIntoView({ behavior: 'smooth' })
		else mapWrapperRef.current?.scrollIntoView()
	}, [mapPickerMode])

	useEffect(() => {
		handleUserMapPress(formik, fillBandwidths)
	}, [userMapPress])

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault()
		// Prevent submiting when choosing from google maps options with keys
		if (
			document.activeElement !== originAddressInputRef.current &&
			document.activeElement !== destinationAddressInputRef.current
		)
			formik.handleSubmit(e)
	}

	// AREAS AND MARKERS
	useEffect(() => {
		fetchAccessAreas(countryId)
	}, [fetchAccessAreas, countryId])

	const fiberNumberSelectFormFields: FormField = {
		text: 'FIBER.NUMBER',
		className: 'col-4 col-sm-6 col-md-4',
		formActions: 'darkFiberService.fiberNumberSelect',
		inputType: FormFieldType.SELECT,
		isLoading: isLoadingFiberNumber,
		options: fiberNumberSelectOption,
		isDisabled: !shouldFiberNumberSelectBeFetched(),
	}

	return (
		<form ref={formRef} onSubmit={onSubmit} className="d-flex flex-column justify-content-center p-0 m-0">
			<div className="row">
				<div className={clsx('col-12 col-xl-7', 'd-flex flex-column justify-content-between')}>
					<div className="row">
						<div
							className="row"
							style={mapPickerMode !== MapPickerMode.NONE ? mapPickerDefaults.mapPickerBackgroundStyle : undefined}
						>
							<ProductSelect
								className="col-12 col-sm-6 col-md-5"
								onChange={(newProduct) => onProductChange(newProduct as IUfinetSelectOption | undefined)}
								value={formik.values.serviceTypeSelect}
								error={formik.errors.serviceTypeSelect?.label}
							/>
						</div>
						{isThisCurrentService(ProductId.ELINE) && (
							<UfinetSelect
								requiredIcon
								className={`col-12 col-sm-6 col-md-5 mt-4 ${!canWritePrefeasibility ? 'd-none' : ''}`}
								labelTitle={translate('SERVICE.TECHNOLOGY')}
								tooltipTitle={translate(`SERVICE.TECHNOLOGY.TOOLTIP`)}
								defaultValue={{ label: translate('SERVICE.CAPACITY.TECHNOLOGY.ETHERNET'), value: Technology.Ethernet }}
								isClearable
								isLoadingOptions={isLoadingTechnologies}
								isDisabled={!canWritePrefeasibility}
								error={formik.errors.technologySelect}
								onChange={onChange(formik, 'technologySelect')}
								value={formik.values.technologySelect?.label && formik.values.technologySelect}
								options={technologiesSelectOption}
								placeholder={translate('SERVICE.TECHNOLOGY')}
							/>
						)}
						<CoordinatesSelector
							hasOrigin={!hasNoOriginService}
							formik={formik}
							fillBandwidths={fillBandwidths}
							formRef={formRef}
						/>
						<div
							className="row pt-4 col-11"
							style={mapPickerMode !== MapPickerMode.NONE ? mapPickerDefaults.mapPickerBackgroundStyle : undefined}
						>
							{isThisCurrentService(ProductId.FIBRA_OSCURA_ID) && (
								<FormBuilder
									key="fiberNumberSelect"
									formField={fiberNumberSelectFormFields}
									translate={translate}
									formik={formik}
									onChange={onChange}
								/>
							)}
							{(isThisCurrentService(ProductId.ELINE) || isThisCurrentService(ProductId.INTERNET)) && (
								<BandwidthSelect
									requiredIcon
									ref={bandwidthRef}
									className="col-4 col-sm-6 col-md-4"
									value={formik.values.bandwidthSelect}
									menuPosition="fixed"
									error={formik.errors.bandwidthSelect?.label}
									onChange={onChange(formik, 'bandwidthSelect')}
									isDisabled={
										!formik.values.serviceTypeSelect?.value ||
										shouldBandwidthCapacitySelectBeDisabled() ||
										!isValidCoordinate({
											lat: formik.values.destinationLatitude,
											lng: formik.values.destinationLongitude,
										})
									}
								/>
							)}
							<UfinetInput
								requiredIcon
								className="col-4 col-sm-6 col-md-4"
								labelTitle={translate('SERVICE.DEADLINE')}
								tooltipTitle={translate('SERVICE.DEADLINE.TOOLTIP')}
								error={formik.errors.deadline}
								type="decimal"
								solid={false}
								onChange={onFormikNumberChanges(formik, 'deadline')}
								value={formik.values.deadline ?? 1}
							/>
							{isThisCurrentService(ProductId.ELINE) && (
								<UfinetSelect
									requiredIcon
									className="col-4 col-sm-6 col-md-4"
									labelTitle={translate('SERVICE.LAYER')}
									tooltipTitle={translate('SERVICE.LAYER.TOOLTIP')}
									isClearable
									error={formik.errors.transportLayerSelect?.label}
									onChange={(v) => formik.setFieldValue('transportLayerSelect', v)}
									value={
										(formik.values.transportLayerSelect as IUfinetSelectOption)?.label &&
										formik.values.transportLayerSelect
									}
									options={TransportLayerSelectOptions}
									placeholder={translate('SERVICE.LAYER')}
								/>
							)}
						</div>

						<div className={!canWritePrefeasibility ? 'd-none' : ''}>
							{getServiceFormComponentByCurrentService(currentService)}
						</div>
					</div>

					<div
						className="row"
						style={mapPickerMode !== MapPickerMode.NONE ? mapPickerDefaults.mapPickerBackgroundStyle : undefined}
					>
						<UfinetButton
							className="mt-5 ms-3 p-5 w-auto"
							content={translate('SERVICE.ADD.CLOSE')}
							icon={faPlus}
							onClick={sendAndHide}
							isDisabled={mapPickerMode !== MapPickerMode.NONE}
						/>
						<UfinetActionButton
							className="mt-5 ms-3 p-5 w-auto"
							content={translate('SERVICE.ADD.CONTINUE')}
							icon={faPlus}
							isDisabled={mapPickerMode !== MapPickerMode.NONE}
						/>
						<UfinetButton
							secondaryButton
							className="mt-5 ms-3 p-5 w-auto"
							content={translate('CLEAR')}
							icon={faTrash}
							onClick={resetForm}
							isDisabled={mapPickerMode !== MapPickerMode.NONE}
						/>
					</div>
				</div>
				<CoordinatesMapSelector formik={formik} countryName={countryName} />
			</div>
		</form>
	)
}

const NewServiceForm: FC<NewServiceFormProps> = (props) => {
	return (
		<CoordinatesSelectorContextProvider>
			<NewServiceFormInternal {...props} />
		</CoordinatesSelectorContextProvider>
	)
}

export { NewServiceForm }
