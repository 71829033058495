import { FC, useCallback, useContext, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import Swal from 'sweetalert2'

import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons/faMapLocationDot'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { deepEqual } from 'fast-equals'
import { colorConstants, MultipleDeleter, Table, UfinetButton, UfinetModal } from 'ufinet-web-components'
import {
	areaService,
	AuthContext,
	Authority,
	IAreaModal,
	IAreaResponse,
	useInternalUser,
	useModal,
	useTranslator,
} from 'ufinet-web-functions'
import { AddAreaModalBody } from '../modals/addModal/AddAreaModalBody'
import { ModAreaModalBody } from '../modals/modModal/ModAreaModalBody'
import { colsArea } from './AreasColData'

type Props = {
	areas: IAreaResponse[] | undefined
	searchAreas: Function
	loadingRecords?: boolean
}

const AreasTable: FC<Props> = ({ searchAreas, areas, loadingRecords = false }: Props) => {
	const intl = useIntl()
	const translate = useTranslator()

	const [modalAdd, showAdd, hideAdd] = useModal()
	const [modalEditMap, showEditMap, hideEditMap] = useModal()
	const [dataModal, setDataModal] = useState<IAreaModal>()
	const [selectedValues, setSelectedValues] = useState<IAreaResponse[]>()

	const internalUser = useInternalUser()
	const authData = useContext(AuthContext)
	const roles = authData.userData?.authorities || []
	const permissions = Authority.getKmzLoadPermissions(roles)

	const _areaService = useMemo(() => areaService(authData, intl), [authData, intl])
	const cols = useMemo(() => colsArea(internalUser, translate), [internalUser, translate])

	const [selectedArea, setSetselectedArea] = useState<IAreaResponse>()

	const getHeaderButtons = () => (
		<>
			{permissions.canDelete && (
				<MultipleDeleter
					setSelectedValues={setSelectedValues}
					selectedValues={selectedValues}
					deleteEndpoint={_areaService.deleteByIdsList}
					search={searchAreas}
				/>
			)}
			{permissions.canWrite && (
				<UfinetButton className="me-3" content={translate('ADD.KMZ')} onClick={onTableAddAction} icon={faPlus} />
			)}
		</>
	)

	const onTableEditMapAction = (data: IAreaModal) => {
		showEditMap()
		setDataModal(data)
	}

	const onTableAddAction = useCallback(() => showAdd(), [showAdd])

	const actionBodyTemplate = (rowData: IAreaModal) => (
		<FontAwesomeIcon
			icon={faMapLocationDot}
			className="p-3 rounded fa-icon-color-blue"
			onClick={() => onTableEditMapAction(rowData)}
		/>
	)

	const onRowEditComplete = (data: IAreaResponse) => {
		if (selectedArea && deepEqual(data, selectedArea)) return
		Swal.fire({
			icon: 'warning',
			title: intl.formatMessage(
				{ id: 'COUNTRY.REGISTRY.UPDATE.TYPE' },
				{ countryName: data.countryName, infrastructureType: data.typeName }
			),
			html: intl.formatMessage({ id: 'COUNTRY.AREA.UPDATE.CONFIRM' }, { value: data.name }),
			showCancelButton: true,
			showConfirmButton: true,
			reverseButtons: true,
			confirmButtonColor: colorConstants.primaryColor,
			cancelButtonColor: colorConstants.dangerColor,
			preConfirm: () => {
				const { id, name, networkAccessAreaStatus, countryName, visible } = data
				_areaService
					.updateArea(id, { name, networkAccessAreaStatus, countryName, visible })
					.then(() => searchAreas())
					.catch(console.error)
			},
		})
	}

	const onSelectionChange = (values: IAreaResponse[]) => setSelectedValues(values)

	return (
		<>
			<Table
				className="fs-6"
				cols={cols}
				selectedValues={selectedValues}
				onSelectionChange={permissions.canDelete && internalUser ? onSelectionChange : undefined}
				onRowEditInit={(e) => setSetselectedArea(e?.data)}
				onRowEditComplete={permissions.canUpdate && internalUser ? onRowEditComplete : undefined}
				content={areas}
				headerButtons={getHeaderButtons()}
				actionBodyTemplate={actionBodyTemplate}
				emptyMessage={translate(loadingRecords ? 'LOADING_DOTS' : 'EMPTY.MESSAGE')}
			/>
			<UfinetModal
				size="xxl"
				show={modalEditMap}
				handleClose={hideEditMap}
				title={intl.formatMessage({ id: 'MODAL.AREAS.EDIT.TITTLE' })}
			>
				{dataModal !== undefined && <ModAreaModalBody data={dataModal} handleClose={hideEditMap} />}
			</UfinetModal>
			<UfinetModal
				size="xxl"
				show={modalAdd}
				handleClose={hideAdd}
				title={intl.formatMessage({ id: 'MODAL.AREAS.ADD.TITTLE' })}
			>
				<AddAreaModalBody hideModal={hideAdd} />
			</UfinetModal>
		</>
	)
}

export { AreasTable }
