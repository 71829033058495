import { PrefeasibilityRepository } from 'modules/prefeasibility/domain/repository/PrefeasibilityRepository'
import { TechnicalGroupRepository } from 'modules/prefeasibility/domain/repository/TechnicalGroupRepository'
import { useNavigate } from 'react-router-dom'
import { PATH_QUOTATION_PENDING } from 'routing/protected/PrivateRoutes'
import { UfinetModal } from 'ufinet-web-components'
import { useTranslator } from 'ufinet-web-functions'
import { PrefeasibilityForm } from './PrefeasibilityForm'
import { usePrefeasibilityModal } from './hooks/PrefeasibilityModalHook'

type PrefeasibilityModalProps = {
	aqsId: string
	contactId?: string
	reference?: string
	finalClient?: string
	countryId: string
	openModalCallback: (callback: () => void) => void
	confirmPrefeasibility: () => void
	prefeasibilityRepository: PrefeasibilityRepository
	technicalGroupRepository: TechnicalGroupRepository
}

const PrefeasibilityModal = ({
	aqsId,
	contactId,
	reference,
	finalClient,
	countryId,
	openModalCallback,
	confirmPrefeasibility,
	prefeasibilityRepository,
	technicalGroupRepository,
}: PrefeasibilityModalProps) => {
	const { isPrefeasibilityModalOpen, hidePrefeasibilityModal } = usePrefeasibilityModal(openModalCallback)

	const translate = useTranslator()
	const navigate = useNavigate()

	return (
		<UfinetModal
			size="xl"
			show={isPrefeasibilityModalOpen}
			handleClose={hidePrefeasibilityModal}
			title={translate('QUOTATION.REQUEST.PREFEASIBILITY')}
			preventCloseOnEscapeKey
			stickyHeader
		>
			<PrefeasibilityForm
				aqsId={aqsId}
				contactId={contactId}
				reference={reference}
				finalClient={finalClient}
				countryId={countryId}
				onFormClosed={hidePrefeasibilityModal}
				confirmPrefeasibility={() => {
					confirmPrefeasibility()
					navigate(`/${PATH_QUOTATION_PENDING}`)
				}}
				prefeasibilityRepository={prefeasibilityRepository}
				technicalGroupRepository={technicalGroupRepository}
			/>
		</UfinetModal>
	)
}

export { PrefeasibilityModal }
