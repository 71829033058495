import { ColData, YesNoSelect } from 'ufinet-web-components'
import { IAreaResponse, TranslatorFunction } from 'ufinet-web-functions'
import { AccessAreaStatusBodyComponent } from './AccessAreaStatusBodyComponent'
import { AccessAreaStatusComponent } from './AccessAreaStatusComponent'

export const colsArea: (internalUser: boolean, translate: TranslatorFunction) => ColData[] = (
	internalUser,
	translate
) => {
	return [
		{ field: 'countryName', header: 'PREVIEW.COUNTRY', width: '290px' },
		{ field: 'name', header: 'AREAS.AREA', width: '290px', editable: true },
		{ field: 'typeName', header: 'AREAS.AREA_TYPE', width: '290px' },
		{
			hidden: !internalUser,
			field: 'visible',
			header: 'ACCESS.VISIBLE',
			width: '150px',
			contentDisplayFormat: 'center',
			editable: true,
			filterOptions: [
				{ label: translate('NO'), value: false },
				{ label: translate('YES'), value: true },
			],
			body: (rowData: IAreaResponse) => translate(rowData.visible ? 'YES' : 'NO'),
			customEditableField: (col: ColData, options: any) => {
				const networkArea: IAreaResponse = options.rowData
				return (
					<YesNoSelect
						value={networkArea.visible}
						onChange={options.editorCallback}
						placeholder={translate('AREAS.VISIBLE')}
					/>
				)
			},
		},
		{
			field: 'networkAccessAreaStatus',
			header: 'AREAS.ACCESS_AREA_STATUS',
			editable: true,
			width: '200px',
			contentDisplayFormat: 'center',
			body: (rowData: IAreaResponse) => <AccessAreaStatusBodyComponent selected={rowData.networkAccessAreaStatus} />,
			customEditableField: (col: ColData, options: any) => {
				const area = options.rowData as IAreaResponse
				return !internalUser ? (
					<AccessAreaStatusBodyComponent selected={area.networkAccessAreaStatus} />
				) : (
					<AccessAreaStatusComponent selected={area.networkAccessAreaStatus} onChange={options.editorCallback} />
				)
			},
		},
		{ field: 'lastModifiedBy', header: 'AREAS.USER_MOD', width: '290px' },
		{ field: 'lastModifiedAt', header: 'AREAS.DATE_MOD', width: '290px', nonFilterable: true },
	].filter((it) => !it.hidden) as ColData[]
}
